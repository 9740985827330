export const defaultCenter = {
    lat: 14.987240,
    lng: 100.347140
}

export const mapSizeStyles = {
    height: "calc(100vh - 80px)",
    width: "100%"
};

export const mapSizeStylesMobile = {
    height: "calc(100svh - 130px)",
    width: "100%"
};

export const mapMiniSizeStyles = {
    height: "326.25px",
    width: "100%",
    borderRadius: "8px"
};

export const mapMiniSizePinStyles = {
    height: "393px",
    width: "100%",
    borderRadius: "8px"
};

export const mapModalSizeStyles = {
    height: "686px",
    width: "100%"
    // width: "1184px"
};

export const mapMobileModalSizeStyles = {
    height: "calc(100svh - 210px)",
    width: "100%"
    // width: "1184px"
};

export const mapPinSizeStyles = {
    height: "393px",
    width: "100%"
};

export const mapStyles = [
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#d3d3d3"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "color": "#808080"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#b3b3b3"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "weight": 1.8
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d7d7d7"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ebebeb"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#a7a7a7"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#efefef"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#696969"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#737373"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d6d6d6"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {},
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#dadada"
            }
        ]
    }
]

export const mapPolygonStyles = {
    strokeWeight: 1,
    strokeColor: "#DD1508",
    fillColor: "#f8a63e",
    fillOpacity: 0.48
}

export const mapCircleStyles = {
    strokeWeight: 1,
    strokeColor: "#DD1508",
    fillColor: "#f8a63e",
    fillOpacity: 0.48
}

export const MapTypeId = {
    "ROADMAP": "roadmap",
    "SATELLITE": "satellite",
    "HYBRID": "hybrid",
    "TERRAIN": "terrain"
}