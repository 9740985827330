
import { Breadcrumb, Button, Card, Carousel, Col, Divider, Dropdown, Form, Image, Input, InputNumber, Menu, Modal, notification, Row, Select, Space } from 'antd';
import { imgfallback, showPrice } from '../../utils/common';
import styles from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as StarDuotone} from '../../assets/icons/components/card-item/star_duotone.svg';
import { ReactComponent as Ico3Dots} from '../../assets/icons/components/card-item/3_dots.svg';
import { ReactComponent as ShoppingBasket} from '../../assets/icons/components/card-item/1-shopping-basket.svg';
import { ReactComponent as Bathroom} from '../../assets/icons/components/card-item/2-bathroom.svg';
import { ReactComponent as LandSizeOrLot} from '../../assets/icons/components/card-item/3-land-size-or-lot.svg';
import { ReactComponent as Coin} from '../../assets/icons/components/card-item/4-coin.svg';
import { ReactComponent as Chat} from '../../assets/icons/components/card-item/5-chat.svg';
import { ReactComponent as Share} from '../../assets/icons/components/card-item/6-share.svg';
import { ReactComponent as BuildingSize} from '../../assets/icons/components/card-item/7-building-size.svg';
import { ReactComponent as Garage} from '../../assets/icons/components/card-item/8-garage.svg';
import { ReactComponent as F1View} from '../../assets/icons/components/card-item/f-1-view.svg';
import { ReactComponent as F2Mouse} from '../../assets/icons/components/card-item/f-2-mouse.svg';
import { ReactComponent as PinIcoLocationHome} from '../../assets/icons/components/card-item/pin-ico-location-home.svg';
import { ReactComponent as PinIcoTargetLight} from '../../assets/icons/components/card-item/pin-ico-target-light.svg';
import { ReactComponent as ImgBoxLight } from '../../assets/icons/pages/findProertyDetail/Img_box_light.svg';
import { ReactComponent as Icons8Facebook } from '../../assets/icons/pages/findProertyDetail/icons8-facebook.svg';
import { ReactComponent as Icons8Instagram } from '../../assets/icons/pages/findProertyDetail/icons8-instagram.svg';
import { ReactComponent as Icons8Twitter } from '../../assets/icons/pages/findProertyDetail/icons8-twitter.svg';
import { RoutePath } from '../../Routes';
import MapContainer from '../GMap';
import { useEffect, useRef, useState } from 'react';
import ModalShareComponent from '../ModalShare';
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import ImgsViewer from 'react-images-viewer';
import { useModalContext } from '../../context/modals';
import firebaseService from '../../services/firebase.service';
import { fetchUserProfile } from '../../services/service.auth';
import { useSelector } from 'react-redux';
import { salesProperty, updateCoinByPId, updateFavoriteByPId } from '../../services/service.property';
import icdollarfalse from '../../assets/icons/pages/findProertyDetail/coin/ic_dollar_false.png';
import icdollartrue from '../../assets/icons/pages/findProertyDetail/coin/ic_dollar_true.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ImageCustom from '../ImageCustom';
import { useForm } from 'antd/lib/form/Form';
import { ReactComponent as IcExpandMore } from '../../assets/icons/pages/Propmarket/my-property-create/ic_expand_more.svg';
import { ReactComponent as PriceTag } from '../../assets/icons/pages/findProertyDetail/price_tag.svg';
import { fetchMasterSalesChannel } from '../../services/service.common';
import { ReactComponent as CircleClose } from '../../assets/icons/modal/circle-close.svg';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as services from "../../services/service.property";
import { deleteBroadcastByBcId, patchBroadcastShow } from '../../services/service.broadcast';
import { deletePinLocationByPinId } from '../../services/service.pinLocation';
import EditorView from '../TextEditor/indexView';
import axios from 'axios';
import { useMasterDataContext } from '../../context/masterData';
import clsx from 'clsx';
import { useMediaQuery } from "react-responsive";

const CardItemPrimary = ({
    isPropmarket = false,
    isPinLocation = false,
    isBroadCast = false,
    bootItem = false,
    dataitem = {}
}) => {
    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });
    const { data: masterData, action: { updateData: setMasterData } } = useMasterDataContext();
    const carousel = useRef(Carousel);
    const { userData } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [toggleDetail, SetToggleDetail] = useState(false);
    const tooltipShare = useRef(null);
    const [data, setData] = useState({
        detail: "",
        property: {},
        images: [],
        deleted: false
    });
    const [defaultMapData, setDefaultMapData] = useState({});
    const { action: actionModal } = useModalContext();
    const [dataAPI, setDataAPI] = useState({
        isFavorite: false,
        isCoin: false,
        count_coin: 0,
        count_comment: 0,
    });
    // const [masterData, setMasterData] = useState({});

    useEffect(function () {
        setData(function(prevData) {
            if (isBroadCast) {
                setDefaultMapData({
                    defaultValue: {
                        rangeArea: dataitem?.radius_m || 0
                    },
                    defaultMarker: {
                        lat: dataitem?.lat || 0,
                        lng: dataitem?.lng || 0,
                    }
                })
                return {
                    ...prevData,
                    detail: `${dataitem?.title || ""}`,
                    property: {
                        ...dataitem || {},
                        amphures: dataitem?.amphures?.am_name,
                        province: dataitem?.province?.pv_name,
                        created_by: getCreateByObject(dataitem)
                    }
                }
            } else if (isPinLocation) {
                setDefaultMapData({
                    defaultValue: {
                        rangeArea: dataitem?.radius_m || 0
                    },
                    defaultMarker: {
                        lat: dataitem?.lat || 0,
                        lng: dataitem?.lng || 0,
                    }
                })
                return {
                    ...prevData,
                    detail: `${dataitem?.desc || ""}`,
                    property: {
                        ...dataitem || {},
                        amphures: dataitem?.amphures?.am_name,
                        province: dataitem?.province?.pv_name,
                        created_by: getCreateByObject(dataitem)
                    }
                }
            } else {
                return {
                    ...prevData,
                    detail: `${dataitem?.property?.advertisement || ""}`,
                    property: {
                        ...dataitem?.property || {},
                        created_by: getCreateByObject(dataitem?.property)
                    },
                    images: (dataitem?.property?.propertyImages || []).map(function (item) {
                        return {
                            ...item,
                            src: item?.path || ""
                        }
                    }).sort((a,b) => (a.seq > b.seq) ? 1 : ((b.seq > a.seq) ? -1 : 0))
                }
            }
        });
    }, [dataitem, isBroadCast, isPinLocation]);

    const getCreateByObject = (data = {}) => {
        const fieldNames = ["createBy", "created_by", "create_by"];
        const fieldName = fieldNames.find(field => {
            return typeof data[field] === "object";
        });
        return {...data[fieldName] || {}}
    }
    
    const verifyBadge = () => {
        if (data?.property?.status_sale === "sold") {
            return (
                <div className='gw-badge-verified'>
                    <span>ขายแล้ว</span>
                </div>
            )
        } else {
            return (<></>);
        }
    }

    function formatterRaduis(value) {
        const km = value / 1000;
        let showText = "";
        if (value >= 1000) {
            showText = `${km.toFixed(1)} กิโลเมตร`
        } else {
            showText = `${value.toFixed(0)} เมตร`
        }
        return `${showText}`;
    }

    const [isOpenLightbox, setIsOpenLightbox] = useState(false);
    const [currImg, setCurrImg] = useState(0);
    
    const onClickImageNavigateDetail = (imgIndex) => {
        setIsOpenLightbox(true);
        setCurrImg(imgIndex);
    }

    const onClickNavigateDetail = ({
        is_message = false
    }) => {
        if (isPropmarket) {
            navigate(`${RoutePath.propmarket.path}/detail/${data.property?.tab_id}?bc_id=${data.property?.bc_id}`, {
                state: {
                    is_message
                }
            })
        } else {
            if (isBroadCast) {
                navigate(`${RoutePath.my_grow_broadcast_detail.path}?bc_id=${data.property?.bc_id}`, {
                    state: {
                        is_message
                    }
                })
            } else if (isPinLocation) {
                navigate(`${RoutePath.my_grow_pin_location_list.path}?pin_id=${dataitem?.inter_id}`, {
                    state: {
                        is_message
                    }
                })
            } else {
                navigate(`${RoutePath.find_property_detail.path}?p_id=${data?.property?.p_id || ""}`, {
                    state: {
                        is_message
                    }
                })
            }
        }
    }

    const onClickNavigateViewImages = () => {
        if (data?.property?.p_id) {
            navigate({
                pathname: RoutePath.find_property_images.path,
                search: `?p_id=${data?.property?.p_id}`
            });
        }
    }

    const PreviewMask = () => (
        <div className='gw-preview-mask'>
            <ImgBoxLight />
            <span>ดูรูปทั้งหมด</span>
        </div>
    )

    const showShare = () => {
        actionModal.updateModalShare({
            isModalShow: true,
            modalData: {...data?.property || {}}
        })
    }

    const ImageList = () => {
        let show_images = [...(data?.images || [])];
        function getImage() {
            const image = show_images.shift();
            return image?.src || "";
        }
        const ImagesType1 = () => (<>
            <div className='gw-card-list-image type-1'>
                <div className='row-1'>
                    <Image
                        onClick={() => onClickImageNavigateDetail(0)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                    <Image
                        onClick={() => onClickImageNavigateDetail(1)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                </div>
                <div className='row-2'>
                    <Image
                        onClick={() => onClickImageNavigateDetail(2)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                    <Image
                        onClick={() => onClickImageNavigateDetail(3)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                    <Image
                        onClick={onClickNavigateViewImages}
                        preview={{
                            mask: <PreviewMask />
                        }}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                </div>
            </div>
        </>);

        const ImagesType2 = () => (<>
            <div className='gw-card-list-image type-2'>
                <div className='col-1'>
                    <Image
                        onClick={() => onClickImageNavigateDetail(0)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                    <Image
                        onClick={() => onClickImageNavigateDetail(1)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                    <Image
                        onClick={onClickNavigateViewImages}
                        preview={{
                            mask: <PreviewMask />
                        }}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                </div>
                <div className='col-2'>
                    <Image
                        onClick={() => onClickImageNavigateDetail(2)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                </div>
            </div>
        </>);

        const ImagesType3 = () => (<>
            <div className='gw-card-carousel-images'>
                <div className='arrow-prev' onClick={_ => carousel.current.prev()}>
                    <LeftOutlined />
                </div>
                <div className='arrow-next' onClick={_ => carousel.current.next()}>
                    <RightOutlined />
                </div>
                <Carousel ref={carousel}>
                    {
                        [...(show_images)].map((item, index) => {
                            return (
                                <div key={index} className='gw-carousel-item-image' onClick={() => onClickImageNavigateDetail(index)}>
                                    <Image
                                        preview={false}
                                        src={item.src}
                                        fallback={imgfallback} />
                                </div>
                            )
                        })
                    }
                    
                </Carousel>
                <ShowTag />
            </div>
        </>);
        
        return (
            <>
                <ImgsViewer
                    imgs={data.images || []}
                    showThumbnails={true}
                    isOpen={isOpenLightbox}
                    onClose={_ => setIsOpenLightbox(false)}
                    currImg={currImg}
                    onClickPrev={(e) => { setCurrImg(currImg - 1) }}
                    onClickNext={(e) => { setCurrImg(currImg + 1) }}
                    onClickThumbnail={index => setCurrImg(index)}
                />
                {/* {data?.property?.image_style === 0 && <ImagesType1 />}
                {data?.property?.image_style === 1 && <ImagesType2 />} */}
                <ImagesType3 />
            </>
        )
    }

    const TagsDescription = (value) => {
        const tagData = data?.property || {};
        switch (value) {
            case "bedroom":
                return (
                    <div className='text-icon'>
                        <ShoppingBasket />
                        <span>{tagData?.bed_room || 0}</span>
                    </div>
                )
            case "bathroom":
                return (
                    <div className='text-icon'>
                        <Bathroom />
                        <span>{tagData?.toilet_room || 0}</span>
                    </div>
                )
            case "area":
                return (
                    <div className='text-icon'>
                        <BuildingSize />
                        <span>{tagData?.use_size_sqm || 0}</span>
                    </div>
                )
            case "area_total":
                return (
                    <div className='text-icon'>
                        <span>{`${tagData?.rai} ไร่ ${tagData?.ngan} งาน ${tagData?.size_sqw} ตรว.`}</span>
                    </div>
                )
            case "car_park":
                return (
                    <div className='text-icon'>
                        <Garage />
                        <span>{tagData?.car_park || `0`}</span>
                    </div>
                )
        
            default:
                break;
        }
    }

    useEffect(() => {
        let unmountFavorite, unmountCoin, unmountComment, unmountUsersCoin;
        
        (async function initData() {
            if (dataitem?.property?.p_id) {
                if (userData?.uid) {
                    unmountFavorite = firebaseService.getFavorites(`${dataitem?.property?.p_id}`, `${userData?.uid}`).on('value',(snapshot) => {
                        if (!snapshot?.val) return;
                        
                        let items = snapshot.val() || {};
                        setDataAPI(prevData => ({
                            ...prevData,
                            isFavorite: items[userData?.uid] || false
                        }));
                    });
                }
                unmountCoin = firebaseService.getCoins(`${dataitem?.property?.p_id}`, `${userData?.uid}`).on('value',(snapshot) => {
                    if (!snapshot?.val) return;
                    
                    let items = snapshot.val() || {};
                    setDataAPI(prevData => ({
                        ...prevData,
                        count_coin: Object.keys(items).length,
                        isCoin: items[userData?.uid] || false
                    }));
                });
                
                unmountComment = firebaseService.getCommentCounts(`${dataitem?.property?.p_id}`, `${userData?.uid}`).on('value',(snapshot) => {
                    if (!snapshot?.val) return;
                    
                    let items = snapshot.val() || {};
                    setDataAPI(prevData => ({
                        ...prevData,
                        count_comment: Object.keys(items).length,
                    }));
                });
            } else if (dataitem?.bc_id) {
                unmountComment = firebaseService.getCommentCounts(`${dataitem?.bc_id}`).on('value',(snapshot) => {
                    if (!snapshot?.val) return;
                    
                    let items = snapshot.val() || {};
                    setDataAPI(prevData => ({
                        ...prevData,
                        count_comment: Object.keys(items).length,
                    }));
                });
            }
        })();
    
        return () => {
            if (unmountFavorite) unmountFavorite();
            if (unmountCoin) unmountCoin();
            if (unmountComment) unmountComment();
            if (unmountUsersCoin) unmountUsersCoin();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, dataitem?.property?.p_id]);

    const onClickFavorite = async () => {
        if (!userData?.uid) {
            actionModal.updateModalLogin(true);
            return;
        }
        const p_id = dataitem.property?.p_id;
        if (!userData?.uid || !p_id) return;
        try {
            await updateFavoriteByPId(p_id, {
                is_favorite: !dataAPI.isFavorite
            });
            // setDataAPI(prevData => {
            //     return {
            //         ...prevData,
            //         isFavorite: !dataAPI.isFavorite
            //     }
            // });
        } catch (error) {
            console.log("error updateFavoriteByPId : ", error)
        }
    }

    const onClickCoin = async () => {
        if (!userData?.uid) {
            actionModal.updateModalLogin(true);
            return;
        }
        const p_id = dataitem.property?.p_id;
        if (!userData?.uid || !p_id) return;
        try {
            await updateCoinByPId(p_id, {
                is_coin: !dataAPI.isCoin
            });
            // setDataAPI(prevData => {
            //     return {
            //         ...prevData,
            //         isCoin: !dataAPI.isFavorite
            //     }
            // });
        } catch (error) {
            console.log("error updateFavoriteByPId : ", error)
        }
    }

    const onClickNavigateAnotherProfile = () => {
        navigate(`${RoutePath.another_profile.path}`.replace(":uid", data?.property?.created_by?.uid));
    }

    const [form] = useForm();
    const [formReport] = useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalReportOpen, setIsModalReportOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();
    

    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        form.resetFields(["sales_channel_id", "sale_cost"]);

        async function loadMasterData() {
            if ((masterData?.sales_channel?.length || 0) === 0) {
                const responseSalesChannel = await fetchMasterSalesChannel(cancelTokenSource);
                setMasterData({
                    sales_channel: [...responseSalesChannel?.data?.data || []]
                });
            }
        }
        if (isModalOpen) {
            loadMasterData();
        }

        return () => {
            cancelTokenSource.cancel("Request canceled by the user.");
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, isModalOpen, setMasterData]);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit();
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish = async (values) => {
        try {
            const response = await salesProperty(data?.property?.p_id, {
                ...values
            });
            if (response?.data?.success) {
                setData(prevData => ({
                    ...prevData,
                    property: {
                        ...prevData.property,
                        status_sale: "sold"
                    }
                }));
                setIsModalOpen(false);
            } else {
                throw Error("response : "+response);
            }
        } catch (error) {
            console.log(error)
            api["error"]({
                message: 'มีบางอย่างผิดพลาด',
            });
        }
    }

    useEffect(() => {
        formReport.resetFields(["message"]);
    }, [formReport, isModalReportOpen]);
    const showModalReport = () => {
        setIsModalReportOpen(true);
    };
    const handleOkFormReport = () => {
        formReport.submit();
    };
    const handleCancelFormReport = () => {
        setIsModalReportOpen(false);
    };
    const onFinishFormReport = async (values) => {
        console.log(`🌦️ ~ onFinishFormReport ~ values:`, values);
        try {
            await services.updateChat(prepareData1());
            await services.updateChat(prepareData2(values));
            notification['success']({
                message: `ส่งรายงานปัญหาเรียบร้อย!`,
            });
            form.setFieldsValue({ message: "" });
            setIsModalReportOpen(false);
        } catch (error) {
            console.log(error)
            notification['error']({
                message: 'มีบางอย่างผิดพลาด',
            });
        }
    }
    const prepareData1 = () => {
        return {
            "type": 3,
            "message": "",
            "attach_property_id": data?.property?.p_id || "",
            "file": "",
            "to_id": "HIzS9rUDnoZw4NWFOAezxIIEDZ22",
        }
    }
    const prepareData2 = (source_data) => {
        return {
            "type": 1,
            "message": source_data.message,
            "attach_property_id": "",
            "file": "",
            "to_id": "HIzS9rUDnoZw4NWFOAezxIIEDZ22",
        }
    }

    const onClickEdit = () => {
        navigate(`${RoutePath.propmarket_my_property_edit.path}`.replace(":id", data?.property?.p_id));
    }

    const onClickDelete = (action = "property") => {
        modal.confirm({
            title: 'คุณต้องการที่ลบหรือไม่ ?',
            className: 'gw-modal-sale',
            icon: <ExclamationCircleOutlined />,
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk: async () => {
                try {
                    if (action === "property") {
                        await services.deleteProperty(data?.property?.p_id);
                    } else if (action === "broad_cast") {
                        if (data?.property?.broadcastMatching?.[0]?.p_id) {
                            await patchBroadcastShow(data?.property?.broadcastMatching?.[0]?.bm_id);
                        } else {
                            await deleteBroadcastByBcId(data?.property?.bc_id);
                        }
                    } else if (action === "pin_location") {
                        await deletePinLocationByPinId(data?.property?.inter_id);
                    }
                    setData(function (prevData) {
                        return {
                            ...prevData,
                            deleted: true
                        }
                    });
                    notification['success']({
                        message: `ลบข้อมูลเรียบร้อย!`,
                    });
                } catch (error) {
                    console.warn(`🌦️ ~ onClickDelete ~ error:`, error);
                    let message = "ลบข้อมูลไม่สําเร็จ";
                    if (error?.response?.data?.message) {
                        message = error?.response?.data?.message;
                    }
                    notification['error']({
                        message,
                    });
                }
            }
        });
    }

    const property_type = data?.property?.property_type || ""; // sale, rent
    const ShowTag = () => {
        let property_type_name = data?.property?.property_type_name || "";
        if (!property_type_name) {
            property_type_name = property_type === "sale" ? "ขาย" : "เช่า";
        }
        return (
            <div className={styles["gw-card-tag"]}>
                <div className={`${styles["gw-card-tag-item"]} ${styles["gw-type"]}`} type={property_type}><span>{property_type_name}</span></div>
                <div className={`${styles["gw-card-tag-item"]} ${styles["gw-category"]}`} category={data?.property?.cate_id}><span>{data?.property?.cate_name || data?.property?.cate_id || ""}</span></div>
            </div>
        )
    }
    const ShowTagBroadCast = () => {
        let property_type_name = data?.property?.filter_type || "";
        const category_name = data?.property?.cate_name || data?.property?.category?.cate_name || data?.property?.cate_id || "";
        const broadcast_type_id = data?.property?.broadcast_type_id || "";
        let isShowTagCategory = true;
        if (`${broadcast_type_id}` === "3") {
            isShowTagCategory = false;
        }
        return (
            <div className={styles["gw-card-tag"]}>
                <div className={`${styles["gw-card-tag-item"]} ${styles["gw-type"]}`} is-broadcast={"true"}><span>{property_type_name}</span></div>
                {
                    isShowTagCategory && 
                    <div className={`${styles["gw-card-tag-item"]} ${styles["gw-category"]}`} is-broadcast={"true"}><span>{category_name}</span></div>
                }
            </div>
        )
    }
    const ShowDescription = () => {
        try {
            return (
                <div style={{ position: "relative" }}>
                    <div className='gw-textarea-block' category={data?.property?.cate_id}>
                        <Input.TextArea 
                            className={clsx("gw-textarea-card")}
                            category={data?.property?.cate_id}
                            broadcasttypeid={data?.property?.broadcast_type_id}
                            placeholder=''
                            autoSize={true} 
                            value={data?.property?.desc} readOnly/>
                    </div>
                    <ShowTagBroadCast />
                </div>
            )
            const parseData = JSON.parse(data?.property?.desc);
            if (parseData.root) {
                return <EditorView  value={data?.property?.desc} selectCategory={data?.property?.cate_id}/>;
            }
            
            throw Error("no data");
        } catch (error) {
            return "";
        }
    }
    const uid = data?.property?.create_by?.uid || data?.property?.created_by?.uid || "";
    const isOwner = uid === userData?.uid;

    const detailElipse = isMobile ? 140 : 350;
    return (
        <div hidden={data?.deleted} className={`${styles["gw-card-item"]} ${(isBroadCast || isPinLocation) ? styles["gw-card-item-broadcast"] : ""} ${isPinLocation ? styles["gw-card-item-pin-location"] : ""}`}>
            {contextHolder}
            {modalContextHolder}
            {
                !isPinLocation && <>
                    <div className='gw-card-header'>
                        <div className='gw-card-header-left gw-btn-link' onClick={onClickNavigateAnotherProfile}>
                            <Image
                                preview={false}
                                referrerPolicy='no-referrer'
                                className='gw-card-image-profile'
                                src={data?.property?.created_by?.avatar || ""}
                                fallback={imgfallback}/>
                            <div className='gw-card-user-info'>
                                <div className='gw-card-username'>
                                    <span>{data?.property?.created_by?.display_name || ""}</span>
                                    {verifyBadge()}
                                </div>
                                <div className='gw-card-location'>
                                    <Breadcrumb
                                        separator={<svg className='gw-dot' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="50" cy="50" r="50" />
                                        </svg>}>
                                        {/* {data?.property?.district && <Breadcrumb.Item>{data?.property?.district || ""}</Breadcrumb.Item>} */}
                                        {data?.property?.amphures && <Breadcrumb.Item>{data?.property?.amphures || ""}</Breadcrumb.Item>}
                                        {data?.property?.province && <Breadcrumb.Item>{data?.property?.province || ""}</Breadcrumb.Item>}
                                    </Breadcrumb>
                                </div>
                            </div>
                        </div>
                        <div className='gw-card-header-right'>
                            {
                                bootItem ? <>
                                    {
                                        data?.property?.status_sale === "sale" && (
                                            <Dropdown
                                                overlayStyle={{
                                                    width: 130
                                                }}
                                                overlay={(
                                                    <Menu>
                                                        <Menu.Item key={data?.property?.p_id + "_mnu_0"} onClick={showModal}>
                                                            ขายแล้ว
                                                        </Menu.Item>
                                                        <Menu.Item key={data?.property?.p_id + "_mnu_1"} onClick={onClickEdit}>
                                                            แก้ไข
                                                        </Menu.Item>
                                                        <Menu.Item key={data?.property?.p_id + "_mnu_3"} onClick={_ => onClickDelete("property")}>
                                                            ลบ
                                                        </Menu.Item>
                                                    </Menu>
                                                )}
                                                trigger={['click']}
                                                placement={"bottomRight"}
                                            >
                                                <Button className={`gw-btn-menu`}>
                                                    <Ico3Dots />
                                                </Button>
                                            </Dropdown>
                                        )
                                    }
                                </> : <>
                                    {
                                        !isBroadCast &&
                                        (
                                            <>
                                                <Button className={`gw-btn-favourite${dataAPI.isFavorite ? ' active' : ''}`} onClick={onClickFavorite}>
                                                    <StarDuotone />
                                                </Button>

                                                <Dropdown
                                                    overlayStyle={{
                                                        width: 130
                                                    }}
                                                    overlay={(
                                                        <Menu>
                                                            <Menu.Item key={"mnu_report"} onClick={showModalReport}>
                                                                รายงาน
                                                            </Menu.Item>
                                                        </Menu>
                                                    )}
                                                    trigger={['click']}
                                                    placement={"bottomRight"}
                                                >
                                                    <Button className={`gw-btn-menu`}>
                                                        <Ico3Dots />
                                                    </Button>
                                                </Dropdown>
                                            </>
                                        )
                                    }
                                    {verifyBadge()}
                                </>
                            }
                            {
                                (isBroadCast && isOwner) &&
                                <Dropdown
                                    overlayStyle={{
                                        width: 130
                                    }}
                                    overlay={(
                                        <Menu>
                                            <Menu.Item key={`is_broad_cast_1`} onClick={_ => onClickDelete("broad_cast")}>
                                                ลบ
                                            </Menu.Item>
                                        </Menu>
                                    )}
                                    trigger={['click']}
                                    placement={"bottomRight"}
                                >
                                    <Button className={`gw-btn-menu`}>
                                        <Ico3Dots />
                                    </Button>
                                </Dropdown>
                            }
                        </div>
                    </div>
                    <div className='gw-card-text-content'>
                        {
                            isBroadCast ? 
                            <>
                                {/* <span className="gw-cursor-pointer" onClick={onClickNavigateDetail}>
                                    <span className='title'>{data?.detail}</span>
                                    <br />
                                    <span>{
                                        isBroadCast ? 
                                        (`${(data?.property?.desc || "").substring(0, 200)}${toggleDetail ? (data?.property?.desc || "").substring(200) : ""}`) : 
                                        (`${(data?.property?.description || "").substring(0, 200)}${toggleDetail ? (data?.property?.description || "").substring(200) : ""}`)
                                    }</span>
                                </span>
                                {
                                    !toggleDetail
                                    && (isBroadCast ? data?.property?.desc || "" : data?.property?.description || "").substring(200).length > 0
                                    && (
                                        <Button type='link' onClick={_ => SetToggleDetail(true)}>
                                            <span>อ่านต่อ...</span>
                                        </Button>
                                    )
                                } */}
                            </> : <>
                                <span className="gw-cursor-pointer" onClick={onClickNavigateDetail}>{
                                    (`${(data?.detail || "").substring(0, detailElipse)}${toggleDetail ? (data?.detail || "").substring(detailElipse) : ""}`)
                                }</span>
                                {
                                    !toggleDetail
                                    && (data?.detail || "").substring(detailElipse).length > 0
                                    && (
                                        <Button type='link' onClick={_ => SetToggleDetail(true)}>
                                            <span>อ่านต่อ...</span>
                                        </Button>
                                    )
                                }
                            </>
                        }
                    </div>
                </>
            }
            {
                (isPinLocation && isOwner) &&
                <div className='gw-card-header gw-float'>
                    <div className='gw-card-header-right'>
                        <Dropdown
                            overlayStyle={{
                                width: 130
                            }}
                            overlay={(
                                <Menu>
                                    <Menu.Item key={`is_pin_location_1`} onClick={_ => onClickDelete("pin_location")}>
                                        ลบ
                                    </Menu.Item>
                                </Menu>
                            )}
                            trigger={['click']}
                            placement={"bottomRight"}
                        >
                            <Button className={`gw-btn-menu`}>
                                <Ico3Dots />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
            }
            {
                (isBroadCast || isPinLocation) && (
                    <>
                        <div className={clsx("gw-cursor-pointer", !isBroadCast ? "gw-map" : "gw-broad-cast")} onClick={onClickNavigateDetail}>
                            {
                                isBroadCast && <ShowDescription />
                            }
                            {
                                isPinLocation && (
                                    <MapContainer {...defaultMapData} isBroadCast={isBroadCast || isPinLocation} />
                                )
                            }
                        </div>
                        {
                            !isPinLocation && (
                                <div className='gw-card-details'>
                                    <div className='gw-card-detail-left'>
                                        <div className='price'>
                                            <span className="gw-cursor-pointer" onClick={onClickNavigateDetail}>
                                                <span className={styles["gw-prefix-unit"]}>฿</span>
                                                <span className={styles["gw-value"]}>{showPrice(data?.property?.cost_min)}-{showPrice(data?.property?.cost_max)}</span>
                                                <span className={styles["gw-suffix-unit"]}>บาท</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </>
                )
            }
            {
                (!isBroadCast && !isPinLocation) && (
                    <>
                        { <ImageList /> }
                        <div className='gw-card-details gw-cursor-pointer' onClick={onClickNavigateDetail}>
                            <div className='gw-card-detail-left'>
                                <div className='price'>
                                    <span>฿ {showPrice(data?.property?.cost)}{property_type === "sale" ? `` : `/เดือน`}</span>
                                </div>
                                <div className='area'>
                                    <span>{showPrice(data?.property?.cost_area, data?.property)}</span>
                                </div>
                            </div>
                            <div className='gw-card-detail-right'>
                                {
                                    // ที่ดิน
                                    data?.property?.cate_id === 1 && (
                                        <>
                                            <div className='layer-2'>
                                                {TagsDescription("area_total")}
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    // บ้าน อาคารพาณิชย์, ธุรกิจ
                                    ([2, 4, 5].includes(data?.property?.cate_id)) && (
                                        <>
                                            <div className='layer-1'>
                                                {TagsDescription("bedroom")}
                                                {TagsDescription("bathroom")}
                                                {TagsDescription("area")}
                                            </div>
                                            <div className='layer-2'>
                                                {TagsDescription("area_total")}
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    // คอนโด
                                    ([3, 6, 7].includes(data?.property?.cate_id)) && (
                                        <>
                                            <div className='layer-1'>
                                                {TagsDescription("area")}
                                                {TagsDescription("bedroom")}
                                                {TagsDescription("bathroom")}
                                            </div>
                                            <div className='layer-2'>
                                                {TagsDescription("car_park")}
                                                {TagsDescription("construction_year")}
                                                {TagsDescription("floor")}
                                                {TagsDescription("total_floor")}
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </>
                )
            }
            <div className='gw-card-footer'>
                {
                    isPinLocation && (
                        <div className='gw-card-footer-pin gw-cursor-pointer' onClick={onClickNavigateDetail}>
                            <div className='gw-text-detail'>
                                <div className='gw-icon'>
                                    <PinIcoLocationHome />
                                </div>
                                <div className='gw-text'>
                                    <span>{dataitem?.interest?.address || ""}</span>
                                </div>
                            </div>
                            <div className='gw-text-detail'>
                                <div className='gw-icon'>
                                    <PinIcoTargetLight />
                                </div>
                                <div className='gw-text'>
                                    <span>รัศมี {formatterRaduis(dataitem?.radius_m || 0)}</span>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className='gw-card-footer-left'>
                    {
                        (!isBroadCast && !isPinLocation) && (
                            <div className={`gw-btn-link btn-coin ${dataAPI?.isCoin ? `active` : ``}`} onClick={onClickCoin}>
                                {
                                    dataAPI.isCoin
                                        ? <Image preview={false} src={icdollartrue} alt="false" />
                                        : <Image preview={false} src={icdollarfalse} alt="true" />
                                }
                            </div>
                        )
                    }
                    {
                        (!isPinLocation) && (
                            <>
                                <div className='gw-btn-link btn-chat' onClick={_ => onClickNavigateDetail({
                                    is_message: true
                                })}>
                                    <Chat />
                                </div>
                                {
                                    !isBroadCast && (
                                        <div
                                            className='gw-btn-link btn-share'
                                            onClick={showShare}
                                        >
                                            <Share />
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
                {
                    !isPinLocation && (
                        <>
                            <div className='gw-card-footer-right'>
                                {
                                    (!isBroadCast) && (
                                        <span>{dataAPI?.count_coin || 0} Coins</span>
                                    )
                                }
                                <span className="gw-cursor-pointer" onClick={onClickNavigateDetail}>{dataAPI?.count_comment || 0} Comments</span>
                            </div>
                        </>
                    )
                }
            </div>
            {
                bootItem && (
                    <>
                        <Divider className={styles["gw-divider"]} />
                        <div className={styles["gw-card-boot"]}>
                            <div className={styles["gw-left"]}>
                                <span>
                                    <F1View />
                                    <span>{`${data?.property?.total_visible || 0} Visible`}</span>
                                </span>
                                <span>
                                    <F2Mouse />
                                    <span>{`${data?.property?.total_view_click || 0} Click`}</span>
                                </span>
                            </div>
                            <div className={styles["gw-right"]}>
                                <Button onClick={_ => {
                                    notification.open({
                                        message: 'IncVis',
                                        description:
                                            'Comming soom',
                                        // onClick: () => {
                                        //     console.log('Notification Clicked!');
                                        // },
                                    });
                                }}>
                                    IncVis
                                </Button>
                            </div>
                        </div>
                    </>
                )
            }
            <Modal forceRender className='gw-modal-sale'
                visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText={"Confirm"} 
                centered
                closeIcon={<CircleClose />}
                width={628}
                >
                <Space direction='vertical' style={{width: "100%"}}>
                    <div className={styles["gw-block-detail"]}>
                        <ImageCustom src={[...(data?.images || [])][0]?.src}/>
                        <span className='gw-detail' title={data?.detail}>{data?.detail || ""}</span>
                    </div>
                    <Form
                        autoComplete="off"
                        form={form}
                        onFinish={onFinish}
                    >
                        <div className={styles["gw-block-container"]}>
                            <span className={styles["gw-title"]}>กรอกข้อมูลการขาย</span>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item
                                        className={`${styles["gw-form-selector"]}`}
                                        name="sales_channel_id"
                                        rules={[{ required: true, message: 'Please select!' }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="ช่องทางที่ขายได้"
                                            allowClear={true}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            suffixIcon={<IcExpandMore />}
                                        >
                                            {
                                                [...masterData?.sales_channel || []].map(function (item) {
                                                    return <Select.Option 
                                                        key={item?.sales_channel_id} 
                                                        value={item?.sales_channel_id}>{item?.title || ""}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className={`${styles["gw-form-input"]}`}
                                        name="sale_cost"
                                        rules={[{ required: true, message: 'Please input!' }]}
                                    >
                                        <InputNumber
                                            prefix={<PriceTag />}
                                            controls={false}
                                            placeholder='ราคาที่ขาย'
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Space>
            </Modal>
            <Modal forceRender className='gw-modal-sale'
                visible={isModalReportOpen} onOk={handleOkFormReport} onCancel={handleCancelFormReport} okText={"Confirm"} 
                centered
                closeIcon={<CircleClose />}
                width={628}
                >
                <Space direction='vertical' style={{width: "100%"}}>
                    <div className={styles["gw-block-detail"]}>
                        <ImageCustom src={[...(data?.images || [])][0]?.src}/>
                        <span className='gw-detail' title={data?.detail}>{data?.detail || ""}</span>
                    </div>
                    <Form
                        autoComplete="off"
                        form={formReport}
                        onFinish={onFinishFormReport}
                    >
                        <div className={styles["gw-block-container"]}>
                            <span className={styles["gw-title"]}>รายงานความผิดปกติ</span>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.Item
                                        className={`${styles["gw-form-input"]}`}
                                        name="message"
                                        rules={[{ required: true, message: 'Please input!' }]}
                                    >
                                        <Input.TextArea placeholder='' maxLength={1000} style={{height: "100px"}}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Space>
            </Modal>
        </div>
    )
}

export default CardItemPrimary;