/*global google*/
import { Circle, GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';
import iconMarkLogo from '../../assets/icons/maps/mark.png';
import { mapMiniSizeStyles, mapStyles, mapPolygonStyles, mapCircleStyles, mapMiniSizePinStyles } from '../../pages/FindPropertyMap/settings';

const MapContainer = ({
	isBroadCast = false,
	defaultValue = {
		rangeArea: 0
	},
	isPinLocation = false,
	mapSizeStyles = mapMiniSizeStyles,
	defaultMarker = {},
	defaultZoom = 10,
	defaultPolygons = []
}) => {
	mapSizeStyles = isPinLocation ? mapMiniSizePinStyles : mapSizeStyles;
    const [ libraries ] = useState(["geometry"]);
	const [map, setMap] = useState(null)
    const [defaultCenter, setDefaultCenter] = useState({
		lat: 13.8027613, lng: 101.3910132
	});

	useEffect(() => {
		let mapCircle;
		let mapMarker;
		let mapPolygon;
		if (map && google) {
			if (isBroadCast || isPinLocation) {
				map.setOptions({draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true});
			}
			if (defaultMarker.lat && defaultMarker.lng) {
				map.setCenter({ ...defaultMarker });
			}
			if (isBroadCast) {
				if (defaultMarker.lat && defaultMarker.lng) {
					mapCircle = new google.maps.Circle({
						...mapCircleStyles,
						map: map,
						center: defaultMarker,
						radius: defaultValue.rangeArea // in meters
					});
					if (google.maps.geometry) {
						const left = new google.maps.geometry.spherical.computeOffset(defaultMarker, defaultValue.rangeArea, -90);
						const right = new google.maps.geometry.spherical.computeOffset(defaultMarker, defaultValue.rangeArea, 90);
						const top = new google.maps.geometry.spherical.computeOffset(defaultMarker, defaultValue.rangeArea, 0);
						const bottom = new google.maps.geometry.spherical.computeOffset(defaultMarker, defaultValue.rangeArea, 180);
						let circleBounds = new google.maps.LatLngBounds();
						circleBounds.extend(defaultMarker);
						circleBounds.extend(left);
						circleBounds.extend(right);
						circleBounds.extend(top);
						circleBounds.extend(bottom);
						map.fitBounds(circleBounds);
					}
					
					mapMarker = new google.maps.Marker({
						position: { ...defaultMarker },
						map,
						icon: {
							url: iconMarkLogo,
							scaledSize: {
								width: 30,
								height: 43
							},
						}
					});
				}
			} else {
				if (defaultMarker.lat && defaultMarker.lng) {
					mapMarker = new google.maps.Marker({
						position: { ...defaultMarker },
						map,
						icon: {
							url: iconMarkLogo,
							scaledSize: {
								width: 30,
								height: 43
							},
						}
					});
				}
				
				if (defaultPolygons.length > 0) {
					mapPolygon = new google.maps.Polygon({
						path: [...defaultPolygons].map(item => {
							return {
								lat: item.latitude,
								lng: item.longitude
							}
						}),
						...mapPolygonStyles,
						map
					});
					let polygonBounds = new google.maps.LatLngBounds();
					const polygonCoords = [...defaultPolygons].map(item => {
						return {
							lat: item.latitude,
							lng: item.longitude
						}
					})
					for (let i = 0; i < polygonCoords.length; i++) {
						polygonBounds.extend(polygonCoords[i]);
					}
					map.setCenter(polygonBounds.getCenter());
					map.fitBounds(polygonBounds);
				}
			}
		}
		return () => {
			if (mapCircle) {
				mapCircle.setMap(null);
			}
			if (mapMarker) {
				mapMarker.setMap(null);
			}
			if (mapPolygon) {
				mapPolygon.setMap(null);
			}
		}
	}, [defaultMarker, defaultPolygons, defaultValue, isBroadCast, map])
	
    const [locations, setLocations] = useState([])
    // Store Polygon path in state
    const [path, setPath] = useState();

	const onLoad = useCallback(function callback(map) {
		setMap(map)
	}, []);

	const onUnmount = useCallback(function callback(map) {
		setMap(null)
	}, []);

	return (
		// <LoadScript
		// 	googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
		// 	libraries={libraries}>
			<GoogleMap
				options={{ 
                    disableDefaultUI: true,
                    disableDoubleClickZoom: true,
					fullscreenControl: false,
					streetViewControl: false,
					mapTypeControl: false,
                    zoomControl: false,
                    scaleControl: false,
                    draggingCursor: false,
					styles: mapStyles
				}}
				mapContainerStyle={mapSizeStyles}
				zoom={defaultZoom}
				center={defaultCenter}
				onLoad={onLoad}
				onUnmount={onUnmount}
			>
				{/* {
					isBroadCast && (
						<Circle
							center={defaultCenter}
							radius={defaultValue.rangeArea}
							options={mapCircleStyles} />
					)
				} */}
				{
					!isBroadCast && (
						<Polygon
							options={mapPolygonStyles}
							// Make the Polygon editable / draggable
							editable={false}
							draggable={false}
							path={path}
						/>
					)
				}
				{
					locations.map(item => {
						return (
							<Marker
								key={item.name}
								icon={{
									url: iconMarkLogo,
									scaledSize: {
										width: 30,
										height: 43
									},
								}}
								position={item.location}
								onClick={() => {
									setDefaultCenter(item.location)
								}}
							/>
						)
					})
				}
			</GoogleMap>
		// </LoadScript>
	)
}

export default MapContainer;