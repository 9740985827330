/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Divider, Modal, Tooltip } from 'antd';
import styles from './styles.module.scss';
import { ReactComponent as CircleClose } from '../../assets/icons/modal/circle-close.svg';
import { ReactComponent as Icons8Facebook } from '../../assets/icons/pages/findProertyDetail/icons8-facebook.svg';
import { ReactComponent as Icons8Instagram } from '../../assets/icons/pages/findProertyDetail/icons8-instagram.svg';
import { ReactComponent as Icons8Twitter } from '../../assets/icons/pages/findProertyDetail/icons8-twitter.svg';
import { RoutePath } from '../../Routes';
import { useEffect, useMemo, useState } from 'react';
import { useModalContext } from '../../context/modals';

const ModalShareComponent = ({
}) => {
    const { dataModalShare, action: actionModal } = useModalContext();
    const [_state, setState] = useState({
        url: ""
    });
    const state = useMemo(_ => {
        return _state;
    }, [_state]);

    const getUrlProperty = (data) => {
        const url = `${process.env.REACT_APP_PREFIX_WEB_URL}/share/property/${data?.p_id || ""}`;
        return url;
    }

    useEffect(_ => {
        setState(prevData => ({
            ...prevData,
            url: getUrlProperty(dataModalShare.modalData)
        }))
    }, [dataModalShare])

    const onClickCloseModal = () => {
        actionModal.updateModalShare({
            isModalShow: false,
            modalData: {}
        })
    }

    const onClickActionShare = (action = "") => {
        let url  = "";
        switch (action) {
            case "FACEBOOK":
                url = `https://www.facebook.com/sharer/sharer.php?u=${state.url}`;
                window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=' + 600 + ',width=' + 600);
                break;
            case "INSTAGRAM":

                break;
            case "TWITTER":
                url = `http://www.twitter.com/share?url=${state.url}`;
                window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=' + 600 + ',width=' + 600);

                break;
        
            default:
                break;
        }
        onClickCloseModal();
    }

    const onClickCopyUrl = async () => {
        navigator.clipboard.writeText(state.url);
    }

    return (
        <>
            <Modal className="gw-modal-container" width={"100%"} visible={dataModalShare.isModalShow} footer="" closable={false}>
                <div className='gw-modal-block-close'>
                    <div className='gw-modal-close' onClick={onClickCloseModal}>
                        <CircleClose />
                    </div>
                </div>
                <div className={`${styles["gw-modal-block-content"]}`}>
                    <div className={styles["gw-title"]}>
                        <span>Share</span>
                    </div>
                    <Divider />
                    <div className={styles["gw-detail"]}>
                        <span>Share this link via</span>
                    </div>
                    <div className={styles["gw-share"]}>
                        <Tooltip trigger={["hover"]} title={"share facebok"} mouseLeaveDelay={0.1}>
                            <div className='gw-btn-link btn-share' onClick={_ => onClickActionShare("FACEBOOK")}>
                                <Icons8Facebook />
                            </div>
                        </Tooltip>
                        <Tooltip trigger={["hover"]} title={"share instagram"} mouseLeaveDelay={0.1}>
                            <div className='gw-btn-link btn-share' onClick={_ => onClickActionShare("INSTAGRAM")}>
                                <Icons8Instagram />
                            </div>
                        </Tooltip>
                        <Tooltip trigger={["hover"]} title={"share twitter"} mouseLeaveDelay={0.1}>
                            <div className='gw-btn-link btn-share' onClick={_ => onClickActionShare("TWITTER")}>
                                <Icons8Twitter />
                            </div>
                        </Tooltip>
                    </div>
                    <div className={styles["gw-detail"]}>
                        <span>Or copy link</span>
                    </div>
                    <Tooltip trigger={["hover"]} title={"click to copy"} mouseLeaveDelay={0.5}>
                        <a onClick={onClickCopyUrl}>
                            <span>{state.url}</span>
                        </a>
                    </Tooltip>
                </div>
            </Modal>
        </>
    )
}

export default ModalShareComponent;