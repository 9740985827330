import { Children, useEffect, useRef, useState } from 'react';
import { BackTop, Breadcrumb, Button, AutoComplete, Col, Divider, Drawer, Form, Image, Input, InputNumber, Modal, Row, Select, Space, Spin, Tooltip, notification } from 'antd';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styles from './style.module.scss';
import { defaultQuestionnaires, imgfallback, prepareFilterPropertySimilar, showPrice, showPriceNumber, showPricePPDetail } from '../../utils/common';
import MapContainer from '../../components/GMap';
import btnStreet from '../../assets/icons/pages/findProertyDetail/btn-street.svg';
import { ReactComponent as CmSend } from '../../assets/icons/pages/findProertyDetail/cm_send.svg';
import { ReactComponent as IconBack } from '../../assets/icons/pages/findProertyDetail/arrow_left_back.svg';
import { ReactComponent as PriceTag } from '../../assets/icons/pages/findProertyDetail/price_tag.svg';
import { ReactComponent as Location } from '../../assets/icons/pages/findProertyDetail/location.svg';
import { ReactComponent as Coin } from '../../assets/icons/pages/findProertyDetail/1_coin.svg';
import { ReactComponent as Star } from '../../assets/icons/pages/findProertyDetail/2_star_light.svg';
import { ReactComponent as Share } from '../../assets/icons/pages/findProertyDetail/3_share.svg';
import { ReactComponent as Car } from '../../assets/icons/pages/findProertyDetail/nb-0-car.svg';
import { ReactComponent as GraduationCap } from '../../assets/icons/pages/findProertyDetail/nb-1-graduation-cap.svg';
import { ReactComponent as ShoppingBag } from '../../assets/icons/pages/findProertyDetail/nb-2-shopping-bag.svg';
import { ReactComponent as Dish } from '../../assets/icons/pages/findProertyDetail/nb-3-dish.svg';
import { ReactComponent as Bed } from '../../assets/icons/pages/findProertyDetail/nb-4-bed.svg';
import { ReactComponent as ImgBoxLight } from '../../assets/icons/pages/findProertyDetail/Img_box_light.svg';
import { ReactComponent as ChieldCheckLight } from '../../assets/icons/pages/findProertyDetail/chield_check_light.svg';
import { ReactComponent as PhoneDuotone } from '../../assets/icons/pages/findProertyDetail/phone_duotone.svg';
import { ReactComponent as ShoppingBasket } from '../../assets/icons/components/card-item/1-shopping-basket.svg';
import { ReactComponent as Bathroom } from '../../assets/icons/components/card-item/2-bathroom.svg';
import { ReactComponent as LandSizeOrLot } from '../../assets/icons/components/card-item/3-land-size-or-lot.svg';
import { ReactComponent as Garage} from '../../assets/icons/components/card-item/8-garage.svg';
import { ReactComponent as BuildingSize } from '../../assets/icons/components/card-item/7-building-size.svg';
import CarouselList from './CarouselList';
import { RoutePath } from '../../Routes';
import CommentComponent from '../../components/Comment';
import CardItemSecondary from '../../components/CardItemSecondary';
import { CaretUpOutlined, LoadingOutlined } from '@ant-design/icons';
import ImgsViewer from 'react-images-viewer';
import { fetchCoinByPId, fetchFavoriteByPId, fetchPerpertyDetails, fetchPerpertyNearby, fetchPerpertySimilar, updateCoinByPId, updateFavoriteByPId, updateViewProperty } from '../../services/service.property';
import { defaultCenter } from '../FindPropertyMap/settings';
import { Helmet } from 'react-helmet';
import { useAuthContext } from '../../context/authentication';
import { useModalContext } from '../../context/modals';
import { useSelector } from 'react-redux';
import firebaseService from '../../services/firebase.service';
import { ReactComponent as IcoBadgeMassenger } from '../../assets/icons/header/ico-badge-massenger.svg';
import icdollarfalse from '../../assets/icons/pages/findProertyDetail/coin/ic_dollar_false.png';
import icdollartrue from '../../assets/icons/pages/findProertyDetail/coin/ic_dollar_true.png';
import { ReactComponent as H1CheckRingRoundLight } from "../../assets/icons/pages/Mygrow/h-1-check_ring_round_light.svg";
import { ReactComponent as H2InfoLight } from "../../assets/icons/pages/Mygrow/h-2-Info_light.svg";
import { useForm } from 'antd/lib/form/Form';
import { salesProperty } from '../../services/service.property';
import { ReactComponent as CircleClose } from '../../assets/icons/modal/circle-close.svg';
import ImageCustom from '../../components/ImageCustom';
import { ReactComponent as IcExpandMore } from '../../assets/icons/pages/Propmarket/my-property-create/ic_expand_more.svg';
import { fetchMasterSalesChannel } from '../../services/service.common';
import { useMediaQuery } from 'react-responsive';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import clsx from 'clsx';
import { createTravelTime, deleteTravelTime, fetchTravelTime, updateTravelTime } from '../../services/service.traveltime.js';
import axios from "axios";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Search } = Input;

const IndexPage = () => {
    const { userData } = useSelector((state) => state.user)
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthentication } = useAuthContext().data;
    const inputCommentRef = useRef();
    const [toggleDetail, SetToggleDetail] = useState(false);
    const [data, setData] = useState({
        detail: `detail`,
        images: [],
        cate_id: null
    });
    const [dataFavorite, setDataFavorite] = useState(false);
    const [dataCoin, setDataCoin] = useState(false);
    const { action: actionModal } = useModalContext();

    const [dataPropertySimilarList, setDataPropertySimilarList] = useState([]);
    const [dataPropertySimilarMeta, setDataPropertySimilarMeta] = useState({});

    const [dataAPI, setDataAPI] = useState({
        isFavorite: false,
        isCoin: false,
        count_coin: 0
    });

    const params = new Proxy(new URLSearchParams(location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    const objParams = {
        p_id: params.p_id
    }

    useEffect(() => {
        return () => { }
    }, [location]);
    

    useEffect(() => {
        if (inputCommentRef.current && location?.state?.is_message) {
            inputCommentRef.current.input.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            inputCommentRef.current.focus();
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        return () => {

        }
    }, [inputCommentRef, location?.state?.is_message]);

    const onClikeNavigateBack = () => {
        if (window?.history?.state?.key) {
            navigate(-1);
        } else {
            navigate(RoutePath.find_property_map.path, {
                replace: true
            });
        }
    }

    const [isOpenLightbox, setIsOpenLightbox] = useState(false);
    const [currImg, setCurrImg] = useState(0);
    
    const onClickImageNavigateDetail = (imgIndex) => {
        setIsOpenLightbox(true);
        setCurrImg(imgIndex);
    }

    const onClickNavigateViewImages = () => {
        if (data?.p_id) {
            navigate({
                pathname: RoutePath.find_property_images.path,
                search: `?p_id=${data?.p_id}`
            });
        }
    }

    const saleBadge = () => {
        return <></>
        return (
            <div className='gw-badge-sale'>
                <span>Financing loan</span>
            </div>
        )
    }

    const PreviewMask = () => (
        <div className='gw-preview-mask'>
            <ImgBoxLight />
            <span>ดูรูปทั้งหมด</span>
        </div>
    )

    useEffect(() => {
        const loadDataProperty = async () => {
            const responseDataProperty = await fetchPerpertyDetails(objParams.p_id);
            if (responseDataProperty?.data?.data) {
                const responseData = responseDataProperty?.data?.data;
                setData(function () {
                    return {
                        ...responseData,
                        detail: responseData?.advertisement,
                        images: (responseData?.propertyImages || []).map(function (item) {
                            return {
                                ...item,
                                src: item?.path || ""
                            }
                        })
                    }
                });
                if (isAuthentication) {
                    Promise.allSettled([
                        fetchFavoriteByPId(objParams.p_id),
                        fetchCoinByPId(objParams.p_id)
                    ]).then(allResponse => {
                        const [
                            favoriteResponse,
                            coinResponse
                        ] = allResponse;
                        if (favoriteResponse?.value?.data?.data) {
                            setDataFavorite(favoriteResponse?.value?.data?.data?.is_favorite || false);
                        }
                        if (coinResponse?.value?.data?.data) {
                            setDataCoin(favoriteResponse?.value?.data?.data?.is_coin || false);
                        }
                    })
                }
                    
                let bodyReq = {
                    "type_viewer": "internal"
                }

                if (searchParams.get("utm_medium")) {
                    bodyReq.type_viewer = "external";
                }
                
                try {
                    await updateViewProperty(objParams.p_id, bodyReq);
                } catch (error) {
                    
                }
            }
            
            const responseSalesChannel = await fetchMasterSalesChannel();
            if (responseSalesChannel?.data?.data) {
                setMasterData({
                    sales_channel: [...responseSalesChannel?.data?.data || []]
                });
            }
        }
        loadDataProperty();
        return () => { };
    }, [isAuthentication, objParams.p_id]);

    useEffect(function () {
        const loadDataPropertySimilar = async () => {
            try {
                const filterSimilar = prepareFilterPropertySimilar(data || {});
                const response = await fetchPerpertyNearby(filterSimilar);
                if (response?.data?.data) {
                    setDataPropertySimilarList([...(response?.data?.data?.items || [])]);
                    setDataPropertySimilarMeta({...(response?.data?.data?.meta || {})});
                } else {
                    throw Error("prepareFilterPropertySimilar no data");
                }
            } catch (error) {
                console.warn("error", error);
                setDataPropertySimilarList([]);
                setDataPropertySimilarMeta({});
            } finally {
    
            }
        }
        
        if (data.p_id) {
            loadDataPropertySimilar()
        }
    }, [data, data.title_deen_no]);

    
    useEffect(() => {
        if (!userData?.uid) return;
        let unmountFavorite, unmountCoin, unmountUsersCoin;
        (async function initData() {
            if (data.p_id) {
                unmountFavorite = firebaseService.getFavorites(`${data.p_id}`, `${userData?.uid}`).on('value',(snapshot) => {
                    if (!snapshot?.val) return;
                    
                    let items = snapshot.val() || {};
                    setDataAPI(prevData => {
                        return {
                            ...prevData,
                            isFavorite: items[userData?.uid] || false
                        }
                    });
                });
                unmountCoin = firebaseService.getCoins(`${data.p_id}`, `${userData?.uid}`).on('value',(snapshot) => {
                    if (!snapshot?.val) return;
                    
                    let items = snapshot.val() || {};
                    setDataAPI(prevData => {
                        return {
                            ...prevData,
                            count_coin: Object.keys(items).length,
                            isCoin: items[userData?.uid] || false
                        }
                    });
                });
            }
        })();

        return () => {
            if (unmountFavorite) unmountFavorite();
            if (unmountCoin) unmountCoin();
            if (unmountUsersCoin) unmountUsersCoin();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, data.p_id]);

    const onClickFavorite = async () => {
        if (!userData?.uid) {
            actionModal.updateModalLogin(true);
            return;
        }
        const p_id = data.p_id;
        if (!userData?.uid || !p_id) return;
        try {
            await updateFavoriteByPId(p_id, {
                is_favorite: !dataAPI.isFavorite
            });
            // setDataAPI(prevData => {
            //     return {
            //         ...prevData,
            //         isFavorite: !dataAPI.isFavorite
            //     }
            // });
        } catch (error) {
            console.warn("error updateFavoriteByPId : ", error)
        }
    }

    const onClickCoin = async () => {
        if (!userData?.uid) {
            actionModal.updateModalLogin(true);
            return;
        }
        const p_id = data.p_id;
        if (!userData?.uid || !p_id) return;
        try {
            await updateCoinByPId(p_id, {
                is_coin: !dataAPI.isCoin
            });
            // setDataAPI(prevData => {
            //     return {
            //         ...prevData,
            //         isCoin: !dataAPI.isFavorite
            //     }
            // });
        } catch (error) {
            console.warn("error updateFavoriteByPId : ", error)
        }
    }

    const ImageList = () => {
        let show_images = [...(data?.images || [])];
        function getImage() {
            const image = show_images.shift();
            return image?.src || "";
        }
        const ImagesType1 = () => (<>
            <div className='gw-card-list-image type-1'>
                <div className='row-1'>
                    <Image
                        onClick={() => onClickImageNavigateDetail(0)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                    <Image
                        onClick={() => onClickImageNavigateDetail(1)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                </div>
                <div className='row-2'>
                    <Image
                        onClick={() => onClickImageNavigateDetail(2)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                    <Image
                        onClick={() => onClickImageNavigateDetail(3)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                    <Image
                        onClick={onClickNavigateViewImages}
                        preview={{
                            mask: <PreviewMask />
                        }}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                </div>
            </div>
        </>);

        const ImagesType2 = () => (<>
            <div className='gw-card-list-image type-2'>
                <div className='col-1'>
                    <Image
                        onClick={() => onClickImageNavigateDetail(0)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                    <Image
                        onClick={() => onClickImageNavigateDetail(1)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                    <Image
                        onClick={onClickNavigateViewImages}
                        preview={{
                            mask: <PreviewMask />
                        }}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                </div>
                <div className='col-2'>
                    <Image
                        onClick={() => onClickImageNavigateDetail(2)}
                        preview={false}
                        className='gw-card-item-image'
                        src={getImage()}
                        fallback={imgfallback} />
                </div>
            </div>
        </>);
        
        return (
            <>
                <ImgsViewer
                    imgs={data.images || []}
                    showThumbnails={true}
                    isOpen={isOpenLightbox}
                    onClose={_ => setIsOpenLightbox(false)}
                    currImg={currImg}
                    onClickPrev={(e) => { setCurrImg(currImg - 1) }}
                    onClickNext={(e) => { setCurrImg(currImg + 1) }}
                    onClickThumbnail={index => setCurrImg(index)}
                />
                <ImagesType1 />
                {/* {data?.image_style === 0 && <ImagesType1 />}
                {data?.image_style === 1 && <ImagesType2 />} */}
            </>
        )
    }

    const TagsDescription = (value) => {
        const tagData = data || {};
        switch (value) {
            case "bedroom":
                return (
                    <div className='text-icon'>
                        <ShoppingBasket />
                        <span>{tagData?.bed_room || 0}</span>
                    </div>
                )
            case "bathroom":
                return (
                    <div className='text-icon'>
                        <Bathroom />
                        <span>{tagData?.toilet_room || 0}</span>
                    </div>
                )
            case "area":
                return (
                    <div className='text-icon'>
                        <BuildingSize />
                        <span>{tagData?.use_size_sqm || 0}</span>
                    </div>
                )
            case "area_total":
                return (
                    <div className='text-icon'>
                        <span>{`${tagData?.rai} ไร่ ${tagData?.ngan} งาน ${tagData?.size_sqw} ตรว.`}</span>
                    </div>
                )
            case "car_park":
                return (
                    <div className='text-icon'>
                        <Garage />
                        <span>{tagData?.car_park || `0`}</span>
                    </div>
                )
        
            default:
                break;
        }
    }
    
    const getUrlProperty = () => {
        const url = `${process.env.REACT_APP_PREFIX_WEB_URL}${RoutePath.find_property_detail.path}?p_id=${data?.p_id || ""}`;
        return url;
    }

    const showShare = () => {
        actionModal.updateModalShare({
            isModalShow: true,
            modalData: {...data || {}}
        })
    }

    const onClickNavigateChat = () => {
        if (data?.created_by?.uid && !isSelfUser()) {
            navigate(`${RoutePath.page_chat_detail.path}?user_id=${data?.created_by?.uid}`, {
                state: { created_by: data?.created_by },
                preventScrollReset: true
            })
        }
    }

    const isSelfUser = () => {
        return userData?.uid === data?.created_by?.uid;
    }

    const onClickNearbyPlace = (location) => {
        navigate(`${RoutePath.find_property_nearby_place.path}`, {
            state: {
                p_id: data?.p_id,
                detail: data?.detail,
                location: location,
                polygon: data?.locationPolygon || [],
            }
        });
    }
    
    const onClickNavigateAnotherProfile = () => {
        navigate(`${RoutePath.another_profile.path}`.replace(":uid", data?.created_by?.uid));
    }

    const getImageVerify = (image = "") => {
        let icon = <H2InfoLight />;
        let active_cls = "";
        if (image) {
            icon = <H1CheckRingRoundLight />;
            active_cls = styles["gw-item-active"];
        }
        
        return {
            icon,
            active_cls,
            image
        }
    }

    const citizen_image = getImageVerify(data?.created_by?.citizen_image);
    const citizen_user_image = getImageVerify(data?.created_by?.citizen_user_image);
    // const citizen_image = getImageVerify(null);
    // const citizen_user_image = getImageVerify(null);

    
    const [form] = useForm();
    const [masterData, setMasterData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        form.resetFields(["sales_channel_id", "sale_cost"]);
    }, [form, isModalOpen]);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit();
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish = async (values) => {
        try {
            const response = await salesProperty(data?.p_id, {
                ...values
            });
            if (response?.data?.success) {
                setData(prevData => ({
                    ...prevData,
                    status_sale: "sold"
                }));
                setIsModalOpen(false);
            } else {
                throw Error("response : "+response);
            }
        } catch (error) {
            console.warn(error)
            api["error"]({
                message: 'มีบางอย่างผิดพลาด',
            });
        }
    }

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const TravelTime = ({
        isMobile = false,
    }) => {
        const [modal, contextHolder] = Modal.useModal();
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [travelTimeList, setTravelTimeList] = useState([]);
        const [defaultDataTravelTime, setDefaultDataTravelTime] = useState({});
        const getTravelTime = async (cancelTokenSource, p_id) => {
            try {
                const response = await fetchTravelTime(cancelTokenSource, p_id);
                setTravelTimeList(response?.data?.data?.items || []);
            } catch (error) {
                console.warn(`🌦️ ~ getTravelTime ~ error:`, error);
            }
        }
        useEffect(() => {
            const cancelTokenSource = axios.CancelToken.source();
            // if (data?.p_id && isModalOpen === false) {
            //     getTravelTime(cancelTokenSource, data?.p_id);
            // }
            if (isModalOpen === false) {
                setDefaultDataTravelTime({});
            }
            return () => {
                cancelTokenSource.cancel("Operation canceled by the user.");
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [data?.p_id, isModalOpen]);
        
        const onClickEditTravelTime = (data) => {
            setIsModalOpen(true);
            setDefaultDataTravelTime(data);
        }

        const onClickDeleteTravelTime = (data) => {
            modal.confirm({
                title: 'คุณต้องการที่ลบหรือไม่ ?',
                className: 'gw-modal-sale',
                icon: <ExclamationCircleOutlined />,
                okText: 'Confirm',
                cancelText: 'Cancel',
                onOk: async () => {
                    const cancelTokenSource = axios.CancelToken.source();
                    try {
                        await deleteTravelTime(data?.travel_time_id);
                        await getTravelTime(cancelTokenSource, data?.p_id);
                    } catch (error) {
                        console.warn(`🌦️ ~ onClickDeleteTravelTime ~ error:`, error);
                    }
                }
            });
        }
        const isOwner = Boolean(userData?.uid) && data?.created_by?.uid === userData?.uid;
        // if (!isOwner && travelTimeList?.length === 0) {
        //     return null;
        // }
        return (
            <div className={clsx('gw-travel-time', isMobile && 'gw-travel-time-mobile')}>
                {contextHolder}
                {
                    isMobile ? <div style={{ marginTop: "5px" }}></div> : (
                        <span style={{ marginBottom: "8px" }}>Travel Time</span>
                    )
                }
                <Space direction='vertical' split={<Divider type="horizontal" />}>
                    {
                        travelTimeList?.map((dataTravelTime, index) => (
                            <div className='gw-travel-time-account' key={`travel-time-${dataTravelTime.travel_time_id}`}>
                                <div className='gw-avatar'>
                                    <span>{dataTravelTime?.address[0] || ""}</span>
                                </div>
                                {/* <Image preview={false} src={data?.created_by?.avatar} /> */}
                                <div className='gw-travel-time-account-info'>
                                    <span>{dataTravelTime?.address}</span>
                                    <span className='gw-travel-time-account-info-time'>
                                        <span className='gw-text-mini'>by car</span>
                                        <span>{dataTravelTime?.note || ""}</span>
                                    </span>
                                    <Space size="small">
                                        {/* <Button className='gw-travel-time-edit-btn' type="link" danger onClick={() => onClickEditTravelTime(dataTravelTime)}>
                                            Edit
                                        </Button> */}
                                        {/* <Button className='gw-travel-time-edit-btn' type="link" danger onClick={() => onClickDeleteTravelTime(dataTravelTime)}>
                                                        Delete
                                                    </Button> */}
                                    </Space>
                                </div>
                            </div>
                        ))
                    }
                </Space>
                <>
                    <div className='gw-travel-time-list'>
                        <Divider />
                        <div className='gw-travel-time-item' style={{ marginTop: "8px" }}>
                            <Button block onClick={() => setIsModalOpen(true)}>add destination</Button>
                        </div>
                    </div>
                    <TravelTimeModal {...{
                        isModalOpen,
                        handleOk: () => setIsModalOpen(false),
                        handleCancel: () => setIsModalOpen(false),
                        defaultData: defaultDataTravelTime,
                        setTravelTimeList: setTravelTimeList
                    }} />
                </>
            </div>
        )
    }

    const TravelTimeModal = ({ isModalOpen, handleOk, handleCancel, defaultData, setTravelTimeList }) => {
        const [dataTravelTime, setDataTravelTime] = useState({
            "address": "",
            "lat": 0,
            "lng": 0,
            "note": "",
            "p_id": data?.p_id
        });

        const {
            ready,
            value,
            setValue,
            suggestions: { status, data: autocompleteData },
            clearSuggestions,
        } = usePlacesAutocomplete();

        const [showAutocomplete, setShowAutocomplete] = useState(false);

        useEffect(() => {
            if (isModalOpen) {
                setShowAutocomplete(true);
            } else {
                setShowAutocomplete(false);
            }
            if (defaultData.travel_time_id && isModalOpen === true) {
                setDataTravelTime(prevData => ({
                    ...prevData,
                    ...defaultData
                }));
            }
            if (isModalOpen === false) {
                setTimeout(() => {
                    setDataTravelTime({
                        "address": "",
                        "lat": 0,
                        "lng": 0,
                        "note": "",
                        "p_id": data?.p_id
                    });
                    clearSuggestions();
                }, 300);
            }
        }, [clearSuggestions, defaultData, isModalOpen, setValue]);

        const getDistance = function (p1, p2) {
            // console.log({
            //     origins: [{ ...p1 }],
            //     destinations: [{ ...p2 }],
            //     travelMode: "DRIVING"
            // })
            const thaiTranslations = {
                "hours": "ชั่วโมง",
                "hour": "ชั่วโมง",
                "mins": "นาที",
                "min": "นาที"
            };
            // eslint-disable-next-line no-undef
            let service = new google.maps.DistanceMatrixService();
            service.getDistanceMatrix({
                origins: [{ ...p1 }],
                destinations: [{ ...p2 }],
                travelMode: "DRIVING"
            },
                (response, status) => {
                    if (status !== "OK") {
                        console.error("Error was: " + status);
                    } else {
                        const duration = response?.rows?.[0]?.elements?.[0]?.duration || {};
                        const timeInEnglish = duration.text || "";
                        const translatedTime = timeInEnglish.replace(/hours?|mins?/g, match => thaiTranslations[match]);
                        setDataTravelTime(prevData => ({
                            ...prevData,
                            note: translatedTime || ""
                        }));
                    }
                }
            );
        }
        
        const handleSelect = async (address) => {
            const regex_lat_lng = /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi;
            setValue(address, false);
            setDataTravelTime(prevData => ({
                ...prevData,
                address
            }))
            clearSuggestions();

            if (regex_lat_lng.test(`${address}`)) {
                const split_str = `${address}`.split(",");
                const lat = Number(split_str[0]) || 0;
                const lng = Number(split_str[1]) || 0;

                try {
                    setDataTravelTime(prevData => ({
                        ...prevData,
                        lat,
                        lng
                    }))
                } catch (error) {
                    console.warn(`🌦️ ~ handleSelect ~ error:`, error);
                }
            } else {
                try {
                    const results = await getGeocode({ address });
                    const selectLocation = getLatLng(results[0]);
                    setDataTravelTime(prevData => ({
                        ...prevData,
                        lat: selectLocation.lat,
                        lng: selectLocation.lng
                    }))
                    getDistance(
                        { lat: data?.latitude_lands, lng: data?.longitude_lands },
                        { lat: selectLocation.lat, lng: selectLocation.lng }
                    );
                } catch (error) {
                    console.warn(`🌦️ ~ handleSelect ~ error:`, error);
                }
            }
        };
        const onSave = async () => {
            if (!dataTravelTime.address) {
                notification['error']({
                    message: 'กรุณาเลือกสถานที่ปลายทาง',
                })
                return;
            }
            try {
                // if (Boolean(dataTravelTime.travel_time_id) === false) {
                //     await createTravelTime(dataTravelTime);
                // } else {
                //     await updateTravelTime(dataTravelTime);
                // }
                setTravelTimeList(prevData => [...prevData, dataTravelTime]);
                setDataTravelTime(prevData => ({
                    ...prevData,
                    "address": "",
                    "lat": 0,
                    "lng": 0,
                    "note": "",
                }));
                handleOk();
            } catch (error) {
                console.warn(`🌦️ ~ onSave ~ error:`, error);
            }
        }
        return (
            <Modal className={clsx('gw-modal-sale', styles["gw-modal-map"])}
                visible={isModalOpen} onOk={onSave} onCancel={handleCancel} okText={"Confirm"}
                 closeIcon={<CircleClose />} width={628}>
                {
                    showAutocomplete &&
                    <AutoComplete
                        dropdownMatchSelectWidth={300}
                        style={{ width: 250, }}
                        className={`${styles["gw-input-search"]}`}
                        onSelect={handleSelect}
                        options={
                            [
                                ...[...(value ? [{
                                    value: value,
                                    label: value
                                }] : [])],
                                ...(autocompleteData || []).map((item, index) => {
                                    return {
                                        value: item["description"],
                                        label: item["description"]
                                    }
                                })
                            ]
                        }
                    >
                        <Search
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            disabled={!ready}
                            placeholder="ค้นหา"
                        />
                    </AutoComplete>
                }
                <div className='gw-travel-time'>
                    {
                        dataTravelTime?.address && (
                            <div className='gw-travel-time-account'>
                                <div className='gw-avatar'>
                                    <span>{dataTravelTime?.address[0] || ""}</span>
                                </div>
                                {/* <Image preview={false} src={data?.created_by?.avatar} /> */}
                                <div className='gw-travel-time-account-info'>
                                    <span>{dataTravelTime?.address}</span>
                                    <span className='gw-travel-time-account-info-time'>
                                        <span className='gw-text-mini'>by car</span>
                                        <span>{dataTravelTime?.note || ""}</span>
                                    </span>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Modal>
        )
    }

    return (
        <div className={`${styles["navigate-container"]}`}>
            <Helmet>
                <title>{`${data?.advertisement ? `${data?.advertisement} - ` : ``}` || ""}Property | Growestate</title>
                <meta name="description" content="Growestate" />
                <meta 
                    property="og:url" 
                    content={getUrlProperty()} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={`${data?.advertisement || ""}`} />
                <meta property="og:description" content={`${data?.address_lands || ""}`} />
                <meta property="og:image" content={`${(data.images || [])[0]?.src || ""}`} />
                <meta name="twitter:card" content="summary_large_image"></meta>
            </Helmet>
            <BackTop>
                <span className='btn-back-to-top'>
                    <CaretUpOutlined />
                </span>
            </BackTop>
            <ImgsViewer
                imgs={data.images || []}
                showThumbnails={true}
                isOpen={isOpenLightbox}
                onClose={_ => setIsOpenLightbox(false)}
                currImg={currImg}
                onClickPrev={(e) => { setCurrImg(currImg - 1) }}
                onClickNext={(e) => { setCurrImg(currImg + 1) }}
                onClickThumbnail={index => setCurrImg(index)}
            />
            <div className={`gw-container`}>
                <Row gutter={[24, 24]} className={`gw-mt-32`}>
                    <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                        <div className={`${styles["gw-header"]}`}>
                            <div className={`gw-btn-link gw-btn-danger`} onClick={onClikeNavigateBack}>
                                <IconBack />
                                <span>Back</span>
                            </div>
                            {!isMobile && <TravelTime />}
                        </div>
                    </Col>
                    <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                        {<ImageList />}
                    </Col>
                    <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                        <div className='gw-body-details'>
                            <div className='gw-layer-1'>
                                <span>{data?.detail || ""}</span>
                            </div>
                            <div className='gw-layer-2'>
                                <div className='gw-card-detail-left'>
                                    <div className='price'>
                                        <PriceTag />
                                        <span className='price-unit'>
                                            <span className='unit'>฿</span>
                                            <span className='value'>{showPrice(data?.cost)}</span>
                                        </span>
                                        {saleBadge()}
                                    </div>
                                    <div className='area'>
                                        <span>{showPrice(data?.cost_area, data)}</span>
                                    </div>
                                </div>
                                <div className='gw-card-detail-right'>
                                    {
                                        // ที่ดิน
                                        data?.cate_id === 1 && (
                                            <>
                                                <div className='layer-2'>
                                                    {TagsDescription("area_total")}
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        // บ้าน อาคารพาณิชย์, ธุรกิจ
                                        ([2, 4, 5].includes(data?.cate_id)) && (
                                            <>
                                                <div className='layer-1'>
                                                    {TagsDescription("bedroom")}
                                                    {TagsDescription("bathroom")}
                                                    {TagsDescription("area")}
                                                </div>
                                                <div className='layer-2'>
                                                    {TagsDescription("area_total")}
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        // คอนโด
                                        ([3, 6, 7].includes(data?.cate_id)) && (
                                            <>
                                                <div className='layer-1'>
                                                    {TagsDescription("area")}
                                                    {TagsDescription("bedroom")}
                                                    {TagsDescription("bathroom")}
                                                </div>
                                                <div className='layer-2'>
                                                    {TagsDescription("car_park")}
                                                    {TagsDescription("construction_year")}
                                                    {TagsDescription("floor")}
                                                    {TagsDescription("total_floor")}
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className='gw-layer-3'>
                                <div className='gw-card-location'>
                                    <Location />
                                    <Breadcrumb
                                        separator={<svg className='gw-dot' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="50" cy="50" r="50" />
                                        </svg>}>
                                        {/* {data?.District?.dt_name && <Breadcrumb.Item>{data?.District?.dt_name || ""}</Breadcrumb.Item>} */}
                                        {data?.amphures && <Breadcrumb.Item>{data?.amphures || ""}</Breadcrumb.Item>}
                                        {data?.province && <Breadcrumb.Item>{data?.province || ""}</Breadcrumb.Item>}
                                    </Breadcrumb>
                                </div>
                            </div>
                            <Divider />
                            <div className='gw-btn-action'>
                                <div className={`gw-btn-circle btn-coin${dataAPI.isCoin ? ' active': ''}`} onClick={onClickCoin}>
                                    <div className='btn-circle'>
                                        {
                                            dataAPI.isCoin 
                                            ? <Image preview={false} src={icdollartrue} alt="false"/>
                                            : <Image preview={false} src={icdollarfalse} alt="true"/>
                                        }
                                    </div>
                                    <span>Coin</span>
                                </div>
                                <div className={`gw-btn-circle btn-favourite ${dataAPI.isFavorite ? ' active': ''}`} onClick={onClickFavorite}>
                                    <div className='btn-circle'>
                                        <Star />
                                    </div>
                                    <span>Favorite</span>
                                </div>
                                <div className='gw-btn-circle btn-coin' onClick={showShare}>
                                    <div className='btn-circle'>
                                        <Share />
                                    </div>
                                    <span>Share</span>
                                </div>
                            </div>
                            <Divider />
                            {
                                (
                                    ((userData?.uid !== data?.created_by?.uid) && data?.status_sale !== "sale")
                                    || userData?.uid === data?.created_by?.uid
                                ) && 
                                <>
                                    <Button type="primary" className='gw-btn-sold' disabled={data?.status_sale  !== "sale"} onClick={data?.status_sale === "sale" && showModal}>
                                        {data?.status_sale === "sale" ? `Sold` : `Sold Out`}
                                    </Button>
                                    <Divider />
                                </>
                            }
                            <div className='gw-mt-16'>
                                <CommentComponent dataItem={data} inputCommentRef={inputCommentRef}/>
                            </div>
                            <Divider />
                            <div className='gw-mt-16 gw-location'>
                                <div className='gw-detail-title'>
                                    <span>Location</span>
                                </div>
                                <div className='gw-mt-16 gw-location-map'>
                                    <MapContainer 
                                        defaultMarker={
                                            data?.latitude_lands && data?.longitude_lands 
                                            ? {lat: data?.latitude_lands, lng: data?.longitude_lands}
                                            : {...defaultCenter}
                                        } 
                                        defaultPolygons={data?.locationPolygon || []}/>
                                    <Tooltip title="Nearby Place">
                                        <div className={`${styles["btn-navigate-nearby-place"]}`} onClick={_ => onClickNearbyPlace({lat: data?.latitude_lands, lng: data?.longitude_lands})}></div>
                                    </Tooltip>
                                    <div className={`${styles["btn-navigate-action"]}`}>
                                        <Button shape="circle" onClick={_ => {
                                            window.open(`https://www.google.com/maps/dir/?api=1&destination=${data?.latitude_lands},${data?.longitude_lands}`, "_blank")
                                        }}>
                                            <CmSend />
                                            <span>Navigation</span>
                                        </Button>
                                        <Button shape="circle" onClick={_ => {
                                            window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${data?.latitude_lands},${data?.longitude_lands}`, "_system");
                                        }}>
                                            <img alt="" src={btnStreet} />
                                            <span>Street</span>
                                            <span>View</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            
                            {isMobile && (
                                <>
                                    <div className='gw-detail-title' style={{ marginBottom: "4px", marginTop: "16px" }}>
                                        <span>Travel Time</span>
                                    </div>
                                    <TravelTime isMobile={true} />
                                </>
                            )}
                            {/* <div className='gw-mt-16 gw-nearby-place'>
                                <div className='gw-detail-title'>
                                    <span>Nearby Place</span>
                                </div>
                                <div className='gw-list-nearby-place gw-mt-16'>
                                    <NearbyPlaceItem dataItem={{ place_type: "1" }} />
                                    <NearbyPlaceItem dataItem={{ place_type: "2" }} />
                                    <NearbyPlaceItem dataItem={{ place_type: "3" }} />
                                    <NearbyPlaceItem dataItem={{ place_type: "4" }} />
                                </div>
                            </div> */}
                            <div className='gw-mt-16 gw-information'>
                                <div className='gw-detail-title'>
                                    <span>Information</span>
                                </div>
                                <Row gutter={[17, 8]} className={`gw-list-informaion gw-mt-16`}>
                                    {
                                        [...defaultQuestionnaires].filter(item => {
                                            return [...item.cate_ids].includes(data?.cate_id) || item.output_field === "cost_area";
                                        }).map((item, index) => {
                                            let show_label = "";
                                            let title_label_custom = "";
                                            switch (item.type) {
                                                case "INPUT_TEXT_UNIT":
                                                    if (item.output_field === "cost_area") {
                                                        show_label = `${showPriceNumber(data.cost_area, data)} บาท`;
                                                        if (data?.cate_id === 1) {
                                                            title_label_custom = `ราคาพื้นที่ต่อตารางวา`;
                                                        } else {
                                                            title_label_custom = `ราคาพื้นที่ต่อตารางเมตร`;
                                                        }
                                                    } else {
                                                        show_label = `${data?.[item.output_field] || 0} ${item.label_unit}`;
                                                    }
                                                    break;
                                                case "INPUT_TEXT":
                                                    show_label = `${data?.[item.output_field]}`;
                                                    break;
                                                case "SELECTOR":
                                                    const findOption = item.options.find(fItem => fItem.value_text === data?.[item.output_field]);
                                                    show_label = `${findOption?.label || ""}`;
                                                    break;
                                                case "SWITCH":
                                                    if (data?.[item.output_field]) {
                                                        show_label = `มี`;
                                                    } else {
                                                        show_label = `ไม่มี`;
                                                    }
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return (
                                                <Col key={`question_${index}`} span={isMobile ? 24 : 12} className='gw-item'>
                                                    <div className='gw-text-left'>{title_label_custom || item.label || ""}</div>
                                                    <div className='gw-text-right'>{show_label}</div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                            <div className='gw-mt-16 gw-more-details'>
                                <div className='gw-detail-title'>
                                    <span>More Details</span>
                                </div>
                                <div className='gw-mt-16 gw-more-details-content'>
                                    <span dangerouslySetInnerHTML={
                                        {
                                            __html: (`${(data?.description || "").substring(0, 350)}${toggleDetail ? (data?.description || "").substring(350) : ""}`)
                                            .replace(/&/g,"&amp;")
                                            .replace(/</g,"&lt;")
                                            .replace(/>/g,"&gt;")
                                            .replace(/\r\n|\r|\n/g,"<br>")
                                        }
                                    }></span>
                                    {
                                        !toggleDetail
                                        && (data?.description || "").substring(350).length > 0
                                        && (
                                            <Button type='link' onClick={_ => SetToggleDetail(true)}>
                                                <span>อ่านต่อ...</span>
                                            </Button>
                                        )
                                    }
                                </div>
                                <Divider />
                                <div className='gw-mt-16 gw-more-details-user'>
                                    <div className='gw-user-details'>
                                        <Image
                                            preview={false}
                                            referrerPolicy='no-referrer'
                                            className='gw-image-profile gw-btn-link'
                                            src={data?.created_by?.avatar || ""}
                                            fallback={imgfallback}
                                            onClick={onClickNavigateAnotherProfile}/>
                                        <div className='gw-user-content'>
                                            <div className='gw-user-fullname gw-btn-link' onClick={onClickNavigateAnotherProfile}>
                                                <span>{data?.created_by?.display_name || ""}</span>
                                            </div>
                                            <Row gutter={[24, 24]} className={styles["gw-list-document"]}>
                                                {/* {
                                                    citizen_image.image && (
                                                        <Col span={12}>
                                                            <div
                                                                className={`${styles["gw-item-document"]} ${citizen_image.active_cls}`}
                                                            >
                                                                <div className={styles["gw-icon"]}>
                                                                    {citizen_image.icon}
                                                                </div>
                                                                <div className={styles["gw-label"]}>
                                                                    <span>รูปถ่ายบัตรประชาชน</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                }
                                                {
                                                    citizen_user_image.image && (
                                                        <Col span={12}>
                                                            <div className={`${styles["gw-item-document"]} ${citizen_user_image.active_cls}`}>
                                                                <div className={styles["gw-icon"]}>
                                                                    {citizen_user_image.icon}
                                                                </div>
                                                                <div className={styles["gw-label"]}>
                                                                    <span>รูปถ่ายตัวเองคู่กับบัตรประชาชน</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                } */}
                                            </Row>
                                                
                                        </div>
                                    </div>
                                    <div className='gw-user-action'>
                                        {
                                            !isSelfUser() ? (<>
                                                <Button className='btn-chat' onClick={onClickNavigateChat}>
                                                    <IcoBadgeMassenger />
                                                </Button>
                                            </>) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                        {/* <CarouselList dataPropertySimilarList={dataPropertySimilarList} /> */}
                    </Col>
                </Row>
            </div>
            <Modal forceRender className='gw-modal-sale'
                visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText={"Confirm"} 
                centered
                closeIcon={<CircleClose />}
                width={628}
                >
                <Space direction='vertical' style={{width: "100%"}}>
                    <div className={styles["gw-block-detail"]}>
                        <ImageCustom src={[...(data?.images || [])][0]?.src}/>
                        <span className='gw-detail' title={data?.detail}>{data?.detail || ""}</span>
                    </div>
                    <Form
                        autoComplete="off"
                        form={form}
                        onFinish={onFinish}
                    >
                        <div className={styles["gw-block-container"]}>
                            <span className={styles["gw-title"]}>กรอกข้อมูลการขาย</span>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item
                                        className={`${styles["gw-form-selector"]}`}
                                        name="sales_channel_id"
                                        rules={[{ required: true, message: 'Please select!' }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="ช่องทางที่ขายได้"
                                            allowClear={true}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            suffixIcon={<IcExpandMore />}
                                        >
                                            {
                                                [...masterData?.sales_channel || []].map(function (item) {
                                                    return <Select.Option 
                                                        key={item?.sales_channel_id} 
                                                        value={item?.sales_channel_id}>{item?.title || ""}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className={`${styles["gw-form-input"]}`}
                                        name="sale_cost"
                                        rules={[{ required: true, message: 'Please input!' }]}
                                    >
                                        <InputNumber
                                            prefix={<PriceTag />}
                                            controls={false}
                                            placeholder='ราคาที่ขาย'
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Space>
            </Modal>
        </div>
    )
}

export default IndexPage;