import React, { createContext, useContext, useState } from "react";
import { node } from 'prop-types';

const LoadingListContext = createContext({});

export function useLoadingListContext() {
    return useContext(LoadingListContext)
}

const initialData = {
    isLoading: false
}

function LoadingListProvidor({ children }) {
    const [data, setData] = useState(initialData);

    function updateData(nextData) {
        setData(_ => ({
            isLoading: nextData
        }))
    }

    const dataStore = {
        data,
        action: {
            updateData,
        }
    }

    return <LoadingListContext.Provider value={dataStore}>{children}</LoadingListContext.Provider>
}

LoadingListProvidor.prototype = {
    children: node.isRequired
}

export default LoadingListProvidor;