import { GoogleMap, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';
import iconMarkLogo from '../../assets/icons/maps/mark.png';
import iconCluster from '../../assets/icons/maps/cluster.png';
import { mapSizeStyles, mapSizeStylesMobile, mapStyles, MapTypeId } from './settings';
import styles from './style.module.scss';
import { defaultCenter as defaultMapCenter } from '../FindPropertyMap/settings';
import { fetchPerpertyMaps } from '../../services/service.property';
import { filterEvent } from '../../utils/common';
import { useLocation } from 'react-router-dom';
import { useFilterContext } from '../../context/filter';
import { Progress } from 'antd';
import { lastLocationFindProperty } from '../../services/localstorage';
import axios from 'axios';
import $ from 'jquery';

// eslint-disable-next-line no-unused-vars
/* global google */

let timmerGetCorners;
let timmerInitLoad;
let markerDatas = [];
function MapContainer({
	showSerchResult, hideSerchResult, mapData, pinList, isMobile = false,
}) {
    const location = useLocation();
    const {data: filterData, action: filterAction} = useFilterContext();

	const [map, setMap] = useState(null);
	// const [markerDatas, setMarkerDatas] = useState([]);
	const [isLoading, setIsLoading] = useState(0);

	useEffect(() => {
		if (filterData.is_init) {
			if (timmerInitLoad) clearTimeout(timmerInitLoad);
			if (timmerGetCorners) clearTimeout(timmerGetCorners);
			timmerInitLoad = setTimeout(() => {
				const corners = lastLocationFindProperty.get();
				loadDataMaps(corners)
			}, 700);
		}
		return () => {}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterData])

	const onLoad = useCallback(function callback(map) {
		setMap(map);
		const corners = lastLocationFindProperty.get();
		map.setZoom(corners.zoom || 8);
		map.setCenter(corners.center || defaultMapCenter);
	}, []);
	
	const onUnmount = useCallback(function callback(map) {
		for (let i = 0; i < markerDatas.length; i++) {
			markerDatas[i].marker.setMap(null);
		}
		markerDatas = [];
		setMap(null);
	}, []);

	function getCorners(_map = map) {
		let corners = {
			zoom: 8,
			center: { "lat": 14.269286118105347, "lng": 100.59796670048054 },
			radius: 0,
			nw: "",
			ne: "",
			sw: "",
			se: "",
		};
		const bounds = _map?.getBounds();
		
		if (_map) {
			if (_map?.getBounds()?.toJSON()) {
				const bounds = _map?.getBounds()?.toJSON();
				corners.nw = `${bounds.north}, ${bounds.west}`;
				corners.ne = `${bounds.north}, ${bounds.east}`;
				corners.sw = `${bounds.south}, ${bounds.west}`;
				corners.se = `${bounds.south}, ${bounds.east}`;
			}
			if (_map?.getZoom()) {
				corners.zoom = _map?.getZoom();
			}
			if (_map?.getCenter()) {
				corners.center = _map?.getCenter()?.toJSON();
			}
			corners.radius = getDistance(bounds.getSouthWest(), bounds.getCenter());
		}
		return {
			zoom: corners.zoom,
			center: corners.center,
			radius: corners.radius,
			xx: corners.nw,
			xy: corners.ne,
			yx: corners.sw,
			yy: corners.se,
		};
	}

	const getDistance = function (p1, p2) {
		const R = 6378137; // Earth’s mean radius in meter
		const dLat = rad(p2.lat() - p1.lat());
		const dLong = rad(p2.lng() - p1.lng());
		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
			Math.sin(dLong / 2) * Math.sin(dLong / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c;
		// return d; // returns the distance in meter
		return parseInt(d / 1000); // returns the distance in kilometer
	};

	const rad = function (x) {
		return x * Math.PI / 180;
	};

	function onCenterChanged(event) {
	}

	function onZoomChanged() {
		// console.log(`🌦️ ~ onZoomChanged ~ getCorners:`, getCorners());
	}

	function onBoundsChanged() {
		setIsLoading(1);
		if (timmerInitLoad) clearTimeout(timmerInitLoad);
		if (timmerGetCorners) clearTimeout(timmerGetCorners);
		timmerGetCorners = setTimeout(() => {
			const corners = getCorners();
			lastLocationFindProperty.set(corners);
			loadDataMaps(corners);
		}, 300);
	}

	async function loadDataMaps(_mapFilterData = {}) {
		try {
			setIsLoading(1);
			const filterSearchClean = filterEvent.decodeFilter(location);
			const mapFilterData = { ...filterSearchClean, ..._mapFilterData, is_init: false }
			filterAction.updateData(mapFilterData);
			delete mapFilterData.center;
			const response = await fetchPerpertyMaps(mapFilterData, setIsLoading);
			const datas = [...response?.data.data || []];
			let markers = markerDatas;
			let is_cluster = datas.some(sitem => sitem?.properties?.cluster);
			let old_marker = markers.filter(fitem => datas.some(sitem => compareMarker(sitem, fitem)));
			let rm_marker = markers.filter(fitem => !datas.some(sitem => compareMarker(sitem, fitem)));
			let new_markers = [];
			let new_markers_pin = [];
			if (
				(is_cluster === false && markers.some(sitem => sitem.is_cluster)) ||
				(is_cluster === true && markers.some(sitem => !sitem.is_cluster))) {
				for (let i = 0; i < markers.length; i++) {
					markers[i].marker.setMap(null);
				}
				new_markers = [...datas]
			} else {
				new_markers = [...datas].filter(fitem => !markers.some(sitem => compareMarker(sitem, fitem)));
				for (let i = 0; i < rm_marker.length; i++) {
					rm_marker[i].marker.setMap(null);
				}
			}

			new_markers.forEach(item => {
				if (is_cluster) {
					new_markers_pin.push({
						...item,
						marker: pinMarker(item, true),
						is_cluster: true,
					})
				} else {
					new_markers_pin.push({
						...item,
						marker: pinMarker(item, false),
						is_cluster: false,
					})
				}
			});
			
			// setMarkerDatas([...old_marker, ...new_markers_pin]);
			// console.log(`🌦️ ~ loadDataMaps ~ markerDatas:`, markerDatas);
			markerDatas = [...old_marker, ...new_markers_pin];
		} catch (error) {
			console.warn(`🌦️ ~ loadDataMaps ~ error:`, error);
		} finally {
			setTimeout(() => {
				setIsLoading(0);
			}, 300);
		}
	}

	const pinMarker = (item = {}, is_cluster = true) => {
		let marker;
		let marker_location = new google.maps.LatLng(item?.geometry?.coordinates[1], item?.geometry?.coordinates[0]);
		if (is_cluster) {
			marker = new google.maps.Marker({
				position: marker_location,
				title: `ID: ${item?.id} TYPE: ${item?.properties?.cluster ? 'cluster' : 'marker'}`,
				map: map,
				icon: {
					url: iconCluster,
					width: 40,
					height: 40,
					anchorIcon: [40, 20],
					textSize: 20,
					textColor: "#DD1508",
					textDecoration: "none",
					fontStyle: "normal",
					fontFamily: "Sarabun",
					backgroundPosition: "0 0",
				},
				label: { color: '#DD1508', fontWeight: '700', fontSize: '18px', text: `${item?.properties?.point_count || 1}` }
			});
			// add listener to redraw the polyline when markers position change
			marker.addListener('click', function () {
				map.setZoom(map.getZoom() + 1);
				map.setCenter(marker_location);
			});
		} else {
			marker = new google.maps.Marker({
				position: marker_location,
				title: `ID: ${item?.id} TYPE: ${item?.properties?.cluster ? 'cluster' : 'marker'}`,
				map: map,
				icon: {
					url: iconMarkLogo,
					scaledSize: {
						width: 30,
						height: 43
					},
				}
			});
			marker.addListener('click', function () {
				$("[p-id]").removeClass("gw-active");
				// Replace 'itemSelector' with your actual selector for the element you want to scroll to
				const $targetElement = $('[p-id="' + item?.properties?.property?.p_id + '"]');
				
				// Get the container element
				const $container = $('#simplebar-custom .simplebar-content-wrapper');
				
				// Calculate the scroll position relative to the container
				const scrollTo = $targetElement.offset().top - $container.offset().top + $container.scrollTop() - 30;
				
				// Scroll to the target element within the container
				$container.animate({
					scrollTop: scrollTo
				}, 500, function() {
					// Animation complete callback function
					$(`[p-id="${item?.properties?.property?.p_id}"]`).addClass("gw-active");
				}); // Adjust the duration as needed
			});
		}
		return marker;
	}

	const compareMarker = (a, b) => {
		if (a?.geometry.coordinates[1] === b?.geometry.coordinates[1] && a?.geometry.coordinates[2] === b?.geometry.coordinates[2]) {
			const a_cp = a?.properties?.point_count || 1;
			const b_cp = b?.properties?.point_count || 1;
			if (a_cp === b_cp) { 
				return true
			}
		}
		return false;
	}

	return (
		<>
			{
				!!isLoading && <>
					{/* <div className={styles["disabled-page"]}></div> */}
					<Progress percent={isLoading} size="small" showInfo={false} style={{position: 'absolute', bottom: '0px', zIndex: 1}}/>
				</>
			}
			<GoogleMap
				mapContainerClassName={styles["gw-map"]}
				options={{
					minZoom: 5,
					fullscreenControl: false,
					streetViewControl: false,
					styles: mapData.mapTypeId === MapTypeId.ROADMAP ? mapStyles : {},
					mapTypeControl: false,
					mapTypeId: mapData.mapTypeId,
					mapTypeControlOptions: {
						style: 1,
						position: 10,
					},
				}}
				mapContainerStyle={isMobile ? mapSizeStylesMobile : mapSizeStyles}
				onLoad={onLoad}
				onUnmount={onUnmount}
				onCenterChanged={onCenterChanged}
				onZoomChanged={onZoomChanged}
				onBoundsChanged={onBoundsChanged}
				onClick={(e) => {
					hideSerchResult();
				} }
			>
			</GoogleMap>
		</>
	);
}

export default MapContainer;