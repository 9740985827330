
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || "",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
    appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || ""
};

const collection = {
    property_comment: "/property-comment",
    property_comment_reply: "/property-comment-reply",
    messages: "/messages",
    recent_messages: "/recent-messages",
    broadcast_comment: "/broadcast-comment",
    broadcast_comment_reply: "/broadcast-comment-reply",
}

export {
    firebaseConfig,
    collection
};

const base_url = process.env.REACT_APP_API_PATH || "";
export default base_url;