import { createSlice } from '@reduxjs/toolkit'

export const chatSlice = createSlice({
    name: 'message',
    initialState: {
        messages: [],
        recentMessages: [],
        countNewMessage: 0,
        countNewNotification: 0,
    },
    reducers: {
        updateMessages: (state, sender) => {
            state.messages = [...sender.payload]
        },
        updateRecentMessages: (state, sender) => {
            state.recentMessages = [...sender.payload]
        },
        updateCountNewMessage: (state, sender) => {
            state.countNewMessage = sender.payload
        },
        updateCountNewNotification: (state, sender) => {
            state.countNewNotification = sender.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    updateMessages,
    updateRecentMessages,
    updateCountNewMessage,
    updateCountNewNotification,
} = chatSlice.actions;

export default chatSlice.reducer