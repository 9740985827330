import { Button, Col, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useLoadingListContext } from "../../context/loaddingList";

const LoadMoreElement = ({
    dataMeta = {},
    loadMorePage = 0,
    loadMoreData = function () { }
}) => {
    const {data: loadingListData} = useLoadingListContext();
    return (<>
        {
            loadingListData?.isLoading
                ? (
                    <Col span={12} offset={6} className={`gw-list-loadmore-property`}>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "var(--gw-brand-color-red-1)" }} spin />} />
                    </Col>
                )
                : <>
                    {
                        dataMeta.totalPages !== 0 && loadMorePage < dataMeta.totalPages && (
                            <Col span={12} offset={6} className={`gw-list-loadmore-property`} onClick={loadMoreData}>
                                <Button block>Loadmore</Button>
                            </Col>
                        )
                    }
                    {
                        (dataMeta?.totalItems || 0) === 0 && (
                            <Col span={12} offset={6} className={`gw-list-loadmore-property`}>
                                <span>ไม่มีข้อมูล</span>
                            </Col>
                        )
                    }
                    {
                        dataMeta.totalPages !== 0 && loadMorePage === dataMeta.totalPages && (
                            <Col span={12} offset={6} className={`gw-list-loadmore-property`}>
                                <span>สิ้นสุดข้อมูล</span>
                            </Col>
                        )
                    }
                </>
        }
    </>)
}

export default LoadMoreElement;