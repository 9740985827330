import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Col, Divider, Drawer, Dropdown, Image as ImageAntd, Input, Menu, message, Modal, notification, Row, Upload } from "antd";
import SimpleBar from 'simplebar-react';
import $ from "jquery";
import styles from './style.module.scss';
import { ReactComponent as IconBack } from '../../assets/icons/pages/findProertyDetail/arrow_left_back.svg';
import { ReactComponent as SearchAltDuotone } from '../../assets/icons/header/search_alt_duotone.svg';
import { ReactComponent as CloseRoundDuotone } from '../../assets/icons/pages/findProertyMap/close_round_duotone.svg';
import { ReactComponent as CmImgBoxLight } from '../../assets/icons/pages/findProertyDetail/cm_img_box_light.svg';
import { ReactComponent as Cm21HomeLight } from '../../assets/icons/pages/findProertyDetail/cm_2_1_home_light.svg';
import { ReactComponent as Cm22AddSquare } from '../../assets/icons/pages/findProertyDetail/cm_2_2_add_square.svg';
import { ReactComponent as CmHappyLight } from '../../assets/icons/pages/findProertyDetail/cm_happy_light.svg';
import { ReactComponent as CmSend } from '../../assets/icons/pages/findProertyDetail/cm_send.svg';
import { RoutePath } from "../../Routes";
import CardItemSecondary from "../../components/CardItemSecondary";
import { imgfallback, prepareSnapshot, filterEvent, validateNotLoadMore, customRequest, resizeFile, toBase64, showPrice } from "../../utils/common";
import stylesDrawer from '../../components/MapFilter/style.module.scss';
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import { updateMessages, updateRecentMessages } from "../../redux/action.message";
import firebaseService from "../../services/firebase.service";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import LoadMoreElement from "../../components/LoadMore";
import { useLoadingListContext } from "../../context/loaddingList";
import { useFilterContext } from "../../context/filter";
import { fetchPropertyMyProperty, fetchUploadImage, updateChat } from "../../services/service.property";
import { usePromiseTracker } from "react-promise-tracker";
import { fetchUserLogin } from '../../services/service.auth';
import ImageCustom from "../../components/ImageCustom";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ReactComponent as Ico3Dots} from '../../assets/icons/components/card-item/3_dots.svg';
import { useMediaQuery } from "react-responsive";

const layoutColumnWeb = {
    LAYOUT_1: {
        id: "LAYOUT_1",
        gutter: [24, 0],
        col_1: 6,
        col_2: 12,
        col_3: 6,
    },
    LAYOUT_2: {
        id: "LAYOUT_2",
        gutter: [24, 0],
        col_1: 6,
        col_2: 12,
        col_3: 0,
    }
}

const layoutColumnMobile = {
    LAYOUT_1: {
        id: "LAYOUT_1",
        gutter: [24, 0],
        col_1: 6,
        col_2: 12,
        col_3: 6,
    },
    LAYOUT_2: {
        id: "LAYOUT_2",
        gutter: [24, 0],
        col_1: 6,
        col_2: 12,
        col_3: 0,
    }
}

const PageChat = () => {
    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });
    let layoutColumn = isMobile ? layoutColumnMobile : layoutColumnWeb;

    const dispatch = useDispatch();
    const { messages = [], recentMessages = [] } = useSelector(state => state.message);
    const [searchText, setSearchText] = useState("");
    const [filterRecentMessages, setFilterRecentMessages] = useState([]);
    const { userData } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [userID, setUserID] = useState(null);
    const [colLayout, setColLayout] = useState(layoutColumn.LAYOUT_2);
    const [messagesHistory, setMessageHistory] = useState([]);
    const [isInit, SetIsInit] = useState(true);
    const [sendToUserData, setSendToUserData] = useState({});
    const [messageSendToTime, setMessageSendToTime] = useState("");
    const [showSelectCard, SetShowSelectCard] = useState(false);
    const [listSelectPlace, setListSelectPlace] = useState({
        datas: [],
        meta: {}
    });
    const [loadMorePage, setLoadMorePage] = useState(1);
    const {action: loadingListAction} = useLoadingListContext();
    const [contentComment, setContentComment] = useState({});
    const {data: filterData, action: filterAction} = useFilterContext();
    const location = useLocation();
    const { promiseInProgress } = usePromiseTracker();

    let countInitImage = 0;

    const inputMessageRef = useRef(null);
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        inputMessageRef?.current?.setValue();
    };
    // useEffect(scrollToBottom, [messagesHistory]);

    useEffect(() => {
        setFilterRecentMessages(recentMessages.filter(item => {
            return item.display_name.toLowerCase().includes(searchText.toLowerCase()) || searchText === "";
        }));
    }, [recentMessages, searchText, userID]);

    useEffect(() => {
        const _uid = searchParams.get("user_id");
        if (!_uid || !userData.uid) return;
        if (_uid === userData.uid) return;
        setUserID(_uid);

        const findRecentMessages = recentMessages.find(item => {
            return item.fromId === _uid || item.toId === _uid;
        });
        if (findRecentMessages) {
            setSendToUserData(findRecentMessages);
        } else if (_uid && location?.state?.created_by?.uid) {
            setSendToUserData({
                ...location?.state?.created_by,
                profileImageUrl: location?.state?.created_by?.avatar || "",
            });
        }
        inputMessageRef?.current?.setValue();
        return () => {
            setUserID(null);
            setSendToUserData({});
        }
    }, [location?.state?.created_by, recentMessages, searchParams, userData.uid]);

    useEffect(() => {
        let unsubscribes = [];
        let isInit = true;
        function prepareMessages(sources = []) {
            let datas = prepareSnapshot(sources).map(item => {
                let type = "text"
                switch (item.type) {
                    case 1: type = "text"; break;
                    case 2: type = "text_image"; break;
                    case 3: type = "text_location"; break;
                    default: break;
                }

                let uid = userData.uid;
                let isSelf = item?.create_user?.uid === uid;
                
                return {
                    ...item,
                    sourceItem: {...item},
                    type: type,
                    self: isSelf,
                    image_src: item.file,
                    create_by: {
                        ...(item.creat_by || item.create_user),
                    },
                }
            });
            datas.sort(function (x, y) {
                return dayjs(x.created_at) - dayjs(y.created_at);
            });
            return datas;
        }

        if (userData.uid && userID) {
            unsubscribes.push(firebaseService
                .getMessages()
                .doc(userData.uid)
                .collection(userID)
                .onSnapshot(qs => {
                    dispatch(updateMessages(prepareMessages(qs)));
                    if (isInit) {
                        setTimeout(() => {
                            scrollToBottom();
                            isInit = false;
                        }, 500);
                    }
                }));
        }

        return () => {
            dispatch(updateMessages([]));
            unsubscribes.forEach(func => {
                func();
            });
        }
    }, [dispatch, userData.uid, userID]);

    useEffect(() => {
        if (!sendToUserData.timestamp) {
            setMessageSendToTime("");
            return;
        }
        let intervalTime;
        setMessageSendToTime(dayjs().to(dayjs(sendToUserData.timestamp)));
        intervalTime = setInterval(() => {
            setMessageSendToTime(dayjs().to(dayjs(sendToUserData.timestamp)));
        }, 10000);
        return () => {
            if (intervalTime) clearInterval(intervalTime);
        }
    }, [sendToUserData.timestamp]);

    const ChatUserItem = ({ dataKey, dataItem }) => {
        let uid = userData.uid;
        let sendToUID = dataItem.toId;
        if (dataItem.toId === uid) {
            sendToUID = dataItem.fromId;
        }

        let [messageTime, setMessageTime] = useState("");
        let [isActive, setIsActive] = useState("");
        const [modal, modalContextHolder] = Modal.useModal();

        useEffect(() => {
            setIsActive(userID === sendToUID ? styles['gw-active'] : "")
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [userID, sendToUID]);

        useEffect(() => {
            let intervalTime;
            setMessageTime(dayjs().to(dayjs(dataItem.timestamp)));
            intervalTime = setInterval(() => {
                setMessageTime(dayjs().to(dayjs(dataItem.timestamp)));
            }, 10000);
            return () => {
                if (intervalTime) clearInterval(intervalTime);
            }
        }, [dataItem]);

        const onClickUserChat = () => {
            if (isMobile) {
                onCloseContact();
            }
            setSearchParams({
                user_id: sendToUID
            });
        }

        const onClickDelete = () => {
            modal.confirm({
                title: 'คุณต้องการที่ลบหรือไม่ ?',
                className: 'gw-modal-sale',
                icon: <ExclamationCircleOutlined />,
                okText: 'Confirm',
                cancelText: 'Cancel',
                onOk: () => {
                    const docRef = firebaseService.getRecentMessages().doc(uid).collection("messages").doc(sendToUID)
                    docRef
                        .delete()
                        .then(() => {
                            if (userID === sendToUID) {
                                setSearchParams({});
                            }
                            notification['success']({
                                message: `ลบเรียบร้อย!`,
                            });
                        })
                        .catch((error) => {
                            console.error('Error removing document: ', error);
                            notification['error']({
                                message: `มีบางอย่างผิดพลาด!`,
                            });
                        });
                }
            });
        }

        return (
            <div className={`${styles['gw-chat-user-container']}`} key={`menu_${dataKey}`}>
                {modalContextHolder}
                <Dropdown
                    overlayStyle={{
                        width: 130
                    }}
                    overlay={(
                        <Menu>
                            <Menu.Item key={"mnu_delete"} onClick={onClickDelete}>
                                ลบ
                            </Menu.Item>
                        </Menu>
                    )}
                    trigger={['click']}
                    placement={"bottomRight"}
                    className="gw-dropdown-menu"
                >
                    <Button ghost className={`gw-btn-menu`}>
                        <Ico3Dots />
                    </Button>
                </Dropdown>
                <div className={`gw-mt-16 gw-btn-link ${styles['gw-menu-item']} ${isActive}`} onClick={onClickUserChat}>
                    <div className={styles['gw-menu-item-img']}>
                        <ImageCustom src={dataItem.profileImageUrl} />
                    </div>
                    <div className={styles['gw-menu-item-description']}>
                        <div className={styles['gw-user-info']}>
                            <span className={styles["gw-text-username"]}>{dataItem.display_name}</span>
                            <span className={styles["gw-text-message"]}>{dataItem.text}</span>
                        </div>
                        <div className={styles['gw-user-notification']}>
                            {/* <div className={styles['gw-badge']}>
                            <span>-</span>
                        </div> */}
                            <span className={styles['gw-date-time']}>{messageTime}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getDate = (dataItem = {}) => {
        if (!dataItem.created_at) return "";
        return dayjs().to(dayjs(dataItem.created_at));
    }

    const getTime = (dataItem = {}) => {
        return "";
        if (!dataItem.created_at) return "";
        return dayjs(dataItem.created_at).format("HH:mm");
    }

    const MessageItem = ({ dataKey, dataItem }) => {
        // text
        // text_image
        // text_location
        useEffect(() => {
            const cls_user_info = styles["gw-message-user-info"];
            const is_cls_self = styles["gw-message-self"];
            const is_not_cls_self = styles["gw-message-not-self"];
            const selector = $(`[class~=${styles["gw-message-block"]}]`);
            selector.each(function () {
                if ($(this).hasClass(is_cls_self) && $(this).next().hasClass(is_not_cls_self)) {
                } else if ($(this).hasClass(is_not_cls_self) && $(this).next().hasClass(is_cls_self)) {
                } else if ($(this).hasClass(is_not_cls_self) && $(this).next(`.${is_not_cls_self}`).length === 0) {
                } else if ($(this).hasClass(is_cls_self) && $(this).next(`.${is_cls_self}`).length === 0) {
                } else {
                    $(this).find(`.${cls_user_info}`).find(".ant-image").hide();
                }
            })
            // code to run after render goes here
        });
        let cls_self = styles["gw-message-not-self"];
        if (dataItem.self) cls_self = styles["gw-message-self"];
        const BlockContentTyping = () => {
            return (
                <div key={`content-typing-${dataKey}`} className={`${styles["gw-message-box"]}`}>
                    <div className={`${styles["gw-message-block-content"]}`}>
                        <div className={`${styles["gw-message-content"]}`}>
                            <div className="typing">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        const BlockContentText = () => {
            return (
                <div key={`content-text-${dataKey}-${dataKey}`} className={`${styles["gw-message-box"]}`}>
                    <div className={`${styles["gw-message-block-content"]}`}>
                        <div className={`${styles["gw-message-content"]}`}>
                            <span>{dataItem.message || ""}</span>
                        </div>
                    </div>
                    <div className={`${styles["gw-message-state"]}`}>
                        {/* <span className={`${styles["gw-message-read"]}`}>อ่านแล้ว</span> */}
                        <span className={`${styles["gw-message-date-time"]}`}>{getDate(dataItem)}</span>
                        <span className={`${styles["gw-message-date-time"]}`}>{getTime(dataItem)}</span>
                    </div>
                </div>
            )
        }
        const BlockContentTextImage = () => {
            return (
                <>
                    <div key={`text-image-${dataKey}`} className={`${styles["gw-message-box"]}`}>
                        <div className={`${styles["gw-message-block-content"]}`}>
                            <div className={`${styles["gw-message-image"]}`}>
                                <ImageAntd
                                    src={dataItem?.image_src}
                                    fallback={imgfallback} />
                            </div>
                        </div>
                        <div className={`${styles["gw-message-state"]}`}>
                            {/* <span className={`${styles["gw-message-read"]}`}>อ่านแล้ว</span> */}
                            <span className={`${styles["gw-message-date-time"]}`}>{getDate(dataItem)}</span>
                            <span className={`${styles["gw-message-date-time"]}`}>{getTime(dataItem)}</span>
                        </div>
                    </div>
                    {/* <BlockContentText dataKey={`text-image`} /> */}
                </>
            )
        }
        return (
            <div key={`message-item-${dataKey}`} className={`${styles["gw-message-block"]} ${cls_self}`}>
                <div className={`${styles["gw-message-user-info"]}`}>
                    <ImageCustom src={dataItem?.create_by?.avatar} className='gw-image-profile'/>
                </div>
                <div className="gw-w-100">
                    {(dataItem.type === "typing") && <BlockContentTyping />}
                    {(dataItem.type === "text") && <BlockContentText />}
                    {(dataItem.type === "text_image") && <BlockContentTextImage />}
                    {(dataItem.type === "text_location") && <BlockContentTextLocation dataKey={dataKey} dataItem={dataItem} />}
                </div>
            </div>
        )
    }

    const BlockContentTextLocation = ({dataKey = "", dataItem = {}, isPreview = false}) => {
        let image = imgfallback;
        let title = "";
        let price = 0;
        let ref_p_id = "";
        if (isPreview) {
            const { propertyImages, advertisement, cost, p_id } = dataItem?.property || {};
            image = [...propertyImages || []][0]?.path || "no image";
            title = advertisement || "";
            price = cost || 0;
            ref_p_id = p_id;
        } else {
            const { advertisement, cost, image: ap_image, p_id} = dataItem?.attach_property || {};
            image = ap_image;
            title = advertisement || "";
            price = cost || 0;
            ref_p_id = p_id;
        }

        const onNavigateComment = () => {
            window.open(`${RoutePath.find_property_detail.path}?p_id=${ref_p_id}`);
        }

        return (
            <>
                <div key={`text-location-${dataKey}`} className={`${styles["gw-message-box"]} ${styles["gw-message-text-location"]}`}>
                    <div className={`${styles["gw-message-block-content"]}`}>
                        <ImageCustom src={image}/>
                        <div className={`${styles["gw-message-location-description"]}`}>
                            <span className={`${styles["gw-title"]}`}>{title}</span>
                            <span className={`${styles["gw-price"]}`}>
                                <span className={`${styles["gw-unit"]}`}>฿</span>
                                <span className={`${styles["gw-value"]}`}>{showPrice(price)}</span>
                            </span>
                            <div className={`gw-btn-link gw-btn-danger`} onClick={onNavigateComment}>
                                <span>ดูรายละเอียด</span>
                            </div>
                        </div>
                    </div>
                    {
                        !isPreview && (
                            <div className={`${styles["gw-message-state"]}`}>
                                {/* <span className={`${styles["gw-message-read"]}`}>อ่านแล้ว</span> */}
                                <span className={`${styles["gw-message-date-time"]}`}>{getDate(dataItem)}</span>
                                <span className={`${styles["gw-message-date-time"]}`}>{getTime(dataItem)}</span>
                            </div>
                        )
                    }
                    
                </div>
                {/* <BlockContentText dataKey={`text-location`} /> */}
            </>
        )
    }

    const onClickNavigate = () => {
        if (window?.history?.state?.key) {
            navigate(-1);
        } else {
            window.location.href = `${RoutePath.find_property_map.path}`;
        }
    }
    
    const closeGrid = (dataSelect) => {
        SetShowSelectCard(false);
        if (dataSelect?.property?.p_id) {
            setContentComment({
                type: 3,
                dataItem: dataSelect,
                attach_property_id: dataSelect?.property?.p_id
            });
        }
    }
    
    const showGrid = () => {
        SetShowSelectCard(true);
    }

    const afterVisibleChange = (event) => {
        if (event) {
            const filterSearchClean = filterEvent.decodeFilter(location);
            filterAction.updateData(filterSearchClean);
            loadDataProperty({...filterSearchClean}, false);
        } else {
            setListSelectPlace({
                datas: [],
                meta: {}
            });
            setLoadMorePage(1);
        }
    }
    
    const loadDataProperty = async (filterBody = {}, loadMore = false) => {
        try {
            loadingListAction.updateData(true);
            const responseDataProperty = await fetchPropertyMyProperty(filterBody);
            const responseItems = responseDataProperty?.data?.data?.items || [];
            const responseMeta = responseDataProperty?.data?.data?.meta || {};
            if (responseDataProperty?.data?.data) {
                setListSelectPlace(function (prevData) {
                    return {
                        ...prevData,
                        meta: {...responseMeta}
                    }
                });
                setListSelectPlace(function (prevData) {
                    return {
                        ...prevData,
                        datas: [...(loadMore ? prevData.datas : []), ...responseItems].map(function (item) {
                            return {...item}
                        })
                    }
                });
            } else {
                throw Error("no data")
            }
        } catch (error) {
            console.log(error)
        } finally {
            loadingListAction.updateData(false);
        }
    }

    const loadMoreData = () => {
        if (!promiseInProgress && validateNotLoadMore(listSelectPlace.meta, loadMorePage)) {
            setLoadMorePage(prevData => {
                return prevData + 1;
            });
        }
    }

    const onChangeUploadPhotos = ({ fileList: newFileList }) => {
        inputMessageRef?.current?.setValue("");
        let lastestFile = newFileList[newFileList.length - 1];
        if (lastestFile?.originFileObj) {
            resizeFile(lastestFile?.originFileObj).then(img => {
                lastestFile["originFileObj"] = img;
                lastestFile["size"] = img?.size || 0;
                lastestFile["type"] = img?.type;
                lastestFile["lastModified"] = img?.lastModified;
                lastestFile["lastModifiedDate"] = img?.lastModifiedDate;
                fetchUploadImage(img).then(async function (responeImage) {
                    lastestFile["fileUpload"] = responeImage?.data?.data || {};
                    let fileBase64 = null;
                    try {
                        fileBase64 = await toBase64(lastestFile.originFileObj);
                    } catch (error) {
                        
                    } finally {
                        lastestFile["fileBase64"] = fileBase64;
                        setContentComment({
                            type: 2,
                            file: lastestFile
                        });
                    }
                })
            })
        }
    };

    const onSendComment = async () => {
        let sendToUID = sendToUserData.uid || "";
        let isNewUser = !!sendToUserData.uid;
        if (!sendToUID) {
            let uid = userData.uid;
            sendToUID = sendToUserData.toId;
            if (sendToUserData.toId === uid) {
                sendToUID = sendToUserData.fromId;
            }
        };
        if (!sendToUID) return;

        const value = `${inputMessageRef?.current?.state?.value || ""}`.trim();
        let request = {
            type: 0,
            message: "",
            attach_property_id: "",
            file: "",
            to_id: sendToUID,
        }
        if (contentComment.type === 2) {
            request.type = 2;
            request.file = contentComment?.file?.fileUpload?.file_id;
            if (!request.file) return;
        } else if (contentComment.type === 3) {
            request.type = 3;
            request.attach_property_id = contentComment?.dataItem?.property?.p_id;
            if (!request.attach_property_id) return;
        } else {
            request.type = 1;
            request.message = value;
            if (!value) return;
        }

        try {
            const response = await updateChat(request);
            if (response?.data.success) {
                setContentComment({});
                if (isNewUser) {
                    window.history.replaceState({}, document.title);
                    navigate(`${RoutePath.page_chat_detail.path}?user_id=${sendToUID}`, {
                        state: {},
                        preventScrollReset: true,
                    });
                }
                scrollToBottom();
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onClickNavigateAnotherProfile = () => {
        navigate(`${RoutePath.another_profile.path}`.replace(":uid", sendToUserData.toId));
    }

    const [showContact, setShowContact] = useState(false);

    const onCloseContact = () => {
        setShowContact(false);
    }

    const onClickShowContact = () => {
        setShowContact(true);
    }

    if (isMobile) {
        return (
            <div className={`gw-container ${styles["gw-chat"]}`}>
                <Row gutter={[24, 0]}>
                    {/* <Col span={colLayout.col_1} className="gw-mt-32">
                        <div className={`gw-mt-24 ${styles["gw-chat-header"]}`}>
                            <span className={`${styles["gw-chat-header-label"]}`}>Conversation</span>
                            <div className={`gw-mt-16 ${styles["gw-chat-header-search"]}`}>
                                <Input placeholder="ค้นหา" prefix={<SearchAltDuotone />} onChange={event => setSearchText(event.target.value)}/>
                            </div>
                        </div>
                        <div className={`gw-mt-24 ${styles["gw-chat-list"]}`}>
                            {
                                [...recentMessages].map((dataItem, indexItem) => {
                                    return <ChatUserItem key={indexItem} dataKey={indexItem} dataItem={dataItem} />
                                })
                            }
                        </div>
                    </Col> */}
                    <Col span={24}>
                        <div className="gw-d-flex">
                            <div className="gw-btn-link gw-btn-danger" onClick={onClickNavigate}>
                                <IconBack />
                                <span>ย้อนกลับ</span>
                            </div>
                            <div className="gw-btn-link gw-btn-danger" style={{ marginLeft: "auto" }} onClick={onClickShowContact}>
                                <span>รายชื่อ</span>
                            </div>
                        </div>
                        <div className={styles["gw-chat-block"]}>
                            {
                                userID && (<>
                                    <div className={`${styles["gw-chat-user-info"]} ${styles["gw-btn-link"]}`} onClick={onClickNavigateAnotherProfile}>
                                        <ImageCustom src={sendToUserData?.profileImageUrl}/>
                                        <div className={styles["gw-chat-description"]}>
                                            <span className={styles["gw-chat-username"]}>{sendToUserData?.display_name}</span>
                                            <span className={styles["gw-chat-time-active"]}>{messageSendToTime}</span>
                                        </div>
                                    </div>
                                    <Divider />
                                </>)
                            }
                            <div className={styles["gw-chat-content"]}>
                                <ImageAntd.PreviewGroup>
                                    <SimpleBar className={styles["gw-chat-history"]}>
                                        {
                                            userID && <>
                                                {
                                                    [...messages].map((dataItem, dataIndex) => {
                                                        return <MessageItem key={`item-${dataIndex}`} dataItem={dataItem} />
                                                    })
                                                }
                                            </>
                                        }
                                        <div ref={messagesEndRef} />
                                    </SimpleBar>
                                </ImageAntd.PreviewGroup>
                                {
                                    !!Object.keys(sendToUserData).length && (
                                        <div className={`gw-comment-input`}>
                                            <div className='gw-input-addons'>
                                                <Upload
                                                    action={false}
                                                    fileList={[]}
                                                    // previewFile={false}
                                                    // multiple={false}
                                                    onChange={onChangeUploadPhotos}
                                                    onPreview={[]}
                                                    customRequest={customRequest}
                                                    accept={"image/png, image/gif, image/jpeg"}
                                                >
                                                    <Button>
                                                        <CmImgBoxLight />
                                                    </Button>
                                                </Upload>
                                                <Button onClick={showGrid}>
                                                    <Cm21HomeLight />
                                                    <Cm22AddSquare className='gw-addsquare' />
                                                </Button>
                                            </div>
                                            <div className='gw-input-user-profile'>
                                                <ImageCustom src={userData?.avatar} className='gw-image-profile' />
                                            </div>
                                            <div className={`gw-input-box`}>
                                                {
                                                    contentComment.type ? <></>
                                                        : (
                                                            <Input onPressEnter={onSendComment} ref={inputMessageRef} placeholder='เขียนความคิดเห็น...' suffix={(
                                                                <>
                                                                    <div className='gw-btn-link btn-coin'>
                                                                        <CmSend onClick={onSendComment} />
                                                                    </div>
                                                                </>
                                                            )} />
                                                        )
                                                }
                                                {
                                                    contentComment.type ? (
                                                        <div className='gw-reply-container'>
                                                            <div style={{ width: "100%" }}>
                                                                {
                                                                    contentComment.type === 2
                                                                        ? (
                                                                            <ImageCustom src={contentComment.file.fileBase64 || ""} className='gw-comment-preview-image' />
                                                                        )
                                                                        : contentComment.type === 3
                                                                            ? (<BlockContentTextLocation dataItem={contentComment?.dataItem} isPreview={true} />)
                                                                            : <></>
                                                                }
                                                            </div>
                                                            <div className='gw-reply-action'>
                                                                <div className='gw-reply-close' onClick={_ => setContentComment({})}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg>
                                                                </div>
    
                                                                <div className='gw-btn-link btn-coin'>
                                                                    <CmSend onClick={onSendComment} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                <Drawer
                    className={`${stylesDrawer["gw-drawer-filter"]}`}
                    headerStyle={{ display: "none" }}
                    bodyStyle={{ padding: "0px" }}
                    push={true}
                    placement="right"
                    afterVisibleChange={afterVisibleChange}
                    onClose={closeGrid}
                    closeIcon={null}
                    visible={showSelectCard}>
                    <InfiniteScroll
                        style={{ padding: "32px 24px 46px 24px" }}
                        initialLoad={false}
                        loadMore={loadMoreData}
                        hasMore={validateNotLoadMore(listSelectPlace.meta, loadMorePage)}
                        useWindow={false}
                    >
                        <div className={`gw-search-resoult-list`}>
                            <div className="gw-search-result">
                                <Row gutter={[0, 16]} className="gw-mt-24">
                                    <Col span={24}>
                                        <div className="gw-btn-action">
                                            <div className="gw-btn-link" onClick={closeGrid}>
                                                <CloseRoundDuotone />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="gw-search-result-header">
                                            <span className="gw-result-count">
                                                <span>{[...listSelectPlace.datas].length}</span>
                                                <span>Posts</span>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={[0, 16]} className="gw-mt-16">
                                    {
                                        [...listSelectPlace.datas].map((item, index) => {
                                            return (
                                                <CardItemSecondary key={index} dataitem={item} hideSelectPlace={closeGrid} />
                                            )
                                        })
                                    }
                                    <LoadMoreElement {...{ dataMeta: listSelectPlace.meta, loadMorePage, loadMoreData }} />
                                </Row>
                            </div>
                        </div>
                    </InfiniteScroll>
                </Drawer>
                <Drawer title="Conversation" push={true} placement="right" onClose={onCloseContact} visible={showContact}>
                    <div className={styles["gw-chat"]}>
                    <div className={`${styles["gw-chat-header"]}`}>
                        <div className={`gw-mt-16 ${styles["gw-chat-header-search"]}`}>
                            <Input placeholder="ค้นหา" prefix={<SearchAltDuotone />} onChange={event => setSearchText(event.target.value)}/>
                        </div>
                    </div>
                    <div className={`gw-mt-24 ${styles["gw-chat-list"]}`}>
                        {
                            [...filterRecentMessages].map((dataItem, indexItem) => {
                                return <ChatUserItem key={indexItem} dataKey={indexItem} dataItem={dataItem} />
                            })
                        }
                    </div>
                    </div>
                </Drawer>
            </div>
        )
    }

    return (
        <div className={`gw-container ${styles["gw-chat"]}`}>
            <Row gutter={[24, 0]}>
                <Col span={colLayout.col_1} className="gw-mt-32">
                    <div className="gw-d-flex">
                        <div className="gw-btn-link gw-btn-danger" onClick={onClickNavigate}>
                            <IconBack />
                            <span>ย้อนกลับ</span>
                        </div>
                    </div>
                    <div className={`gw-mt-24 ${styles["gw-chat-header"]}`}>
                        <span className={`${styles["gw-chat-header-label"]}`}>Conversation</span>
                        <div className={`gw-mt-16 ${styles["gw-chat-header-search"]}`}>
                            <Input placeholder="ค้นหา" prefix={<SearchAltDuotone />} onChange={event => setSearchText(event.target.value)}/>
                        </div>
                    </div>
                    <div className={`gw-mt-24 ${styles["gw-chat-list"]}`}>
                        {
                            [...filterRecentMessages].map((dataItem, indexItem) => {
                                return <ChatUserItem key={indexItem} dataKey={indexItem} dataItem={dataItem} />
                            })
                        }
                    </div>
                </Col>
                <Col span={colLayout.col_2}>
                    <div className={styles["gw-chat-block"]}>
                        {
                            userID && (<>
                                <div className={`${styles["gw-chat-user-info"]} ${styles["gw-btn-link"]}`} onClick={onClickNavigateAnotherProfile}>
                                    <ImageCustom src={sendToUserData?.profileImageUrl}/>
                                    <div className={styles["gw-chat-description"]}>
                                        <span className={styles["gw-chat-username"]}>{sendToUserData?.display_name}</span>
                                        <span className={styles["gw-chat-time-active"]}>{messageSendToTime}</span>
                                    </div>
                                </div>
                                <Divider />
                            </>)
                        }
                        <div className={styles["gw-chat-content"]}>
                            <ImageAntd.PreviewGroup>
                                <SimpleBar className={styles["gw-chat-history"]}>
                                    {
                                        userID && <>
                                            {
                                                [...messages].map((dataItem, dataIndex) => {
                                                    return <MessageItem key={`item-${dataIndex}`} dataItem={dataItem} />
                                                })
                                            }
                                        </>
                                    }
                                    <div ref={messagesEndRef} />
                                </SimpleBar>
                            </ImageAntd.PreviewGroup>
                            {
                                !!Object.keys(sendToUserData).length && (
                                    <div className={`gw-comment-input`}>
                                        <div className='gw-input-addons'>
                                            <Upload
                                                action={false}
                                                fileList={[]}
                                                // previewFile={false}
                                                // multiple={false}
                                                onChange={onChangeUploadPhotos}
                                                onPreview={[]}
                                                customRequest={customRequest}
                                                accept={"image/png, image/gif, image/jpeg"}
                                            >
                                                <Button>
                                                    <CmImgBoxLight />
                                                </Button>
                                            </Upload>
                                            <Button onClick={showGrid}>
                                                <Cm21HomeLight />
                                                <Cm22AddSquare className='gw-addsquare' />
                                            </Button>
                                        </div>
                                        <div className='gw-input-user-profile'>
                                            <ImageCustom src={userData?.avatar} className='gw-image-profile' />
                                        </div>
                                        <div className={`gw-input-box`}>
                                            {
                                                contentComment.type ? <></>
                                                    : (
                                                        <Input onPressEnter={onSendComment} ref={inputMessageRef} placeholder='เขียนความคิดเห็น...' suffix={(
                                                            <>
                                                                <div className='gw-btn-link btn-coin'>
                                                                    <CmSend onClick={onSendComment} />
                                                                </div>
                                                            </>
                                                        )} />
                                                    )
                                            }
                                            {
                                                contentComment.type ? (
                                                    <div className='gw-reply-container'>
                                                        <div style={{ width: "100%" }}>
                                                            {
                                                                contentComment.type === 2
                                                                    ? (
                                                                        <ImageCustom src={contentComment.file.fileBase64 || ""} className='gw-comment-preview-image' />
                                                                    )
                                                                    : contentComment.type === 3
                                                                        ? (<BlockContentTextLocation dataItem={contentComment?.dataItem} isPreview={true} />)
                                                                        : <></>
                                                            }
                                                        </div>
                                                        <div className='gw-reply-action'>
                                                            <div className='gw-reply-close' onClick={_ => setContentComment({})}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                </svg>
                                                            </div>

                                                            <div className='gw-btn-link btn-coin'>
                                                                <CmSend onClick={onSendComment} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Col>
            </Row>
            <Drawer
                className={`${stylesDrawer["gw-drawer-filter"]}`}
                headerStyle={{ display: "none" }}
                bodyStyle={{ padding: "0px" }}
                push={true}
                placement="right"
                afterVisibleChange={afterVisibleChange}
                onClose={closeGrid}
                closeIcon={null}
                visible={showSelectCard}>
                <InfiniteScroll
                    style={{ padding: "32px 24px 46px 24px" }}
                    initialLoad={false}
                    loadMore={loadMoreData}
                    hasMore={validateNotLoadMore(listSelectPlace.meta, loadMorePage)}
                    useWindow={false}
                >
                    <div className={`gw-search-resoult-list`}>
                        <div className="gw-search-result">
                            <Row gutter={[0, 16]} className="gw-mt-24">
                                <Col span={24}>
                                    <div className="gw-btn-action">
                                        <div className="gw-btn-link" onClick={closeGrid}>
                                            <CloseRoundDuotone />
                                        </div>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className="gw-search-result-header">
                                        <span className="gw-result-count">
                                            <span>{[...listSelectPlace.datas].length}</span>
                                            <span>Posts</span>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={[0, 16]} className="gw-mt-16">
                                {
                                    [...listSelectPlace.datas].map((item, index) => {
                                        return (
                                            <CardItemSecondary key={index} dataitem={item} hideSelectPlace={closeGrid} />
                                        )
                                    })
                                }
                                <LoadMoreElement {...{ dataMeta: listSelectPlace.meta, loadMorePage, loadMoreData }} />
                            </Row>
                        </div>
                    </div>
                </InfiniteScroll>
            </Drawer>
        </div>
    )
}

export default PageChat;