import { Button, Row, Col } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { useModalContext } from '../../context/modals';
import GWMap from './MapsNew';
import MapFilter from '../../components/MapFilter';
import styles from './style.module.scss';
import icoFilter from '../../assets/icons/pages/findProertyMap/filter_alt.svg';
import { ReactComponent as IcoFilter } from '../../assets/icons/pages/findProertyMap/filter_alt.svg';
import icoMenu from '../../assets/icons/pages/findProertyMap/menu.svg';
import icoMapLayer1 from '../../assets/icons/pages/findProertyMap/maps_1.svg';
import icoMapLayer2 from '../../assets/icons/pages/findProertyMap/maps_2.svg';
import { ReactComponent as CloseRoundDuotone } from '../../assets/icons/pages/findProertyMap/close_round_duotone.svg';
import * as localServices from '../../services/localstorage';
import { filterEvent, prepareFilterPropertySimilar } from '../../utils/common';
import CardItemSecondary from "../../components/CardItemSecondary";
import { RoutePath } from "../../Routes";
import SimpleBar from 'simplebar-react';
import { fetchPerperty, fetchPerpertySimilar } from "../../services/service.property";
import { useFilterContext } from "../../context/filter";
import * as local from '../../services/localstorage';
import { useMediaQuery } from "react-responsive";
import addRingLight from '../../assets/icons/pages/Mygrow/Add_ring_light.svg';

const layoutColumnWeb = {
    LAYOUT_1: {
        id: "LAYOUT_1",
        gutter: [24, 0],
        col_1: 18,
        col_2: 6
    },
    LAYOUT_2: {
        id: "LAYOUT_2",
        gutter: [0, 0],
        col_1: 24,
        col_2: 0
    }
}

const layoutColumnMobile = {
    LAYOUT_1: {
        id: "LAYOUT_1",
        gutter: [24, 0],
        col_1: 4,
        col_2: 20
    },
    LAYOUT_2: {
        id: "LAYOUT_2",
        gutter: [0, 0],
        col_1: 24,
        col_2: 0
    }
}

const MapTypeId = {
    "ROADMAP": "roadmap",
    "SATELLITE": "satellite",
    "HYBRID": "hybrid",
    "TERRAIN": "terrain"
}

const IndexPage = () => {
    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });
    let layoutColumn = isMobile ? layoutColumnMobile : layoutColumnWeb;
    
    const navigate = useNavigate();
    const location = useLocation();
    const filterSearchClean = filterEvent.decodeFilter(location);

    const modalContext = useRef(useModalContext());
    const [visibleDrawerFilter, setVisibleDrawerFilter] = useState(false);
    const [colLayout, setColLayout] = useState(layoutColumn.LAYOUT_2);
    const [mapData, setMapData] = useState({
        location: {},
        mapTypeId: MapTypeId.ROADMAP
    });
    const [dataPropertyList, setDataPropertyList] = useState([]);
    const [dataPropertyMeta, setDataPropertyMeta] = useState({});
    const [isLoadProperty, setIsLoadProperty] = useState(false);
    const {data: filterData, action: filterAction} = useFilterContext();
    const [dataPropertySimilarList, setDataPropertySimilarList] = useState([]);
    const [dataPropertySimilarMeta, setDataPropertySimilarMeta] = useState({});

    useEffect(() => {
        const currentMNewsData = localServices.checkModalNews(modalContext?.current?.data);
        modalContext.current.action.updateModalNews(currentMNewsData);
        return () => {};
    }, [modalContext]);

    useEffect(() => {
        setVisibleDrawerFilter(false);
        const filterSimilar = local.lastLocation.get();
        if (filterSimilar.title_deen_no || filterSimilar.title_deen_no === 0) {
            showGrid({
                data: {
                    property: {
                        title_deen_no: filterSimilar.title_deen_no
                    }
                }
            })
        }
        return () => {};
    }, []);

    const onClikeNavigateList = () => {
        const path = isMobile ? RoutePath.find_property_list.pathMobile : RoutePath.find_property_list.path;
        navigate(`${path}${location.search}`)
    }

    const showDrawerFilter = () => {
        setVisibleDrawerFilter(true);
    };

    const closeDrawerFilter = () => {
        setVisibleDrawerFilter(false);
    };

    useEffect(() => {
        filterAction.updateData({...filterSearchClean, is_init: true});
        return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const onFilterFinish = (_values) => {
        const values = {..._values};
        const path = isMobile ? RoutePath.find_property_map.pathMobile : RoutePath.find_property_map.path;
        console.log(`🌦️ ~ onFilterFinish ~ path:`, path);
        navigate({
            search: `?${filterEvent.encodeFilter(values)}`,
        });
        closeDrawerFilter();
    };

    const onFilterFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFilterReset = (...args) => {
        console.log('Reset:', `defaultValueFilter`);
    };

    const isLayout2 = () => {
        return colLayout.id === "LAYOUT_2";
    }
    
    const toggleGrid = () => {
        colLayout.id === "LAYOUT_1" ? setColLayout(layoutColumn.LAYOUT_2) : setColLayout(layoutColumn.LAYOUT_1)
    }
    
    const closeGrid = () => {
        setColLayout(layoutColumn.LAYOUT_2);
        setDataPropertySimilarList([]);
        setDataPropertySimilarMeta({});
        local.lastLocation.set({});
    }
    
    const showGrid = async (markerData = {}) => {
        const properties = markerData?.properties || {};
        setColLayout(layoutColumn.LAYOUT_1);
        local.lastLocation.set({
            // title_deen_no: filterSimilar.title_deen_no,
            p_id: properties.property.p_id
        });
        setDataPropertySimilarList([{...properties}]);
        setDataPropertySimilarMeta({});
        return;
        try {
            const filterSimilar = prepareFilterPropertySimilar(markerData?.properties?.property || {});
            local.lastLocation.set({
                // title_deen_no: filterSimilar.title_deen_no,
                p_id: filterSimilar.p_id
            });
            const response = await fetchPerpertySimilar(filterSimilar, filterSimilar.title_deen_no);
            if (response?.data?.data) {
                setDataPropertySimilarList([...(response?.data?.data?.items || [])]);
                setDataPropertySimilarMeta({ ...(response?.data?.data?.meta || {}) });
                return;
            }
            throw Error("prepareFilterPropertySimilar no data");
        } catch (error) {
            console.warn("error", error);
            setDataPropertySimilarList([]);
            setDataPropertySimilarMeta({});
        } finally {
        }
    }

    const onClickToggleLayer = () => {
        if (MapTypeId.ROADMAP === mapData.mapTypeId) {
            setMapData(prevState => {
                return {
                    ...prevState,
                    mapTypeId: MapTypeId.HYBRID
                }
            });
        } else {
            setMapData(prevState => {
                return {
                    ...prevState,
                    mapTypeId: MapTypeId.ROADMAP
                }
            });
        }
    }

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        }
    }

    function showPosition(position) {
        setMapData(prevState => {
            return {
                ...prevState,
                zoom: 15,
                location: {
                    lat: position?.coords?.latitude,
                    lng: position?.coords?.longitude
                }
            }
        });
    }

    const onClickSetCurrentLocation = () => {
        getLocation()
    }

    const loadDataProperty = async (requestBody) => {
        setIsLoadProperty(true);
        const responseDataProperty = await fetchPerperty(requestBody);
        if (responseDataProperty?.data?.data) {
            setDataPropertyMeta(function () {
                return {...(responseDataProperty?.data?.data?.meta || {})}
            });
            setDataPropertyList(function () {
                return [...(responseDataProperty?.data?.data?.items || [])]
            });
            setIsLoadProperty(false);
        }else {
            setDataPropertyMeta(function () {
                return {}
            });
            setDataPropertyList(function () {
                return []
            });
        }
        setIsLoadProperty(false);
    }

    const onNavigateAddBtn = useCallback(
        () => {
            navigate(RoutePath.propmarket_my_property_create.path);
        },
        [navigate],
    );

    return (
        <Row gutter={colLayout.gutter} className={`${styles["navigate-container"]}`}>
            {/* <Helmet>
                <title>Find Property Map | Growestate</title>
            </Helmet> */}
            <div className={`${styles["btn-navigate-action"]}`}>
                {/* <Button shape="circle" onClick={onNavigateAddBtn}>
                    <img src={addRingLight} alt="" />
                    <span>{`Create`}</span>
                </Button> */}
                {
                    isLayout2() && (
                        <Button shape="circle" onClick={showDrawerFilter}>
                            <img src={icoFilter} alt="" />
                            <span>Filter</span>
                        </Button>
                    )
                }
                <Button shape="circle" onClick={onClikeNavigateList}>
                    <img src={icoMenu} alt="" />
                    <span>List</span>
                </Button>
            </div>
            <div className={`${styles["btn-action-maps"]}`}>
                <Button shape="circle" onClick={onClickToggleLayer}>
                    <img src={icoMapLayer1} alt="" />
                </Button>
                <Button shape="circle" onClick={onClickSetCurrentLocation}>
                    <img src={icoMapLayer2} alt="" />
                </Button>
            </div>
            <Col span={colLayout.col_1} className={`${styles["col-map"]}`}>
                <GWMap 
                    {
                        ...{
                            showSerchResult: showGrid,
                            hideSerchResult: closeGrid,
                            mapData: mapData,
                            pinList: dataPropertyList,
                            isMobile: isMobile
                        }
                    }
                />
            </Col>
            <Col span={colLayout.col_2}>
                <SimpleBar className="gw-search-resoult-list">
                    <div className="gw-search-result">
                        <Row gutter={[0, 16]} className="gw-mt-24">
                            <Col span={24}>
                                <div className="gw-btn-action">
                                    <div className="gw-btn-link" onClick={closeGrid}>
                                        <CloseRoundDuotone />
                                    </div>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="gw-search-result-header">
                                    <span className="gw-result-count">
                                        <span>{dataPropertySimilarList.length}</span>
                                        <span>Posts</span>
                                    </span>
                                    <div className="gw-btn-link gw-result-filter" onClick={showDrawerFilter}>
                                        <IcoFilter />
                                        <span>Filter</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]} className="gw-mt-16">
                            {
                                dataPropertySimilarList.map((item, index) => {
                                    return (
                                        <Col key={index} span={24}>
                                            <CardItemSecondary isSelector={false} dataitem={item} />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </SimpleBar>
            </Col>
            <MapFilter 
                visibleDrawerFilter={visibleDrawerFilter}
                closeDrawerFilter={closeDrawerFilter}
                onFilterFinish={onFilterFinish}
                onFilterFinishFailed={onFilterFinishFailed}
                onFilterReset={onFilterReset}
            />
        </Row>
    )
}

export default IndexPage;