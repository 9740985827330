import { useEffect, useMemo, useState } from 'react';
import styles from './style.module.scss';
import MyGrowMain from "../MyGrow";
import stylesCardItem from '../../components/CardItemPrimary/style.module.scss';
import stylesPropmarketDetails from './style.module.scss';
import { Breadcrumb, Button, Col, Divider, Image, Input, Row } from 'antd';
import CardItemPrimary from '../../components/CardItemPrimary';
import { ReactComponent as SearchAltDuotone } from '../../assets/icons/header/search_alt_duotone.svg';
import { defaultValueFilter, imgfallback } from '../../utils/common';
import CommentComponent from '../../components/Comment';
import MapContainer from '../../components/GMap';
import { ReactComponent as IconBack } from '../../assets/icons/pages/findProertyDetail/arrow_left_back.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from '../../Routes';
import stylesBroadcast from './../MyGrow3Broadcast/style.module.scss';
import stylesMyGrow6RecentlyView from './../MyGrow6RecentlyView/style.module.scss';
import MapFilter from '../../components/MapFilter';
import { ReactComponent as IcoFilter } from '../../assets/icons/pages/findProertyMap/filter_alt.svg';
import * as services from "../../services/service.pinLocation";
import { fetchPerpertyDetails } from '../../services/service.property';
import ListElement from '../../components/ListElement';

const MyGrow4PinLocationList = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const pin_id = searchParams.get('pin_id') || "";
    const [data, setData] = useState({});
    const [_dataList, setDataList] = useState([]);
    
    const dataList = useMemo(() => {
        return _dataList;
    }, [_dataList]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const response = await services.fetchPinLocationByPinId(pin_id);
                const responseData = response?.data?.data || {};
                if (response?.data?.data) {
                    setData(responseData);
                    const requestPropertyList = [...responseData.interestMatching || []].map(item => {
                        return fetchPerpertyDetails(item.p_id)
                    });
                    Promise.allSettled(requestPropertyList).then(allResponse => {
                        const prepareData = allResponse
                            .map(item => {
                                if (item?.value?.data?.data) {
                                    return {
                                        property: item?.value?.data?.data || {}
                                    }
                                }
                                return null;
                            })
                            .filter(item => item);
                        setDataList(prepareData);
                    })
                } else {
                    throw Error("no data")
                }
            } catch (error) {
                setData({});
            }
        }
        loadData()
    }, [pin_id]);

    const getData = useMemo(() => {
        return data;
    }, [data]);

    const prepareMapData = () => {
        let result = {};
        if (getData.lat && getData.lng) {
            result["defaultMarker"] = {
                lat: getData.lat,
                lng: getData.lng
            }
        }
        if (getData.radius_m) {
            result["defaultValue"] = {
                rangeArea: getData.radius_m
            }
        }
        return result;
    }

    const onClikeNavigateBack = () => {
        if (window?.history?.state?.key) {
            navigate(-1);
        } else {
            navigate(RoutePath.my_grow_broadcast.path, {
                replace: true
            });
        }
    }
    
    const [visibleDrawerFilter, setVisibleDrawerFilter] = useState(false);

    const showDrawerFilter = () => {
        setVisibleDrawerFilter(true);
    };

    const closeDrawerFilter = () => {
        setVisibleDrawerFilter(false);
    };

    const onFilterFinish = (values) => {
        console.log('Success:', values);
    };

    const onFilterFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFilterReset = (...args) => {
        console.log('Reset:', defaultValueFilter);
    };
    return (
        <MyGrowMain>
            <div className={`${stylesBroadcast["gw-header"]}`}>
                <div className={`gw-text-title`}>
                    <span>Pin Location</span>
                </div>
                <Button type="link" className={`${stylesMyGrow6RecentlyView["btn-link"]}`} onClick={showDrawerFilter}>
                    <IcoFilter />
                    <span>ตัวกรอง</span>
                </Button>
            </div>
            <Row gutter={[24, 24]} className={`gw-mt-16`} style={{ paddingBottom: 35}}>
                <Col span={24}>
                    <div className={`${styles["gw-header"]}`}>
                        <div className={`gw-btn-link gw-btn-danger`} onClick={onClikeNavigateBack}>
                            <IconBack />
                            <span>Back</span>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <MapContainer {...prepareMapData()} isBroadCast={true} isPinLocation={true}/>
                </Col>
                <Col span={24}>
                    <Row gutter={[24, 24]}>
                        <ListElement list={dataList} type={"DEFAULT_24"}/>
                    </Row>
                </Col>
            </Row>
            <MapFilter 
                visibleDrawerFilter={visibleDrawerFilter}
                closeDrawerFilter={closeDrawerFilter}
                onFilterFinish={onFilterFinish}
                onFilterFinishFailed={onFilterFinishFailed}
                onFilterReset={onFilterReset}
            />
        </MyGrowMain>
    )
}

export default MyGrow4PinLocationList;