/*global google*/
import styles from './style.module.scss';
import { AutoComplete, Button, Col, Form, Input, Modal, Row, Slider } from "antd";
import { Circle, GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { defaultCenter, mapCircleStyles, mapModalSizeStyles, mapStyles, MapTypeId } from '../FindPropertyMap/settings';
import React, { useCallback, useEffect, useState } from 'react';
import iconMarkLogo from '../../assets/icons/maps/mark.png';
import { ReactComponent as BackLight } from '../../assets/icons/pages/Propmarket/my-property-create/Back_light.svg';
import { ReactComponent as DellLight } from '../../assets/icons/pages/Propmarket/my-property-create/Dell_light.svg';
import PropTypes from 'prop-types';
import MapContainer from "../../components/GMap";
import icoMapLayer1 from '../../assets/icons/pages/findProertyMap/maps_1.svg';
import icoMapLayer2 from '../../assets/icons/pages/findProertyMap/maps_2.svg';
import html2canvas from 'html2canvas';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

const { Search } = Input;
const ModalMap = ({
    visibleModalMap: visible = true,
    hideModalMap: hideModal,
    userPinMarker,
    setUserPinMarker,
    formData = {},
    setAreaRange: setNewAreaRange,
    setMapImage
}) => {
	const [map, setMap] = useState(null);
	const [mapSnapshot, setMapSnapshot] = useState(null);
    const [pin, setPin] = useState();
    const [zoom, setZoom] = useState();
    const [mapData, setMapData] = useState({
        location: {},
        mapTypeId: MapTypeId.ROADMAP
    });
	const onLoad = useCallback(function callback(map) {
		setMap(map)
	}, []);
	const onLoadSnapshot = useCallback(function callback(map) {
		setMapSnapshot(map)
	}, []);
	const onUnmount = useCallback(function callback(map) {
		setMap(null)
	}, []);
	const onUnmountSnapshot = useCallback(function callback(map) {
		setMap(null)
	}, []);
    useEffect(() => {
        setPin(userPinMarker);
    }, [userPinMarker, visible]);

    const [areaRangeShow, setAreaRangeShow] = useState(formatterSlider(formData.area_range));
    const [areaRange, setAreaRange] = useState(formData.area_range);
    const onClickSubmit = () => {
        const location = pin?.location || defaultCenter;
        setUserPinMarker({
            location: location,
            zoom: zoom || 7,
            percel: {}
        });
        setNewAreaRange(areaRange);
        onSnapshotMap();
        hideModal();
    }

    function onIdle(map) {
        updateFitBounds();
    }

    function onIdleSnapshot(map) {
        updateFitBounds();
    }

    function onSnapshotMap() {
        html2canvas(document.getElementsByClassName("gm-style")[2], {
            useCORS: true,
            allowTaint: true,
            backgroundColor: "#ebebeb"
        }).then(function(canvas) {
            canvas.toBlob(function (blob) {
                blob.name = `map-${new Date().valueOf()}.png`;
                setMapImage(blob);
            })
        });
    }

    useEffect(() => {
        if (areaRange) {
            updateFitBounds();
        }
    }, [areaRange]);
    
    useEffect(() => {
        if (window.google) {
            updateFitBounds()
        }
    }, [window.google]);
    
    function updateFitBounds() {
        const location = pin?.location || defaultCenter;
        if (window.google && location && areaRange && mapSnapshot) {
            const left = new google.maps.geometry.spherical.computeOffset(location, areaRange, -90);
            const right = new google.maps.geometry.spherical.computeOffset(location, areaRange, 90);
            const top = new google.maps.geometry.spherical.computeOffset(location, areaRange, 0);
            const bottom = new google.maps.geometry.spherical.computeOffset(location, areaRange, 180);
            let circleBounds = new google.maps.LatLngBounds();
            circleBounds.extend(location);
            circleBounds.extend(left);
            circleBounds.extend(right);
            circleBounds.extend(top);
            circleBounds.extend(bottom);
            mapSnapshot.fitBounds(circleBounds);
        }
    }

    function formatterSlider(value) {
        const km = value / 1000;
        let showText = "";
        if (value >= 1000) {
            showText = `${km.toFixed(1)} กม.`
        } else {
            showText = `${value.toFixed(0)} ม.`
        }
        return `${showText}`;
    }
    function getValueSlider(value) {
        setAreaRange(value)
        setAreaRangeShow(formatterSlider(value))
        return value;
    }
    const onClickMapChangePinLocation = (event) => {
        setPin(prevData => {
            return {
                ...prevData,
                location: event?.latLng.toJSON()
            }
        })
        map.panTo(event?.latLng.toJSON());
    }

    const onSetMapChangePinLocation = (value = {}) => {
        setPin(prevData => {
            return {
                ...prevData,
                location: value
            }
        })
        map.panTo(value);
        map.setZoom(15);
    }

	useEffect(() => {
		if (map) {
			if (mapData.location.lat && mapData.location.lng) {
				map.panTo(mapData.location);
			}
		}
	}, [mapData.location, map]);

	useEffect(() => {
		if (map) {
			if (mapData.zoom) {
				map.setZoom(mapData.zoom)
			}
		}
	}, [mapData.zoom, map]);

    const onClickToggleLayer = () => {
        if (MapTypeId.ROADMAP === mapData.mapTypeId) {
            setMapData(prevState => {
                return {
                    ...prevState,
                    mapTypeId: MapTypeId.HYBRID
                }
            });
        } else {
            setMapData(prevState => {
                return {
                    ...prevState,
                    mapTypeId: MapTypeId.ROADMAP
                }
            });
        }
    }

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        }
    }

    function showPosition(position) {
        setMapData(prevState => {
            return {
                ...prevState,
                zoom: 15,
                location: {
                    lat: position?.coords?.latitude,
                    lng: position?.coords?.longitude
                }
            }
        });
    }

    const onClickSetCurrentLocation = () => {
        getLocation()
    }

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address) => {
        const regex_lat_lng = /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi;
        setValue(address, false);
        clearSuggestions();
    
        if (regex_lat_lng.test(`${address}`)) {
            const split_str = `${address}`.split(",");
            const lat = Number(split_str[0]);
            const lng = Number(split_str[1]);
            
            try {
                onSetMapChangePinLocation({
                    lat,
                    lng
                });
            } catch (error) {
                console.warn(`🌦️ ~ handleSelect ~ error:`, error);
            }
        } else {
            try {
                const results = await getGeocode({ address });
                const selectLocation = getLatLng(results[0]);
                setPin(selectLocation);
                map.panTo(selectLocation);
                map.setZoom(15);
            } catch (error) {
                console.warn(`🌦️ ~ handleSelect ~ error:`, error);
            }
        }
    };

    
    return (
        <Modal visible={visible} title={null} closable={null} footer={null} width={1280} className={styles["gw-modal-map"]}>
            <Row gutter={[0, 40]}>
                <Col span={24} className={styles["gw-block-container"]}>
                    <Row gutter={[0, 24]}>
                        <Col>
                            <label className={`${styles["gw-text-title"]}`}>
                                <span>Location</span>
                            </label>
                        </Col>
                        <Col span={24}>
                            <AutoComplete
                                dropdownMatchSelectWidth={300}
                                style={{
                                    width: 250,
                                }}
                                options={
                                    [
                                        ...[...(value ? [{
                                            value: value,
                                            label: value
                                        }] : [])],
                                        ...(data || []).map((item, index) => {
                                            return {
                                                value: item["description"],
                                                label: item["description"]
                                            }
                                        })
                                    ]
                                }
                                className={`${styles["gw-input-search"]}`}
                                onSelect={handleSelect}
                            >
                                <Search
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    disabled={!ready}
                                    placeholder="ค้นหา"
                                />
                            </AutoComplete>
                            <div className={`${styles["btn-action-maps"]}`}>
                                {/* <Button shape='circle' onClick={onClickClearPolygons}>
                                    <DellLight />
                                </Button> */}
                                <Button shape="circle" onClick={onClickToggleLayer}>
                                    <img src={icoMapLayer1} alt="" />
                                </Button>
                                {/* <Button shape="circle" onClick={onClickSetCurrentLocation}>
                                    <img src={icoMapLayer2} alt="" />
                                </Button> */}
                            </div>
                            <GoogleMap
                                mapTypeId={mapData.mapTypeId}
                                options={{
                                    styles: mapData.mapTypeId === MapTypeId.ROADMAP ? mapStyles : {},
                                    disableDefaultUI: true,
                                    // disableDoubleClickZoom: true,
                                    // fullscreenControl: false,
                                    // streetViewControl: false,
                                    // mapTypeControl: false,
                                    // zoomControl: false,
                                    // scaleControl: false,
                                    // draggingCursor: false,
                                }}
                                mapContainerStyle={mapModalSizeStyles}
                                zoom={7}
                                center={defaultCenter}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                mapContainerClassName={styles["gw-map-container"]}
                                onCenterChanged={() => {
                                    // console.log(map)
                                    // console.log("onCenterChanged")
                                    // console.log(map?.getBounds()?.toJSON())
                                }}
                                onZoomChanged={() => {
                                    setZoom(map?.getZoom())
                                }}
                                onClick={onClickMapChangePinLocation}
                                onIdle={onIdle}
                            >
                                <Marker
                                    icon={{
                                        url: iconMarkLogo,
                                        scaledSize: {
                                            width: 30,
                                            height: 43
                                        },
                                    }}
                                    position={pin?.location || defaultCenter}
                                    draggable={true}
                                    onDragEnd={(event) => {
                                        setPin(prevData => {
                                            return {
                                                ...prevData,
                                                location: event?.latLng.toJSON()
                                            }
                                        })
                                        map.panTo(event?.latLng.toJSON());
                                    }}
                                />
                                <Circle
                                    center={pin?.location || defaultCenter}
                                    radius={areaRange}
                                    options={mapCircleStyles} />
                            </GoogleMap>
                            <div className='gw-map-snapshot'>
                                <GoogleMap
                                    mapTypeId={mapData.mapTypeId}
                                    options={{
                                        styles: mapData.mapTypeId === MapTypeId.ROADMAP ? mapStyles : {},
                                        disableDefaultUI: true,
                                    }}
                                    mapContainerStyle={mapModalSizeStyles}
                                    zoom={7}
                                    center={defaultCenter}
                                    onLoad={onLoadSnapshot}
                                    onUnmount={onUnmountSnapshot}
                                    mapContainerClassName={styles["gw-map-container"]}
                                    onIdle={onIdleSnapshot}
                                >
                                    <Marker
                                        icon={{
                                            url: iconMarkLogo,
                                            scaledSize: {
                                                width: 30,
                                                height: 43
                                            },
                                        }}
                                        position={pin?.location || defaultCenter}
                                        draggable={true}
                                    />
                                    <Circle
                                        center={pin?.location || defaultCenter}
                                        radius={areaRange}
                                        options={mapCircleStyles} />
                                </GoogleMap>
                            </div>
                        </Col>
                        <Col span={12} offset={6}>
                            <div className={styles["gw-form-input-range-group"]}>
                                <label className={styles["gw-text-title"]}>
                                    <span>รัศมี</span>
                                </label>
                                <div className={styles["gw-range-title"]}>
                                    <span className={styles["gw-range-start"]}>1 กิโลเมตร</span>
                                    <span className={styles["gw-range-end"]}>50 กิโลเมตร</span>
                                </div>
                                <Form.Item
                                    className={styles["gw-form-input-range"]}
                                    labelCol={{ span: 24 }}
                                >
                                    <Slider
                                        onChange={getValueSlider}
                                        tipFormatter={formatterSlider}
                                        step={1000}
                                        min={1000}
                                        max={50000} />
                                </Form.Item>
                                <label className={styles["gw-text-result"]}>
                                    <span>{areaRangeShow}</span>
                                </label>
                            </div>
                        </Col>
                        <Col span={24} className={styles["btn-group-footer"]}>
                            <Row justify="center" align='middle'>
                                <Col>
                                    <Row gutter={[26, 0]}>
                                        <Col>
                                            <Button
                                                className={`${styles["btn-form"]} ${styles["gw-btn-clear"]}`}
                                                onClick={hideModal}>
                                                <div>
                                                    <span>Cancel</span>
                                                </div>
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                className={`${styles["btn-form"]} ${styles["gw-btn-confirm"]}`}
                                                onClick={onClickSubmit}
                                            >
                                                <div>
                                                    <span>Submit</span>
                                                </div>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}

// ModalMap.propTypes = {
//     visibleModalMap: PropTypes.bool,
//     hideModalMap: PropTypes.func,
//     userPinMarker: PropTypes.exact({
//         location: PropTypes.object,
//         zoom: PropTypes.number
//     }),
//     setUserPinMarker: PropTypes.func
// }

export default ModalMap;