import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import base_url from './config';

const endpoint = {
    property_category: `${base_url}/property-category`,
    master_country: `${base_url}/property/master/country`,
    master_province: `${base_url}/property/master/province`,
    master_amphures: `${base_url}/property/master/amphures?pv_id=`,
    master_districts: `${base_url}/property/master/districts?am_id=`,
    // master_percel: `https://45.136.238.121/landsmap/percel`,
    master_percel: `${base_url}/landsmap/percel`,
    sales_channel: `${base_url}/sales-channel`,
}

const fetchPropertyCategory = () => {
    return trackPromise(axios.get(endpoint.property_category));
}

const fetchMasterCountry = () => {
    return trackPromise(axios.get(endpoint.master_country));
}

const fetchMasterProvince = () => {
    return trackPromise(axios.get(endpoint.master_province));
}

const fetchMasterAmphures = (pv_id = "") => {
    return trackPromise(axios.get(endpoint.master_amphures + pv_id));
}

const fetchMasterDistricts = (am_id = "") => {
    return trackPromise(axios.get(endpoint.master_districts + am_id));
}

const fetchMasterPercel = ({
    prov_id = "",
    am_id = "",
    percel_no = "",
}) => {
    return trackPromise(axios.get(`${endpoint.master_percel}?prov_id=${prov_id}&am_id=${am_id}&percel_no=${percel_no}`));
}

const fetchMasterSalesChannel = (cancelTokenSource) => {
    return trackPromise(axios.get(endpoint.sales_channel, {
        cancelToken: cancelTokenSource?.token
    }));
}

export {
    fetchPropertyCategory,
    fetchMasterCountry,
    fetchMasterProvince,
    fetchMasterAmphures,
    fetchMasterDistricts,
    fetchMasterPercel,
    fetchMasterSalesChannel,
}