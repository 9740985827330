import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        userData: {},
    },
    reducers: {
        updateUserData: (state, sender) => {
            state.userData = {...sender.payload}
        }
    },
})

// Action creators are generated for each case reducer function
export const { updateUserData } = authSlice.actions

export default authSlice.reducer