import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import base_url from './config';

const endpoint = {
    news_feed_property_recommended: `${base_url}/news-feed/property-recommended`,
    news_feed_property_new: `${base_url}/news-feed/property-new`,
    news_feed_property_popular: `${base_url}/news-feed/property-popular`,
    news_feed_property_nearly: `${base_url}/news-feed/property-nearly`,
}

const fetchNewsFeedPropertyRecommended = (body = {}, cancelTokenSource) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return trackPromise(axios.get(endpoint.news_feed_property_recommended + `?${queryString}`, {
        cancelToken: cancelTokenSource?.token
    }));
}

const fetchNewsFeedPropertyNew = (body = {}, cancelTokenSource) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return trackPromise(axios.get(endpoint.news_feed_property_new + `?${queryString}`, {
        cancelToken: cancelTokenSource?.token
    }));
}

const fetchNewsFeedPropertyPopular = (body = {}, cancelTokenSource) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return trackPromise(axios.get(endpoint.news_feed_property_popular + `?${queryString}`, {
        cancelToken: cancelTokenSource?.token
    }));
}

const fetchNewsFeedPropertyNearly = (body = {}, cancelTokenSource) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return trackPromise(axios.get(endpoint.news_feed_property_nearly + `?${queryString}`, {
        cancelToken: cancelTokenSource?.token
    }));
}

export {
    fetchNewsFeedPropertyRecommended,
    fetchNewsFeedPropertyNew,
    fetchNewsFeedPropertyPopular,
    fetchNewsFeedPropertyNearly,
}