import styles from './style.module.scss';
import { Button, Col, Form, Image, Input, InputNumber, message, notification, Row, Select, Switch, Upload } from "antd";
import { useEffect, useState, useMemo } from 'react';
import MyPropertyCreateStep1 from './Step1';
import MyPropertyCreateStep2 from './Step2';
import ModalMap from './ModalMap';
import ModalMapPolygon from './ModalMapPolygon';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';
import { fetchMasterCountry, fetchMasterProvince, fetchPropertyCategory } from '../../services/service.common';
import { useForm } from 'antd/lib/form/Form';
import { fetchCreateProperty, fetchPerpertyDetails, fetchUpdateProperty } from '../../services/service.property';
import { RoutePath } from '../../Routes';
import { defaultQuestionnaires } from '../../utils/common';
import { useMediaQuery } from 'react-responsive';
import { useAuthContext } from '../../context/authentication';
import { useModalContext } from '../../context/modals';

const MyPropertyCreate = () => {
    const { id } = useParams();
    const [ libraries ] = useState(["drawing", "geometry", "places"]);
    const [form] = useForm();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const [formData, setFormData] = useState({
        sale_price_unit: "THB",
        country: 87,
        questionnaires: [...defaultQuestionnaires]
    });
    const formDataMemo = useMemo(() => formData, [formData]);
    const [visibleModalMap, setVisibleModalMap] = useState(false);
    const [visibleModalMapPolygon, setVisibleModalMapPolygon] = useState(false);
    const [userPinMarker, setUserPinMarker] = useState(null);
    const [masterData, setMasterData] = useState({
        property_category: [],
        master_country: [],
        master_province: [],
        master_amphures: [],
        master_districts: [],
    });
    const { data: auth } = useAuthContext();
    const { action: modalAction, dataLogin } = useModalContext();
    const [isPending, setIsPending] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            setIsPending(true);
            Promise.allSettled([
                fetchPropertyCategory(),
                fetchMasterCountry(),
                fetchMasterProvince()
            ]).then(async function (responses) {
                const [
                    resPropertyCategory,
                    resMasterCountry,
                    resMasterProvince,
                ] = responses;
                setMasterData(function (prevData){
                    return {
                        ...prevData,
                        property_category: 
                            (resPropertyCategory?.value?.data?.data || [])
                            .sort((a,b) => (a.cate_id > b.cate_id) ? 1 : ((b.cate_id > a.cate_id) ? -1 : 0)),
                        master_country: resMasterCountry?.value?.data?.data || [],
                        master_province: resMasterProvince?.value?.data?.data || [],
                    }
                })
                if (id) {
                    try {
                        const responseDataProperty = await fetchPerpertyDetails(id);
                        if (responseDataProperty?.data?.data) {
                            const responseData = responseDataProperty?.data?.data;
                            setFormData(prevData => ({
                                ...prevData,
                                ...responseData,
                                places_type: responseData.cate_id,
                                upload_photos: [...responseData.propertyImages || []].map(item => ({
                                    ...item,
                                    thumbUrl: item.path,
                                    url: item.path,
                                    fileUpload: {
                                        file_id: item.file_id || "",
                                    }
                                })),
                            }));
                            form.setFieldsValue({
                                location_province: responseData.pv_id,
                                location_district: responseData.amphures_id,
                                // 54;: responseData.dt_id,
                                // "";: responseData.district,
                                country: responseData.country_id,
                                // 0;: responseData.image_style,
                                places_type: responseData.cate_id,
                                property_type: responseData.property_type,
                                // header_ads: responseData.landmark,
                                header_ads: responseData.advertisement,
                                more_information: responseData.description,
                                sale_price: responseData.cost,
                                location: {
                                    lat: responseData.latitude_lands,
                                    lng: responseData.longitude_lands,
                                },
                                // "";: responseData.address_lands,
                                location_title_deed_number: responseData.title_deen_no,
                                polygons: [...responseData.locationPolygon || []].map(item => ({
                                    ...item,
                                    lat: item.latitude,
                                    lng: item.longitude,
                                })),
                                upload_photos: [...responseData.propertyImages || []].map(item => ({
                                    ...item,
                                    thumbUrl: item.path,
                                    url: item.path,
                                    fileUpload: {
                                        file_id: item.file_id || "",
                                    }
                                })),
                                questionnaires: [...defaultQuestionnaires].map(item => ({
                                    ...item,
                                    value_text: responseData[item.output_field],
                                    value_bool: responseData[item.output_field] === true,
                                }))
                            });
                            setUserPinMarker({
                                location: {
                                    lat: responseData.latitude_lands,
                                    lng: responseData.longitude_lands,
                                },
                                polygons: [...responseData.locationPolygon || []].map(item => ({
                                    ...item,
                                    lat: item.latitude,
                                    lng: item.longitude,
                                })),
                                percel: {
                                    parcellat: responseData.latitude_lands,
                                    parcellon: responseData.longitude_lands,
                                }
                            });
                        }
                        setIsPending(false);
                    } catch (error) {
                        console.log(`🌦️ ~ error:`, error);
                    }
                }
            })
        }
        loadData();
        return () => { };
    }, [form, id]);

    useEffect(() => {
        // console.log("formData", formData)
        // console.log("getformDataMemo", formDataMemo)
      return () => {
      }
    }, [formData, formDataMemo])
    
    const isSubmit = () => {
        return activeTab === 2;
    }

    const onClickNextTab = () => {
        setActiveTab(activeTab + 1)
    }

    const onClickPreviousTab = () => {
        if (activeTab <= 1) {
            navigate(-1);
        } else {
            setActiveTab(activeTab - 1)
        }
    }

    useEffect(() => {
        if (dataLogin.isLoginSuccess) {
            form.submit();
        }
        return () => {
        }
    }, [dataLogin.isLoginSuccess, form])

    const onFinish = async (values) => {
        const _fromData = {...formData, ...values}
        setFormData(_fromData);
        if (isSubmit()) {
            const requestBody = prepareData({..._fromData, ...userPinMarker});
            if (validateData(requestBody)) {
                try {
                    if (!auth?.isAuthentication) {
                        modalAction.updateModalLogin(true);
                        return;
                    }
                    let response;
                    if (!id) {
                        response = await fetchCreateProperty(requestBody);
                    } else {
                        response = await fetchUpdateProperty(requestBody);
                    }
                    if (response?.data?.data?.p_id) {
                        notification['success']({
                            message: 'Map',
                            description:
                                'Create success!',
                        });
                        if (window?.history?.state?.key) {
                            navigate(-1);
                        } else {
                            window.location.href = `${RoutePath.find_property_map.path}`;
                        }
                    }
                } catch (error) {
                    console.log("create property error", error);
                    notification['error']({
                        message: `Can not create property!`,
                        description: "Something went wrong!",
                    });
                }
            }
        } else {
            onClickNextTab()
        }
    }

    const prepareData = (sourceData = {}) => {
        let requestData = {};
        if (id) {
            requestData = { ...sourceData }
        }
        requestData['pv_id'] = sourceData.location_province;
        requestData['amphures_id'] = sourceData.location_district;
        requestData['dt_id'] = 54;
        requestData['district'] = "";
        requestData['country_id'] = sourceData.country;
        requestData['image_style'] = 0;
        requestData['cate_id'] = sourceData.places_type;
        requestData['property_type'] = sourceData.property_type;
        requestData['landmark'] = sourceData.header_ads;
        requestData['advertisement'] = sourceData.header_ads;
        requestData['description'] = sourceData.more_information;
        requestData['cost'] = sourceData.sale_price;
        requestData['latitude_lands'] = sourceData?.location?.lat || 0;
        requestData['longitude_lands'] = sourceData?.location?.lng || 0;
        requestData['address_lands'] = "";
        requestData['title_deen_no'] = sourceData.location_title_deed_number || 0;
        requestData['locationPolygon'] = (sourceData.polygons || []).map(function (item, index) {
            let objPolygon = {
                seq: index + 1,
                latitude: item.lat,
                longitude: item.lng,
            }
            return objPolygon;
        });
        requestData['property_images'] = (sourceData.upload_photos || []).map(function (item, index) {
            let objImage = {
                seq: index + 1,
                file_id: item?.fileUpload?.file_id,
            }
            return objImage;
        });

        const arr_questionnaires = [...sourceData.questionnaires || []];
        let obj_questionnaires = {};
        arr_questionnaires.map((item) => {
            switch (item.type) {
                case "INPUT_TEXT_UNIT":
                case "INPUT_TEXT":
                case "SELECTOR":
                    switch (item.output_field) {
                        case "title_deen_no":
                        case "size_sqm":
                        case "bed_room":
                        case "toilet_room":
                        case "car_park":
                        case "floor":
                        case "cost_area":
                        case "use_size_sqm":
                        case "free_room":
                        case "total_floor":
                        case "construction_year":
                            if ([...item.cate_ids || []].includes(sourceData.places_type)) {
                                obj_questionnaires[item.output_field] = Number(item.value_text || 0);
                            } else {
                                obj_questionnaires[item.output_field] = 0;
                            }
                            break;
                        
                        case "decoration":
                            obj_questionnaires[item.output_field] = item.value_text || "no";
                            break;
                    
                        default:
                            if ([...item.cate_ids || []].includes(sourceData.places_type)) {
                                obj_questionnaires[item.output_field] = item.value_text;
                            } else {
                                obj_questionnaires[item.output_field] = "";
                            }
                            break;
                    }
                    break;
                case "SWITCH":
                    if ([...item.cate_ids || []].includes(sourceData.places_type)) {
                        obj_questionnaires[item.output_field] = item.value_bool;
                    } else {
                        obj_questionnaires[item.output_field] = false;
                    }
                    break;

                default:
                    break;
            }
            return item;
        });
        requestData = {...requestData, ...obj_questionnaires};
        return requestData;
    }

    const validateData = (sourceData = {}) => {
        if (!sourceData?.latitude_lands && (sourceData?.latitude_lands && sourceData?.longitude_lands)) {
            notification['error']({
                message: 'Map',
                description:
                    'Please select map!',
            });
            return false;
        } else if (!((sourceData?.locationPolygon || []).length > 0)) {
            notification['error']({
                message: 'Map',
                description:
                    'Please draw map!',
            });
            return false;
        }
        return true;
    }

    const onFinishFailed = (values) => {
        console.log("onFinishFailed", values)
    }

    const showModalMap = () => {
        setVisibleModalMap(true);
    }

    const hideModalMap = () => {
        setVisibleModalMap(false);
    }

    const showModalMapPolygon = () => {
        setVisibleModalMapPolygon(true);
    }

    const hideModalMapPolygon = () => {
        setVisibleModalMapPolygon(false);
    }

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    return (
        <div className={`gw-container gw-mt-24`}>
            <Form
                name="form_data"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={formDataMemo}
                form={form}
            >
                {activeTab === 1 && <MyPropertyCreateStep1
                    form={form} masterData={masterData} formData={formData}
                    setIsPending={setIsPending}
                    isMobile={isMobile}
                />}
                {activeTab === 2 && <MyPropertyCreateStep2 
                    form={form} masterData={masterData} 
                    formData={formData} showModalMap={showModalMap} 
                    showModalMapPolygon={showModalMapPolygon} 
                    userPinMarker={userPinMarker}
                    setUserPinMarker={setUserPinMarker}
                    isMobile={isMobile}
                    />}
                <Row gutter={[24, 35]}>
                    <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6} className={styles["gw-btn-actions"]}>
                        <Row justify='space-between' gutter={[12, 0]}>
                            <Col span={12}>
                                <Button
                                    // disabled={activeTab <= 1}
                                    className={`${styles["btn-form"]} ${styles["gw-btn-clear"]}`}
                                    htmlType="button" onClick={onClickPreviousTab}>
                                    <div>
                                        <span>Cancel</span>
                                    </div>
                                </Button>
                            </Col>
                            <Col span={12} style={{ display: "flex", justifyContent: "end"}}>
                                {
                                    <Button
                                        className={`${styles["btn-form"]} ${styles["gw-btn-confirm"]}`}
                                        htmlType={"submit"}
                                        disabled={isPending}
                                    >
                                        <div>
                                            <span>{isSubmit() ? "Submit" : "Next"}</span>
                                        </div>
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            {/* <LoadScript
                loadingElement={<span>Loading</span>}
                libraries={libraries}
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}> */}
                <ModalMap visibleModalMap={visibleModalMap} hideModalMap={hideModalMap} userPinMarker={userPinMarker} setUserPinMarker={setUserPinMarker}/>
                <ModalMapPolygon visibleModalMap={visibleModalMapPolygon} hideModalMap={hideModalMapPolygon} userPinMarker={userPinMarker} setUserPinMarker={setUserPinMarker}/>
            {/* </LoadScript> */}
        </div>
    )
}

export default MyPropertyCreate;