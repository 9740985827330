import styles from "./style.module.scss";
import MyGrowMain from "../MyGrow";
import { Button, Col, Form, Input, InputNumber, notification, Row, Select, Slider } from "antd";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as IcExpandMore } from "../../assets/icons/pages/Propmarket/my-property-create/ic_expand_more.svg";
import { ReactComponent as IcUploadPhoto } from "../../assets/icons/pages/Propmarket/my-property-create/ic_add_a_photo.svg";
import { ReactComponent as IcUploadVideo } from "../../assets/icons/pages/Propmarket/my-property-create/ic_video_fill.svg";
import { ReactComponent as PriceTag } from "../../assets/icons/pages/findProertyDetail/price_tag.svg";
import { ReactComponent as SearchAltDuotone } from '../../assets/icons/header/search_alt_duotone.svg';
import MapContainer from "../../components/GMap";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../Routes";
import ModalMap from './ModalMap';
import { fetchMasterAmphures, fetchMasterProvince, fetchPropertyCategory } from "../../services/service.common";
import { useForm } from "antd/lib/form/Form";
import * as services from "../../services/service.broadcast";
import { fetchUploadImage } from "../../services/service.property";
import Editor from "../../components/TextEditor";
import { $getRoot } from "lexical";
import clsx from "clsx";

// Maximum character limit for the editor
const MAX_CHARACTER_LIMIT = 200;
// Maximum number of lines allowed
const MAX_LINE_LIMIT = 6;

const MyGrow3BroadcastCreate = props => {
    const navigate = useNavigate();
    const [form] = useForm();
    const [mapImage, setMapImage] = useState(null);

    const [masterData, setMasterData] = useState({
        property_category: [],
        master_province: [],
        master_amphures: [],
        master_broadcast_type: [],
    });

    useEffect(() => {
        const loadData = async () => {
            Promise.allSettled([
                fetchPropertyCategory(),
                fetchMasterProvince(),
                services.fetchBroadcastType(),
            ]).then(function (responses) {
                const [
                    resPropertyCategory,
                    resMasterProvince,
                    resBroadcastType
                ] = responses;
                setMasterData(function (prevData){
                    return {
                        ...prevData,
                        property_category: 
                            (resPropertyCategory?.value?.data?.data || [])
                            .sort((a,b) => (a.cate_id > b.cate_id) ? 1 : ((b.cate_id > a.cate_id) ? -1 : 0)),
                        master_province: resMasterProvince?.value?.data?.data || [],
                        master_broadcast_type: resBroadcastType?.value?.data?.data || [],
                    }
                })
            })
        }
        loadData();
        return () => { };
    }, []);

    const onChangeProvince = async (value) => {
        const response = await fetchMasterAmphures(value);
        setMasterData(function (prevData){
            return {
                ...prevData,
                master_amphures: response?.data?.data || [],
            }
        })
        form.setFieldsValue({
            location_district: null
        });
    }

    const onClickNavigateBack = () => {
        if (window?.history?.state?.key) {
            navigate(-1);
        } else {
            navigate(RoutePath.my_grow_broadcast.path, {
                replace: true
            });
        }
        // navigate(RoutePath.my_grow_broadcast.path)
    }
    const [formData, setFormData] = useState({
        area_range: 1000,
        // desc: `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"สวัสดีๆ หาที่ๆ ๆ ๆ ๆ ","type":"text","version":1},{"type":"linebreak","version":1},{"detail":0,"format":0,"mode":"normal","style":"","text":"บรรทัดที่2","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`
    });
    const formDataMemo = useMemo(() => formData, [formData]);
    const [areaRange, setAreaRange] = useState(formData.area_range);
    const [visibleModalMap, setVisibleModalMap] = useState(false);
    const [userPinMarker, setUserPinMarker] = useState(null);
    const [selectCategory, setSelectCategory] = useState(null);
    const [selectBoardcastType, setSelectBroadcastType] = useState(null);

    const showModalMap = () => {
        setVisibleModalMap(true);
    }

    const hideModalMap = () => {
        setVisibleModalMap(false);
    }

    const onFinish = async values => {
        const sourceValues = {...values, ...userPinMarker, radius_m: areaRange};
        const dataPrepareValues = prepareData(sourceValues);
        if (validateData(dataPrepareValues)) {
            try {
                try {
                    if (!mapImage) throw Error("no mapImage!")
                    const response = await fetchUploadImage(mapImage);
                    dataPrepareValues.map_image = response?.data?.data?.file_id || "";
                } catch (error) {
                    console.log("Upload map fail!")
                }
                const response = await services.createBroadcast(dataPrepareValues);
                if (response?.data?.success) {
                    notification['success']({
                        message: 'Map',
                        description:
                            'สร้าง broadcast สำเร็จ',
                    });
                    if (window?.history?.state?.key) {
                        navigate(-1);
                    } else {
                        navigate(RoutePath.my_grow_broadcast.path, {
                            replace: true
                        });
                    }
                } else {
                    throw Error("create broadcast fail!")
                }
            } catch (error) {
                console.log(error)
                notification['error']({
                    message: 'Broadcast',
                    description:
                        'ไม่สามารถสร้าง broadcast ได้',
                });
            }
        }
    };

    const onFinishFailed = values => {
        console.log("onFinishFailed", values);
    };

    const prepareData = (sourceData = {}) => {
        let result = {};
        result["pv_id"] = sourceData.pv_id //2,
        result["cate_id"] = sourceData.cate_id //3,
        result["amphures_id"] = sourceData.amphures_id //9,
        result["title"] = sourceData.title || "" //"หาที่แจ่มๆ",
        result["desc"] = sourceData.desc || "" //"string",
        result["cost_min"] = sourceData.cost_min //0,
        result["cost_max"] = sourceData.cost_max //0,
        result["radius_m"] = sourceData.radius_m //500,
        result["lat"] = sourceData.location?.lat || 0 //0,
        result["map_image"] = "" //"string",
        result["district"] = "" //"string",
        result["lng"] = sourceData.location?.lng || 0 //0,
        result["is_showing"] = true //true
        result["broadcast_type_id"] = sourceData?.filter_type_id || 0 //0
        
        return result;
    }

    const validateData = (sourceData = {}) => {
        if (!sourceData?.lat || !sourceData?.lng || !sourceData?.radius_m) {
            notification['error']({
                message: 'Map',
                description:
                    'Please set raduis area!',
            });
            return false;
        }
        return true;
    }
    const [countTitle, setCountTitle] = useState(0);
    const [countDesc, setCountDesc] = useState({
        chars: 0,
        lines: 0
    });

    const onChangeTitle = (event) => {
        setCountTitle(`${event?.target?.value || ""}`.length);
    }
    const onChangeDesc = (event) => {
        setCountDesc({
            chars: `${event?.target?.value || ""}`.length
        });
    }

    const onChangeTextEditor = (editorState, onChangeForm) => {
        const editorStateJSON = editorState.toJSON();
        const editorStateJSONString = JSON.stringify(editorStateJSON);
        onChangeForm(editorStateJSONString);

        editorState.read(() => {
            // Read the contents of the EditorState here.
            const root = $getRoot();
            // Get the text content from the root element
            const textContent = root.getTextContent();
            // Count the number of lines
            const lineCount = textContent.split('\n').length;
            // Count the number of characters
            const charCount = textContent.length;

            setCountDesc({
                chars: charCount,
                lines: lineCount
            });
        });
    }

    return (
        <MyGrowMain>
            <div className={`gw-text-title`}>
                <span>Broadcast</span>
            </div>
            <div className={`gw-mt-24 ${styles["gw-block-container"]}`}>
                <Form
                    name="form_data"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    initialValues={formDataMemo}
                    form={form}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <label className={styles["gw-text-title"]}>
                                <span>Broadcast for more available</span>
                            </label>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className={`${styles["gw-form-selector"]}`}
                                name="filter_type_id"
                                rules={[{ required: true, message: 'Please select!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Filter Type"
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    suffixIcon={<IcExpandMore />}
                                    onChange={value => {
                                        setSelectBroadcastType(value);
                                        if (value === 3) {
                                            form.setFieldsValue({
                                                cate_id: 1
                                            });
                                            setSelectCategory(1);
                                        } else {
                                            form.setFieldsValue({
                                                cate_id: null
                                            });
                                            setSelectCategory(null);
                                        }
                                    }}
                                >
                                    {
                                        masterData.master_broadcast_type.map(function (item) {
                                            return <Select.Option
                                                key={item?.broadcast_type_id}
                                                value={item?.broadcast_type_id}>{item?.name || ""}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} className={styles["gw-form-count"]}>
                            <Form.Item
                                className={`${styles["gw-form-textarea"]}`}
                                name="desc"
                                rules={[{ required: true, message: 'Please input!' }]}
                            >
                                <Input.TextArea 
                                    className={clsx("gw-textarea-card")}
                                    category={selectCategory}
                                    broadcasttypeid={selectBoardcastType}
                                    placeholder='กรอกรายละเอียด' 
                                    maxLength={200} 
                                    // style={{ minHeight: 200, paddingTop: 100, paddingBottom: 100 }} 
                                    onChange={onChangeDesc}
                                    autoSize={true}/>
                            </Form.Item>
                            <span className={styles["gw-count-text"]}>{countDesc.chars}/200 ตัวอักษร</span>
                        </Col>
                        {
                            selectBoardcastType !== 3 && (
                                <Col span={24}>
                                    <Form.Item
                                        className={`${styles["gw-form-selector"]}`}
                                        name="cate_id"
                                        rules={[{ required: true, message: 'Please select!' }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="ประเภทอสังหา"
                                            allowClear={true}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            suffixIcon={<IcExpandMore />}
                                            onChange={value => {
                                                setSelectCategory(value);
                                            }}
                                        >
                                            {
                                                masterData.property_category.map(function (item) {
                                                    return <Select.Option
                                                        key={item?.cate_id}
                                                        value={item?.cate_id}>{item?.cate_name || ""}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )
                        }
                        {/* <Col span={24} className={styles["gw-form-count"]}>
                            <Form.Item
                                className={`${styles["gw-form-textarea"]}`}
                                name="desc"
                                // rules={[{ required: true, message: 'Please input!' }]}
                                rules={[{
                                    validator: (_, value) => {
                                        if (countDesc.chars > MAX_CHARACTER_LIMIT) {
                                            return Promise.reject(`Maximum ${MAX_CHARACTER_LIMIT} characters`);
                                        }
                                        if (countDesc.lines > MAX_LINE_LIMIT) {
                                            return Promise.reject(`Maximum ${MAX_LINE_LIMIT} lines`);
                                        }
                                        return Promise.resolve();
                                    }
                                }]}
                            >
                                <Editor selectCategory={selectCategory} onChangeTextEditor={onChangeTextEditor}/>
                            </Form.Item>
                            <span className={styles["gw-count-text"]}>{countDesc.chars}/{MAX_CHARACTER_LIMIT} ตัวอักษร</span>
                        </Col> */}
                        <Col span={12}>
                            <Form.Item
                                className={`${styles["gw-form-input"]}`}
                                name="cost_min"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject('Please input!');
                                            } else if (getFieldValue("cost_min") > getFieldValue("cost_max")) {
                                                return Promise.reject('ราคา(จาก) ต้องมากกว่า ราคา(ถึง)');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputNumber
                                    prefix={<PriceTag />}
                                    controls={false}
                                    placeholder='ราคา(จาก)'
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className={`${styles["gw-form-input"]}`}
                                name="cost_max"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject('Please input!');
                                            } else if (getFieldValue("cost_min") > getFieldValue("cost_max")) {
                                                return Promise.reject('ราคา(จาก) ต้องมากกว่า ราคา(ถึง)');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputNumber
                                    prefix={<PriceTag />}
                                    controls={false}
                                    placeholder='ราคา(ถึง)'
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className={`${styles["gw-form-selector"]}`}
                                name="pv_id"
                                rules={[{ required: true, message: 'Please select!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="จังหวัด"
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    suffixIcon={<IcExpandMore />}
                                    onChange={onChangeProvince}
                                >
                                    {
                                        masterData.master_province.map(function (item) {
                                            return <Select.Option 
                                                key={item?.id} 
                                                value={item?.id}>{item?.pv_name || ""}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className={`${styles["gw-form-selector"]}`}
                                name="amphures_id"
                                rules={[{ required: true, message: 'Please select!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="อำเภอ"
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    suffixIcon={<IcExpandMore />}
                                >
                                    {
                                        masterData.master_amphures.map(function (item) {
                                            return <Select.Option 
                                                key={item?.id} 
                                                value={item?.id}>{item?.am_name || ""}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <label className={styles["gw-text-title"]}>
                                <span>Location</span>
                            </label>
                        </Col>
                        <Col span={24}>
                            <div className={`${styles["gw-chat-header-search"]}`}>
                                {/* <Input placeholder="ค้นหา" prefix={<SearchAltDuotone />} /> */}
                                <Button
                                    className={`${styles["btn-form"]} ${styles["gw-btn-red"]}`}
                                    onClick={showModalMap}
                                >
                                    <div>
                                        <span>{"กำหนดขอบเขต"}</span>
                                    </div>
                                </Button>
                            </div>
                            <div className={styles["gw-map-broadcast"]}>
                                <MapContainer
                                    isBroadCast={true}
                                    defaultMarker={userPinMarker?.location}
                                    defaultZoom={userPinMarker?.zoom}
                                    defaultValue={{
                                        rangeArea: areaRange
                                    }}
                                />
                            </div>
                        </Col>
                        <Col span={24}>
                            <Row justify='space-between'>
                                <Col>
                                    <Button
                                        // disabled={activeTab <= 1}
                                        className={`${styles["btn-form"]} ${styles["gw-btn-clear"]}`}
                                        htmlType="button" onClick={onClickNavigateBack}>
                                        <div>
                                            <span>Cancel</span>
                                        </div>
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className={`${styles["btn-form"]} ${styles["gw-btn-confirm"]}`}
                                        htmlType={"submit"}
                                    >
                                        <div>
                                            <span>{"Create"}</span>
                                        </div>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </div>
            <ModalMap 
                visibleModalMap={visibleModalMap} 
                hideModalMap={hideModalMap} 
                userPinMarker={userPinMarker} 
                setUserPinMarker={setUserPinMarker}
                formData={formData}
                setAreaRange={setAreaRange}
                setMapImage={setMapImage}
                />
        </MyGrowMain>
    );
};

export default MyGrow3BroadcastCreate;
