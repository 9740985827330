import React, { createContext, useContext, useState } from "react";
import { node } from 'prop-types';
import axios from "axios";
import { eraseCookie, getCookie, getPublicToken, refreshAccessToken, setCookie, tokenFieldName, tokenPBFieldName } from "../services/service.auth";

const AuthContext = createContext({});

export function useAuthContext() {
    return useContext(AuthContext)
}

const initialData = {
    isAuthentication: false
}

function AuthProvidor({ children }) {
    const [data, setData] = useState(initialData);

    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        const token = getCookie(tokenFieldName);
        if (token && token !== "undefined") {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });

    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            // Access Token was expired
            if (err?.response?.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const responsePbt = await getPublicToken();
                    const public_token = responsePbt?.data?.data?.public_token;
                    if (!public_token) throw Error("fail public_token");
                    setCookie(tokenPBFieldName, public_token, "", "");

                    const responseRf = await refreshAccessToken(public_token);
                    if (responseRf?.data?.data) {
                        const { access_token, refresh_token, expire } = responseRf?.data?.data || {};
                        setCookie(tokenFieldName, access_token, refresh_token, expire);
                    }
                    return axios(originalConfig);
                } catch (_error) {
                    // console.log("_error", _error)
                    return Promise.reject(_error);
                }
            }
            return Promise.reject(err);
        });

    function updateAuth(auth) {
        setData(prevAuth => ({
            ...prevAuth,
            ...auth
        }));
        
        // Add a request interceptor
        axios.interceptors.request.use(function (config) {
            const token = getCookie(tokenFieldName);
            if (token && token !== "undefined") {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });
    }

    const dataStore = {
        data: data || {},
        action: {
            updateAuth,
        }
    }

    return <AuthContext.Provider value={dataStore}>{children}</AuthContext.Provider>
}

AuthProvidor.prototype = {
    children: node.isRequired
}

export default AuthProvidor;