import { useEffect, useState } from 'react';
import stylesBroadcast from './../MyGrow3Broadcast/style.module.scss';
import styles from './style.module.scss';
import MyGrowMain from "../MyGrow";
import { Button, Col, Input, Row } from 'antd';
import CardItemPrimary from '../../components/CardItemPrimary';
import { ReactComponent as SearchAltDuotone } from '../../assets/icons/header/search_alt_duotone.svg';
import * as services from "../../services/service.pinLocation";
import LoadMoreElement from '../../components/LoadMore';
import ListElement from '../../components/ListElement';
import InfiniteScroll from 'react-infinite-scroller';
import { usePromiseTracker } from 'react-promise-tracker';
import { validateNotLoadMore } from '../../utils/common';
import { useLoadingListContext } from '../../context/loaddingList';

const MyGrow4PinLocation = (props) => {
    const { promiseInProgress } = usePromiseTracker();
    const [dataList, setDataList] = useState([]);
    const [dataMeta, setDataMeta] = useState({});
    const [loadMorePage, setLoadMorePage] = useState(1);
    const { action: loadingListAction } = useLoadingListContext();

    useEffect(() => {
        const loadData = async () => {
            try {
                loadingListAction.updateData(true);
                const responseDataProperty = await services.fetchPinLocation();
                const responseItems = responseDataProperty?.data?.data?.items || [];
                const responseMeta = responseDataProperty?.data?.data?.meta || {};
                if (responseDataProperty?.data?.data) {
                    setDataMeta(function (prevData) {
                        return {...responseMeta}
                    });
                    setDataList(function (prevData) {
                        return [...prepareDatas(responseItems)]
                    });
                } else {
                    throw Error("no data")
                }
            } catch (error) {
                setDataMeta({});
                setDataList([]);
            } finally {
                loadingListAction.updateData(false);
            }
        }
        loadData()
    }, []);

    const prepareDatas = (datas = []) => {
        let result = [...datas];
        result = result.map(item => {
            return {
                ...item,
                ...(item.interest || {})
            };
        });
        return result;
    }
    
    const loadMoreData = () => {
        if (!promiseInProgress && validateNotLoadMore(dataMeta, loadMorePage)) {
            setLoadMorePage(prevData => {
                return prevData + 1;
            });
        }
    }

    return (
        <MyGrowMain>
            <div className={`gw-text-title`}>
                <span>Pin Location</span>
            </div>
            {/* <div className={`gw-mt-24 ${stylesBroadcast["gw-header"]}`}>
                <div className={stylesBroadcast["gw-text-prefix"]}>
                    <span>ค้นหา</span>
                </div>
                <div className={`${stylesBroadcast["gw-chat-header-search"]}`}>
                    <Input placeholder="ค้นหา" prefix={<SearchAltDuotone />} />
                </div>
            </div> */}
            <InfiniteScroll
                threshold={750}
                initialLoad={false}
                loadMore={loadMoreData}
                hasMore={validateNotLoadMore(dataMeta, loadMorePage)}
            >
                <Row gutter={[24, 24]} className={`gw-mt-16`}>
                    <ListElement list={dataList} type={"PINLOCATION_24"}/>
                    <LoadMoreElement {...{ dataMeta, loadMorePage, loadMoreData }} />
                </Row>
            </InfiniteScroll>
        </MyGrowMain>
    )
}

export default MyGrow4PinLocation;