import React, { createContext, useContext, useEffect, useState } from "react";
import { node } from 'prop-types';
import * as services from '../services/service.header';
import { filterEvent } from "../utils/common";
import { useLocation } from "react-router-dom";
import { useFilterContext } from "./filter";
import { usePromiseTracker } from "react-promise-tracker";

const HeaderContext = createContext({});

export function useHeaderContext() {
    return useContext(HeaderContext)
}

const initialPage = {
    page: 1,
    limit: 15,
    isInit: true
}

const initialDataNotification = {
    isLoading: false,
    meta: {},
    list: []
}

function HeaderProvidor({ children }) {
    const { promiseInProgress } = usePromiseTracker();
    const location = useLocation();
    const [notifications, setNotifications] = useState(initialDataNotification);
    const [notificationOptions, setNotificationOptions] = useState(initialPage);
    const [notificationsIsLoad, setNotificationsIsLoad] = useState(false);

    useEffect(function () {
        if (
            !notificationOptions.isInit
            && notificationOptions.page) {
            loadNotificationData({ page: notificationOptions.page, limit: notificationOptions.limit }, true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationOptions]);

    async function loadNotificationData(filterRequest = {}, loadMore = false) {
        setNotificationsIsLoad(true);
        setTimeout(async () => {
            try {
                setNotifications(prevData => ({
                    ...prevData,
                    isLoading: true
                }));
                const responseDataProperty = await services.fetchNotifications(filterRequest);
                const responseItems = responseDataProperty?.data?.data?.items || [];
                const responseMeta = responseDataProperty?.data?.data?.meta || {};
                if (responseDataProperty?.data?.data) {
                    setNotifications(prevData => ({
                        ...prevData,
                        meta: { ...responseMeta },
                        list: [...(loadMore ? prevData.list : []), ...responseItems]
                    }));
                } else {
                    throw Error("no data")
                }
            } catch (error) {
                setNotifications(initialDataNotification);
            } finally {
                setNotifications(prevData => ({
                    ...prevData,
                    isLoading: false
                }));
                setNotificationsIsLoad(false);
            }
        }, 0);
    };

    function initNotificationData() {
        setNotificationOptions(prevData => {
            return {
                ...prevData,
                page: 1,
                isInit: true
            }
        })
        loadNotificationData({ page: notificationOptions.page, limit: notificationOptions.limit }, false);
    }

    function loadMoreNotificationData() {
        if (!promiseInProgress && notificationsIsLoad === false) {
            setNotificationOptions(prevData => {
                return {
                    ...prevData,
                    page: prevData.page + 1,
                    isInit: false
                }
            })
        }
    }

    function hasLoadmoreNotification() {
        const total_pages = notifications?.meta?.totalPages || 0;
        let is_loadmore = false;
        if (total_pages <= 1) {
            is_loadmore = false;
            return is_loadmore;
        }
        if (total_pages > 1 && notificationOptions.page !== total_pages) {
            is_loadmore = true;
            return is_loadmore
        }
        return is_loadmore;
    }

    async function updateNotificationRead(body) {
        try {
            const response = await services.updateNotificationRead(body);
            if (response?.data?.data?.noti_id) {
                setNotifications(prevData => {
                    return {
                        ...prevData,
                        list: notifications.list.map(item => {
                            if (item.noti_id === response?.data?.data?.noti_id) {
                                const { isRead } = response?.data?.data || {};
                                return {
                                    ...item,
                                    isRead
                                }
                            }
                            return item;
                        })
                    }
                })
            }
        } catch (error) {
            
        }
    }

    const dataStore = {
        data: {
            notifications,
            notificationsIsLoad,
        },
        action: {
            initNotificationData,
            loadMoreNotificationData,
            hasLoadmoreNotification,
            updateNotificationRead,
        }
    }

    return <HeaderContext.Provider value={dataStore}>{children}</HeaderContext.Provider>
}

HeaderProvidor.prototype = {
    children: node.isRequired
}

export default HeaderProvidor;