import { Route, Routes } from 'react-router-dom';
import FindPropertyMap from "./pages/FindPropertyMap";
import FindPropertyList from "./pages/FindPropertyList";
import FindPropertyDetail from "./pages/FindPropertyDetail";
import FindPropertyViewImages from "./pages/FindPropertyViewImages";
import FindPropertyNearbyPlace from "./pages/FindPropertyNearbyPlace";
import PageChat from './pages/Chat';
import NewsFeed from './pages/NewsFeed';
import Propmarket from './pages/Propmarket';
import MyPropertyCreate from './pages/PropmarketMyPropertyCreate';
import PropmarketDetails from './pages/PropmarketDetails';
import MyGrow1Profile from './pages/MyGrow1Profile';
import MyGrow2SellProperty from './pages/MyGrow2SellProperty';
import MyGrow3Broadcast from './pages/MyGrow3Broadcast';
import MyGrow3BroadcastCreate from './pages/MyGrow3BroadcastCreate';
import MyGrow3BroadcastDetail from './pages/MyGrow3BroadcastDetail';
import MyGrow4PinLocation from './pages/MyGrow4PinLocation';
import MyGrow5Favourite from './pages/MyGrow5Favourite';
import MyGrow6RecentlyView from './pages/MyGrow6RecentlyView';
import MyGrow7Setting from './pages/MyGrow7Setting';
import MyGrow8GetHelp from './pages/MyGrow8GetHelp';
import MyGrow4PinLocationCreate from './pages/MyGrow4PinLocationCreate';
import MyGrow4PinLocationPin from './pages/MyGrow4PinLocationPin';
import MyGrow4PinLocationList from './pages/MyGrow4PinLocationList';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useAuthContext } from './context/authentication';
import { fetchUserProfile } from './services/service.auth';
import { updateUserData } from './redux/action.user';
import { useDispatch, useSelector } from 'react-redux';
import firebaseService from './services/firebase.service';
import { firestore } from './services/firebase.utils';
import { updateCountNewMessage, updateRecentMessages } from './redux/action.message';
import { prepareSnapshot } from './utils/common';
import dayjs from 'dayjs';
import MyGrow1ProfileEdit from './pages/MyGrow1ProfileEdit';
import AnotherProfile from './pages/AnotherPropertyList';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useLoadScript } from '@react-google-maps/api';
import MyGrow6Policy from './pages/MyGrow6Policy';
import MyGrow8ReportAPoblem from './pages/MyGrow8ReportAPoblem';
import { useMediaQuery } from "react-responsive";

export const RoutePath = {
    find_property_map: {
        title: "ขายบ้าน ขายที่ดิน ขายคอนโด ด้วยตัวช่วย AI - GrowEstate.AI",
        root_path: "",
        sub_path: "/",
        sub_path_mobile: "find-property-map",
        get path() {
            return this.root_path + this.sub_path;
        },
        get pathMobile() {
            return this.root_path + this.sub_path_mobile;
        },
        component: <FindPropertyMap />,
        props: {
            active_tab: "1",
            exact: true,
        }
    },
    find_property_list: {
        title: "ขายบ้าน ขายที่ดิน ขายคอนโด ด้วยตัวช่วย AI - GrowEstate.AI",
        root_path: "/",
        sub_path: "find-property-list",
        sub_path_mobile: "/",
        get path() {
            return this.root_path + this.sub_path;
        },
        get pathMobile() {
            return this.sub_path_mobile;
        },
        component: <FindPropertyList />,
        props: {
            active_tab: "1",
            exact: true,
        }
    },
    find_property_detail: {
        title: "Property | Growestate",
        root_path: "/",
        sub_path: "find-property-detail",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <FindPropertyDetail />,
        props: {
            active_tab: "1",
        }
    },
    find_property_images: {
        title: "Gallery | Growestate",
        root_path: "/",
        sub_path: "find-property-images",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <FindPropertyViewImages />,
        props: {
            active_tab: "1",
        }
    },
    find_property_nearby_place: {
        title: "Nearby place | Growestate",
        root_path: "/",
        sub_path: "nearby-place",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <FindPropertyNearbyPlace />,
        props: {}
    },
    page_chat_detail: {
        title: "Chat | Growestate",
        root_path: "/",
        sub_path: "chat",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <PageChat />,
        props: {
            active_tab: "1",
        }
    },
    news_feed_root: {
        title: "New feed | Growestate",
        root_path: "/",
        sub_path: "news-feed",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <NewsFeed />,
        props: {
            active_tab: "2",
        }
    },
    news_feed_slug: {
        title: "New feed | Growestate",
        root_path: "/",
        sub_path: "news-feed/:id",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <NewsFeed />,
        props: {
            active_tab: "2",
        }
    },
    propmarket: {
        title: "Propmarket | Growestate",
        root_path: "/",
        sub_path: "propmarket",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <Propmarket />,
        props: {
            active_tab: "3",
        }
    },
    propmarket_slug: {
        title: "Propmarket | Growestate",
        root_path: "/",
        sub_path: "propmarket/:id",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <Propmarket />,
        props: {
            active_tab: "3",
        }
    },
    propmarket_slug_details: {
        title: "Propmarket | Growestate",
        root_path: "/",
        sub_path: "propmarket/detail/:id",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <PropmarketDetails />,
        props: {
            active_tab: "3",
        }
    },
    propmarket_my_property_create: {
        title: "Create | Growestate",
        root_path: "/",
        sub_path: "propmarket/my-property/create",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyPropertyCreate />,
        props: {
            active_tab: "3",
        }
    },
    propmarket_my_property_edit: {
        title: "Edit | Growestate",
        root_path: "/",
        sub_path: "propmarket/my-property/edit/:id",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyPropertyCreate />,
        props: {
            active_tab: "3",
        }
    },
    my_grow_profile: {
        title: "My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/profile",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow1Profile />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_profile_edit: {
        title: "My Grow | edit profile",
        root_path: "/",
        sub_path: "my-grow/edit-profile",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow1ProfileEdit />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_sell_property: {
        title: "Sell property - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/sell-property",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow2SellProperty />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_broadcast: {
        title: "Broadcast - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/broadcast",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow3Broadcast />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_broadcast_create: {
        title: "Create broadcast - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/broadcast/create",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow3BroadcastCreate />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_broadcast_detail: {
        title: "Broadcast - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/broadcast/detail",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow3BroadcastDetail />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_pin_location: {
        title: "Pin location - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/pin-location",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow4PinLocation />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_pin_location_list: {
        title: "Pin location list - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/pin-location-list",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow4PinLocationList />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_pin_location_create: {
        title: "Create pin location - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/pin-location/create",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow4PinLocationCreate />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_pin_location_pin: {
        title: "Pin location - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/pin-location/pin",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow4PinLocationPin />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_favorite: {
        title: "Favorite - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/favorite",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow5Favourite />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_recently_view: {
        title: "Recently view - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/recently-view",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow6RecentlyView />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_policy: {
        title: "Policy - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/policy",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow6Policy />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_setting: {
        title: "Setting - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/setting",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow7Setting />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_get_help: {
        title: "Get help - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/get-help",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow8GetHelp />,
        props: {
            active_tab: "4",
        }
    },
    my_grow_report_problem: {
        title: "แจ้งปัญหา - My Grow | Growestate",
        root_path: "/",
        sub_path: "my-grow/report-problem",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <MyGrow8ReportAPoblem />,
        props: {
            active_tab: "4",
        }
    },
    another_profile: {
        title: "Propmarket Profile | Growestate",
        root_path: "/",
        sub_path: "propmarket-profile/:uid",
        get path() {
            return this.root_path + this.sub_path;
        },
        component: <AnotherProfile />,
        props: {
            active_tab: "4",
        }
    },
}

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
const RRoutes = () => {
    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });
    const { data: auth } = useAuthContext();
    const dispatch = useDispatch();

    useEffect(() => {
        let unsubscribes = [];
        let isInit = true;

        (async function () {
            if (auth.isAuthentication) {
                try {
                    const response = await fetchUserProfile();
                    let data = response?.data?.data || {}
                    // data.uid = "Eu2nQay64SMbMKo5UyaFSKDS0lI3"
                    dispatch(updateUserData(data));
                    if (data?.uid) {
                        unsubscribes.push(
                            firebaseService
                                .getRecentMessages()
                                .doc(data?.uid)
                                .collection("messages")
                                .onSnapshot(qsRMsg => {
                                    let sourceDatas = prepareSnapshot(qsRMsg);
                                    sourceDatas = sourceDatas.sort((x, y) => {
                                        return dayjs(y.timestamp).valueOf() - dayjs(x.timestamp).valueOf()
                                    })
                                    dispatch(updateRecentMessages([
                                        ...sourceDatas
                                    ]));
                                    if (!isInit) {
                                        dispatch(updateCountNewMessage(1));
                                    }
                                    isInit = false;
                                })
                        )
                        
                    }
                } catch (error) {
                    console.warn("error", error);
                }
            }
        })();
        
        return () => {
            unsubscribes.forEach(func => {
                func();
            })
        }
    }, [auth, dispatch]);

    return (
        <Routes>
            <Route path={isMobile ? RoutePath.find_property_list.pathMobile : RoutePath.find_property_map.path} >
                {
                    Object.entries(RoutePath).map(([rKey, rItem]) => {
                        const path = isMobile ? rItem.pathMobile || rItem.path : rItem.path;
                        return (<Route key={rKey} path={path} element={<>
                            <Helmet>
                                <title>{rItem.title || "ขายบ้าน ขายที่ดิน ขายคอนโด ด้วยตัวช่วย AI - GrowEstate.AI"}</title>
                            </Helmet>
                            {rItem.component}
                        </>} {...rItem.props} index={path === "/" ? true : false}/>)
                    })
                }
            </Route>
        </Routes>
    )
};

const LoadScriptPage = (props) => {
    const [ libraries ] = useState(["drawing", "geometry", "places"]);
    const { isLoaded, loadError, url } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    });

    if (!isLoaded) {
        return <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "var(--gw-brand-color-red-1)" }} spin />} />
    }
    if (loadError) {
        return <span>Google map loadscript error!!</span>;
    }

    return (
        <RRoutes {...props} googleMapsScriptBaseUrl={url} />
    );
}

export default LoadScriptPage;
