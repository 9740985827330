import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './style.module.scss';
import MyGrowMain from "../MyGrow";
import stylesCardItem from '../../components/CardItemPrimary/style.module.scss';
import stylesPropmarketDetails from '../../pages/PropmarketDetails/style.module.scss';
import { Breadcrumb, Col, Divider, Image, Input, Row } from 'antd';
import CardItemPrimary from '../../components/CardItemPrimary';
import { ReactComponent as SearchAltDuotone } from '../../assets/icons/header/search_alt_duotone.svg';
import { imgfallback, showPrice } from '../../utils/common';
import CommentComponent from '../../components/CommentBroadcast';
import MapContainer from '../../components/GMap';
import { ReactComponent as IconBack } from '../../assets/icons/pages/findProertyDetail/arrow_left_back.svg';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from '../../Routes';
import * as services from "../../services/service.broadcast";
import EditorView from '../../components/TextEditor/indexView';
import { $getRoot, EditorState, createEditor } from 'lexical';
import clsx from 'clsx';

const layoutColumn = {
    LAYOUT_1: {
        id: "LAYOUT_1",
        gutter: [24, 0],
        col_1: 6,
        col_2: 12,
        col_3: 6,
    },
    LAYOUT_2: {
        id: "LAYOUT_2",
        gutter: [24, 0],
        col_1: 6,
        col_2: 12,
        col_3: 0,
    }
}

const MyGrow3BroadcastDetail = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const bc_id = searchParams.get('bc_id') || "";
    const [data, setData] = useState({});
    const [colLayout, setColLayout] = useState(layoutColumn.LAYOUT_2);
    const inputCommentRef = useRef();
    const location = useLocation();

    useEffect(() => {
        const loadDataBroadcast = async () => {
            try {
                const response = await services.fetchBroadcastByBcId(bc_id);
                const responseData = response?.data?.data || {};
                if (response?.data?.data) {
                    setData(responseData);
                } else {
                    throw Error("no data")
                }
            } catch (error) {
                setData({});
            }
        }
        if (bc_id) {
            loadDataBroadcast()
        }
    }, [bc_id]);

    const getData = useMemo(() => {
        return data;
    }, [data]);

    const prepareMapData = () => {
        let result = {};
        if (getData.lat && getData.lng) {
            result["defaultMarker"] = {
                lat: getData.lat,
                lng: getData.lng
            }
        }
        if (getData.radius_m) {
            result["defaultValue"] = {
                rangeArea: getData.radius_m
            }
        }
        return result;
    }

    const verifyBadge = () => {
        if (getData?.status_verify) {
            return (
                <div className='gw-badge-verified'>
                    <span>Verified</span>
                </div>
            )
        } else {
            return (<></>);
        }
    }

    const closeGrid = () => {
        setColLayout(layoutColumn.LAYOUT_2)
    }

    const showGrid = () => {
        setColLayout(layoutColumn.LAYOUT_1)
    }

    const onClikeNavigateBack = () => {
        if (window?.history?.state?.key) {
            navigate(-1);
        } else {
            navigate(RoutePath.my_grow_broadcast.path, {
                replace: true
            });
        }
    }

    useEffect(() => {
        if (inputCommentRef.current && location?.state?.is_message) {
            inputCommentRef.current.input.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            inputCommentRef.current.focus();
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        return () => {

        }
    }, [inputCommentRef, location?.state?.is_message]);

    const ShowTag = () => {
        let property_type_name = data?.filter_type || "";
        const category_name = data?.cate_name || data?.category?.cate_name || data?.cate_id || "";
        const broadcast_type_id = data?.broadcast_type_id || "";
        let isShowTagCategory = true;
        if (`${broadcast_type_id}` === "3") {
            isShowTagCategory = false;
        }
        return (
            <div className={styles["gw-card-tag"]}>
                <div className={`${styles["gw-card-tag-item"]} ${styles["gw-type"]}`} is-broadcast={"true"}><span>{property_type_name}</span></div>
                {
                    isShowTagCategory && 
                    <div className={`${styles["gw-card-tag-item"]} ${styles["gw-category"]}`} is-broadcast={"true"}><span>{category_name}</span></div>
                }
            </div>
        )
    }
    const ShowDescription = () => {
        try {
            return (
                <div style={{ position: "relative" }}>
                    <div className='gw-textarea-block' category={data?.cate_id}>
                        <Input.TextArea 
                            className={clsx("gw-textarea-card")}
                            category={data?.cate_id}
                            broadcasttypeid={data?.broadcast_type_id}
                            placeholder=''
                            autoSize={true} 
                            value={data?.desc} readOnly/>
                    </div>
                    <ShowTag />
                </div>
            )
        } catch (error) {
            return "";
        }
    }

    return (
        <MyGrowMain>
            <div className={`gw-text-title`}>
                <span>Broadcast</span>
            </div>
            <Row gutter={[24, 24]} className={`gw-mt-32`} style={{ paddingBottom: 35}}>
                <Col span={24}>
                    <div className={`${styles["gw-header"]}`}>
                        <div className={`gw-btn-link gw-btn-danger`} onClick={onClikeNavigateBack}>
                            <IconBack />
                            <span>Back</span>
                        </div>
                    </div>
                </Col>
                <Col span={24} className={`${stylesPropmarketDetails["gw-feed-content"]}`}>
                    <div className={`${stylesCardItem["gw-card-item"]} ${stylesPropmarketDetails["gw-detail-broadcast"]}`}>
                        <div className='gw-card-header'>
                            <div className='gw-card-header-left'>
                                <Image
                                    preview={false}
                                    referrerPolicy='no-referrer'
                                    className='gw-card-image-profile'
                                    src={getData.create_by?.avatar || ""}
                                    fallback={imgfallback} />
                                <div className='gw-card-user-info'>
                                    <div className='gw-card-username gw-btn-link'>
                                        <span>{getData.create_by?.display_name || ""}</span>
                                        {verifyBadge()}
                                    </div>
                                    <div className='gw-card-location'>
                                        <Breadcrumb
                                            separator={<svg className='gw-dot' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="50" cy="50" r="50" />
                                            </svg>}>
                                            {/* {getData?.district?.dt_name && <Breadcrumb.Item>{getData?.district?.dt_name || ""}</Breadcrumb.Item>} */}
                                            {getData?.amphures?.am_name && <Breadcrumb.Item>{getData?.amphures?.am_name || ""}</Breadcrumb.Item>}
                                            {getData?.province?.pv_name && <Breadcrumb.Item>{getData?.province?.pv_name || ""}</Breadcrumb.Item>}
                                        </Breadcrumb>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <div className='gw-card-text-content'>
                            <span className='gw-text-title'>ประกาศ ซื้อบ้าน</span>
                            {/* <span className='gw-text-details gw-title'>{getData.title || ""}</span> */}
                            <span className='gw-text-details'><ShowDescription /></span>
                        </div>
                        {/* <div className='gw-map'>
                            <MapContainer {...prepareMapData()} isBroadCast={true} />
                        </div> */}
                        <div className='gw-card-details'>
                            <div className='gw-card-detail-left'>
                                <div className='gw-price-title'>
                                    <span>ช่วงราคาที่ต้องการ:</span>
                                </div>
                                <div className='price'>
                                    <span>
                                        <span className={styles["gw-value"]}>{showPrice(getData?.cost_min)}-{showPrice(getData?.cost_max)}</span>
                                        <span className={stylesCardItem["gw-suffix-unit"]}>บาท</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <CommentComponent dataItem={data} inputCommentRef={inputCommentRef}/>
                    </div>
                </Col>
            </Row>
        </MyGrowMain>
    )
}

export default MyGrow3BroadcastDetail;