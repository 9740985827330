import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import MyGrowMain from "../MyGrow";
import { Button, Col, Divider, Image, Row } from "antd";
import { ReactComponent as H0ViewLight } from "../../assets/icons/pages/Mygrow/h-0-View_light.svg";
import { ReactComponent as H1CheckRingRoundLight } from "../../assets/icons/pages/Mygrow/h-1-check_ring_round_light.svg";
import { ReactComponent as H2InfoLight } from "../../assets/icons/pages/Mygrow/h-2-Info_light.svg";
import CardItemPrimary from "../../components/CardItemPrimary";
import { useFilterContext } from "../../context/filter";
import { filterEvent, validateNotLoadMore } from "../../utils/common";
import * as services from "../../services/service.property";
import LoadMoreElement from '../../components/LoadMore';
import ListElement from "../../components/ListElement";
import InfiniteScroll from "react-infinite-scroller";
import { usePromiseTracker } from "react-promise-tracker";
import { useLoadingListContext } from "../../context/loaddingList";
import { useAuthContext } from "../../context/authentication";
import { RoutePath } from "../../Routes";

const MyGrow1Profile = props => {
    const { promiseInProgress } = usePromiseTracker();
    const navigate = useNavigate();
    const location = useLocation();
    const [_dataList, setDataList] = useState([]);
    const [dataMeta, setDataMeta] = useState({});
    const [loadMorePage, setLoadMorePage] = useState(1);
    const {data: filterData, action: filterAction} = useFilterContext();
    const {action: loadingListAction} = useLoadingListContext();
    const { data: auth } = useAuthContext();
    const [userData, setUserData] = useState({});
    
    const dataList = useMemo(() => {
        return _dataList;
    }, [_dataList]);

    useEffect(() => {
        const filterSearchClean = filterEvent.decodeFilter(location);
        filterAction.updateData(filterSearchClean);
        loadData({ ...filterSearchClean });
        return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    
    useEffect(function () {
        if (loadMorePage !== 1 && Object.keys(filterData.filterSearchObj || {}).length > 0) {
            loadData({ ...filterData.filterSearchObj, page: loadMorePage }, true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMorePage]);

    const loadData = async (requestBody, loadMore = false) => {
        try {
            loadingListAction.updateData(true);
            const responseDataProperty = await services.fetchPropertyMyProperty(requestBody);
            const responseItems = responseDataProperty?.data?.data?.items || [];
            const responseMeta = responseDataProperty?.data?.data?.meta || {};
            if (responseDataProperty?.data?.data) {
                setDataMeta(function (prevData) {
                    return { ...responseMeta }
                });
                setDataList(function (prevData) {
                    return [...(loadMore ? prevData : []), ...responseItems]
                });
            } else {
                throw Error("no data")
            }
        } catch (error) {
            setDataMeta({});
            setDataList([]);
        } finally {
            loadingListAction.updateData(false);
        }
    }

    const loadMoreData = () => {
        if (!promiseInProgress && validateNotLoadMore(dataMeta, loadMorePage)) {
            setLoadMorePage(prevData => {
                return prevData + 1;
            });
        }
    }

    useEffect(() => {
        async function loadData() {
            const response = await services.getPropertyProfile();
            if (response?.data?.data) {
                setUserData(response?.data?.data || {})
            }
        }
        if (auth?.isAuthentication) {
            loadData();
        }
    }, [auth?.isAuthentication]);

    const onClickEditProfile = () => {
        navigate(RoutePath.my_grow_profile_edit.path);
    }

    const getImageVerify = (image = "") => {
        let icon = <H2InfoLight />;
        let active_cls = "";
        if (image) {
            icon = <H1CheckRingRoundLight />;
            active_cls = styles["gw-item-active"];
        }
        
        return {
            icon,
            active_cls,
            image
        }
    }
    const [visiblePreview1, setVisiblePreview1] = useState(false);
    const [visiblePreview2, setVisiblePreview2] = useState(false);
    
    const citizen_image = getImageVerify(userData?.user?.citizen_image);
    const citizen_user_image = getImageVerify(userData?.user?.citizen_user_image);

    return (
        <MyGrowMain>
            {
                !!userData?.user?.uid && (
                    <div className={styles["gw-header"]}>
                        <div className={styles["gw-status"]}>
                            <div className={styles["gw-group-text"]}>
                                <span className={styles["gw-text-count"]}>{userData?.total_property || 0}</span>
                                <span className={styles["gw-text-detail"]}>
                                    รายการอสังหา
                                </span>
                            </div>
                            <div className={styles["gw-group-text"]}>
                                <span className={styles["gw-text-count"]}>{userData?.sold_property || 0}</span>
                                <span className={styles["gw-text-detail"]}>
                                    ที่ขายแล้ว
                                </span>
                            </div>
                            <div className={styles["gw-group-text"]}>
                                <span className={styles["gw-text-count"]}>{userData?.sale_property || 0}</span>
                                <span className={styles["gw-text-detail"]}>
                                    กำลังขาย
                                </span>
                            </div>
                        </div>
                        <Divider className="gw-mt-24" />
                        <div className={styles["gw-description"]}>
                            <span className={styles["gw-text-title"]}>Description</span>
                            <span className={styles["gw-text-detail"]}>{userData?.user?.about_me || userData?.about_me || ""}</span>
                        </div>
                        <Row gutter={[24, 24]} className={styles["gw-list-document"]}>
                            <Col span={12}>
                                <div
                                    className={`${styles["gw-item-document"]} ${citizen_image.active_cls}`}
                                >
                                    <Image
                                        width={10}
                                        style={{ display: 'none' }}
                                        src={citizen_image.image}
                                        preview={{
                                            visible: visiblePreview1,
                                            src: citizen_image.image,
                                            onVisibleChange: value => {
                                                setVisiblePreview1(value);
                                            },
                                        }}
                                    />
                                    <div className={styles["gw-icon"]}>
                                        {citizen_image.icon}
                                    </div>
                                    <div className={styles["gw-label"]}>
                                        <span>รูปถ่ายบัตรประชาชน</span>
                                    </div>
                                    {
                                        citizen_image.image && (
                                            <Button
                                                shape="circle"
                                                className={styles["gw-suffix"]}
                                                onClick={_ => setVisiblePreview1(true)}
                                            >
                                                <H0ViewLight />
                                            </Button>
                                        )
                                    }
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className={`${styles["gw-item-document"]} ${citizen_user_image.active_cls}`}>
                                    <Image
                                        width={10}
                                        style={{ display: 'none' }}
                                        src={citizen_user_image.image}
                                        preview={{
                                            visible: visiblePreview2,
                                            src: citizen_user_image.image,
                                            onVisibleChange: value => {
                                                setVisiblePreview2(value);
                                            },
                                        }}
                                    />
                                    <div className={styles["gw-icon"]}>
                                        {citizen_user_image.icon}
                                    </div>
                                    <div className={styles["gw-label"]}>
                                        <span>รูปถ่ายตัวเองคู่กับบัตรประชาชน</span>
                                    </div>
                                    {
                                        citizen_user_image.image && (
                                            <Button
                                                shape="circle"
                                                className={styles["gw-suffix"]}
                                                onClick={_ => setVisiblePreview2(true)}
                                            >
                                                <H0ViewLight />
                                            </Button>
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Button className={`gw-mt-16 ${styles["gw-btn-edit"]}`} onClick={onClickEditProfile}>
                            แก้ไขข้อมูลส่วนตัว
                        </Button>
                    </div>
                )
            }
            <div className={`gw-mt-16 ${styles["gw-text-title"]}`}>
                <span>Propmarket</span>
            </div>
            <InfiniteScroll
                threshold={750}
                initialLoad={false}
                loadMore={loadMoreData}
                hasMore={validateNotLoadMore(dataMeta, loadMorePage)}
            >
                <Row gutter={[24, 24]} className={`gw-mt-32`}>
                    <ListElement list={dataList} type={"BOOTITEM_24"} />
                    <LoadMoreElement {...{
                        dataMeta,
                        loadMorePage,
                        loadMoreData
                    }} />
                </Row>
            </InfiniteScroll>
        </MyGrowMain>
    );
};

export default MyGrow1Profile;
