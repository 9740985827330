import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra';
import relativeTime from 'dayjs/plugin/relativeTime';
import { lastIndexOf, substr } from '@7urtle/lambda';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';

import AuthProvidor from './context/authentication'
import ModalProvidor from './context/modals'
import FilterProvidor from './context/filter'
import LoadingListProvidor from './context/loaddingList'

import Header from './components/Header';
import ModalLogin from './components/ModalLogin';
import ModalNews from './components/ModalNews';
import ScrollToTop from './utils/ScrollToTop';
import LoadingSpiner from './components/Loading';
import { Helmet } from 'react-helmet';
import ModalShareComponent from './components/ModalShare';
import HeaderProvidor from './context/header';
import { Provider } from 'react-redux';
import store from './redux/store';
import { useMediaQuery } from 'react-responsive';
import MasterDataProvidor from './context/masterData';

// dayjs.locale("th");
// dayjs.extend(buddhistEra);
dayjs.extend(relativeTime);

/**
 * Turns URL path into router basename by removing everything after the last slash
 * 
 * @HindleyMilner getBasename :: string -> string
 *
 * @pure
 * @param {string} path URL path, probably window.location.pathname
 * @returns {string} final basename
 * 
 * @example
 * getBaseName('/some/structure/page'); // => '/some/structure'
 */
export const getBasename = path => substr(lastIndexOf('/')(path))(0)(path);

/**
 * Base Template component holding the basic web application
 * @returns {JSX.Element}
 */
const App = () => {
	return (
		<>
			<Helmet>
				<title>Growestate</title>
			</Helmet>
			<BrowserRouter basename={"/"}>
				<Provider store={store}>
					<LoadingSpiner>
						<AuthProvidor>
							<ModalProvidor>
								<FilterProvidor>
									<HeaderProvidor>
										<MasterDataProvidor>
											<Header />
											<main>
												<ScrollToTop />
												<LoadingListProvidor>
													<Routes />
												</LoadingListProvidor>
											</main>
											<ModalLogin />
											<ModalNews />
											<ModalShareComponent />
										</MasterDataProvidor>
									</HeaderProvidor>
								</FilterProvidor>
							</ModalProvidor>
						</AuthProvidor>
					</LoadingSpiner>
				</Provider>
			</BrowserRouter>
		</>
	);
};

export default App;