import { useEffect, useMemo, useState } from 'react';
import stylesBroadcast from './../MyGrow3Broadcast/style.module.scss';
import styles from './style.module.scss';
import MyGrowMain from "../MyGrow";
import { cleanFilter, defaultValueFilter, filterEvent, prepareFilter, validateNotLoadMore } from '../../utils/common';
import { Button, Col, Input, Row, Select } from 'antd';
import CardItemPrimary from '../../components/CardItemPrimary';
import MapFilter from '../../components/MapFilter';
import { ReactComponent as IcoExpandDownLight } from '../../assets/icons/pages/findProertyMap/filter/expand_down_light.svg';
import * as services from "../../services/service.property";
import { useLocation, useNavigate } from 'react-router-dom';
import { useFilterContext } from '../../context/filter';
import LoadMoreElement from '../../components/LoadMore';
import { RoutePath } from '../../Routes';
import ListElement from '../../components/ListElement';
import InfiniteScroll from 'react-infinite-scroller';
import { usePromiseTracker } from 'react-promise-tracker';
import { useLoadingListContext } from '../../context/loaddingList';

const MyGrow2SellProperty = (props) => {
    const { promiseInProgress } = usePromiseTracker();
    const navigate = useNavigate();
    const location = useLocation();
    const [_dataList, setDataList] = useState([]);
    const [dataMeta, setDataMeta] = useState({});
    const [loadMorePage, setLoadMorePage] = useState(1);
    const {data: filterData, action: filterAction} = useFilterContext();
    const {action: loadingListAction} = useLoadingListContext();
    
    const dataList = useMemo(() => {
        return _dataList;
    }, [_dataList]);
    
    useEffect(() => {
        const filterSearchClean = filterEvent.decodeFilter(location);
        filterAction.updateData(filterSearchClean);
        loadData({ ...filterSearchClean });
        return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    
    useEffect(function () {
        if (loadMorePage !== 1 && Object.keys(filterData.filterSearchObj || {}).length > 0) {
            loadData({ ...filterData.filterSearchObj, page: loadMorePage }, true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMorePage]);

    const loadData = async (requestBody, loadMore = false) => {
        try {
            loadingListAction.updateData(true);
            const responseDataProperty = await services.fetchPropertyMyProperty(requestBody);
            const responseItems = responseDataProperty?.data?.data?.items || [];
            const responseMeta = responseDataProperty?.data?.data?.meta || {};
            if (responseDataProperty?.data?.data) {
                setDataMeta(function (prevData) {
                    return { ...responseMeta }
                });
                setDataList(function (prevData) {
                    return [...(loadMore ? prevData : []), ...responseItems]
                });
            } else {
                throw Error("no data")
            }
        } catch (error) {
            setDataMeta({});
            setDataList([]);
        } finally {
            loadingListAction.updateData(false);
        }
    }

    const loadMoreData = () => {
        if (!promiseInProgress && validateNotLoadMore(dataMeta, loadMorePage)) {
            setLoadMorePage(prevData => {
                return prevData + 1;
            });
        }
    }

    const onChangeSelect = (value) => {
        const filterSearchClean = cleanFilter(prepareFilter({
            sale_status: value
        }))
        filterAction.updateData(filterSearchClean);
        loadData({ ...filterSearchClean });
    }

    return (
        <MyGrowMain>
            <div className={styles["gw-header"]}>
                <div className={`gw-text-title`}>
                    <span>Sell Property</span>
                </div>
                
                <Select
                    placeholder="เลือก..."
                    suffixIcon={<IcoExpandDownLight />}
                    defaultValue=""
                    onChange={onChangeSelect}
                >
                    <Select.Option value="">ทั้งหมด</Select.Option>
                    <Select.Option value="sold">ขายแล้ว</Select.Option>
                    <Select.Option value="sale">รอขาย</Select.Option>
                </Select>
            </div>
            <InfiniteScroll
                threshold={750}
                initialLoad={false}
                loadMore={loadMoreData}
                hasMore={validateNotLoadMore(dataMeta, loadMorePage)}
            >
                <Row gutter={[24, 24]} className={`gw-mt-32`}>
                    <ListElement list={dataList} type={"BOOTITEM_24"}/>
                    <LoadMoreElement {...{
                        dataMeta,
                        loadMorePage,
                        loadMoreData
                    }} />
                </Row>
            </InfiniteScroll>
        </MyGrowMain>
    )
}

export default MyGrow2SellProperty;