import { useState } from 'react';
import stylesBroadcast from './../MyGrow3Broadcast/style.module.scss';
import styles from './style.module.scss';
import MyGrowMain from "../MyGrow";
import { defaultValueFilter } from '../../utils/common';
import { Button, Col, Input, Row } from 'antd';
import CardItemPrimary from '../../components/CardItemPrimary';
import MapFilter from '../../components/MapFilter';
import { ReactComponent as SearchAltDuotone } from '../../assets/icons/header/search_alt_duotone.svg';
import { ReactComponent as IcoFilter } from '../../assets/icons/pages/findProertyMap/filter_alt.svg';
import MapContainer from '../../components/GMap';
import { mapPinSizeStyles } from '../FindPropertyMap/settings';

const MyGrow4PinLocationPin = (props) => {
    const [visibleDrawerFilter, setVisibleDrawerFilter] = useState(false);

    const showDrawerFilter = () => {
        setVisibleDrawerFilter(true);
    };

    const closeDrawerFilter = () => {
        setVisibleDrawerFilter(false);
    };

    const onFilterFinish = (values) => {
        console.log('Success:', values);
    };

    const onFilterFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFilterReset = (...args) => {
        console.log('Reset:', defaultValueFilter);
    };
    return (
        <MyGrowMain>
            <div className={`${stylesBroadcast["gw-header"]}`}>
                <div className={`gw-text-title`}>
                    <span>Pin Location</span>
                </div>
                <Button type="link" className={`${styles["btn-link"]}`} onClick={showDrawerFilter}>
                    <IcoFilter />
                    <span>ตัวกรอง</span>
                </Button>
            </div>
            <div className={`gw-mt-24 ${styles["gw-header-map"]}`}>
                <MapContainer
                    mapSizeStyles={mapPinSizeStyles}
                    isBroadCast={true}
                    defaultValue={{
                        rangeArea: 1500
                    }}
                />
            </div>
            <Row gutter={[24, 24]} className={`gw-mt-24`}>
                {
                    [{ image_type: "1" }, { image_type: "2" }].map((item, index) => (
                        <Col span={24} key={index}>
                            <CardItemPrimary dataitem={item} />
                        </Col>
                    ))
                }
            </Row>
            <MapFilter 
                visibleDrawerFilter={visibleDrawerFilter}
                closeDrawerFilter={closeDrawerFilter}
                onFilterFinish={onFilterFinish}
                onFilterFinishFailed={onFilterFinishFailed}
                onFilterReset={onFilterReset}
            />
        </MyGrowMain>
    )
}

export default MyGrow4PinLocationPin;