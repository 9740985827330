import React, { createContext, useContext, useState } from "react";
import { node } from 'prop-types';

const MasterDataContext = createContext({});

export function useMasterDataContext() {
    return useContext(MasterDataContext)
}

const initialData = {
    
}

function MasterDataProvidor({ children }) {
    const [data, setData] = useState(initialData);
    const [provinceDatas, setProvinceDatas] = useState([]);
    const [masterDataBoardCast, setMasterDataBoardCast] = useState({
        category: [],
        type: [],
    });

    function updateData(nextData) {
        setData(_ => ({
            ...nextData
        }))
    }

    function updateProvinceDatas(nextData) {
        setProvinceDatas(_ => (nextData))
    }

    function updateMasterDataBoardCast(nextData) {
        setMasterDataBoardCast(prevData => ({
            ...prevData,
            ...nextData
        }))
    }

    const dataStore = {
        data,
        masterData: {
            provinces: [...provinceDatas],
            masterDataBoardCast: { ...masterDataBoardCast, }
        },
        action: {
            updateData,
            updateProvinceDatas,
            updateMasterDataBoardCast,
        }
    }

    return <MasterDataContext.Provider value={dataStore}>{children}</MasterDataContext.Provider>
}

MasterDataProvidor.prototype = {
    children: node.isRequired
}

export default MasterDataProvidor;