import { useEffect, useState } from 'react';
import stylesBroadcast from './../MyGrow3Broadcast/style.module.scss';
import styles from './style.module.scss';
import MyGrowMain from "../MyGrow";
import { defaultValueFilter, filterEvent, validateNotLoadMore } from '../../utils/common';
import { Button, Col, Input, Row } from 'antd';
import CardItemPrimary from '../../components/CardItemPrimary';
import MapFilter from '../../components/MapFilter';
import { ReactComponent as SearchAltDuotone } from '../../assets/icons/header/search_alt_duotone.svg';
import { ReactComponent as IcoFilter } from '../../assets/icons/pages/findProertyMap/filter_alt.svg';
import * as services from "../../services/service.property";
import { useLocation, useNavigate } from 'react-router-dom';
import { useFilterContext } from '../../context/filter';
import LoadMoreElement from '../../components/LoadMore';
import { RoutePath } from '../../Routes';
import ListElement from '../../components/ListElement';
import InfiniteScroll from 'react-infinite-scroller';
import { usePromiseTracker } from 'react-promise-tracker';
import { useLoadingListContext } from '../../context/loaddingList';

const MyGrow5Favourite = (props) => {
    const { promiseInProgress } = usePromiseTracker();
    const navigate = useNavigate();
    const location = useLocation();
    const [visibleDrawerFilter, setVisibleDrawerFilter] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [dataMeta, setDataMeta] = useState({});
    const [loadMorePage, setLoadMorePage] = useState(1);
    const { data: filterData, action: filterAction } = useFilterContext();
    const { action: loadingListAction } = useLoadingListContext();

    const showDrawerFilter = () => {
        setVisibleDrawerFilter(true);
    };

    const closeDrawerFilter = () => {
        setVisibleDrawerFilter(false);
    };

    const onFilterFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFilterReset = (...args) => {
        console.log('Reset:', defaultValueFilter);
    };
    
    useEffect(() => {
        const filterSearchClean = filterEvent.decodeFilter(location);
        filterAction.updateData(filterSearchClean);
        loadData({ ...filterSearchClean });
        return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    
    useEffect(function () {
        if (loadMorePage !== 1 && Object.keys(filterData.filterSearchObj || {}).length > 0) {
            loadData({ ...filterData.filterSearchObj, page: loadMorePage }, true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMorePage]);

    const onFilterFinish = (values) => {
        navigate({
            pathname: RoutePath.my_grow_favorite.path,
            search: `?${filterEvent.encodeFilter(values)}`
        });
        closeDrawerFilter();
    };

    const loadData = async (requestBody, loadMore = false) => {
        try {
            loadingListAction.updateData(true);
            const responseDataProperty = await services.fetchPropertyFavorit(requestBody);
            const responseItems = responseDataProperty?.data?.data?.items || [];
            const responseMeta = responseDataProperty?.data?.data?.meta || {};
            if (responseDataProperty?.data?.data) {
                setDataMeta(function (prevData) {
                    return { ...responseMeta }
                });
                setDataList(function (prevData) {
                    return [...(loadMore ? prevData : []), ...responseItems]
                });
            } else {
                throw Error("no data")
            }
        } catch (error) {
            setDataMeta({});
            setDataList([]);
        } finally {
            loadingListAction.updateData(false);
        }
    }

    const loadMoreData = () => {
        if (!promiseInProgress && validateNotLoadMore(dataMeta, loadMorePage)) {
            setLoadMorePage(prevData => {
                return prevData + 1;
            });
        }
    }

    return (
        <MyGrowMain>
            <div className={`gw-text-title`}>
                <span>Favorite</span>
            </div>
            <div className={`gw-mt-24 ${stylesBroadcast["gw-header"]}`}>
                {/* <div className={stylesBroadcast["gw-text-prefix"]}>
                    <span>ค้นหา</span>
                </div>
                <div className={`${stylesBroadcast["gw-chat-header-search"]}`}>
                    <Input placeholder="ค้นหา" prefix={<SearchAltDuotone />} />
                </div> */}
                <Button type="link" className={`${styles["btn-link"]}`} onClick={showDrawerFilter}>
                    <IcoFilter />
                    <span>ตัวกรอง</span>
                </Button>
            </div>
            <InfiniteScroll
                threshold={750}
                initialLoad={false}
                loadMore={loadMoreData}
                hasMore={validateNotLoadMore(dataMeta, loadMorePage)}
            >
                <Row gutter={[24, 24]} className={`gw-mt-16`}>
                    <ListElement list={dataList} type={"DEFAULT_24"} />
                    <LoadMoreElement {...{
                        dataMeta,
                        loadMorePage,
                        loadMoreData
                    }} />
                </Row>
            </InfiniteScroll>
            <MapFilter 
                visibleDrawerFilter={visibleDrawerFilter}
                closeDrawerFilter={closeDrawerFilter}
                onFilterFinish={onFilterFinish}
                onFilterFinishFailed={onFilterFinishFailed}
                onFilterReset={onFilterReset}
            />
        </MyGrowMain>
    )
}

export default MyGrow5Favourite;