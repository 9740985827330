import { Spin } from 'antd';
import { usePromiseTracker } from "react-promise-tracker";

function LoadingSpiner({ children }) {
    const { promiseInProgress } = usePromiseTracker();
    return <>
        {children}
    </>
    // return (
    //     <Spin spinning={promiseInProgress} style={{zIndex: 1001}}>
    //         {children}
    //     </Spin>
    // )
};

export default LoadingSpiner;