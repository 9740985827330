import { Children, useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Divider, Image, Row } from 'antd';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styles from './style.module.scss';
import { imgfallback } from '../../utils/common';
import { ReactComponent as IconBack } from '../../assets/icons/pages/findProertyDetail/arrow_left_back.svg';
import Gallery from 'react-photo-gallery-react17';
import reactImageSize from 'react-image-size';
import { RoutePath } from '../../Routes';
import ImgsViewer from 'react-images-viewer';
import { ReactComponent as ImgBoxLight } from '../../assets/icons/pages/findProertyDetail/Img_box_light.svg';
import { fetchImagesProperty } from '../../services/service.property';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';

const mockImagesNoSize = [
    {
        src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599"
    },
    {
        src: "https://images.unsplash.com/photo-1495615080073-6b89c9839ce0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=906&q=80"
    },
    {
        src: "https://source.unsplash.com/qDkso9nvCg0/600x799"
    },
    {
        src: "https://source.unsplash.com/iecJiKe_RNg/600x799"
    },
    {
        src: "https://source.unsplash.coms/epcsn8Ed8kY/600x799"
    },
    {
        src: "https://source.unsplash.com/NQSWvyVRIJk/800x599"
    },
    {
        src: "https://source.unsplash.com/zh7GEuORbUw/600x799"
    },
]

const ratioImage = {
    "1:1": {
        width: 1,
        height: 1
    }, 
    "3:4": {
        width: 3,
        height: 4
    }, 
    "4:3": {
        width: 4,
        height: 3
    }
}

const IndexPage = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [images, setImages] = useState([]);
    const [sourceImages, setSourceImages] = useState([]);
    const [meta, setMeta] = useState({});
    const onClikeNavigateBack = () => {
        // navigate(RoutePath.find_property_detail.path)
        navigate(-1);
    }

    useEffect( () => {
        const p_id = searchParams.get("p_id");
        if (p_id) {
            async function loadData() {
                try {
                    const response = await fetchImagesProperty(p_id);
                    if (response?.data?.data) {
                        const res_images = response?.data?.data?.items || [];
                        const res_meta = response?.data?.data?.meta || {};
                        setMeta(res_meta);
                        prepareImages(res_images);
                    }
                } catch (error) {
                    console.log("loadData images error : ", error)
                }
            }
            loadData();
        }
        function prepareImages(sourceImages) {
            sourceImages = [...sourceImages].map(item => {
                return {
                    ...item,
                    src: item.path
                };
            });
            sourceImages = sourceImages.sort((a,b) => (a.seq > b.seq) ? 1 : ((b.seq > a.seq) ? -1 : 0));
            setSourceImages(sourceImages);
            const premiseList = sourceImages.map((imageItem) => {
                return reactImageSize(imageItem.src)
            })
            Promise.allSettled(premiseList).then((response) => {
                const result = response.map((resItem) => {
                    let retio = ratioImage['1:1'];
                    if (resItem.status === "fulfilled") {
                        const calSize = resItem.value.width - resItem.value.height;
                        if (calSize > 75) {
                            retio = ratioImage['4:3'];
                        } else if (calSize < -75) {
                            retio = ratioImage['3:4'];
                        }
                        return {
                            src: resItem.value.currentSrc,
                            ...retio
                        }
                    }
                    return {
                        src: "",
                        ...retio
                    }
                })
                setImages(result)
            });
        }
    }, [searchParams])

    const [isOpenLightbox, setIsOpenLightbox] = useState(false);
    const [currImg, setCurrImg] = useState(0);
    
    const onClickImageNavigateDetail = (imgIndex) => {
        setIsOpenLightbox(true);
        setCurrImg(imgIndex);
    }

    const PreviewMask = () => (
        <div className='gw-preview-mask'>
            <ImgBoxLight />
            <span>Preview</span>
        </div>
    )

    return (
        <div className={`${styles["navigate-container"]}`}>
            <Helmet>
                <title>{searchParams.get("p_id") || ""} - Gallery | Growestate</title>
            </Helmet>
            <ImgsViewer
                imgs={sourceImages}
                showThumbnails={true}
                isOpen={isOpenLightbox}
                onClose={_ => setIsOpenLightbox(false)}
                currImg={currImg}
                onClickPrev={(e) => { setCurrImg(currImg - 1) }}
                onClickNext={(e) => { setCurrImg(currImg + 1) }}
                onClickThumbnail={index => setCurrImg(index)}
            />
            <div className={`gw-container`}>
                <Row gutter={[24, 24]} className={`gw-mt-32`}>
                    <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                        <div className={`${styles["gw-header"]}`}>
                            <div className={`gw-btn-link gw-btn-danger`} onClick={onClikeNavigateBack}>
                                <IconBack />
                                <span>Back</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                        <Gallery
                            margin={8.25}
                            columns={3}
                            direction={"row"}
                            renderImage={(data) => {
                                return <Image
                                    {...data.photo}
                                    key={data.key}
                                    wrapperStyle={{ margin: data.margin }}
                                    wrapperClassName='gw-item-image'
                                    fallback={imgfallback}
                                    onClick={() => onClickImageNavigateDetail(data.index)}
                                    preview={{
                                        visible: false,
                                        mask: <PreviewMask />
                                    }}
                                />
                            }}
                            photos={images} />
                        <div className='gw-count-images'>
                            <span>{images.length || 0}</span>
                            <span>Photos</span>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default IndexPage;