import { useEffect, useMemo, useState } from 'react';
import styles from './style.module.scss';
import MyGrowMain from "../MyGrow";
import { Button, Col, Input, Row } from 'antd';
import CardItemPrimary from '../../components/CardItemPrimary';
import { ReactComponent as SearchAltDuotone } from '../../assets/icons/header/search_alt_duotone.svg';
import * as services from "../../services/service.broadcast";
import LoadMoreElement from '../../components/LoadMore';
import ListElement from '../../components/ListElement';
import InfiniteScroll from 'react-infinite-scroller';
import { usePromiseTracker } from 'react-promise-tracker';
import { filterEvent, validateNotLoadMore } from '../../utils/common';
import { useLoadingListContext } from '../../context/loaddingList';
import BroadcastFilter, { decodeFilter } from '../../components/BroadcastFilter/BroadcastFilter';
import { ReactComponent as IcoFilter } from '../../assets/icons/pages/findProertyMap/filter_alt.svg';
import { useModalContext } from '../../context/modals';
import pageListstyles from '../FindPropertyList/style.module.scss';
import { useLocation } from 'react-router-dom';
import axios from "axios";

const MyGrow3Broadcast = (props) => {
    const location = useLocation();
    const { promiseInProgress } = usePromiseTracker();
    const [_dataList, setDataList] = useState([]);
    const [dataMeta, setDataMeta] = useState({});
    const [loadMorePage, setLoadMorePage] = useState(1);
    const { action: loadingListAction } = useLoadingListContext();
    const { action: { updateDrawerBroadcastFilter } } = useModalContext();
    
    const dataList = useMemo(() => {
        return _dataList;
    }, [_dataList]);

    const loadDataBroadcast = async (cancelTokenSource, filterBody = {}, loadMore = false) => {
        try {
            loadingListAction.updateData(true);
            const responseDataProperty = await services.fetchBroadcast(filterBody, cancelTokenSource);
            const responseItems = responseDataProperty?.data?.data?.items || [];
            const responseMeta = responseDataProperty?.data?.data?.meta || {};
            if (responseDataProperty?.data?.data) {
                setDataMeta(function (prevData) {
                    return {...responseMeta}
                });
                setDataList(function (prevData) {
                    return [...(loadMore ? prevData : []), ...responseItems]
                });
            } else {
                throw Error("no data")
            }
        } catch (error) {
            setDataMeta({});
            setDataList([]);
        } finally {
            loadingListAction.updateData(false);
        }
    }

    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        loadDataBroadcast(cancelTokenSource, { ...prepareFilter(location), page: 1, limit: 10 }, false);
        return () => {
            cancelTokenSource.cancel("Request canceled by the user.");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    
    useEffect(function () {
        const cancelTokenSource = axios.CancelToken.source();
        if (loadMorePage !== 1) {
            loadDataBroadcast(cancelTokenSource, {...prepareFilter(location), page: loadMorePage, limit: 10}, true);
        }
        return () => {
            cancelTokenSource.cancel("Request canceled by the user.");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMorePage]);

    const prepareFilter = (location = {}) => {
        const filters = decodeFilter(location, { range_field: ["price"] });
        return filters;
    }

    const loadMoreData = () => {
        if (!promiseInProgress && validateNotLoadMore(dataMeta, loadMorePage)) {
            console.log("is load ")
            setLoadMorePage(prevData => {
                return prevData + 1;
            });
        }
    }

    return (
        <MyGrowMain>
            <div className={`${pageListstyles["gw-header"]} gw-mt-24`} style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={`gw-text-title`}>
                    <span>Broadcast</span>
                </div>
                <div className={`${pageListstyles["gw-header-action"]}`}>
                    <Button type="link" className={`${pageListstyles["btn-link"]}`} onClick={_ => updateDrawerBroadcastFilter(true)}>
                        <IcoFilter />
                        <span>Filter</span>
                    </Button>
                </div>
            </div>
            <InfiniteScroll
                threshold={750}
                initialLoad={false}
                loadMore={loadMoreData}
                hasMore={validateNotLoadMore(dataMeta, loadMorePage)}
            >
                <Row gutter={[24, 24]} className={`gw-mt-16`}>
                    <ListElement list={dataList} type={"BROADCAST_24"}/>
                    <LoadMoreElement {...{ dataMeta, loadMorePage, loadMoreData }} />
                </Row>
            </InfiniteScroll>
            <BroadcastFilter />
        </MyGrowMain>
    )
}

export default MyGrow3Broadcast;