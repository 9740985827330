import styles from './style.module.scss';
import { Breadcrumb, Button, Carousel, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { imgfallback, showPrice } from "../../utils/common";
import { ReactComponent as ShoppingBasket } from '../../assets/icons/components/card-item/1-shopping-basket.svg';
import { ReactComponent as Bathroom } from '../../assets/icons/components/card-item/2-bathroom.svg';
import { ReactComponent as LandSizeOrLot } from '../../assets/icons/components/card-item/3-land-size-or-lot.svg';
import { ReactComponent as Coin} from '../../assets/icons/components/card-item/4-coin.svg';
import { ReactComponent as Chat} from '../../assets/icons/components/card-item/5-chat.svg';
import { ReactComponent as Share} from '../../assets/icons/components/card-item/6-share.svg';
import { ReactComponent as BuildingSize } from '../../assets/icons/components/card-item/7-building-size.svg';
import { ReactComponent as Garage} from '../../assets/icons/components/card-item/8-garage.svg';
import { ReactComponent as StarDuotone } from '../../assets/icons/components/card-item/star_duotone.svg';
import { RoutePath } from '../../Routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ModalShareComponent from '../ModalShare';
import { useModalContext } from '../../context/modals';
import firebaseService from '../../services/firebase.service';
import { useSelector } from 'react-redux';
import { updateCoinByPId, updateFavoriteByPId } from '../../services/service.property';
import icdollarfalse from '../../assets/icons/pages/findProertyDetail/coin/ic_dollar_false.png';
import icdollartrue from '../../assets/icons/pages/findProertyDetail/coin/ic_dollar_true.png';

const CardItemSecondary = ({
    hideSelectPlace,
    isSelector = true,
    dataitem = {},
    isNotShowComment = false
}) => {
    const { userData } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [toggleDetail, SetToggleDetail] = useState(false);
    const [data, setData] = useState({
        detail: "",
        property: {},
        images: [],
    });
    const { action: actionModal } = useModalContext();

    const [dataAPI, setDataAPI] = useState({
        isFavorite: false,
        isCoin: false,
        count_coin: 0,
        count_comment: 0,
    });

    useEffect(function () {
        setData(function(prevData) {
            return {
                ...prevData,
                detail: `${dataitem?.property?.advertisement || ""}`,
                property: dataitem?.property || {},
                images: (dataitem?.property?.propertyImages || []).map(function (item) {
                    return {
                        ...item,
                        src: item?.path || ""
                    }
                }).sort((a,b) => (a.seq > b.seq) ? 1 : ((b.seq > a.seq) ? -1 : 0))
            }
        })
    }, [dataitem]);

    const getImages = useMemo(() => {
        return data.images || [];
    }, [data.images]);

    const onClickNavigateDetail = ({
        is_message = false
    }) => {
        navigate(`${RoutePath.find_property_detail.path}?p_id=${data?.property?.p_id || ""}`, {
            state: {
                is_message
            }
        })
    }

    const onClickNavigateAnotherProfile = () => {
        navigate(`${RoutePath.another_profile.path}`.replace(":uid", data?.property?.created_by?.uid));
    }
    
    
    const verifyBadge = () => {
        if (data?.property?.status_verify) {
            return (
                <div className='gw-badge-verified'>
                    <span>Verified</span>
                </div>
            )
        } else {
            return (<></>);
        }
    }
    
    const onClickSelectItem = useCallback(
      () => {
        hideSelectPlace(dataitem)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [hideSelectPlace],
    )

    const TagsDescription = (value) => {
        const tagData = data?.property || {};
        switch (value) {
            case "bedroom":
                return (
                    <div className='text-icon'>
                        <ShoppingBasket />
                        <span>{tagData?.bed_room || 0}</span>
                    </div>
                )
            case "bathroom":
                return (
                    <div className='text-icon'>
                        <Bathroom />
                        <span>{tagData?.toilet_room || 0}</span>
                    </div>
                )
            case "area":
                return (
                    <div className='text-icon'>
                        <BuildingSize />
                        <span>{tagData?.use_size_sqm || 0}</span>
                    </div>
                )
            case "area_total":
                return (
                    <div className='text-icon'>
                        <span>{`${tagData?.rai} ไร่ ${tagData?.ngan} งาน ${tagData?.size_sqw} ตรว.`}</span>
                    </div>
                )
            case "car_park":
                return (
                    <div className='text-icon'>
                        <Garage />
                        <span>{tagData?.car_park || `0`}</span>
                    </div>
                )
        
            default:
                break;
        }
    }
    
    const showShare = () => {
        actionModal.updateModalShare({
            isModalShow: true,
            modalData: {...data?.property || {}}
        })
    }
    useEffect(() => {
        let unmountFavorite, unmountCoin, unmountComment, unmountUsersCoin;
        (async function initData() {
            if (dataitem.property?.p_id && !isNotShowComment) {
                if (userData?.uid) {
                    unmountFavorite = firebaseService.getFavorites(`${dataitem?.property?.p_id}`, `${userData?.uid}`).on('value',(snapshot) => {
                        if (!snapshot?.val) return;
                        
                        let items = snapshot.val() || {};
                        setDataAPI(prevData => {
                            return {
                                ...prevData,
                                isFavorite: items[userData?.uid] || false
                            }
                        });
                    });
                }
                unmountCoin = firebaseService.getCoins(`${dataitem?.property?.p_id}`, `${userData?.uid}`).on('value',(snapshot) => {
                    if (!snapshot?.val) return;
                    
                    let items = snapshot.val() || {};
                    setDataAPI(prevData => {
                        return {
                            ...prevData,
                            count_coin: Object.keys(items).length,
                            isCoin: items[userData?.uid] || false
                        }
                    });
                });
                
                unmountComment = firebaseService.getCommentCounts(`${dataitem?.property?.p_id}`, `${userData?.uid}`).on('value',(snapshot) => {
                    if (!snapshot?.val) return;
                    
                    let items = snapshot.val() || {};
                    setDataAPI(prevData => {
                        return {
                            ...prevData,
                            count_comment: Object.keys(items).length,
                        }
                    });
                });
            }
        })();

        return () => {
            if (unmountFavorite) unmountFavorite();
            if (unmountCoin) unmountCoin();
            if (unmountComment) unmountComment();
            if (unmountUsersCoin) unmountUsersCoin();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, dataitem?.property?.p_id]);

    const onClickFavorite = async () => {
        if (!userData?.uid) {
            actionModal.updateModalLogin(true);
            return;
        }
        const p_id = data?.property?.p_id;
        if (!userData?.uid || !p_id) return;
        try {
            await updateFavoriteByPId(p_id, {
                is_favorite: !dataAPI.isFavorite
            });
            // setDataAPI(prevData => {
            //     return {
            //         ...prevData,
            //         isFavorite: !dataAPI.isFavorite
            //     }
            // });
        } catch (error) {
            console.log("error updateFavoriteByPId : ", error)
        }
    }

    const onClickCoin = async () => {
        if (!userData?.uid) {
            actionModal.updateModalLogin(true);
            return;
        }
        const p_id = data?.property?.p_id;
        if (!userData?.uid || !p_id) return;
        try {
            await updateCoinByPId(p_id, {
                is_coin: !dataAPI.isCoin
            });
            // setDataAPI(prevData => {
            //     return {
            //         ...prevData,
            //         isCoin: !dataAPI.isFavorite
            //     }
            // });
        } catch (error) {
            console.log("error updateFavoriteByPId : ", error)
        }
    }
    
    const property_type = data?.property?.property_type || ""; // sale, rent
    const ShowTag = () => {
        let property_type_name = data?.property?.property_type_name || "";
        if (!property_type_name) {
            property_type_name = property_type === "sale" ? "ขาย" : "เช่า";
        }
        return (
            <div className={styles["gw-card-tag"]}>
            <div className={`${styles["gw-card-tag-item"]} ${styles["gw-type"]}`} type={property_type}><span>{property_type_name}</span></div>
            <div className={`${styles["gw-card-tag-item"]} ${styles["gw-category"]}`} category={data?.property?.cate_id}><span>{data?.property?.cate_name || data?.property?.cate_id || ""}</span></div>
            </div>
        )
    }

    return (
        <div className='gw-carousel-item' p-id={`${data?.property?.p_id}`}>
            {
                isSelector && (
                    <div className='gw-item-mask ant-image-mask' onClick={onClickSelectItem}>
                        <span>Select</span>
                    </div>
                )
            }
            <div className='gw-carousel-item-header'>
                <Image
                    preview={false}
                    referrerPolicy='no-referrer'
                    className='gw-card-image-profile'
                    src={data?.property?.created_by?.avatar || ""}
                    fallback={imgfallback}/>
                <div className='gw-card-user-info'>
                    <div className='gw-card-username gw-btn-link' onClick={onClickNavigateAnotherProfile}>
                        <span>{data?.property?.created_by?.display_name || ""}</span>
                        {verifyBadge()}
                    </div>
                    <div className='gw-card-location'>
                        <Breadcrumb
                            separator={<svg className='gw-dot' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="50" />
                            </svg>}>
                            {/* {data?.property?.district && <Breadcrumb.Item>{data?.property?.district || ""}</Breadcrumb.Item>} */}
                            {data?.property?.amphures && <Breadcrumb.Item>{data?.property?.amphures || ""}</Breadcrumb.Item>}
                            {data?.property?.province && <Breadcrumb.Item>{data?.property?.province || ""}</Breadcrumb.Item>}
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <div className='gw-card-text-content'>
                <>
                    <span className="gw-cursor-pointer" onClick={onClickNavigateDetail}>{
                        (`${(data?.detail || "").substring(0, 90)}${toggleDetail ? (data?.detail || "").substring(90) : ""}`)
                    }</span>
                    {
                        !toggleDetail
                        && (data?.detail || "").substring(90).length > 0
                        && (
                            <Button type='link' onClick={_ => SetToggleDetail(true)}>
                                <span>อ่านต่อ...</span>
                            </Button>
                        )
                    }
                </>
            </div>
            <div className='gw-card-carousel-images'>
                <Carousel>
                    {
                        [...(getImages)].map((item, index) => {
                            return (
                                <div key={index} className='gw-carousel-item-image' onClick={onClickNavigateDetail}>
                                    <Image
                                        preview={false}
                                        referrerPolicy='no-referrer'
                                        src={item.src}
                                        fallback={imgfallback} />
                                </div>
                            )
                        })
                    }
                    
                </Carousel>
                <ShowTag />
                <Button className={`gw-card-carousel-images-button${dataAPI.isFavorite ? ' active': ''}`} onClick={onClickFavorite}>
                    <StarDuotone />
                </Button>
            </div>
            <div className='gw-card-price'>
                <div className='gw-card-detail-left'>
                    <div className='price'>
                        <span>฿ {showPrice(data?.property?.cost)}{property_type === "sale" ? `` : `/เดือน`}</span>
                    </div>
                    <div className='area'>
                        <span>{showPrice(data?.property?.cost_area, data?.property)}</span>
                    </div>
                </div>
                <div className='gw-card-detail-right'>
                    {
                        // ที่ดิน
                        data?.property?.cate_id === 1 && (
                            <>
                                <div className='layer-2'>
                                    {TagsDescription("area_total")}
                                </div>
                            </>
                        )
                    }
                    {
                        // บ้าน อาคารพาณิชย์, ธุรกิจ
                        ([2, 4, 5].includes(data?.property?.cate_id)) && (
                            <>
                                <div className='layer-1'>
                                    {TagsDescription("bedroom")}
                                    {TagsDescription("bathroom")}
                                    {TagsDescription("area")}
                                </div>
                                <div className='layer-2'>
                                    {TagsDescription("area_total")}
                                </div>
                            </>
                        )
                    }
                    {
                        // คอนโด
                        ([3, 6, 7].includes(data?.property?.cate_id)) && (
                            <>
                                <div className='layer-1'>
                                    {TagsDescription("area")}
                                    {TagsDescription("bedroom")}
                                    {TagsDescription("bathroom")}
                                </div>
                                <div className='layer-2'>
                                    {TagsDescription("car_park")}
                                    {TagsDescription("construction_year")}
                                    {TagsDescription("floor")}
                                    {TagsDescription("total_floor")}
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
            <div className='gw-card-footer'>
                <div className='gw-card-footer-left'>
                    <div className={`gw-btn-link btn-coin${dataAPI.isCoin ? ' active': ''}`} onClick={onClickCoin}>
                        {
                            dataAPI.isCoin 
                            ? <Image preview={false} src={icdollartrue} alt="false"/>
                            : <Image preview={false} src={icdollarfalse} alt="true"/>
                        }
                    </div>
                    <div className='gw-btn-link btn-chat' onClick={_ => onClickNavigateDetail({
                        is_message: true
                    })}>
                        <Chat />
                    </div>
                    <div
                        className='gw-btn-link btn-share'
                        onClick={showShare}
                    >
                        <Share />
                    </div>
                </div>
                {
                    !isNotShowComment && (<>
                        <div className='gw-card-footer-right'>
                            <span>{dataAPI.count_coin || 0} Coins</span>
                            <span className="gw-cursor-pointer" onClick={onClickNavigateDetail}>{dataAPI?.count_comment || 0} Comments</span>
                        </div>
                    </>)
                }
            </div>
        </div>
    )
}

export default CardItemSecondary;