
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styles from './style.module.scss';
import stylesCardItem from '../../components/CardItemPrimary/style.module.scss';
import { Breadcrumb, Button, Col, Divider, Drawer, Image, Row } from "antd";
import { RoutePath } from '../../Routes';
import { imgfallback, showPrice } from '../../utils/common';
import {ReactComponent as IcoMyProperty} from '../../assets/icons/pages/Propmarket/1_ico_my_property.svg';
import {ReactComponent as IcoSignal} from '../../assets/icons/pages/Propmarket/2_ico_signal.svg';
import {ReactComponent as IcoCrown} from '../../assets/icons/pages/Propmarket/2_ico_crown.svg';
import { ReactComponent as CloseRoundDuotone } from '../../assets/icons/pages/findProertyMap/close_round_duotone.svg';
import MapContainer from '../../components/GMap';
import CommentComponent from '../../components/Comment';
import CommentBroadcastComponent from '../../components/CommentBroadcast';
import CardItemSecondary from '../../components/CardItemSecondary';
import stylesDrawer from '../../components/MapFilter/style.module.scss';
import * as services from "../../services/service.broadcast";
import { $getRoot, createEditor } from 'lexical';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as IconBack } from '../../assets/icons/pages/findProertyDetail/arrow_left_back.svg';

const tabMenu = {
    my_property: {
        id: "my-property",
        label: "My Property"
    },
    broadcast: {
        id: "broadcast",
        label: "Broadcast"
    },
    premium_user: {
        id: "premium-user",
        label: "Premium User"
    },
}

const layoutColumn = {
    LAYOUT_1: {
        id: "LAYOUT_1",
        gutter: [24, 0],
        col_1: 6,
        col_2: 12,
        col_3: 6,
    },
    LAYOUT_2: {
        id: "LAYOUT_2",
        gutter: [24, 0],
        col_1: 6,
        col_2: 12,
        col_3: 0,
    }
}

const PropmarketDetails = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const bc_id = searchParams.get('bc_id') || "";
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(tabMenu.broadcast.id);
    const [colLayout, setColLayout] = useState(layoutColumn.LAYOUT_2);
    const [showSelectCard, SetShowSelectCard] = useState(false);
    const [data, setData] = useState({});
    const inputCommentRef = useRef();
    const location = useLocation();

    const getData = useMemo(() => {
        return data;
    }, [data]);

    useEffect(() => {
        onActiveTab(id || tabMenu.broadcast.id);
        const loadDataBroadcast = async () => {
            try {
                const response = await services.fetchBroadcastByBcId(bc_id);
                const responseData = response?.data?.data || {};
                if (response?.data?.data) {
                    setData(responseData);
                } else {
                    throw Error("no data")
                }
            } catch (error) {
                setData({});
            }
        }
        if (bc_id) {
            loadDataBroadcast()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onActiveTab = (tab) => {
        switch (tab) {
            case tabMenu.my_property.id:
            case tabMenu.broadcast.id:
            case tabMenu.premium_user.id:
                setActiveTab(tab);
                break;
            default:
                break;
        }
    };

    const onclickActiveTab = (tab) => {
        navigate(`${RoutePath.propmarket.path}/${tab}`)
    };

    const verifyBadge = () => {
        return (
            <div className='gw-badge-verified'>
                <span>Verified</span>
            </div>
        )
    }

    const prepareMapData = () => {
        let result = {};
        if (getData.lat && getData.lng) {
            result["defaultMarker"] = {
                lat: getData.lat,
                lng: getData.lng
            }
        }
        if (getData.radius_m) {
            result["defaultValue"] = {
                rangeArea: getData.radius_m
            }
        }
        return result;
    }
    
    const closeGrid = () => {
        SetShowSelectCard(false)
    }
    
    const showGrid = () => {
        SetShowSelectCard(true)
    }

    useEffect(() => {
        if (inputCommentRef.current && location?.state?.is_message) {
            inputCommentRef.current.input.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            inputCommentRef.current.focus();
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        return () => {

        }
    }, [inputCommentRef, location?.state?.is_message]);

    const ShowDescription = () => {
        try {
            return getData?.desc || "";
            const parseData = JSON.parse(getData?.desc);
            if (parseData.root) {
                const editor = createEditor();
                const parsedEditorState = editor.parseEditorState(getData?.desc);
                const editorStateTextString = parsedEditorState.read(() => $getRoot().getTextContent())

                return editorStateTextString;
            }// Parse the editor state JSON
            throw Error("no data");
        } catch (error) {
            return "";
        }
    }

    const onClikeNavigateBack = () => {
        if (window?.history?.state?.key) {
            navigate(-1);
        } else {
            navigate("/propmarket/broadcast", {
                replace: true
            });
        }
    }

    return (
        <div className={`gw-container gw-mt-48`}>
            <Row gutter={[24, 0]}>
                <Col span={isMobile ? 0 : colLayout.col_1} className={`${styles["gw-side-menu"]}`}>
                    <div className={`${styles["gw-header"]}`}>
                        <span>Propmarket</span>
                    </div>
                    <div className={`${styles["gw-list-menu"]}`}>
                        <Button 
                            disabled={activeTab === tabMenu.my_property.id} 
                            className={`${styles["gw-btn-ico-link"]}`}
                            onClick={() => onclickActiveTab(tabMenu.my_property.id)}
                            >
                            <div className={`${styles["gw-btn-icon"]}`}>
                                <IcoMyProperty />
                            </div>
                            <span>{tabMenu.my_property.label}</span>
                        </Button>
                        <Button 
                            disabled={activeTab === tabMenu.broadcast.id} 
                            className={`${styles["gw-btn-ico-new-product"]}`}
                            onClick={() => onclickActiveTab(tabMenu.broadcast.id)}
                            >
                            <div className={`${styles["gw-btn-icon"]}`}>
                                <IcoSignal />
                            </div>
                            <span>{tabMenu.broadcast.label}</span>
                        </Button>
                        <Button
                            disabled={activeTab === tabMenu.premium_user.id}
                            className={`${styles["gw-btn-ico-favorite"]}`}
                            onClick={() => onclickActiveTab(tabMenu.premium_user.id)}
                        >
                            <div className={`${styles["gw-btn-icon"]}`}>
                                <IcoCrown />
                            </div>
                            <span>{tabMenu.premium_user.label}</span>
                        </Button>
                    </div>
                </Col>
                <Col span={isMobile ? 24 : colLayout.col_2}>

                    {isMobile && (
                        <Row gutter={[24, 24]} style={{ marginTop: 10, marginBottom: 10 }}>
                            <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                                <div>
                                    <div className={`gw-btn-link gw-btn-danger`} onClick={onClikeNavigateBack}>
                                        <IconBack />
                                        <span>Back</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>)}
                    <div className={`${styles["gw-feed-content"]}`}>
                        <div className={`${styles["gw-feed-list"]}`}>
                            <Row gutter={[0, 24]}>
                                <Col span={24}>
                                    <div className={`${stylesCardItem["gw-card-item"]} ${styles["gw-detail-broadcast"]}`}>
                                        <div className='gw-card-header'>
                                            <div className='gw-card-header-left'>
                                                <Image
                                                    preview={false}
                                                    referrerPolicy='no-referrer'
                                                    className='gw-card-image-profile'
                                                    src={getData.create_by?.avatar || ""}
                                                    fallback={imgfallback} />
                                                <div className='gw-card-user-info'>
                                                    <div className='gw-card-username gw-btn-link'>
                                                        <span>{getData.create_by?.display_name || ""}</span>
                                                        {verifyBadge()}
                                                    </div>
                                                    <div className='gw-card-location'>
                                                        <Breadcrumb
                                                            separator={<svg className='gw-dot' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="50" cy="50" r="50" />
                                                            </svg>}>
                                                            {/* {getData?.district?.dt_name && <Breadcrumb.Item>{getData?.district?.dt_name || ""}</Breadcrumb.Item>} */}
                                                            {getData?.amphures?.am_name && <Breadcrumb.Item>{getData?.amphures?.am_name || ""}</Breadcrumb.Item>}
                                                            {getData?.province?.pv_name && <Breadcrumb.Item>{getData?.province?.pv_name || ""}</Breadcrumb.Item>}
                                                        </Breadcrumb>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider />
                                        <div className='gw-card-text-content'>
                                            <span className='gw-text-title'>ประกาศ ซื้อบ้าน</span>
                                            <span className='gw-text-details'><ShowDescription /></span>
                                        </div>
                                        <div className='gw-map'>
                                            <MapContainer {...prepareMapData()} isBroadCast={true} />
                                        </div>
                                        <div className='gw-card-details'>
                                            <div className='gw-card-detail-left'>
                                                <div className='gw-price-title'>
                                                    <span>ช่วงราคาที่ต้องการ:</span>
                                                </div>
                                                <div className='price'>
                                                    <span>
                                                        <span className={styles["gw-value"]}>{showPrice(getData?.cost_min)}-{showPrice(getData?.cost_max)}</span>
                                                        <span className={stylesCardItem["gw-suffix-unit"]}>บาท</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider />
                                        <CommentBroadcastComponent dataItem={data} inputCommentRef={inputCommentRef}/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default PropmarketDetails;