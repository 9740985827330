import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import base_url from './config';

const endpoint = {
    get_travel_time: `${base_url}/travel-time/travel-time-property/:pid`,
    travel_time: `${base_url}/travel-time`,
    update_travel_time: `${base_url}/travel-time/:travel_time_id`,
}

const fetchTravelTime = (cancelTokenSource, p_id = "") => {
    const url = `${endpoint.get_travel_time}`.replace(`:pid`, p_id);
    return trackPromise(axios.get(url, { cancelToken: cancelTokenSource?.token }));
}

const createTravelTime = (data = {}) => {
    return trackPromise(axios.post(endpoint.travel_time, data));
}

const updateTravelTime = (data = {}) => {
    const url = `${endpoint.update_travel_time}`.replace(`:travel_time_id`, data.travel_time_id);
    return trackPromise(axios.put(url, data));
}

const deleteTravelTime = (travel_time_id = "") => {
    const url = `${endpoint.travel_time}`.replace(`:travel_time_id`, travel_time_id);
    return trackPromise(axios.delete(url));
}

export {
    fetchTravelTime,
    createTravelTime,
    updateTravelTime,
    deleteTravelTime
}