import firebase from 'firebase/compat/app';
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signOut,
    FacebookAuthProvider,
    OAuthProvider
} from "firebase/auth";
import { firebaseConfig } from "./config";
import 'firebase/compat/firestore';
import 'firebase/compat/database';

const app = firebase.initializeApp(firebaseConfig);
const auth = getAuth(app);

export const logout = () => {
    signOut(auth);
};

const googleProvider = new GoogleAuthProvider();
export const signInWithGoogle = async () => signInWithPopup(auth, googleProvider);

const facebookProvider = new FacebookAuthProvider();
export const signInwithFacebook = async () => signInWithPopup(auth, facebookProvider);

const appleProvider = new OAuthProvider('apple.com');
export const signInwithApple = async () => signInWithPopup(auth, appleProvider);

const firestore = firebase.firestore();

const database = firebase.database();

export {
    firestore,
    database
}