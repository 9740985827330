import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import base_url from './config';

const endpoint = {
    property: `${base_url}/property`,
    property_maps: `${base_url}/property/property-maps`,
    property_my_property: `${base_url}/property/my-property`,
    property_upload_image: `${base_url}/property/upload-image`,
    property_property_image: `${base_url}/property/property-image`,
    property_property_similar: `${base_url}/property/property-similar`,
    property_property_similar_new: `${base_url}/property/property-nearly`,
    property_favorite: `${base_url}/property/favorite`,
    property_coin: `${base_url}/property/coin`,
    property_favorit_list: `${base_url}/property/favorit`,
    property_recently_list: `${base_url}/property/recently`,
    property_comment: `${base_url}/property-comment`,
    property_comment_reply: `${base_url}/property-comment/reply`,
    property_property_view: `${base_url}/property/property-view`,
    send_message: `${base_url}/send-message`,
    property_property_profile: `${base_url}/property/property-profile`,
    autocomplete: `${base_url}/autocomplete`,
    property_profile_byid: `${base_url}/property/property-profile-byid`,
    another_property: `${base_url}/property/another-property`,
    property_sale: `${base_url}/property/property-sale`,
    broadcast_comment: `${base_url}/broadcast-comment`,
    broadcast_comment_reply: `${base_url}/broadcast-comment/reply`,
    property_property_maps_data: `${base_url}/property/property-maps-data`,
}

const fetchPerperty = (body = {}) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return trackPromise(axios.get(endpoint.property + `?${queryString}`));
}

const fetchPerpertyMaps = (body = {}, setIsLoading) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return trackPromise(axios.get(endpoint.property_maps + `?${queryString}`, {
        onDownloadProgress: function (progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total); // you can use this to show user percentage of file downloaded
            setIsLoading(percentCompleted);
        }
    }));
}

const fetchPropertyMyProperty = (body = {}, cancelTokenSource) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return trackPromise(axios.get(endpoint.property_my_property + `?${queryString}`, {
        cancelToken: cancelTokenSource?.token
    }));
}

const fetchPerpertySimilar = (body = {}, title_deen_no) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return trackPromise(axios.get(endpoint.property_property_similar + `?${queryString}`));
}

const fetchPerpertyNearby = (body = {}) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return trackPromise(axios.get(endpoint.property_property_similar_new + `?${queryString}`));
}

const fetchPerpertyDetails = (p_id) => {
    return trackPromise(axios.get(`${endpoint.property}/${p_id}`));
}

const fetchUploadImage = (data) => {
    const formData = new FormData();
    formData.append("image", data);
    return trackPromise(axios.post(endpoint.property_upload_image, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    }));
}

const fetchCreateProperty = (body = {}) => {
    return trackPromise(axios.post(endpoint.property, body));
}

const fetchUpdateProperty = (body = {}) => {
    return trackPromise(axios.put(`${endpoint.property}/${body.p_id}`, body));
}

const fetchImagesProperty = (p_id = "") => {
    return trackPromise(axios.get(`${endpoint.property_property_image}/${p_id}`));
}

const fetchFavoriteByPId = (p_id = "") => {
    return trackPromise(axios.get(`${endpoint.property_favorite}/${p_id}`));
}

const fetchCoinByPId = (p_id = "") => {
    return trackPromise(axios.get(`${endpoint.property_coin}/${p_id}`));
}

const updateFavoriteByPId = (p_id = "", body={}) => {
    return trackPromise(axios.post(`${endpoint.property_favorite}/${p_id}`, body));
}

const updateCoinByPId = (p_id = "", body={}) => {
    return trackPromise(axios.post(`${endpoint.property_coin}/${p_id}`, body));
}

const fetchPropertyFavorit = (body = {}) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return trackPromise(axios.get(endpoint.property_favorit_list + `?${queryString}`));
}

const fetchPropertyRecently = (body = {}) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return trackPromise(axios.get(endpoint.property_recently_list + `?${queryString}`));
}

const updateComment = (p_id = "", body={}) => {
    return trackPromise(axios.post(`${endpoint.property_comment}/${p_id}`, body));
}

const updateCommentReply = (p_comment_id = "", body={}) => {
    return trackPromise(axios.post(`${endpoint.property_comment_reply}/${p_comment_id}`, body));
}

const updateViewProperty = (p_id, body = {}) => {
    return trackPromise(axios.post(`${endpoint.property_property_view}/${p_id}`, body));
}

const updateChat = (body={}) => {
    return trackPromise(axios.post(`${endpoint.send_message}`, body));
}

const getPropertyProfile = (body={}) => {
    return trackPromise(axios.get(`${endpoint.property_property_profile}`, body));
}

const getAutocomplete = (value = "") => {
    return trackPromise(axios.get(`${endpoint.autocomplete}/${value}`));
}

const getPropertyAnotherProfile = (uid) => {
    return trackPromise(axios.get(`${endpoint.property_profile_byid}/${uid}`));
}

const fetchAnotherPerperty = (body = {}, uid) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return trackPromise(axios.get(`${endpoint.another_property}/${uid}` + `?${queryString}`));
}

const salesProperty = (p_id, body = {}) => {
    return trackPromise(axios.put(`${endpoint.property_sale}/${p_id}`, body));
}

const updateBCComment = (bc_id = "", body={}) => {
    return trackPromise(axios.post(`${endpoint.broadcast_comment}/${bc_id}`, body));
}

const updateBCCommentReply = (bc_comment_id = "", body={}) => {
    return trackPromise(axios.post(`${endpoint.broadcast_comment_reply}/${bc_comment_id}`, body));
}

const deleteProperty = (p_id) => {
    return trackPromise(axios.delete(`${endpoint.property}/${p_id}`));
}

const propertyPropertyMapsData = (body = {}, cancelTokenSource) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return trackPromise(axios.get(endpoint.property_property_maps_data + `?${queryString}`, {
        cancelToken: cancelTokenSource?.token
    }));
}


export {
    fetchPerperty,
    fetchPerpertyMaps,
    fetchPropertyMyProperty,
    fetchPerpertyDetails,
    fetchUploadImage,
    fetchCreateProperty,
    fetchUpdateProperty,
    fetchImagesProperty,
    fetchPerpertySimilar,
    fetchPerpertyNearby,
    fetchFavoriteByPId,
    fetchCoinByPId,
    updateFavoriteByPId,
    updateCoinByPId,
    fetchPropertyFavorit,
    fetchPropertyRecently,
    updateComment,
    updateCommentReply,
    updateViewProperty,
    updateChat,
    getPropertyProfile,
    getAutocomplete,
    getPropertyAnotherProfile,
    fetchAnotherPerperty,
    salesProperty,
    updateBCComment,
    updateBCCommentReply,
    deleteProperty,
    propertyPropertyMapsData,
}