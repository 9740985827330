import { configureStore } from '@reduxjs/toolkit';
import propertyReducer from './action.property';
import userReducer from './action.user';
import messageReducer from './action.message';

export default configureStore({
    reducer: {
        property: propertyReducer,
        user: userReducer,
        message: messageReducer
    },
})