
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './style.module.scss';
import pageListstyles from '../FindPropertyList/style.module.scss';
import { Button, Col, Drawer, Row } from "antd";
import { RoutePath } from '../../Routes';
import {ReactComponent as IcoMyProperty} from '../../assets/icons/pages/Propmarket/1_ico_my_property.svg';
import {ReactComponent as IcoSignal} from '../../assets/icons/pages/Propmarket/2_ico_signal.svg';
import {ReactComponent as IcoCrown} from '../../assets/icons/pages/Propmarket/2_ico_crown.svg';
import CardItemPrimary from '../../components/CardItemPrimary';
import addRingLight from '../../assets/icons/pages/Mygrow/Add_ring_light.svg';
import { fetchPerperty, fetchPropertyMyProperty } from '../../services/service.property';
import { useAuthContext } from '../../context/authentication';
import { filterEvent, validateNotLoadMore } from '../../utils/common';
import { useFilterContext } from '../../context/filter';
import { fetchBroadcast, fetchBroadcastMaping } from '../../services/service.broadcast';
import LoadMoreElement from '../../components/LoadMore';
import ListElement from '../../components/ListElement';
import InfiniteScroll from 'react-infinite-scroller';
import { usePromiseTracker } from 'react-promise-tracker';
import { useLoadingListContext } from '../../context/loaddingList';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as IconBack } from '../../assets/icons/pages/findProertyDetail/arrow_left_back.svg';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import BroadcastFilter, { decodeFilter } from '../../components/BroadcastFilter/BroadcastFilter';
import { useModalContext } from '../../context/modals';
import { ReactComponent as IcoFilter } from '../../assets/icons/pages/findProertyMap/filter_alt.svg';

const tabMenu = {
    my_property: {
        id: "my-property",
        label: "My Property"
    },
    broadcast: {
        id: "broadcast",
        label: "Broadcast"
    },
    premium_user: {
        id: "premium-user",
        label: "Premium User"
    },
}

const Propmarket = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const { id: defaultID } = useParams();
    const id = isMobile ? defaultID || "" : defaultID || tabMenu.my_property.id;
    const { promiseInProgress } = usePromiseTracker();
    const { data: auth } = useAuthContext();
    const navigate = useNavigate();
    const location = useLocation();
    const splitPath = location.pathname.split("/").filter(item => item);
    const searchPath = `/${splitPath.slice(0, 2).join("/")}`;
    const [activeTab, setActiveTab] = useState(id);
    const [isShowAddBtn, setIsShowAddBtn] = useState(true);
    const [dataList, setDataList] = useState([]);
    const [dataMeta, setDataMeta] = useState({});
    const [loadMorePage, setLoadMorePage] = useState(1);
    const {data: filterData, action: filterAction} = useFilterContext();
    const {action: loadingListAction, data: loadingListData} = useLoadingListContext();
    const { dataDrawer: { visibleDrawerBroadcastFilter }, action: { updateDrawerBroadcastFilter } } = useModalContext();

    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        const filterSearchClean = filterEvent.decodeFilter(location);
        filterAction.updateData(filterSearchClean);

        switch (id) {
            case tabMenu.my_property.id:
                loadDataProperty(cancelTokenSource, {...filterSearchClean}, false);
                break;
            case tabMenu.broadcast.id:
                loadDataBroadcast(cancelTokenSource, { ...prepareFilter(location), page: 1, limit: 10 }, false);
                break;
            case tabMenu.premium_user.id:
                loadDataPremiumUser(cancelTokenSource, {...filterSearchClean}, false);
                break;
            default:
                break;
        }
        return () => {
            setDataMeta({});
            setDataList([]);
            console.log("unmounting...");
            cancelTokenSource.cancel("Operation canceled by the user.");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, location]);
    

    const prepareFilter = (location = {}) => {
        const filters = decodeFilter(location, { range_field: ["price"] });
        return filters;
    }
    
    const loadDataProperty = async (cancelTokenSource, filterBody = {}, loadMore = false) => {
        try {
            loadingListAction.updateData(true);
            const responseDataProperty = await fetchPropertyMyProperty(filterBody, cancelTokenSource);
            const responseItems = responseDataProperty?.data?.data?.items || [];
            const responseMeta = responseDataProperty?.data?.data?.meta || {};
            if (responseDataProperty?.data?.data) {
                setDataMeta(function (prevData) {
                    return {...responseMeta}
                });
                setDataList(function (prevData) {
                    return [...(loadMore ? prevData : []), ...responseItems].map(function (item) {
                        return {...item, tab_id: id}
                    })
                });
            } else {
                throw Error("no data")
            }
        } catch (error) {
            setDataMeta({});
            setDataList([]);
        } finally {
            loadingListAction.updateData(false);
        }
    }
    
    const loadDataBroadcast = async (cancelTokenSource, filterBody = {}, loadMore = false) => {
        try {
            loadingListAction.updateData(true);
            const responseDataProperty = await fetchBroadcast(filterBody, cancelTokenSource);
            const responseItems = responseDataProperty?.data?.data?.items || [];
            const responseMeta = responseDataProperty?.data?.data?.meta || {};
            if (responseDataProperty?.data?.data) {
                setDataMeta(function (prevData) {
                    return {...responseMeta}
                });
                setDataList(function (prevData) {
                    return [...(loadMore ? prevData : []), ...responseItems].map(function (item) {
                        return {...item, tab_id: id}
                    })
                });
            } else {
                throw Error("no data")
            }
        } catch (error) {
            setDataMeta({});
            setDataList([]);
        } finally {
            loadingListAction.updateData(false);
        }
    }
    
    const loadDataPremiumUser = async (cancelTokenSource) => {
        try {
            loadingListAction.updateData(true);
            const responseDataProperty = await fetchBroadcastMaping(cancelTokenSource);
            const responseItems = responseDataProperty?.data?.data?.items || [];
            const responseMeta = responseDataProperty?.data?.data?.meta || {};
            if (responseDataProperty?.data?.data) {
                setDataMeta(function (prevData) {
                    return {...responseMeta}
                });
                setDataList(function (prevData) {
                    return [...responseItems].map(function (item) {
                        return {...item, tab_id: id}
                    })
                });
            } else {
                throw Error("no data")
            }
        } catch (error) {
            setDataMeta({});
            setDataList([]);
        } finally {
            loadingListAction.updateData(false);
        }
    }

    useEffect(function () {
        const cancelTokenSource = axios.CancelToken.source();
        if (loadMorePage !== 1 && Object.keys(filterData.filterSearchObj || {}).length > 0) {
            switch (id) {
                case tabMenu.my_property.id:
                    loadDataProperty(cancelTokenSource, { ...filterData.filterSearchObj, page: loadMorePage }, true);
                    break;
                case tabMenu.broadcast.id:
                    loadDataBroadcast(cancelTokenSource, {...prepareFilter(location), page: loadMorePage, limit: 10}, true);
                    // loadDataBroadcast({ ...filterData.filterSearchObj, page: loadMorePage }, true);
                    break;
                case tabMenu.premium_user.id:
                    // loadDataPremiumUser({ ...filterData.filterSearchObj, page: loadMorePage }, true);
                    break;
                default:
                    break;
            }
        }
        return () => {
            cancelTokenSource.cancel("Operation canceled by the user.");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMorePage]);

    const loadMoreData = () => {
        if (!promiseInProgress && validateNotLoadMore(dataMeta, loadMorePage)) {
            setLoadMorePage(prevData => {
                return prevData + 1;
            });
        }
    }

    const onActiveTab = (tab) => {
        switch (tab) {
            case tabMenu.my_property.id:
            case tabMenu.broadcast.id:
            case tabMenu.premium_user.id:
                setActiveTab(tab);
                break;
            default:
                navigate(`${RoutePath.news_feed_root.path}`);
                break;
        }
    };

    const onclickActiveTabMobile = (tab) => {
        onClose();
        setActiveTab(tab);
        setShowContent(true);
        localStorage.setItem("showContent_propmarket", true);
        navigate(`${RoutePath.propmarket.path}/${tab}`)
    };

    const onclickActiveTab = (tab) => {
        onClose();
        setActiveTab(tab);
        navigate(`${RoutePath.propmarket.path}/${tab}`)
    };

    const [labelAddBtn, setLabelAddBtn] = useState("Create");
    useEffect(() => {
        switch (location.pathname) {
            case RoutePath.my_grow_profile.path:
            case "/propmarket/my-property":
            case "/propmarket/premium-user":
            case "/propmarket/broadcast":
                setLabelAddBtn("Create");
                break;
                // setLabelAddBtn("ปักหมุด");
                // break;
            default:
                setIsShowAddBtn(false);
                break;
        }

        return () => {

        }
    }, [location.pathname, searchPath]);

    const onNavigateAddBtn = useCallback(
        () => {
            switch (searchPath) {
                case "/propmarket":
                case "/propmarket/my-property":
                case "/propmarket/premium-user":
                    navigate(RoutePath.propmarket_my_property_create.path);
                    break;
                case "/propmarket/broadcast":
                    navigate(RoutePath.my_grow_broadcast_create.path);
                    break;

                default:
                    break;
            }
        },
        [navigate, searchPath],
    );

    const [showContent, setShowContent] = useState(localStorage.getItem("showContent_propmarket") || false);
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const RenderNavigateMobile = () => {
        return (
            <div className={`${styles["gw-list-menu"]}`}>
                <Button
                    className={`${styles["gw-btn-ico-link"]}`}
                    onClick={() => onclickActiveTabMobile(tabMenu.my_property.id)}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoMyProperty />
                    </div>
                    <span>{tabMenu.my_property.label}</span>
                </Button>
                <Button
                    className={`${styles["gw-btn-ico-new-product"]}`}
                    onClick={() => onclickActiveTabMobile(tabMenu.broadcast.id)}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoSignal />
                    </div>
                    <span>{tabMenu.broadcast.label}</span>
                </Button>
                <Button
                    className={`${styles["gw-btn-ico-favorite"]}`}
                    onClick={() => onclickActiveTabMobile(tabMenu.premium_user.id)}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoCrown />
                    </div>
                    <span>{tabMenu.premium_user.label}</span>
                </Button>
            </div>
        )
    };

    const RenderNavigateWeb = () => {
        return (
            <div className={`${styles["gw-list-menu"]}`}>
                <Button
                    disabled={activeTab === tabMenu.my_property.id}
                    className={`${styles["gw-btn-ico-link"]}`}
                    onClick={() => onclickActiveTab(tabMenu.my_property.id)}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoMyProperty />
                    </div>
                    <span>{tabMenu.my_property.label}</span>
                </Button>
                <Button
                    disabled={activeTab === tabMenu.broadcast.id}
                    className={`${styles["gw-btn-ico-new-product"]}`}
                    onClick={() => onclickActiveTab(tabMenu.broadcast.id)}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoSignal />
                    </div>
                    <span>{tabMenu.broadcast.label}</span>
                </Button>
                <Button
                    disabled={activeTab === tabMenu.premium_user.id}
                    className={`${styles["gw-btn-ico-favorite"]}`}
                    onClick={() => onclickActiveTab(tabMenu.premium_user.id)}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoCrown />
                    </div>
                    <span>{tabMenu.premium_user.label}</span>
                </Button>
            </div>
        )
    };

    const onClikeNavigateBack = () => {
        setShowContent(false);
        localStorage.removeItem("showContent_propmarket");
    };

    return (
        <div className={`${styles["navigate-container"]}`}>
            {
                auth?.isAuthentication && (
                    <div className={`${styles["btn-navigate-action"]}`}>
                        <Button shape="circle" onClick={onNavigateAddBtn}>
                            <img src={addRingLight} alt="" />
                            <span>{labelAddBtn}</span>
                        </Button>
                    </div>
                )
            }
            <div className={`gw-container gw-mt-48`}>
                <Row gutter={[24, 0]}>
                    <Col span={isMobile ? 24 : 6} className={`${styles["gw-side-menu"]}`}>
                        {
                            !isMobile || (isMobile && !showContent) ? (
                                <div className={`${styles["gw-header"]}`}>
                                    <span>Propmarket</span>
                                </div>
                            ) : null
                        }
                        {isMobile && showContent && (
                            <Row gutter={[24, 24]} style={{ marginTop: 10, marginBottom: 10 }}>
                                <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                                    <div>
                                        <div className={`gw-btn-link gw-btn-danger`} onClick={onClikeNavigateBack}>
                                            <IconBack />
                                            <span>Back</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>)}
                        {!isMobile && <RenderNavigateWeb />}
                        {isMobile && !showContent && <RenderNavigateMobile />}
                    </Col>
                    <Col span={isMobile ? showContent ? 24 : 0 : 12}>
                        <div className={`${pageListstyles["gw-header"]} gw-mt-24`} style={{ display: "flex", justifyContent: "end", marginBottom: 16 }}>
                            <div className={`${pageListstyles["gw-header-action"]}`}>
                                <Button type="link" className={`${pageListstyles["btn-link"]}`} onClick={_ => updateDrawerBroadcastFilter(true)}>
                                    <IcoFilter />
                                    <span>Filter</span>
                                </Button>
                            </div>
                        </div>
                        <div className={`${styles["gw-feed-content"]}`}>
                            <div className={`${styles["gw-feed-list"]}`}>
                                <InfiniteScroll
                                    threshold={750}
                                    initialLoad={false}
                                    loadMore={loadMoreData}
                                    hasMore={validateNotLoadMore(dataMeta, loadMorePage)}
                                >
                                    {
                                        activeTab === tabMenu.my_property.id && (
                                            <Row gutter={[0, 24]}>
                                                <ListElement list={dataList} type={"BOOTITEM_24"}/>
                                                <LoadMoreElement {...{ dataMeta, loadMorePage, loadMoreData }} />
                                            </Row>
                                        )
                                    }
                                    {
                                        activeTab === tabMenu.broadcast.id && (
                                            <>
                                                <Row gutter={[0, 24]}>
                                                    <ListElement list={dataList} type={"BOOTITEM_PROPMARKET_24"} />
                                                    <LoadMoreElement {...{ dataMeta, loadMorePage, loadMoreData }} />
                                                </Row>
                                                <BroadcastFilter />
                                            </>
                                        )
                                    }
                                    {
                                        activeTab === tabMenu.premium_user.id && (
                                            <Row gutter={[0, 24]}>
                                                <ListElement list={dataList} type={"BOOTITEM_PROPMARKET_24"}/>
                                                <LoadMoreElement {...{ dataMeta, loadMorePage, loadMoreData }} />
                                            </Row>
                                        )
                                    }
                                </InfiniteScroll>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Drawer title="Propmarket" push={true} placement="right" onClose={onClose} visible={open}>
                <div className={styles["gw-side-menu"]}>
                    <RenderNavigateWeb />
                </div>
            </Drawer>
        </div>
    )
}

export default Propmarket;