
/* global google */
import styles from './style.module.scss';
import { Button, Col, Form, Input, InputNumber, Modal, notification, Row, Select } from "antd";
import { ReactComponent as IcExpandMore } from '../../assets/icons/pages/Propmarket/my-property-create/ic_expand_more.svg';
import { ReactComponent as SearchAltDuotone } from '../../assets/icons/header/search_alt_duotone.svg';
import { DrawingManager, GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api';
import { defaultCenter, mapModalSizeStyles, mapPinSizeStyles, mapPolygonStyles, mapStyles } from '../FindPropertyMap/settings';
import { useCallback, useEffect, useRef, useState } from 'react';
import iconMarkLogo from '../../assets/icons/maps/mark.png';
import { fetchMasterAmphures, fetchMasterPercel } from '../../services/service.common';
import { ExclamationCircleFilled, SearchOutlined, CloseOutlined } from '@ant-design/icons';

const { Search } = Input;
const { confirm } = Modal;

const MyPropertyCreateStep2 = ({
    showModalMap,
    showModalMapPolygon,
    userPinMarker,
    setUserPinMarker,
    masterData,
    formData = {},
    form,
    isMobile = false
}) => {
    const [percelData, setPercelData] = useState(null);
    const refInput = useRef(null);
    const [inputValue, setInputValue] = useState(form.getFieldValue("location_title_deed_number") || "");
	const [map, setMap] = useState(null)
    const [masterDistricts, setMasterDistricts] = useState([]);
	const onLoad = useCallback(function callback(map) {
		setMap(map)
	}, []);
	const onUnmount = useCallback(function callback(map) {
		setMap(null)
	}, []);
    useEffect(() => {
        if (form) {
            const province_id = form.getFieldValue("location_province");
            async function loadData() {
                const response = await fetchMasterAmphures(province_id);
                setMasterDistricts(response?.data?.data || []);
            }
            if (province_id) {
                loadData();
            }
        }
        return () => { }
    }, [form]);
    const onChangeProvince = async (value) => {
        const response = await fetchMasterAmphures(value);
        setMasterDistricts(response?.data?.data || []);
        form.setFieldsValue({
            location_district: null
        });
    }

    useEffect(() => {
		if (map && google) {
            map.setOptions({draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true});
			if (google.maps.geometry) {
                const polygons = userPinMarker?.polygons || [];
                if (polygons.length > 0) {
                    let bounds = new google.maps.LatLngBounds();
                    for (let i = 0; i < polygons.length; i++) {
                        bounds.extend(polygons[i]);
                    }
                    map.fitBounds(bounds);
                }
			}
		}
    }, [map, userPinMarker?.polygons])

    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (userPinMarker?.location?.lat) {
            setPercelData(prevData => ({
                userPinMarker,
                ...prevData,
                ...userPinMarker?.percel || {},
                init: true
            }))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!percelData?.init) {
            if (percelData) {
                const {
                    parcellat,
                    parcellon,
                } = percelData;
                setUserPinMarker(prevData => {
                    return ({
                        ...percelData.userPinMarker || {},
                        ...prevData,
                        location: {
                            lat: Number(parcellat),
                            lng: Number(parcellon)
                        },
                        zoom: 13,
                        percel: percelData
                    })
                });
            } else {
                setUserPinMarker(null);
            }
        } else {
            setPercelData(prevData => ({...prevData, init: false}))
        }
        return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [percelData, setUserPinMarker]);

    const onSubmitSearch = async () => {
        try {
            await form.validateFields(["location_title_deed_number", "location_province", "location_district"]);
            confirm({
                title: 'คุณต้องการค้นหาและกำหนดที่ตั้งใหม่?',
                icon: <ExclamationCircleFilled />,
                async onOk() {
                    const {location_province, location_district} = form?.getFieldsValue() || {};
                    const province = [...masterData.master_province || []].find(fitem => `${fitem.id}` === `${location_province}`);
                    const district = [...masterDistricts || []].find(fitem => `${fitem.id}` === `${location_district}`);

                    const percel_no = refInput?.current?.value;
                    try {
                        const response = await fetchMasterPercel({
                            prov_id: province?.pv_landmap_id || "",
                            am_id: district?.am_landmap_id || "",
                            percel_no: percel_no,
                        });
                        const address = response.data.data.result[0];
                        if (address) {
                            setPercelData(address);
                        } else {
                            throw Error("address not found!")
                        }
                    } catch (error) {
                        console.log(error)
                        api["error"]({
                            message: 'ไม่พบที่อยู่',
                        });
                    }
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } catch (error) {
            console.log(error)
        }
    }

    const onClearSearch = () => {
        setPercelData(null);
    }


    return (
        <Row gutter={[24, 35]}>
            {contextHolder}
            <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                <div className={styles["gw-block-container"]}>
                    <label className={styles["gw-text-title"]}>
                        <span>Location</span>
                    </label>
                    <Row gutter={[16, 16]} className={`gw-mt-24`}>
                        <Col span={12}>
                            <Form.Item
                                className={`${styles["gw-form-selector"]}`}
                                name="location_province"
                                rules={[{ required: true, message: 'Please select!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="จังหวัด"
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    suffixIcon={<IcExpandMore />}
                                    onChange={onChangeProvince}
                                    disabled={percelData}
                                >
                                    {
                                        masterData.master_province.map(function (item) {
                                            return <Select.Option 
                                                key={item?.id} 
                                                value={item?.id}>{item?.pv_name || ""}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className={`${styles["gw-form-selector"]}`}
                                name="location_district"
                                rules={[{ required: true, message: 'Please select!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="อำเภอ"
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    suffixIcon={<IcExpandMore />}
                                    disabled={percelData}
                                >
                                    {
                                        masterDistricts.map(function (item) {
                                            return <Select.Option 
                                                key={item?.id} 
                                                value={item?.id}>{item?.am_name || ""}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className={`${styles["gw-form-input"]}`}
                                name="location_title_deed_number"
                                // rules={[
                                //     ({ getFieldValue }) => ({
                                //         validator(_, value) {
                                //         if (refInput.current.value) {
                                //             return Promise.resolve();
                                //         }
                                //         return Promise.reject(new Error('Please input!'));
                                //         },
                                //     }),
                                // ]}
                            >
                                <Input.Group compact>
                                    <InputNumber
                                        ref={refInput}
                                        controls={false}
                                        placeholder='เลขที่โฉนด'
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        disabled={percelData}
                                        value={inputValue}
                                        onChange={value => {
                                            setInputValue(value);
                                            form.setFieldsValue({
                                                location_title_deed_number: value
                                            })
                                        }}
                                    />
                                    <Button type="primary" onClick={onSubmitSearch} hidden={percelData}>
                                        <SearchOutlined />
                                    </Button>
                                    <Button type="primary" onClick={onClearSearch} hidden={!percelData}>
                                        <CloseOutlined />
                                    </Button>
                                </Input.Group>
                                {/* <Search
                                    ref={refInput}
                                    className={`gw-menu-toggle ${styles["gw-input-search"]}`}
                                    placeholder="เลขที่โฉนด"
                                    onSearch={onSubmitSearch}
                                /> */}
                            </Form.Item>
                        </Col>
                        <Col span={12} className={styles["btn-form-action"]}>
                            <Button 
                                disabled={percelData}
                                onClick={showModalMap}
                                className={`${styles["btn-form"]} ${styles["gw-btn-confirm"]} ${styles["gw-w-100"]}`} 
                                htmlType="button">
                                <div>
                                    <span>กำหนดที่ตั้งเอง</span>
                                </div>
                            </Button>
                        </Col>
                        <Col span={24}>
                            <GoogleMap
                                mapTypeId='hybrid'
                                options={{
                                    styles: mapStyles,
                                    disableDefaultUI: true,
                                    // disableDoubleClickZoom: true,
                                    // fullscreenControl: false,
                                    // streetViewControl: false,
                                    // mapTypeControl: false,
                                    // zoomControl: false,
                                    // scaleControl: false,
                                    // draggingCursor: false,
                                }}
                                mapContainerStyle={mapPinSizeStyles}
                                zoom={userPinMarker?.zoom || 7}
                                center={userPinMarker?.location || defaultCenter}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                mapContainerClassName={styles["gw-map-container"]}
                            >
                                {
                                    userPinMarker?.location && 
                                    <Marker
                                        icon={{
                                            url: iconMarkLogo,
                                            scaledSize: {
                                                width: 30,
                                                height: 43
                                            },
                                        }}
                                        position={userPinMarker?.location}
                                    />
                                }
                                {
                                    userPinMarker?.polygons && (
                                        <Polygon
                                            options={mapPolygonStyles}
                                            editable={false}
                                            draggable={false}
                                            path={userPinMarker?.polygons}
                                        />
                                    )
                                }
                            </GoogleMap>
                        </Col>
                        <Col span={24}>
                            <Row align='middle' justify='center'>
                                <Button
                                    onClick={showModalMapPolygon}
                                    disabled={!userPinMarker}
                                    className={`${styles["btn-form"]} ${styles["gw-btn-confirm"]} ${styles["gw-btn-col"]}`}
                                    htmlType="button">
                                    <div>
                                        <span>กำหนดรูปแปลง</span>
                                    </div>
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    )
}

export default MyPropertyCreateStep2;