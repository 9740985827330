import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import base_url from './config';

const endpoint = {
    notification: `${base_url}/notification`,
    notification_notification_read: `${base_url}/notification/notification-read`,
    notification_count: `${base_url}/notification/notification-count`,
}

const fetchNotifications = (body = {}) => {
    const queryString = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&');
    return axios.get(endpoint.notification + `?${queryString}`);
}

const fetchNotificationsCount = (body = {}) => {
    return axios.get(endpoint.notification_count);
}

const updateNotificationRead = (body = {}) => {
    return axios.patch(endpoint.notification_notification_read + `/${body.noti_id}`, {
        "is_read": true
    });
}

export {
    fetchNotifications,
    updateNotificationRead,
    fetchNotificationsCount,
}