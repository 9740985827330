/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Carousel, Modal } from 'antd';
import styles from './styles.module.scss';
import { ReactComponent as CircleClose } from '../../assets/icons/modal/circle-close.svg';
import arrowRight from '../../assets/icons/modal/news/arrow_right_long_light.svg';
import { useModalContext } from '../../context/modals';
import { useRef } from 'react';
import * as localServices from '../../services/localstorage';

const ModalLoginComponent = () => {
    const carouselRef = useRef(null);
    const { data: modal, action: modalAction } = useModalContext();

    const handleCancel = () => {
        localServices.setLoadedModalNews()
        modalAction.updateModalNews(false);
    };

    const onClickPrevious = () => {
        carouselRef.current?.prev();
    }

    const onClickNext = () => {
        carouselRef.current?.next();
    }

    return (
        <>
            {/* <Modal className="gw-modal-container" width={"100%"} visible={modal.isModalNewsShow} footer="" closable={false}> */}
            <Modal className="gw-modal-container" width={"100%"} visible={false} footer="" closable={false}>
                <div className='gw-modal-block-close'>
                    <div className='gw-modal-close' onClick={handleCancel}>
                        <CircleClose />
                    </div>
                </div>
                <div className={`${styles["gw-modal-block-content"]}`}>
                    <div className={`${styles["gw-modal-content"]}`}>
                        <Carousel ref={carouselRef} autoplay={false} className={`${styles["gw-modal-carousel"]}`}>
                            <div className={`${styles["gw-modal-item"]}`}>
                                <div gw-class="text-content">
                                    <span gw-class="label-title">หัวข้อแนะนำ 1</span>
                                    <span gw-class="label-description">คำบรรยายลักษณะพิเศษของหัวข้อแนะนำ 1</span>
                                </div>
                            </div>
                            <div className={`${styles["gw-modal-item"]}`}>
                                <div gw-class="text-content">
                                    <span gw-class="label-title">หัวข้อแนะนำ 1</span>
                                    <span gw-class="label-description">คำบรรยายลักษณะพิเศษของหัวข้อแนะนำ 1</span>
                                </div>
                            </div>
                            <div className={`${styles["gw-modal-item"]}`}>
                                <div gw-class="text-content">
                                    <span gw-class="label-title">หัวข้อแนะนำ 1</span>
                                    <span gw-class="label-description">คำบรรยายลักษณะพิเศษของหัวข้อแนะนำ 1</span>
                                </div>
                            </div>
                            <div className={`${styles["gw-modal-item"]}`}>
                                <div gw-class="text-content">
                                    <span gw-class="label-title">หัวข้อแนะนำ 1</span>
                                    <span gw-class="label-description">คำบรรยายลักษณะพิเศษของหัวข้อแนะนำ 1</span>
                                </div>
                            </div>
                        </Carousel>
                        <div className={`${styles["gw-modal-carousel-action"]}`}>
                            <Button onClick={onClickPrevious} className={`${styles["gw-btn-previous"]}`}>
                                <div>
                                    <span>Previous</span>
                                </div>
                            </Button>
                            <Button onClick={onClickNext} className={`${styles["gw-btn-next"]}`}>
                                <div>
                                    <span>Next</span>
                                    <img src={arrowRight} alt="arrowRight" />
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalLoginComponent;