import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import base_url from './config';

const endpoint = {
    pin_location: `${base_url}/pin-location`,
}

const fetchPinLocation = () => {
    return trackPromise(axios.get(endpoint.pin_location));
}

const fetchPinLocationByPinId = (pin_id = "") => {
    return trackPromise(axios.get(`${endpoint.pin_location}/${pin_id}`));
}

const createPinLocation = (body = {}) => {
    return trackPromise(axios.post(endpoint.pin_location, body));
}

const deletePinLocationByPinId = (pin_id = "") => {
    return trackPromise(axios.delete(`${endpoint.pin_location}/${pin_id}`));
}

export {
    fetchPinLocation,
    fetchPinLocationByPinId,
    createPinLocation,
    deletePinLocationByPinId
}