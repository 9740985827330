import React, { createContext, useContext, useState } from "react";
import { node } from 'prop-types';

const ModalContext = createContext({});

export function useModalContext() {
    return useContext(ModalContext)
}

const initialData = {
    isModalLoginShow: false,
    isModalNewsShow: false,
    isShowDrawerBroadcastFilter: false
}

const initialDataModalShare = {
    isModalShow: false,
    modalData: {}
}

const initialDataLogin = {
    isLoginSuccess: false,
}

function ModalProvidor({ children }) {
    const [data, setData] = useState(initialData);
    const [dataModalShare, setDataModalShare] = useState(initialDataModalShare);
    const [dataLogin, setDataLogin] = useState(initialDataLogin);
    const [visibleDrawerBroadcastFilter, setVisibleDrawerBroadcastFilter] = useState(false);

    function updateModalLogin(isModalLoginShow = false) {
        setData(prevData => ({
            ...prevData,
            isModalLoginShow: isModalLoginShow
        }))
    }

    function updateDataLogin(isLoginSuccess = false) {
        setDataLogin(prevData => ({
            ...prevData,
            isLoginSuccess: isLoginSuccess
        }))
    }

    function updateModalNews(isModalNewsShow = false) {
        setData(prevData => ({
            ...prevData,
            isModalNewsShow: isModalNewsShow
        }))
    }

    function updateModalShare(nextData) {
        setDataModalShare(prevData => ({
            ...prevData,
            ...nextData
        }))
    }

    function updateDrawerBroadcastFilter(nextData) {
        setVisibleDrawerBroadcastFilter(nextData)
    }

    const dataStore = {
        data,
        dataModalShare,
        dataLogin,
        dataDrawer: {
            visibleDrawerBroadcastFilter
        },
        action: {
            updateModalLogin,
            updateModalNews,
            updateModalShare,
            updateDataLogin,
            updateDrawerBroadcastFilter,
        }
    }

    return <ModalContext.Provider value={dataStore}>{children}</ModalContext.Provider>
}

ModalProvidor.prototype = {
    children: node.isRequired
}

export default ModalProvidor;