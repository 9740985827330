import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.min.css';
import 'simplebar/dist/simplebar.min.css';
import './scss/index.scss';
import './scss/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
