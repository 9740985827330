import { memo } from "react";
import { Col } from "antd";
import CardItemPrimary from "../CardItemPrimary";

const ListElement = memo(({ list = [], type = "PROPERTY", isMobile = false }) => {
    switch (type) {
        case "BOOTITEM_24":
            return list.map((item, index) => (
                <Col span={24} key={index}>
                    <CardItemPrimary dataitem={item} bootItem={true} />
                </Col>
            ));
        case "BROADCAST_24":
            return list.map((item, index) => (
                <Col span={24} key={index}>
                    <CardItemPrimary dataitem={item} isBroadCast={true} />
                </Col>
            ));
        case "PINLOCATION_24":
            return list.map((item, index) => (
                <Col span={24} key={index}>
                    <CardItemPrimary dataitem={item} isPinLocation={true} />
                </Col>
            ));
        case "DEFAULT_24":
            return list.map((item, index) => (
                <Col span={24} key={index}>
                    <CardItemPrimary dataitem={item} />
                </Col>
            ));
        case "BOOTITEM_PROPMARKET_24":
            return list.map((item, index) => (
                <Col span={24} key={index}>
                    <CardItemPrimary dataitem={item} isBroadCast={true} isPropmarket={true}/>
                </Col>
            ));
        // DEFAULT_12_6
        default:
            return list.map((item, index) => (
                <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6} key={index}>
                    <CardItemPrimary dataitem={item} />
                </Col>
            ));
    }
});

export default ListElement;