/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState, useEffect } from "react";
import { Form, Input, Button, Drawer, Select, Slider, Radio, Checkbox, Dropdown, Space, Menu, InputNumber, Divider, Collapse } from "antd";
import { ReactComponent as IcoPinLight } from '../../assets/icons/pages/findProertyMap/filter/pin_light.svg';
import { ReactComponent as IcoExpandDownLight } from '../../assets/icons/pages/findProertyMap/filter/expand_down_light.svg';
import styles from './style.module.scss';
import { defaultValueFilter } from "../../utils/common";
import $ from 'jquery';
import SimpleBar from 'simplebar-react';
import { fetchPropertyCategory } from "../../services/service.common";
import { useFilterContext } from "../../context/filter";
import { useDispatch } from "react-redux";
import { updatePropertyMapList } from "../../redux/action.property";

const { Option } = Select;

const MapFilter = ({
    visibleDrawerFilter,
    closeDrawerFilter,
    onFilterFinish,
    onFilterFinishFailed,
    onFilterReset,
}) => {
    const [ form ] = Form.useForm();
    const {data: filterData, action: filterAction} = useFilterContext();
    const dispatch = useDispatch()

    useEffect(() => {
        form.setFieldsValue({ ...defaultValueFilter, ...filterData });
        return () => { }
    }, [filterData, form]);
    
    useEffect(function () {
        if (visibleDrawerFilter) {
            $(".ant-drawer-mask").on("click", function () {
                closeDrawerFilter(`${`$(".ant-drawer-mask").on("click"`}`);
            });
        }
    }, [closeDrawerFilter, visibleDrawerFilter])

    const [priceRangeTemp, SetPriceRangeTemp] = useState({
        from: 0,
        to: 0
    });
    const [areaRangeTemp, SetAreaRangeTemp] = useState({
        from: 0,
        to: 0
    });
    const [priceRange, SetPriceRange] = useState({
        from: (filterData.price_range || [])[0] || 0,
        to: (filterData.price_range || [])[1] || 0
    });
    const [areaRange, SetAreaRange] = useState({
        from: (filterData.total_usable_area_range || [])[0] || 0,
        to: (filterData.total_usable_area_range || [])[1] || 0
    });

    useEffect(function () {
        SetPriceRange({
            from: (filterData.price_range || [])[0] || 0,
            to: (filterData.price_range || [])[1] || 0
        });
    }, [filterData.price_range]);
    useEffect(function () {
        SetAreaRange({
            from: (filterData.total_usable_area_range || [])[0] || 0,
            to: (filterData.total_usable_area_range || [])[1] || 0
        });
    }, [filterData.total_usable_area_range]);

    const [visibleDropdownArea, setVisibleDropdownArea] = useState(false);
    const [visibleDropdownPrice, setVisibleDropdownPrice] = useState(false);
    const dropdownPriceRange = (props) => {
        const setVisibleDropdown = setVisibleDropdownPrice;
        const { from: fromValue, to: toValue } = priceRangeTemp;
        const SetTemp = SetPriceRangeTemp;
        const SetSource = SetPriceRange;

        const onClickClear = () => {
            SetTemp({
                from: 0,
                to: 0
            });
            SetSource({
                from: 0,
                to: 0
            });
            setVisibleDropdown(false);
        }
        const onClickSave = () => {
            if (fromValue <= toValue) {
                SetSource({
                    from: fromValue,
                    to: toValue
                });
                setVisibleDropdown(false);
            }
        }
        const onChangeInputFrom = (value) => {
            if (value <= toValue) {
                SetTemp(prevVal => {
                    return {
                        ...prevVal,
                        from: value
                    }
                });
            }
        }
        const onChangeInputTo = (value) => {
            if (fromValue <= value) {
                SetTemp(prevVal => {
                    return {
                        ...prevVal,
                        to: value
                    }
                });
            }
        }
        const onClickSelectValue = (event, target, value) => {
            $(event.target).closest("div").find("li").removeClass("active");
            $(event.target).closest("li").addClass("active");
            SetTemp(prevVal => {
                return {
                    ...prevVal,
                    [target]: value
                }
            });
        }
        return (
            <Menu>
                <div className={styles["dropdown-input-range"]}>
                    <div className="dropdown-container">
                        <span className="dropdown-label">ราคา</span>
                        <div className="input-range-container">
                            <div className="input-range">
                                <span>ต่ำสุด</span>
                                <InputNumber 
                                    value={fromValue} 
                                    min={0} 
                                    max={toValue || 50000000} 
                                    onChange={onChangeInputFrom} 
                                    prefix="฿" 
                                    controls={false} />
                                <SimpleBar autoHide={false} className={`option-range active`}>
                                    <li key={`range_min_reset`} >
                                        <a onClick={e => onClickSelectValue(e, "from", 0)}>
                                            <span>ไม่ระบุ</span>
                                        </a>
                                    </li>
                                    {
                                        Array(50000000 / 500000).fill(0).map((_, index) => {
                                            const realValue =(index + 1) * 500000;
                                            const showValue = (realValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                            const showUnit = "บาท";
                                            return (
                                                <li key={`range_min_${index}`}>
                                                    <a onClick={e => onClickSelectValue(e, "from", realValue)}>
                                                        <span>{showValue} {showUnit}</span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </SimpleBar>
                            </div>
                            <div className="input-range">
                                <span>สูงสุด</span>
                                <InputNumber 
                                    value={toValue} 
                                    min={fromValue || 0} 
                                    max={50000000} 
                                    onChange={onChangeInputTo} 
                                    prefix="฿" 
                                    controls={false} />
                                <SimpleBar autoHide={false} className={`option-range active`}>
                                    <li key={`range_min_reset`} >
                                        <a onClick={e => onClickSelectValue(e, "to", 0)}>
                                            <span>ไม่ระบุ</span>
                                        </a>
                                    </li>
                                    {
                                        Array(50000000 / 500000).fill(0).map((_, index) => {
                                            const realValue =(index + 1) * 500000;
                                            const showValue = (realValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                            const showUnit = "บาท";
                                            return (
                                                <li key={`range_min_${index}`}>
                                                    <a onClick={e => onClickSelectValue(e, "to", realValue)}>
                                                        <span>{showValue} {showUnit}</span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className="dropdown-container">
                        <div className="action-container">
                            <Menu.Item>
                                <Button type="link" onClick={onClickClear}>
                                    ล้างการตั้งค่า
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                                <Button type="primary" onClick={onClickSave}>
                                    บันทึกการตั้งค่า
                                </Button>
                            </Menu.Item>
                        </div>
                    </div>
                </div>
            </Menu>
        )
    }

    const dropdownAreaRange = (props) => {
        const setVisibleDropdown = setVisibleDropdownArea;
        const { from: fromValue, to: toValue } = areaRangeTemp;
        const SetTemp = SetAreaRangeTemp;
        const SetSource = SetAreaRange;

        const onClickClear = () => {
            SetTemp({
                from: 0,
                to: 0
            });
            SetSource({
                from: 0,
                to: 0
            });
            setVisibleDropdown(false);
        }
        const onClickSave = () => {
            if (fromValue <= toValue) {
                SetSource({
                    from: fromValue,
                    to: toValue
                });
                setVisibleDropdown(false);
            }
        }
        const onChangeInputFrom = (value) => {
            if (value <= toValue) {
                SetTemp(prevVal => {
                    return {
                        ...prevVal,
                        from: value
                    }
                });
            }
        }
        const onChangeInputTo = (value) => {
            if (fromValue <= value) {
                SetTemp(prevVal => {
                    return {
                        ...prevVal,
                        to: value
                    }
                });
            }
        }
        const onClickSelectValue = (event, target, value) => {
            $(event.target).closest("div").find("li").removeClass("active");
            $(event.target).closest("li").addClass("active");
            SetTemp(prevVal => {
                return {
                    ...prevVal,
                    [target]: value
                }
            });
        }
        return (
            <Menu>
                <div className={styles["dropdown-input-range"]}>
                    <div className="dropdown-container">
                        <span className="dropdown-label">ขนาด</span>
                        <div className="input-range-container">
                            <div className="input-range">
                                <span>ต่ำสุด</span>
                                <InputNumber value={fromValue} min={0} max={toValue || 300} onChange={onChangeInputFrom} controls={false} />
                                <SimpleBar autoHide={false} className={`option-range active`}>
                                    <li key={`range_min_reset`} >
                                        <a onClick={e => onClickSelectValue(e, "from", 0)}>
                                            <span>ไม่ระบุ</span>
                                        </a>
                                    </li>
                                    {
                                        Array(300 / 10).fill(0).map((_, index) => {
                                            const realValue =(index + 1) * 10;
                                            const showValue = (realValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                            const showUnit = "ตรม.";
                                            return (
                                                <li key={`range_min_${index}`}>
                                                    <a onClick={e => onClickSelectValue(e, "from", realValue)}>
                                                        <span>{showValue} {showUnit}</span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </SimpleBar>
                            </div>
                            <div className="input-range">
                                <span>สูงสุด</span>
                                <InputNumber value={toValue} min={fromValue || 0} max={300} onChange={onChangeInputTo} controls={false} />
                                <SimpleBar autoHide={false} className={`option-range active`}>
                                    <li key={`range_min_reset`} >
                                        <a onClick={e => onClickSelectValue(e, "to", 0)}>
                                            <span>ไม่ระบุ</span>
                                        </a>
                                    </li>
                                    {
                                        Array(300 / 10).fill(0).map((_, index) => {
                                            const realValue =(index + 1) * 10;
                                            const showValue = (realValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                            const showUnit = "ตรม.";
                                            return (
                                                <li key={`range_min_${index}`}>
                                                    <a onClick={e => onClickSelectValue(e, "to", realValue)}>
                                                        <span>{showValue} {showUnit}</span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className="dropdown-container">
                        <div className="action-container">
                            <Menu.Item>
                                <Button type="link" onClick={onClickClear}>
                                    ล้างการตั้งค่า
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                                <Button type="primary" onClick={onClickSave}>
                                    บันทึกการตั้งค่า
                                </Button>
                            </Menu.Item>
                        </div>
                    </div>
                </div>
            </Menu>
        )
    }

    const [prevValue1, setPrevValue1] = useState([]);
    const [prevValue2, setPrevValue2] = useState([]);
    const [prevValue3, setPrevValue3] = useState([]);
    const [prevValue4, setPrevValue4] = useState([]);

    useEffect(function () {
        if (filterData.number_of_bedrooms) {
            setPrevValue1([filterData.number_of_bedrooms]);
        }
        if (filterData.number_of_bathrooms) {
            setPrevValue2([filterData.number_of_bathrooms]);
        }
        if (filterData.number_of_parking_spaces) {
            setPrevValue3([filterData.number_of_parking_spaces]);
        }
        if (filterData.decoration_type) {
            setPrevValue4([filterData.decoration_type]);
        }
    }, [filterData])

    const onFilterValue = (prevVal, currValue, setPrevValue) => {
        let filterValue = []
        if (currValue.length > 1) {
            filterValue = currValue.filter(fItem => !prevVal.includes(fItem));
        } else {
            filterValue = currValue
        }
        setPrevValue(filterValue)
        return filterValue;
    }

    const [dataFilter, setDataFilter] = useState({
        property_category: []
    })

    useEffect(() => {
        const loadData = async () => {
            Promise.allSettled([
                fetchPropertyCategory()
            ]).then(function (responses) {
                const [resPropertyCategory] = responses;
                setDataFilter(function (prevData){
                    return {
                        ...prevData,
                        property_category: resPropertyCategory?.value?.data?.data || [],
                    }
                })
            })
        }
        loadData();
        return () => { };
    }, []);

    return (
        <Drawer
            forceRender
            className={`${styles["gw-drawer-filter"]}`}
            headerStyle={{ display: "none" }}
            bodyStyle={{ padding: "0px" }}
            push={true}
            // mask={null}
            placement="right"
            onClose={null}
            closeIcon={null}
            visible={visibleDrawerFilter}>
            <div style={{ padding: "32px 24px 46px 24px" }}>
                <div className={`${styles["gw-dw-content"]}`}>
                    <div className={`${styles["gw-dw-header"]}`}>
                        <div className={`${styles["gw-header-text"]}`}>
                            <span className={`${styles["gw-title"]}`}>Filter</span>
                            <span className={`${styles["gw-sub-title"]}`}>ค้นหาที่อยู่อาศัยที่ใช่สำหรับคุณ</span>
                        </div>
                        <div className={`${styles["gw-header-action"]}`}>
                            <Button type="link" className={`${styles["btn-back"]}`} onClick={closeDrawerFilter}>
                                Back
                            </Button>
                        </div>
                    </div>
                    <div className={`${styles["gw-dw-form"]}`}>
                        <Form
                            form={form}
                            name="basic"
                            // initialValues={{ ...defaultValueFilter, ...filterData }}
                            onFinish={(values) => {
                                dispatch(updatePropertyMapList({
                                    list: [],
                                    meta: {}
                                }));
                                onFilterFinish({
                                    ...values,
                                    price_range: (priceRange.from === 0 && priceRange.from === priceRange.to) ? null : [priceRange.from, priceRange.to],
                                    total_usable_area_range: (areaRange.from === 0 && areaRange.from === areaRange.to) ? null : [areaRange.from, areaRange.to]
                                });
                            }}
                            onFinishFailed={onFilterFinishFailed}
                            autoComplete="off"
                        >
                            <label className={`${styles["text-title"]}`}>
                                <span>กรอกข้อมูล</span>
                            </label>
                            <Form.Item
                                className="filter-input-text gw-mt-16"
                                name="places_name"
                                label={
                                    <label className={`${styles["field-label"]}`}>
                                        <span>สถานที่</span>
                                    </label>
                                }
                                labelCol={{ span: 24 }}
                            >
                                <Input placeholder="พิมพ์ เขต, จังหวัด หรือชื่อสถานที่" prefix={<IcoPinLight />} />
                            </Form.Item>

                            <Form.Item
                                className="filter-dropdown gw-mt-42"
                                name="places_type">
                                <Select
                                    placeholder="เลือก..."
                                    allowClear
                                    suffixIcon={<IcoExpandDownLight />}
                                >
                                    {
                                        dataFilter.property_category.map(function (item) {
                                            // cate_id
                                            return <Option key={`${item.cate_id}`} value={`${item.cate_id}`}>{item?.cate_name || ""}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                className="filter-input-dropdown-range gw-mt-16"
                                name="price_range"
                                label={
                                    <label className={`${styles["field-label"]}`}>
                                        <span>ช่วงราคา (บาท)</span>
                                    </label>
                                }
                                labelCol={{ span: 24 }}
                            >
                                <Dropdown overlay={dropdownPriceRange} trigger={["click"]}>
                                    <Button key={"button_dropdown"} onClick={_ => visibleDropdownPrice ? setVisibleDropdownPrice(false) : setVisibleDropdownPrice(true)}>
                                        <div className="price-val">
                                            <span className="val-form">{`${priceRange.from}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                            <span className="val-space">-</span>
                                            <span className="val-to">{`${priceRange.to}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                            <span className="val-unit">บาท</span>
                                        </div>
                                        <IcoExpandDownLight />
                                    </Button>
                                </Dropdown>
                            </Form.Item>

                            <Form.Item
                                className="filter-dropdown gw-mt-16"
                                name="property_type"
                                label={
                                    <label className={`${styles["field-label"]}`}>
                                        <span>ประเภท</span>
                                    </label>
                                }
                                labelCol={{ span: 24 }}>
                                <Select
                                    placeholder="เลือก..."
                                    allowClear
                                    suffixIcon={<IcoExpandDownLight />}
                                >
                                    <Option value={`sale`}><span>ขาย</span></Option>
                                    <Option value={`rent`}><span>เช่า</span></Option>
                                    <Option value={`all`}><span>ไม่เลือก</span></Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                className="filter-input-dropdown-range gw-mt-16"
                                name="total_usable_area_range"
                                label={
                                    <label className={`${styles["field-label"]}`}>
                                        <span>ขนาดพื้นที่ใช้สอยทั้งหมด (ตรม.)</span>
                                    </label>
                                }
                                labelCol={{ span: 24 }}
                            >
                                <Dropdown overlay={dropdownAreaRange} trigger={["click"]}>
                                    <Button onClick={_ => visibleDropdownArea ? setVisibleDropdownArea(false) : setVisibleDropdownArea(true)}>
                                        <div className="price-val">
                                            <span className="val-form">{`${areaRange.from}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                            <span className="val-space">-</span>
                                            <span className="val-to">{`${areaRange.to}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                            <span className="val-unit">ตรม.</span>
                                        </div>
                                        <IcoExpandDownLight />
                                    </Button>
                                </Dropdown>
                            </Form.Item>

                            <Form.Item
                                className="filter-button-checkbox gw-mt-16"
                                name="number_of_bedrooms"
                                label={
                                    <label className={`${styles["field-label"]}`}>
                                        <span>จำนวนห้อนนอน</span>
                                    </label>
                                }
                                labelCol={{ span: 24 }}
                                getValueFromEvent={(...args) => {
                                    const currValue = args[0] || [];
                                    const filterValue = onFilterValue(prevValue1, currValue, setPrevValue1);
                                    return filterValue;
                                }}
                            >
                                <Checkbox.Group
                                    options={[
                                        { label: 'ไม่กำหนด', value: '0' },
                                        { label: '1', value: '1' },
                                        { label: '2', value: '2' },
                                        { label: '3', value: '3' },
                                        { label: '4', value: '4' },
                                        { label: '5+', value: '5' },
                                ]} />
                            </Form.Item>

                            <Form.Item
                                className="filter-button-checkbox gw-mt-16"
                                name="number_of_bathrooms"
                                label={
                                    <label className={`${styles["field-label"]}`}>
                                        <span>จำนวนห้อนน้ำ</span>
                                    </label>
                                }
                                labelCol={{ span: 24 }}
                                getValueFromEvent={(...args) => {
                                    const currValue = args[0] || [];
                                    const filterValue = onFilterValue(prevValue2, currValue, setPrevValue2);
                                    return filterValue;
                                }}
                            >
                                <Checkbox.Group options={[
                                    { label: 'ไม่กำหนด', value: '0' },
                                    { label: '1', value: '1' },
                                    { label: '2', value: '2' },
                                    { label: '3', value: '3' },
                                    { label: '4', value: '4' },
                                    { label: '5+', value: '5' },
                                ]} />
                            </Form.Item>

                            <Form.Item
                                className="filter-button-checkbox gw-mt-16"
                                name="number_of_parking_spaces"
                                label={
                                    <label className={`${styles["field-label"]}`}>
                                        <span>จำนวนที่จอดรถ</span>
                                    </label>
                                }
                                labelCol={{ span: 24 }}
                                getValueFromEvent={(...args) => {
                                    const currValue = args[0] || [];
                                    const filterValue = onFilterValue(prevValue3, currValue, setPrevValue3);
                                    return filterValue;
                                }}
                            >
                                <Checkbox.Group options={[
                                    { label: 'ไม่กำหนด', value: '0' },
                                    { label: '1', value: '1' },
                                    { label: '2', value: '2' },
                                    { label: '3', value: '3' },
                                    { label: '4', value: '4' },
                                    { label: '5+', value: '5' },
                                ]} />
                            </Form.Item>

                            <Form.Item
                                className="filter-button-checkbox gw-mt-16"
                                name="decoration_type"
                                label={
                                    <label className={`${styles["field-label"]}`}>
                                        <span>การตกแต่ง</span>
                                    </label>
                                }
                                labelCol={{ span: 24 }}
                                getValueFromEvent={(...args) => {
                                    const currValue = args[0] || [];
                                    const filterValue = onFilterValue(prevValue4, currValue, setPrevValue4);
                                    return filterValue;
                                }}
                            >
                                <Checkbox.Group options={[
                                    { label: 'ตกแต่งทั้งหมด', value: 'yes' },
                                    { label: 'ตกแต่งบางส่วน', value: 'some' },
                                    { label: 'ไม่มีการตกแต่ง', value: 'no' },
                                ]} />
                            </Form.Item>

                            <Form.Item className={`${styles["gw-action"]}`}>
                                <Button className={`${styles["gw-btn-clear"]}`} onClick={() => {
                                    form.setFieldsValue({ ...defaultValueFilter});
                                    SetPriceRangeTemp({from: 0, to: 0});
                                    SetAreaRangeTemp({from: 0, to: 0});
                                    SetPriceRange({from: 0, to: 0});
                                    SetAreaRange({from: 0, to: 0});
                                    onFilterReset()
                                }}>
                                    <div>
                                        <span>Clear</span>
                                    </div>
                                </Button>
                                <Button className={`${styles["gw-btn-confirm"]}`} htmlType="submit">
                                    <div>
                                        <span>Confirm</span>
                                    </div>
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default MapFilter;