import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Divider, Image, Row, Space, Spin } from 'antd';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styles from './style.module.scss';
import { ReactComponent as IconBack } from '../../assets/icons/pages/findProertyDetail/arrow_left_back.svg';
import { Helmet } from 'react-helmet';
import { ReactComponent as ICO1RailwayStation } from '../../assets/icons/pages/findProertyNearbyPlace/1-railway-station.svg';
import iCO1RailwayStation from '../../assets/icons/pages/findProertyNearbyPlace/1-railway-station.png';
import { ReactComponent as ICO2Restaurant } from '../../assets/icons/pages/findProertyNearbyPlace/2-restaurant.svg';
import iCO2Restaurant from '../../assets/icons/pages/findProertyNearbyPlace/2-restaurant.png';
import { ReactComponent as ICO3Pharmacy } from '../../assets/icons/pages/findProertyNearbyPlace/3-pharmacy.svg';
import iCO3Pharmacy from '../../assets/icons/pages/findProertyNearbyPlace/3-pharmacy.png';
import { ReactComponent as ICO4Mortarboard } from '../../assets/icons/pages/findProertyNearbyPlace/4-mortarboard.svg';
import iCO4Mortarboard from '../../assets/icons/pages/findProertyNearbyPlace/4-mortarboard.png';
import { ReactComponent as ICO5Bag } from '../../assets/icons/pages/findProertyNearbyPlace/5-bag.svg';
import iCO5Bag from '../../assets/icons/pages/findProertyNearbyPlace/5-bag.png';
import { GoogleMap, InfoWindow, LoadScript, Marker, useLoadScript } from '@react-google-maps/api';
import { MapTypeId, defaultCenter, mapMobileModalSizeStyles, mapModalSizeStyles, mapPolygonStyles, mapStyles } from '../FindPropertyMap/settings';
import { useCallback } from 'react';
import iconMarkLogo from '../../assets/icons/maps/mark.png';
import icoMapLayer1 from '../../assets/icons/pages/findProertyMap/maps_1.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

const map_places = {
    "train_station|airport|bus_station": {
        key: "place_0",
        search: ["train_station", "airport", "bus_station"],
        icon: {
            url: iCO1RailwayStation,
            scaledSize: {
                width: 21,
                height: 21
            },
        }
    },
    "restaurant|bar|cafe": {
        key: "place_1",
        search: ["restaurant", "bar", "cafe"],
        icon: {
            url: iCO2Restaurant,
            scaledSize: {
                width: 22,
                height: 22
            },
        }
    },
    "hospital": {
        key: "place_2",
        search: ["hospital"],
        icon: {
            url: iCO3Pharmacy,
            scaledSize: {
                width: 23,
                height: 23
            },
        }
    },
    "school|university|primary_school|secondary_school": {
        key: "place_3",
        search: ["school", "university", "primary_school", "secondary_school"],
        icon: {
            url: iCO4Mortarboard,
            scaledSize: {
                width: 20,
                height: 20
            },
        }
    },
    "shopping_mall": {
        key: "place_4",
        search: ["shopping_mall"],
        icon: {
            url: iCO5Bag,
            scaledSize: {
                width: 23,
                height: 23
            },
        }
    },
}

let mapPolygon;
const IndexPage = ({
    googleMapsScriptBaseUrl
}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const navigate = useNavigate();
    const { state } = useLocation();
    const [searchParams] = useSearchParams();
    const [mapData, setMapData] = useState({
        location: {},
        mapTypeId: MapTypeId.ROADMAP
    });
	const [map, setMap] = useState(null);

    const [placeKeyWord, setPlaceKeyWord] = useState("");
    const [placeKey, setPlaceKey] = useState({});
    const [pinLocation, setPinLocation] = useState(state?.location || {});
    const [nearbySearch, setNearSearch] = useState([]);
    const [isLoadData, setIsLoadData] = useState(false);
    const [mapSearchPlaces, setMapSearchPlaces] = useState({});
    const [activeInfo, setActiveInfo] = useState(null);

    useEffect(() => {
        if (map && window.google) {
            if (nearbySearch.length) {
                let polygonBounds = new window.google.maps.LatLngBounds();
                [...nearbySearch, {
                    location: pinLocation,
                    currentMarker: true,
                }].forEach(item => {
                    if (item.currentMarker) {
                        polygonBounds.extend(item.location);
                    } else {
                        polygonBounds.extend(item.geometry.location.toJSON());
                    }
                })
                map.setCenter(polygonBounds.getCenter());
                map.fitBounds(polygonBounds);
            } else {
                map.setCenter(pinLocation);
                map.setZoom(14);
                if (state?.polygon?.length > 0) {
                    if (mapPolygon) mapPolygon.setMap(null);
                    mapPolygon = new window.google.maps.Polygon({
                        path: [...state?.polygon].map(item => {
                            return {
                                lat: item.latitude,
                                lng: item.longitude
                            }
                        }),
                        ...mapPolygonStyles,
                        map
                    });
					let polygonBounds = new window.google.maps.LatLngBounds();
					const polygonCoords = [...state?.polygon].map(item => {
						return {
							lat: item.latitude,
							lng: item.longitude
						}
					})
					for (let i = 0; i < polygonCoords.length; i++) {
						polygonBounds.extend(polygonCoords[i]);
					}
					map.setCenter(polygonBounds.getCenter());
					map.fitBounds(polygonBounds);
                }
            }
        }
        return () => {
            
        }
    }, [map, nearbySearch, pinLocation, state?.polygon]);

    const onClikeNavigateBack = () => {
        navigate(-1);
    }

    const onClickActive = (index = -1) => {
        const findKey = Object.keys(map_places).map((key) => [key, map_places[key]])?.[index]?.[1];
        if (findKey?.key !== placeKey?.key) {
            setPlaceKey(findKey);
            setPlaceKeyWord(Object.keys(map_places).map((key) => [key, map_places[key]])?.[index]?.[0]);
        } else {
            setActiveInfo(null);
            setPlaceKey({});
            setPlaceKeyWord("");
        }
    }

    useEffect(() => {
        const loadDataPlaces = async (keyword) => {
            try {
                setIsLoadData(true);
                setNearSearch([]);

                if (mapSearchPlaces[placeKey.key]?.length) {
                    setNearSearch([...mapSearchPlaces[placeKey.key] || []].filter(item => item.business_status));
                    setIsLoadData(false);
                    return;
                }

                const location = new window.google.maps.LatLng(pinLocation);
                const request = {
                    location: location,
                    radius: '3000',
                    types: [...placeKey?.search || []],
                    language: "th"
                };
                const service = new window.google.maps.places.PlacesService(map);
                service.nearbySearch(request, function (results, status) {
                    setTimeout(() => {
                        const final_results = [...results || []];
                        setNearSearch(final_results);
                        setMapSearchPlaces({
                            ...mapSearchPlaces,
                            [placeKey.key]: final_results
                        })
                        setIsLoadData(false);
                    }, 0)
                });
            } catch (error) {
                console.warn("🚀 ~ file: index.js:74 ~ loadDataPlaces ~ error:", error);
            } finally {
                // setIsLoadData(false);
            }
        };
        // eslint-disable-next-line no-undef
        if (placeKeyWord) {
            loadDataPlaces(placeKeyWord);
        } else {
            setNearSearch([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, pinLocation, placeKey.key, placeKey?.search, placeKeyWord]);
    
	const onLoad = useCallback(function callback(map) {
		setMap(map)
	}, []);

	const onUnmount = useCallback(function callback(map) {
		setMap(null)
	}, []);

    const onClickToggleLayer = () => {
        if (MapTypeId.ROADMAP === mapData.mapTypeId) {
            setMapData(prevState => {
                return {
                    ...prevState,
                    mapTypeId: MapTypeId.HYBRID
                }
            });
        } else {
            setMapData(prevState => {
                return {
                    ...prevState,
                    mapTypeId: MapTypeId.ROADMAP
                }
            });
        }
    }

    return (
        <div className={`${styles["navigate-container"]}`}>
            <Helmet>
                <title>Nearby place | Growestate</title>
            </Helmet>
            <div className={`gw-container`}>
                <Row gutter={[24, 24]} className={`gw-mt-32`}>
                    <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                        <div className={`${styles["gw-header"]}`}>
                            <div className={`gw-btn-link gw-btn-danger`} onClick={onClikeNavigateBack}>
                                <IconBack />
                                <span>Back</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                        <Space size={12} wrap className={`${styles["gw-button-toggle"]}`}>
                            <Button active={`${placeKey?.key === "place_0"}`} onClick={_ => onClickActive(0)} disabled={isLoadData}><ICO1RailwayStation /></Button>
                            <Button active={`${placeKey?.key === "place_1"}`} onClick={_ => onClickActive(1)} disabled={isLoadData}><ICO2Restaurant /></Button>
                            <Button active={`${placeKey?.key === "place_2"}`} onClick={_ => onClickActive(2)} disabled={isLoadData}><ICO3Pharmacy /></Button>
                            <Button active={`${placeKey?.key === "place_3"}`} onClick={_ => onClickActive(3)} disabled={isLoadData}><ICO4Mortarboard /></Button>
                            <Button active={`${placeKey?.key === "place_4"}`} onClick={_ => onClickActive(4)} disabled={isLoadData}><ICO5Bag /></Button>
                        </Space>
                        <div className={`${styles["btn-action-maps"]}`}>
                            <Button shape="circle" onClick={onClickToggleLayer}>
                                <img src={icoMapLayer1} alt="" />
                                {/* <img src={mapData.mapTypeId === MapTypeId.ROADMAP ? icoMapLayer1 : icoMapLayer2} alt="" /> */}
                            </Button>
                            {/* <Button shape="circle" onClick={onClickSetCurrentLocation}>
                                <img src={icoMapLayer2} alt="" />
                            </Button> */}
                        </div>
                        <GoogleMap
                            mapTypeId={mapData.mapTypeId}
                            options={{
                                styles: mapData.mapTypeId === MapTypeId.ROADMAP ? mapStyles : {},
                                disableDefaultUI: true,
                            }}
                            mapContainerStyle={isMobile ? mapMobileModalSizeStyles : mapModalSizeStyles}
                            zoom={14}
                            center={pinLocation}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            mapContainerClassName={styles["gw-map-container"]}
                            // onClick={onClickMapChangePinLocation}
                        >
                            { pinLocation && <MarkerInfo 
                                keyActiveInfo={"none"}
                                activeInfo={activeInfo} 
                                setActiveInfo={setActiveInfo} 
                                key={`marker-none`} pinLocation={pinLocation} detail={state?.detail} /> }
                            {
                                nearbySearch.map((item, index) => {
                                    return <MarkerInfo 
                                        keyActiveInfo={`marker-${placeKey?.key}-${index}`}
                                        activeInfo={activeInfo}
                                        setActiveInfo={setActiveInfo}
                                        key={`marker-${placeKey?.key}-${index}`} 
                                        pinLocation={item.geometry.location.toJSON()} 
                                        detail={`${item.name}`} 
                                        icon={placeKey?.icon}
                                    />
                                })
                            }
                        </GoogleMap>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

const MarkerInfo = ({
    pinLocation,
    detail,
    icon,
    setActiveInfo,
    activeInfo,
    keyActiveInfo
}) => {
    // const [isShowInfo, setIsShowInfo] = useState(false);
    const markerIcon = {
        url: iconMarkLogo,
        scaledSize: {
            width: 30,
            height: 43
        },
    }
    if (!pinLocation.lat) return <></>;
    return <Marker
        icon={icon || markerIcon}
        position={pinLocation}
        onClick={_ => setActiveInfo(keyActiveInfo)}
    >
        {
            activeInfo === keyActiveInfo && <InfoWindow
                onCloseClick={_ => setActiveInfo(null)}
                options={{ maxWidth: 300 }}>
                <span>{detail || ""}</span>
            </InfoWindow>
        }
    </Marker>
}

export default IndexPage;