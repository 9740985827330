import { useEffect, useState } from "react";
import { Image, Menu, Spin } from "antd";
import dayjs from 'dayjs';
import styles from './styles.module.scss';
import { ReactComponent as NotificationIco1 } from '../../assets/icons/header/notification-ico-1.svg';
import { ReactComponent as NotificationIco2 } from '../../assets/icons/header/notification-ico-2.svg';
import { ReactComponent as NotificationIcClock } from '../../assets/icons/header/notification-ic_clock.svg';
import { ReactComponent as NotificationIco3 } from '../../assets/icons/header/notification-ico-3.svg';
import InfiniteScroll from "react-infinite-scroller";
import { useHeaderContext } from "../../context/header";
import { LoadingOutlined } from '@ant-design/icons';
import { imgfallback } from "../../utils/common";
import { useDispatch } from "react-redux";
import { updateCountNewNotification } from "../../redux/action.message";

const OverlayNotificationList = () => {
    const {data: headerData, action: headerAction} = useHeaderContext();
    const dispatch = useDispatch()
    
    useEffect(_ => {
        headerAction.initNotificationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(_ => {
    //     if (headerData?.notifications?.list?.length) {
    //         dispatch(updateCountNewNotification(headerData?.notifications?.list?.filter(item => item?.isRead === false)?.length || 0));
    //     }
    // }, [dispatch, headerData?.notifications?.list])
    
    const SubHeaderMenu = ({
        textTitle = ""
    }) => {
        return (
            <div className={styles["gw-sub-header-menu"]}>
                <span className={styles["gw-text-title"]}>{textTitle}</span>
            </div>
        )
    }
    const MenuItem = ({dataKey, dataItem}) => {
        const onClickNotification = async () => {
            try {
                await headerAction.updateNotificationRead(dataItem)
            } catch (error) {
                console.log(`🌦️ ~ onClickNotification ~ error:`, error);
            } finally {
                if (dataItem.noti_type_id === 1) {
                    window.open("/propmarket/detail/broadcast?bc_id=" + dataItem.bc_id, '_self');
                }
                if (dataItem.noti_type_id === 2) {
                    window.open("/find-property-detail?p_id=" + dataItem.p_id, '_self');
                }
                if (dataItem.noti_type_id === 3) {
                    window.open("/my-grow/pin-location-list?pin_id=" + dataItem.inter_id, '_self');
                }
            }
        }
        
        return (
            <Menu.Item 
                key={`menu_${dataKey}`} 
                className={`${styles['gw-menu-item']} ${!dataItem?.isRead ? styles['gw-read'] : ""}`} 
                onClick={onClickNotification}>
                <div className={styles['gw-menu-item-img']}>
                    <Image
                        preview={false}
                        referrerPolicy='no-referrer'
                        className='gw-card-image-profile'
                        src={dataItem?.createBy?.avatar || ""}
                        fallback={imgfallback}/>
                </div>
                <div className={styles['gw-menu-item-description']}>
                    <span className={styles["gw-text-description"]}>{dataItem?.title}</span>
                    <div className={styles['gw-menu-item-time']}>
                        <NotificationIcClock />
                        <span className={styles['gw-date-time']}>{
                            dataItem?.relative_time
                        }</span>
                        <span className={styles['gw-dash']}>-</span>
                        <span className={styles['gw-date-location']}>{dataItem?.notificationTypeMaster?.type_name || ""}</span>
                    </div>
                </div>
            </Menu.Item>
        )
    }
    return (
        <>
            <InfiniteScroll
                key={"infinite-scroll-noti"}
                threshold={250}
                initialLoad={false}
                loadMore={headerAction.loadMoreNotificationData}
                hasMore={headerAction.hasLoadmoreNotification()}
                loader={<Spin key={"test"} indicator={<LoadingOutlined style={{ fontSize: 24, color: "var(--gw-brand-color-red-1)" }} spin />} />}
                useWindow={false}
            >
                <Menu.Divider />
                <Menu.ItemGroup title={<SubHeaderMenu textTitle='ล่าสุด' />}>
                    {
                        [...headerData?.notifications?.list].map((dataItem, dataIndex) => {
                            const key = `is_not_read_${dataIndex}`;
                            return MenuItem({
                                dataKey: key,
                                dataItem
                            })
                        })
                    }
                </Menu.ItemGroup>
                {
                    [...headerData?.notifications?.list].length === 0 
                    && headerData.notificationsIsLoad
                    && <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "var(--gw-brand-color-red-1)" }} spin />} />
                }
                {/* <Menu.ItemGroup title={<SubHeaderMenu textTitle='ก่อนหน้านี้' />}>
                    {
                        mockNotification.filter(fitem => fitem.is_read).map((dataItem, dataIndex) => {
                            const key = `is_read_${dataIndex}`;
                            return MenuItem({
                                dataKey: key, 
                                dataItem
                            })
                        })
                    }
                </Menu.ItemGroup> */}
            </InfiniteScroll>
        </>
    )
}

export default OverlayNotificationList;