
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './style.module.scss';
import pageListstyles from '../FindPropertyList/style.module.scss';
import { Button, Col, Drawer, Row } from "antd";
import { RoutePath } from '../../Routes';
import {ReactComponent as IcoLink} from '../../assets/icons/pages/NewsFeed/1_ico_link.svg';
import {ReactComponent as IcoNewProduct} from '../../assets/icons/pages/NewsFeed/2_ico_new_product.svg';
import {ReactComponent as IcoFavorite} from '../../assets/icons/pages/NewsFeed/3_ico_favorite.svg';
import {ReactComponent as IcoNearby} from '../../assets/icons/pages/NewsFeed/4_ico_nearby.svg';
import background from '../../assets/icons/pages/NewsFeed/Recommended-BG.png';
import background2 from '../../assets/icons/pages/NewsFeed/New-Property-BG.png';
import background3 from '../../assets/icons/pages/NewsFeed/Popular-BG.jpg';
import background4 from '../../assets/icons/pages/NewsFeed/Nearby-BG.png';

import { ReactComponent as IcoFilter } from '../../assets/icons/pages/findProertyMap/filter_alt.svg';
import { defaultValueFilter, filterEvent, validateNotLoadMore } from '../../utils/common';
import MapFilter from '../../components/MapFilter';
import CardItemPrimary from '../../components/CardItemPrimary';
import { fetchPerperty } from '../../services/service.property';
import { fetchNewsFeedPropertyNearly, fetchNewsFeedPropertyNew, fetchNewsFeedPropertyPopular, fetchNewsFeedPropertyRecommended } from '../../services/service.newFeed';
import { useFilterContext } from '../../context/filter';
import LoadMoreElement from '../../components/LoadMore';
import ListElement from '../../components/ListElement';
import InfiniteScroll from 'react-infinite-scroller';
import { usePromiseTracker } from 'react-promise-tracker';
import { useLoadingListContext } from '../../context/loaddingList';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as IconBack } from '../../assets/icons/pages/findProertyDetail/arrow_left_back.svg';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import axios from 'axios';

const tabMenu = {
    recommended: {
        id: "recommended",
        label: "Recommended"
    },
    new_property: {
        id: "new-property",
        label: "New Property"
    },
    popular: {
        id: "popular",
        label: "Popular"
    },
    nearby: {
        id: "nearby",
        label: "Nearby"
    },
}

const NewsFeed = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const { id: defaultID } = useParams();
    const id = isMobile ? defaultID || "" : defaultID || tabMenu.recommended.id;
    const { promiseInProgress } = usePromiseTracker();
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(tabMenu.recommended.id);
    const [visibleDrawerFilter, setVisibleDrawerFilter] = useState(false);
    const {data: filterData, action: filterAction} = useFilterContext();
    const [dataList, setDataList] = useState([]);
    const [_dataMeta, setDataMeta] = useState({});
    const [loadMorePage, setLoadMorePage] = useState(1);
    const [userLocation, setUserLocation] = useState(null);
    const { action: loadingListAction, data: loadingListData } = useLoadingListContext();

    const dataMeta = useMemo(() => {
        return _dataMeta;
    }, [_dataMeta]);

    useEffect(() => {
        // Create a cancel token source
        const cancelTokenSource = axios.CancelToken.source();
        if (id) {
            onActiveTab(id);
            setDataMeta({});
            setDataList([]);
            const filterSearchClean = filterEvent.decodeFilter(location);
            filterAction.updateData(filterSearchClean);
            switch (id) {
                case tabMenu.recommended.id:
                    loadDataByFunc(cancelTokenSource, fetchNewsFeedPropertyRecommended, { ...filterSearchClean });
                    break;
                case tabMenu.new_property.id:
                    loadDataByFunc(cancelTokenSource, fetchNewsFeedPropertyNew, { ...filterSearchClean });
                    break;
                case tabMenu.popular.id:
                    loadDataByFunc(cancelTokenSource, fetchNewsFeedPropertyPopular, { ...filterSearchClean });
                    break;
                case tabMenu.nearby.id:
                    let _coords = {};
                    getCoords()
                        .then(coords => {
                            _coords = {
                                lat: coords?.latitude || '',
                                lng: coords?.longitude || '',
                            }
                            setUserLocation(_coords);
                        })
                        .finally(() => {
                            if (_coords.lat && _coords.lng) {
                                loadDataByFunc(cancelTokenSource, fetchNewsFeedPropertyNearly, { ...filterSearchClean, ..._coords });
                            }
                        })
                    break;
                default:
                    break;
            }
        }
        return () => {
            console.log("unmount");
            if (cancelTokenSource) {
                cancelTokenSource.cancel('Request canceled due to component unmount or URL change');
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, location]);

    function getCoords() {
        return new Promise((resolve, reject) =>
            navigator.permissions ?
                // Permission API is implemented
                navigator.permissions.query({
                    name: 'geolocation'
                }).then(permission =>{
                    // is geolocation granted?
                    return permission.state === "granted" || permission.state === "prompt"
                        ? navigator.geolocation.getCurrentPosition(pos => resolve(pos.coords))
                        : resolve(null)
                }
                ) :

                // Permission API was not implemented
                reject(new Error("Permission API is not supported"))
        )
    }

    async function loadDataByFunc(cancelTokenSource, func = function(){
        return new Promise((_, reject) => {
            reject("no func")
        })
    }, filterBody = {}, loadMore = false) {
        try {
            console.log("🌦️ ~ loadDataByFunc ~ START");
            loadingListAction.updateData(true);
            const responseDataProperty = await func(filterBody, cancelTokenSource);
            const responseItems = responseDataProperty?.data?.data?.items || [];
            const responseMeta = responseDataProperty?.data?.data?.meta || {};
            if (responseDataProperty?.data?.data) {
                setDataMeta(function (prevData) {
                    return {...responseMeta}
                });
                setDataList(function (prevData) {
                    return [...(loadMore ? prevData : []), ...responseItems]
                });
            } else {
                throw Error("no data")
            }
            console.log("LOAD DATA END");
            loadingListAction.updateData(false);
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.log("LOAD DATA END ERROR", error);
                loadingListAction.updateData(false);
            }
            setDataMeta({});
            setDataList([]);
        } finally {
        }
    }

    useEffect(function () {
        // Create a cancel token source
        const cancelTokenSource = axios.CancelToken.source();

        if (loadMorePage !== 1 && Object.keys(filterData.filterSearchObj || {}).length > 0) {
            switch (id) {
                case tabMenu.recommended.id:
                    loadDataByFunc(cancelTokenSource, fetchNewsFeedPropertyRecommended, { ...filterData.filterSearchObj, page: loadMorePage }, true);
                    break;
                case tabMenu.new_property.id:
                    loadDataByFunc(cancelTokenSource, fetchNewsFeedPropertyNew, { ...filterData.filterSearchObj, page: loadMorePage }, true);
                    break;
                case tabMenu.popular.id:
                    loadDataByFunc(cancelTokenSource, fetchNewsFeedPropertyPopular, { ...filterData.filterSearchObj, page: loadMorePage }, true);
                    break;
                case tabMenu.nearby.id:
                    loadDataByFunc(cancelTokenSource, fetchNewsFeedPropertyNearly, { ...filterData.filterSearchObj, ...userLocation, page: loadMorePage }, true);
                    break;
                default:
                    break;
            }
        }
        return () => {
            console.log("unmount");
            if (cancelTokenSource) cancelTokenSource.cancel('Request canceled due to component unmount or URL change');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMorePage]);

    const loadMoreData = () => {
        if (!promiseInProgress && validateNotLoadMore(dataMeta, loadMorePage)) {
            setLoadMorePage(prevData => {
                return prevData + 1;
            });
        }
    }

    const onActiveTab = (tab) => {
        switch (tab) {
            case tabMenu.recommended.id:
            case tabMenu.new_property.id:
            case tabMenu.popular.id:
            case tabMenu.nearby.id:
                setActiveTab(tab);
                break;
            default:
                navigate(`${RoutePath.news_feed_root.path}`);
                break;
        }
    };

    const onclickActiveTabMobile = (tab) => {
        onClose();
        setShowContent(true);
        localStorage.setItem("showContent_newsfeed", true);
        navigate(`${RoutePath.news_feed_root.path}/${tab}`);
    };

    const onclickActiveTab = (tab) => {
        onClose();
        navigate(`${RoutePath.news_feed_root.path}/${tab}`)
    };

    const showDrawerFilter = () => {
        setVisibleDrawerFilter(true);
    };

    const closeDrawerFilter = () => {
        setVisibleDrawerFilter(false);
    };

    const onFilterFinish = (values) => {
        navigate({
            pathname: location.pathname,
            search: `?${filterEvent.encodeFilter(values)}`
        });
        closeDrawerFilter();
    };

    const onFilterFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFilterReset = (...args) => {
        console.log('Reset:', defaultValueFilter);
    };
    
    const [showContent, setShowContent] = useState(localStorage.getItem("showContent_newsfeed") || false);
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const RenderNavigateMobile = () => {
        return (
            <div className={`${styles["gw-list-menu"]}`} style={{ marginTop: 20 }}>
                <Button
                    className={`${styles["gw-btn-ico-link"]}`}
                    onClick={() => onclickActiveTabMobile("recommended")}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoLink />
                    </div>
                    <span>{tabMenu.recommended.label}</span>
                </Button>
                <Button
                    className={`${styles["gw-btn-ico-new-product"]}`}
                    onClick={() => onclickActiveTabMobile("new-property")}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoNewProduct />
                    </div>
                    <span>{tabMenu.new_property.label}</span>
                </Button>
                <Button
                    className={`${styles["gw-btn-ico-favorite"]}`}
                    onClick={() => onclickActiveTabMobile("popular")}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoFavorite />
                    </div>
                    <span>{tabMenu.popular.label}</span>
                </Button>
                <Button
                    className={`${styles["gw-btn-ico-nearby"]}`}
                    onClick={() => onclickActiveTabMobile("nearby")}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoNearby />
                    </div>
                    <span>{tabMenu.nearby.label}</span>
                </Button>
            </div>
        )
    };

    const RenderNavigateWeb = () => {
        return (
            <div className={`${styles["gw-list-menu"]}`}>
                <Button
                    disabled={activeTab === tabMenu.recommended.id}
                    className={`${styles["gw-btn-ico-link"]}`}
                    onClick={() => onclickActiveTab("recommended")}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoLink />
                    </div>
                    <span>{tabMenu.recommended.label}</span>
                </Button>
                <Button
                    disabled={activeTab === tabMenu.new_property.id}
                    className={`${styles["gw-btn-ico-new-product"]}`}
                    onClick={() => onclickActiveTab("new-property")}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoNewProduct />
                    </div>
                    <span>{tabMenu.new_property.label}</span>
                </Button>
                <Button
                    disabled={activeTab === tabMenu.popular.id}
                    className={`${styles["gw-btn-ico-favorite"]}`}
                    onClick={() => onclickActiveTab("popular")}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoFavorite />
                    </div>
                    <span>{tabMenu.popular.label}</span>
                </Button>
                <Button
                    disabled={activeTab === tabMenu.nearby.id}
                    className={`${styles["gw-btn-ico-nearby"]}`}
                    onClick={() => onclickActiveTab("nearby")}
                >
                    <div className={`${styles["gw-btn-icon"]}`}>
                        <IcoNearby />
                    </div>
                    <span>{tabMenu.nearby.label}</span>
                </Button>
            </div>
        )
    };

    const onClikeNavigateBack = () => {
        setShowContent(false);
        localStorage.removeItem("showContent_newsfeed");
        navigate(`${RoutePath.news_feed_root.path}`);
    };

    return (
        <div className={`gw-container gw-mt-48`}>
            <Row gutter={[24, 0]}>
                <Col span={isMobile ? 24 : 6} className={`${styles["gw-side-menu"]}`}>
                    {
                        !isMobile || (isMobile && !showContent) ? (
                            <div className={`${styles["gw-header"]}`}>
                                <span>News Feed</span>
                            </div>
                        ) : null
                    }
                    {isMobile && showContent && (
                        <Row gutter={[24, 24]} style={{ marginTop: 10, marginBottom: 10 }}>
                            <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                                <div>
                                    <div className={`gw-btn-link gw-btn-danger`} onClick={onClikeNavigateBack}>
                                        <IconBack />
                                        <span>Back</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>)}
                    {!isMobile && <RenderNavigateWeb />}
                    {isMobile && !showContent && <RenderNavigateMobile />}
                </Col>
                <Col span={isMobile ? showContent ? 24 : 0 : 12}>
                    <div className={`${styles["gw-feed-content"]}`}>
                        <div className={`${styles["gw-feed-text-header"]}`}>
                            {activeTab === tabMenu.recommended.id && (
                                <>
                                    <img src={background} alt="background" />
                                    <span>{tabMenu.recommended.label}</span>
                                </>
                            )}
                            {activeTab === tabMenu.new_property.id && (
                                <>
                                    <img src={background2} alt="background2" />
                                    <span>{tabMenu.new_property.label}</span>
                                </>
                            )}
                            {activeTab === tabMenu.popular.id && (
                                <>
                                    <img src={background3} alt="background3" />
                                    <span>{tabMenu.popular.label}</span>
                                </>
                            )}
                            {activeTab === tabMenu.nearby.id && (
                                <>
                                    <img src={background4} alt="background4" />
                                    <span>{tabMenu.nearby.label}</span>
                                </>
                            )}
                        </div>
                        <div className={`${pageListstyles["gw-header"]} gw-mt-24`}>
                            <div className={`${pageListstyles["gw-header-text"]}`}>
                                <span className={`${pageListstyles["gw-title"]}`}>Total Posts</span>
                                <span className={`${pageListstyles["gw-sub-title"]}`}>{dataList.length} Posts</span>
                            </div>
                            <div className={`${pageListstyles["gw-header-action"]}`}>
                                <Button type="link" className={`${pageListstyles["btn-link"]}`} onClick={showDrawerFilter}>
                                    <IcoFilter />
                                    <span>Filter</span>
                                </Button>
                            </div>
                        </div>
                        <div className={`${styles["gw-feed-list"]}`}>
                            <InfiniteScroll
                                threshold={750}
                                initialLoad={false}
                                loadMore={loadMoreData}
                                hasMore={validateNotLoadMore(dataMeta, loadMorePage)}
                            >
                                <Row gutter={[0, 24]}>
                                    <ListElement list={dataList} type={"DEFAULT_24"} isMobile={isMobile}/>
                                    {
                                        (activeTab === "nearby" && !userLocation?.lat) ? (
                                            <Col span={12} offset={6} className={`gw-list-loadmore-property`} onClick={loadMoreData}>
                                                <span>ไม่สามารถเข้าถึงพิกัดได้</span>
                                            </Col>
                                        ) : <LoadMoreElement {...{ dataMeta, loadMorePage, loadMoreData }} />
                                    }
                                </Row>
                            </InfiniteScroll>
                        </div>
                    </div>
                </Col>
            </Row>
            
            <Drawer title="News feed" push={true} placement="right" onClose={onClose} visible={open}>
                <div className={styles["gw-side-menu"]}>
                    <RenderNavigateWeb />
                </div>
            </Drawer>
            <MapFilter 
                visibleDrawerFilter={visibleDrawerFilter}
                closeDrawerFilter={closeDrawerFilter}
                onFilterFinish={onFilterFinish}
                onFilterFinishFailed={onFilterFinishFailed}
                onFilterReset={onFilterReset}
            />
        </div>
    )
}

export default NewsFeed;