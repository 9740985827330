import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import MyGrowMain from "../MyGrow";
import * as services from "../../services/service.property";
import { useAuthContext } from "../../context/authentication";
import { Button, Col, Form, Image, Input, notification, Row, Upload } from "antd";
import { useForm } from "antd/lib/form/Form";
import { customRequest, resizeFile } from "../../utils/common";
import { ReactComponent as IcUploadPhoto } from '../../assets/icons/pages/Propmarket/my-property-create/ic_add_a_photo.svg';
import { RoutePath } from "../../Routes";
import { fetchUserProfile, updateUserProfile } from "../../services/service.auth";

const image_source_path = "http://185.78.165.78:3010/";

const MyGrow1ProfileEdit = props => {
    const [form] = useForm();
    const navigate = useNavigate();
    const { data: auth } = useAuthContext();
    const [userData, setUserData] = useState({});
    const [validateTel, setValidateTel] = useState({
        hasFeedback: false,
        isVerify: false
    });

    useEffect(() => {
        async function loadData() {
            const response = await fetchUserProfile();
            const data = response?.data?.data;
            if (data) {
                setUserData(data || {});
                data?.citizen_user_image && setFileListPhoto1([{
                    uid: '-1',
                    status: 'done',
                    url: data?.citizen_user_image,
                }]);
                data?.citizen_image && setFileListPhoto2([{
                    uid: '-2',
                    status: 'done',
                    url: data?.citizen_image,
                }]);
                data?.avatar && setFileListPhoto3([{
                    uid: '-3',
                    status: 'done',
                    url: data?.avatar,
                }]);
                form.setFieldsValue(response?.data?.data)
            }
        }
        if (auth?.isAuthentication) {
            loadData();
        }
    }, [auth?.isAuthentication, form]);

    const getImageUrl = (list = []) => {
        // const path = (list || [])[0]?.fileUpload?.path;
        // if (path) return `${image_source_path}${path}`;
        // return "";
        return (list || [])[0]?.fileUpload?.file_id || "";
    }

    const prepareData = (source_data) => {
        let path_citizen_image = getImageUrl(source_data.citizen_image);
        let path_citizen_user_image = getImageUrl(source_data.citizen_user_image);
        let path_avatar = getImageUrl(source_data.avatar);
        return {
            "display_name": source_data.display_name,
            "about_me": source_data.about_me,
            "citizen_image": path_citizen_image,
            "citizen_user_image": path_citizen_user_image,
            "fname": source_data.fname,
            "lname": source_data.lname,
            "email": source_data.email,
            "tel": source_data.tel,
            "company": source_data.company,
            "avatar": path_avatar
        }
    }

    const onFinish = async (values) => {
        try {
            const response = await updateUserProfile(prepareData(values));
            console.log()
            if (response?.data?.success) {
                navigate(RoutePath.my_grow_profile.path);
            } else {
                throw Error("save fail!")
            }
        } catch (error) {
            console.log(error);
            notification['error']({
                message: `Can not update user profile!`,
                description: "Something went wrong!",
            });
        }
        console.log("onFinish", prepareData(values))
    }

    const onFinishFailed = (values) => {
        console.log("onFinishFailed", values)
    }

    const onClickBack = () => {
        navigate(RoutePath.my_grow_profile.path);
    }

    const [visiblePreview1, setVisiblePreview1] = useState(false);
    const [visiblePreview2, setVisiblePreview2] = useState(false);
    const [visiblePreview3, setVisiblePreview3] = useState(false);
    const [imagePreview1, setImagePreview1] = useState("");
    const [imagePreview2, setImagePreview2] = useState("");
    const [imagePreview3, setImagePreview3] = useState("");
    const [fileListPhoto1, setFileListPhoto1] = useState([]);
    const [fileListPhoto2, setFileListPhoto2] = useState([]);
    const [fileListPhoto3, setFileListPhoto3] = useState([]);

    const getFileListPhoto = ({ fileList: currentFileList }) => {
        return currentFileList;
    }

    const onChangeUploadPhotos = ({ fileList: newFileList }, funcSet = () => {}) => {
        let lastestFile = newFileList[newFileList.length - 1];
        if (lastestFile?.originFileObj) {
            resizeFile(lastestFile?.originFileObj).then(img => {
                lastestFile["originFileObj"] = img;
                lastestFile["size"] = img?.size || 0;
                lastestFile["type"] = img?.type;
                lastestFile["lastModified"] = img?.lastModified;
                lastestFile["lastModifiedDate"] = img?.lastModifiedDate;
                services.fetchUploadImage(img).then(function (responeImage) {
                    lastestFile["fileUpload"] = responeImage?.data?.data || {};
                })
            })
        }
        funcSet([...newFileList]);
    };

    const onPreviewPhoto = async (file, index) => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        switch (index) {
            case 1:
                setImagePreview1(src);
                setVisiblePreview1(true);
                break;
            case 2:
                setImagePreview2(src);
                setVisiblePreview2(true);
                break;
            case 3:
                setImagePreview3(src);
                setVisiblePreview3(true);
                break;
            default:
                break;
        }
    };

    const uploadButtonPhotos = (
        <div className={styles["gw-btn-upload"]}>
            <IcUploadPhoto />
            <div className={styles["gw-btn-upload-text"]}>
                <span>Upload photo</span>
            </div>
        </div>
    );

    const onClickVerityTel = () => {
        setValidateTel(prevData => ({...prevData, isVerify: true}));
        form.validateFields(["tel"]);
    }

    return (
        <MyGrowMain>
            <div className={styles["gw-header"]}>
                <div className={`gw-text-title`}>
                    <span>Edit profile</span>
                </div>
            </div>
            <Form
                className={`gw-mt-32 ${styles["gw-block-container"]}`}
                name="form_profile"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={{}}
                form={form}
            >
                <Row gutter={[24, 24]} className={`gw-mt-8`}>
                    <Col span={24}>
                        <Form.Item
                            className={`${styles["gw-form-input"]}`}
                            name="display_name"
                            rules={[{ required: true, message: 'Please input!' }]}
                        >
                            <Input placeholder='ชื่อที่แสดง' maxLength={250}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            className={`${styles["gw-form-input"]}`}
                            name="about_me"
                            // rules={[{ required: true, message: 'Please input!' }]}
                        >
                            <Input.TextArea placeholder='เกี่ยวกับฉัน' maxLength={1000} style={{height: "100px"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className={`${styles["gw-form-input"]}`}
                            name="fname"
                            rules={[{ required: true, message: 'Please input!' }]}
                        >
                            <Input placeholder='ชื่อ' maxLength={250}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className={`${styles["gw-form-input"]}`}
                            name="lname"
                            rules={[{ required: true, message: 'Please input!' }]}
                        >
                            <Input placeholder='สกุล' maxLength={250}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className={`${styles["gw-form-input"]}`}
                            name="tel"
                            hasFeedback={validateTel.hasFeedback}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (`${value}`.trim() !== userData.tel) {
                                            setValidateTel(prevData => ({...prevData, hasFeedback: true}));
                                            if (!validateTel.isVerify) {
                                                return Promise.reject('Tel is not verify!');
                                            }
                                        } else {
                                            setValidateTel(prevData => ({...prevData, hasFeedback: false}));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input onChange={_ => setValidateTel(prevData => ({...prevData, isVerify: false}))} placeholder='เบอร์โทร' maxLength={10}/>
                        </Form.Item>
                    </Col>
                    <Col span={12} className={styles["btn-form-action"]}>
                        <Button 
                            onClick={onClickVerityTel}
                            className={`${styles["btn-form"]} ${styles["gw-btn-confirm"]} ${styles["gw-w-100"]}`} 
                            htmlType="button">
                            <div>
                                <span>Verify number</span>
                            </div>
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            className={`${styles["gw-form-input"]}`}
                            name="email"
                            rules={[{ required: true, type: "email", message: 'Please input!' }]}
                        >
                            <Input placeholder='อีเมล' maxLength={250}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            className={`${styles["gw-form-input"]}`}
                            name="company"
                            // rules={[{ required: true, message: 'Please input!' }]}
                        >
                            <Input placeholder='ชื่อบริษัท' maxLength={250}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <label className={`${styles["gw-text-title"]}`}>
                            <span>รูปถ่ายตัวเองคู่บัตรประชาชน</span>
                        </label>
                        <Image
                            width={10}
                            style={{ display: 'none' }}
                            src={imagePreview1}
                            preview={{
                                visible: visiblePreview1,
                                src: imagePreview1,
                                onVisibleChange: value => {
                                    setVisiblePreview1(value);
                                },
                            }}
                        />
                        <Form.Item
                            className={`gw-mt-8 ${styles["gw-form-upload"]}`}
                            name="citizen_user_image"
                            getValueFromEvent={getFileListPhoto}
                            valuePropName="file"
                            // rules={[
                            //     ({ getFieldValue }) => ({
                            //         validator(_, value) {
                            //             if (fileListPhoto1.length < 1) {
                            //                 return Promise.reject('Please upload more than 1 images!');
                            //             } else if (fileListPhoto1.length > 30) {
                            //                 return Promise.reject('Upload upto 30 images!');
                            //             }
                            //             return Promise.resolve();
                            //         },
                            //     }),
                            // ]}
                        >
                            <Upload
                                action={false}
                                listType="picture-card"
                                fileList={fileListPhoto1}
                                onChange={e => onChangeUploadPhotos(e, setFileListPhoto1)}
                                onPreview={e => onPreviewPhoto(e, 1)}
                                customRequest={customRequest}
                                accept={"image/png, image/gif, image/jpeg"}
                            >
                                {fileListPhoto1.length < 1 && uploadButtonPhotos}
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <label className={`${styles["gw-text-title"]}`}>
                            <span>รูปถ่ายบัตรประชาชน</span>
                        </label>
                        <Image
                            width={10}
                            style={{ display: 'none' }}
                            src={imagePreview2}
                            preview={{
                                visible: visiblePreview2,
                                src: imagePreview2,
                                onVisibleChange: value => {
                                    setVisiblePreview2(value);
                                },
                            }}
                        />
                        <Form.Item
                            className={`gw-mt-8 ${styles["gw-form-upload"]}`}
                            name="citizen_image"
                            getValueFromEvent={getFileListPhoto}
                            valuePropName="file"
                            // rules={[
                            //     ({ getFieldValue }) => ({
                            //         validator(_, value) {
                            //             if (fileListPhoto2.length < 1) {
                            //                 return Promise.reject('Please upload more than 1 images!');
                            //             } else if (fileListPhoto2.length > 30) {
                            //                 return Promise.reject('Upload upto 30 images!');
                            //             }
                            //             return Promise.resolve();
                            //         },
                            //     }),
                            // ]}
                        >
                            <Upload
                                action={false}
                                listType="picture-card"
                                fileList={fileListPhoto2}
                                onChange={e => onChangeUploadPhotos(e, setFileListPhoto2)}
                                onPreview={e => onPreviewPhoto(e, 2)}
                                customRequest={customRequest}
                                accept={"image/png, image/gif, image/jpeg"}
                            >
                                {fileListPhoto2.length < 1 && uploadButtonPhotos}
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <label className={`${styles["gw-text-title"]}`}>
                            <span>รูปโปรไฟล์</span>
                        </label>
                        <Image
                            width={10}
                            style={{ display: 'none' }}
                            src={imagePreview3}
                            preview={{
                                visible: visiblePreview3,
                                src: imagePreview3,
                                onVisibleChange: value => {
                                    setVisiblePreview3(value);
                                },
                            }}
                        />
                        <Form.Item
                            className={`gw-mt-8 ${styles["gw-form-upload"]}`}
                            name="avatar"
                            getValueFromEvent={getFileListPhoto}
                            valuePropName="file"
                            // rules={[
                            //     ({ getFieldValue }) => ({
                            //         validator(_, value) {
                            //             if (fileListPhoto3.length < 1) {
                            //                 return Promise.reject('Please upload more than 1 images!');
                            //             } else if (fileListPhoto3.length > 30) {
                            //                 return Promise.reject('Upload upto 30 images!');
                            //             }
                            //             return Promise.resolve();
                            //         },
                            //     }),
                            // ]}
                        >
                            <Upload
                                action={false}
                                listType="picture-card"
                                fileList={fileListPhoto3}
                                onChange={e => onChangeUploadPhotos(e, setFileListPhoto3)}
                                onPreview={e => onPreviewPhoto(e, 3)}
                                customRequest={customRequest}
                                accept={"image/png, image/gif, image/jpeg"}
                            >
                                {fileListPhoto3.length < 1 && uploadButtonPhotos}
                            </Upload>
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Row gutter={[24, 35]} justify='space-between' className={styles["gw-btn-actions"]}>
                    <Col>
                        <Button
                            className={`${styles["btn-form"]} ${styles["gw-btn-clear"]}`}
                            htmlType="button" onClick={onClickBack}>
                            <div>
                                <span>Cancel</span>
                            </div>
                        </Button>
                    </Col>
                    <Col>
                        {
                            <Button
                                className={`${styles["btn-form"]} ${styles["gw-btn-confirm"]}`}
                                htmlType={"submit"}
                            >
                                <div>
                                    <span>{"Submit"}</span>
                                </div>
                            </Button>
                        }
                    </Col>
                </Row>
            </Form>
            {/* <div gutter={[24, 24]} className={`gw-mt-32 ${styles["gw-content"]}`}>
                contetn
            </div> */}
        </MyGrowMain>
    );
};

export default MyGrow1ProfileEdit;
