import styles from './style.module.scss';
import { Button, Col, Divider, Image, Modal, Row } from "antd";
import { imgfallback } from '../../utils/common';
import { ReactComponent as FacebookNegative } from '../../assets/icons/pages/Mygrow/Facebook - Negative.svg';
import { ReactComponent as PhoneFill } from '../../assets/icons/pages/Mygrow/Phone_fill.svg';
import { ReactComponent as CreditCardFill } from '../../assets/icons/pages/Mygrow/Credit card_fill.svg';
import { ReactComponent as AppleNegative } from '../../assets/icons/pages/Mygrow/Apple - Negative.svg';
import { ReactComponent as Menu1AgentSvg } from '../../assets/icons/pages/Mygrow/menu-1-Agent.svg';
import { ReactComponent as Menu2GroupSvg } from '../../assets/icons/pages/Mygrow/menu-2-Group.svg';
import { ReactComponent as Menu3Signal2Svg } from '../../assets/icons/pages/Mygrow/menu-3-signal 2.svg';
import { ReactComponent as Menu4PinLightSvg } from '../../assets/icons/pages/Mygrow/menu-4-Pin_light.svg';
import { ReactComponent as Menu5StarLightSvg } from '../../assets/icons/pages/Mygrow/menu-5-Star_light.svg';
import { ReactComponent as Menu6ViewLightSvg } from '../../assets/icons/pages/Mygrow/menu-6-View_light.svg';
import { ReactComponent as Menu7SettingLineLightSvg } from '../../assets/icons/pages/Mygrow/menu-7-Setting_line_light.svg';
import { ReactComponent as Menu8QuestionLightSvg } from '../../assets/icons/pages/Mygrow/menu-8-Question_light.svg';
import { ReactComponent as MenuFile } from '../../assets/icons/pages/Mygrow/icons8-file.svg';
import addRingLight from '../../assets/icons/pages/Mygrow/Add_ring_light.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../Routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as services from '../../services/service.auth';
import { useAuthContext } from '../../context/authentication';
import growestate from '../../assets/images/GrowEstate.AI-2.png';
import ios from '../../assets/images/ios.png';
import android from '../../assets/images/android.webp';
import qr_android from '../../assets/images/QR_POP_GOOGLE.png';
import qr_ios from '../../assets/images/QR_POP_APPLE.png';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as IconBack } from '../../assets/icons/pages/findProertyDetail/arrow_left_back.svg';

const MyGrowMain = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data: auth } = useAuthContext();
    const splitPath = location.pathname.split("/").filter(item => item);
    const searchPath = `/${splitPath.slice(0, 2).join("/")}`;

    const [isShowAddBtn, setIsShowAddBtn] = useState(true);
    const [labelAddBtn, setLabelAddBtn] = useState("Create");
    const [pageName, setPageName] = useState("");
    const [userData, SetUserData] = useState({}); 
    useEffect(() => {
        let _pathname = `${location.pathname}`;
        const token = services.getCookie(services.tokenRFFieldName);
        if (!token) {
            if (_pathname === RoutePath.my_grow_policy.path) return; 
            if (!auth?.isAuthentication && _pathname !== RoutePath.my_grow_get_help.path) {
                navigate(RoutePath.my_grow_get_help.path);
            }
        }
    }, [auth?.isAuthentication, location.pathname, navigate]);

    useEffect(() => {
        let _pathname = `${location.pathname}`;
        switch (_pathname) {
            case RoutePath.my_grow_profile.path:
            case RoutePath.my_grow_sell_property.path:
            case RoutePath.my_grow_broadcast.path:
                setLabelAddBtn("Create");
                break;
            case RoutePath.my_grow_pin_location.path:
                setLabelAddBtn("ปักหมุด");
                break;
            default:
                setIsShowAddBtn(false);
                break;
        }
        
        switch (_pathname) {
            case RoutePath.my_grow_profile.path:
            case RoutePath.my_grow_profile_edit.path:
                setPageName("profile");
                break;
            case RoutePath.my_grow_sell_property.path:
                setPageName("sell_property");
                break;
            case RoutePath.my_grow_broadcast.path:
            case RoutePath.my_grow_broadcast_create.path:
            case RoutePath.my_grow_broadcast_detail.path:
                setPageName("broadcast");
                break;
            case RoutePath.my_grow_pin_location.path:
            case RoutePath.my_grow_pin_location_list.path:
            case RoutePath.my_grow_pin_location_create.path:
                setPageName("pin_location");
                break;
            case RoutePath.my_grow_favorite.path:
                setPageName("favorite");
                break;
            case RoutePath.my_grow_recently_view.path:
                setPageName("recently_view");
                break;
            case RoutePath.my_grow_policy.path:
                setPageName("policy");
                break;
            case RoutePath.my_grow_setting.path:
                setPageName("setting");
                break;
            case RoutePath.my_grow_get_help.path:
            case RoutePath.my_grow_report_problem.path:
                setPageName("get_help");
                break;

            default:
                break;
        }

        return () => {
        }
    }, [location.pathname]);

    const onNavigateAddBtn = useCallback(
        () => {
            switch (searchPath) {
                case RoutePath.my_grow_profile.path:
                    navigate(RoutePath.propmarket_my_property_create.path);
                    break;
                case RoutePath.my_grow_sell_property.path:
                    navigate(RoutePath.propmarket_my_property_create.path);
                    break;
                case RoutePath.my_grow_broadcast.path:
                    navigate(RoutePath.my_grow_broadcast_create.path);
                    break;
                case RoutePath.my_grow_pin_location.path:
                    navigate(RoutePath.my_grow_pin_location_create.path);
                    break;
                case RoutePath.my_grow_favorite.path:

                    break;
                case RoutePath.my_grow_recently_view.path:

                    break;
                case RoutePath.my_grow_setting.path:

                    break;
                case RoutePath.my_grow_get_help.path:

                    break;

                default:
                    break;
            }
        },
        [navigate, searchPath],
    );

    const onClickNavigateMenuMobile = (action) => {
        setShowContent(true);
        localStorage.setItem("showContent_grow", true)
        switch (action) {
            case "profile":
                navigate(RoutePath.my_grow_profile.path);
                break;
            case "sell_property":
                navigate(RoutePath.my_grow_sell_property.path);
                break;
            case "broadcast":
                navigate(RoutePath.my_grow_broadcast.path);
                break;
            case "pin_location":
                navigate(RoutePath.my_grow_pin_location.path);
                break;
            case "favorite":
                navigate(RoutePath.my_grow_favorite.path);
                break;
            case "recently_view":
                navigate(RoutePath.my_grow_recently_view.path);
                break;
            case "policy":
                navigate(RoutePath.my_grow_policy.path);
                break;
            case "setting":
                navigate(RoutePath.my_grow_setting.path);
                break;
            case "get_help":
                navigate(RoutePath.my_grow_get_help.path);
                break;

            default:
                break;
        }
    }

    const onClickNavigateMenu = (action) => {
        switch (action) {
            case "profile":
                navigate(RoutePath.my_grow_profile.path);
                break;
            case "sell_property":
                navigate(RoutePath.my_grow_sell_property.path);
                break;
            case "broadcast":
                navigate(RoutePath.my_grow_broadcast.path);
                break;
            case "pin_location":
                navigate(RoutePath.my_grow_pin_location.path);
                break;
            case "favorite":
                navigate(RoutePath.my_grow_favorite.path);
                break;
            case "recently_view":
                navigate(RoutePath.my_grow_recently_view.path);
                break;
            case "policy":
                navigate(RoutePath.my_grow_policy.path);
                break;
            case "setting":
                navigate(RoutePath.my_grow_setting.path);
                break;
            case "get_help":
                navigate(RoutePath.my_grow_get_help.path);
                break;

            default:
                break;
        }
    }

    const triggerLoad = useMemo(() => {
        return `${auth?.isAuthentication}${new Date().valueOf()}`;
    }, [auth?.isAuthentication])

    useEffect(() => {
        async function loadData() {
            try {
                const response = await services.fetchUserProfile();
                if (response?.data?.data) {
                    SetUserData(response?.data?.data);
                }
            } catch (error) {
                console.log("error", error)
            }
        }
        if (auth?.isAuthentication) {
            loadData();
        }
    }, [auth?.isAuthentication, triggerLoad]);

    const getUserData = useMemo(() => {
        return userData;
    }, [userData])


    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [showContent, setShowContent] = useState(localStorage.getItem("showContent_grow") || false);

    const onClikeNavigateBack = () => {
        setShowContent(false);
        localStorage.removeItem("showContent_newsfeed");
    };

    const isShowButtonBack = () => {
        if (["/my-grow/broadcast/detail", "/my-grow/pin-location-list"].includes(location.pathname)) {
            return false;
        }
        return true;
    }

    const [isModalOpen, setIsModalOpen] = useState("");
  
    const showModal = (value) => {
        setIsModalOpen(value);
    };

    const handleCancel = () => {
        setIsModalOpen("");
    };

    if (isMobile) {
        return (
            <div className={`${styles["navigate-container"]}`}>
                {
                    isShowAddBtn && (
                        <div className={`${styles["btn-navigate-action"]}`}>
                            <Button shape="circle" onClick={onNavigateAddBtn}>
                                <img src={addRingLight} alt="" />
                                <span>{labelAddBtn}</span>
                            </Button>
                        </div>
                    )
                }
                <div className={`gw-container gw-mt-32`}>
                    <Row gutter={[24, 0]}>
                        <Col span={showContent ? 0 : 24}>
                            <div className={styles["gw-text-header"]}>
                                <span className={styles["gw-text-title"]}>My Grow</span>
                                <span className={styles["gw-text-detail"]}>Growing Together</span>
                            </div>
                            <div className={styles["gw-menu-sidebar"]}>
                                {
                                    auth?.isAuthentication && <>
                                        <div className={styles["gw-user-info"]}>
                                            <div className={styles["gw-image-profile"]}>
                                                <Image
                                                    preview={false}
                                                    referrerPolicy='no-referrer'
                                                    src={getUserData?.avatar || ""}
                                                    fallback={imgfallback} />
                                            </div>
                                            <div className={styles["gw-user-details"]}>
                                                <div className={styles["gw-user-name"]}>
                                                    <span>{getUserData?.display_name || ""}</span>
                                                </div>
                                                <div className={styles["gw-user-contact"]}>
                                                    {/* <Button
                                                        className={`${styles["gw-btn-circle"]} ${styles["gw-active"]}`}
                                                        className={`${styles["gw-btn-circle"]}`}
                                                        shape="circle" icon={<FacebookNegative />} />
                                                    <Button
                                                        className={`${styles["gw-btn-circle"]}`}
                                                        shape="circle" icon={<PhoneFill />} />
                                                    <Button
                                                        className={`${styles["gw-btn-circle"]}`}
                                                        shape="circle" icon={<AppleNegative />} /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <Divider />
                                    </>
                                }
                                <div className={styles["gw-menu-list"]}>
                                    {
                                        auth?.isAuthentication && <>
                                            <div className={`gw-btn-link ${styles["gw-menu-item"]}`} onClick={() => onClickNavigateMenuMobile("profile")}>
                                                <div className={styles["gw-icon"]}>
                                                    <Menu1AgentSvg />
                                                </div>
                                                <div className={styles["gw-label"]}>
                                                    <span>Profile</span>
                                                </div>
                                            </div>
                                            <div className={`gw-btn-link ${styles["gw-menu-item"]}`} onClick={() => onClickNavigateMenuMobile("sell_property")}>
                                                <div className={styles["gw-icon"]}>
                                                    <Menu2GroupSvg />
                                                </div>
                                                <div className={styles["gw-label"]}>
                                                    <span>Sell Property</span>
                                                </div>
                                            </div>
                                            <div className={`gw-btn-link ${styles["gw-menu-item"]} ${styles["gw-premium"]}`} onClick={() => onClickNavigateMenuMobile("broadcast")}>
                                                <div className={styles["gw-icon"]}>
                                                    <Menu3Signal2Svg />
                                                </div>
                                                <div className={styles["gw-label"]}>
                                                    <span>Broadcast</span>
                                                </div>
                                            </div>
                                            <div className={`gw-btn-link ${styles["gw-menu-item"]} ${styles["gw-premium"]}`} onClick={() => onClickNavigateMenuMobile("pin_location")}>
                                                <div className={styles["gw-icon"]}>
                                                    <Menu4PinLightSvg />
                                                </div>
                                                <div className={styles["gw-label"]}>
                                                    <span>Pin Location</span>
                                                </div>
                                            </div>
                                            <div className={`gw-btn-link ${styles["gw-menu-item"]}`} onClick={() => onClickNavigateMenuMobile("favorite")}>
                                                <div className={styles["gw-icon"]}>
                                                    <Menu5StarLightSvg />
                                                </div>
                                                <div className={styles["gw-label"]}>
                                                    <span>Favorite</span>
                                                </div>
                                            </div>
                                            <div className={`gw-btn-link ${styles["gw-menu-item"]}`} onClick={() => onClickNavigateMenuMobile("recently_view")}>
                                                <div className={styles["gw-icon"]}>
                                                    <Menu6ViewLightSvg />
                                                </div>
                                                <div className={styles["gw-label"]}>
                                                    <span>Recently View</span>
                                                </div>
                                            </div>
                                            <div className={`gw-btn-link ${styles["gw-menu-item"]}`} onClick={() => onClickNavigateMenuMobile("setting")}>
                                                <div className={styles["gw-icon"]}>
                                                    <Menu7SettingLineLightSvg />
                                                </div>
                                                <div className={styles["gw-label"]}>
                                                    <span>Setting</span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className={`gw-btn-link ${styles["gw-menu-item"]}`} onClick={() => onClickNavigateMenuMobile("policy")}>
                                        <div className={styles["gw-icon"]}>
                                            <MenuFile />
                                        </div>
                                        <div className={styles["gw-label"]}>
                                            <span>Policy</span>
                                        </div>
                                    </div>
                                    <div className={`gw-btn-link ${styles["gw-menu-item"]}`} onClick={() => onClickNavigateMenuMobile("get_help")}>
                                        <div className={styles["gw-icon"]}>
                                            <Menu8QuestionLightSvg />
                                        </div>
                                        <div className={styles["gw-label"]}>
                                            <span>Get Help</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={showContent ? 24 : 0} className={styles["gw-content"]}>
                            {showContent && isShowButtonBack() && (
                                <Row gutter={[24, 24]} style={{ marginTop: 10, marginBottom: 10 }}>
                                    <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                                        <div>
                                            <div className={`gw-btn-link gw-btn-danger`} onClick={onClikeNavigateBack}>
                                                <IconBack />
                                                <span>Back</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>)}
                            {props?.children}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
    return (
        <div className={`${styles["navigate-container"]}`}>
            {
                isShowAddBtn && (
                    <div className={`${styles["btn-navigate-action"]}`}>
                        <Button shape="circle" onClick={onNavigateAddBtn}>
                            <img src={addRingLight} alt="" />
                            <span>{labelAddBtn}</span>
                        </Button>
                    </div>
                )
            }
            <div className={`gw-container gw-mt-32`}>
                <Row gutter={[24, 0]}>
                    <Col span={6}>
                        <div className={styles["gw-text-header"]}>
                            <span className={styles["gw-text-title"]}>My Grow</span>
                            <span className={styles["gw-text-detail"]}>Growing Together</span>
                        </div>
                        <div className={styles["gw-menu-sidebar"]}>
                            {
                                auth?.isAuthentication && <>
                                    <div className={styles["gw-user-info"]}>
                                        <div className={styles["gw-image-profile"]}>
                                            <Image
                                                preview={false}
                                                referrerPolicy='no-referrer'
                                                src={getUserData?.avatar || ""}
                                                fallback={imgfallback} />
                                        </div>
                                        <div className={styles["gw-user-details"]}>
                                            <div className={styles["gw-user-name"]}>
                                                <span>{getUserData?.display_name || ""}</span>
                                            </div>
                                            <div className={styles["gw-user-contact"]}>
                                                {/* <Button
                                                    className={`${styles["gw-btn-circle"]} ${styles["gw-active"]}`}
                                                    className={`${styles["gw-btn-circle"]}`}
                                                    shape="circle" icon={<FacebookNegative />} />
                                                <Button
                                                    className={`${styles["gw-btn-circle"]}`}
                                                    shape="circle" icon={<PhoneFill />} />
                                                <Button
                                                    className={`${styles["gw-btn-circle"]}`}
                                                    shape="circle" icon={<AppleNegative />} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                </>
                            }
                            <div className={styles["gw-menu-list"]}>
                                {
                                    auth?.isAuthentication && <>
                                        <div className={`gw-btn-link ${styles["gw-menu-item"]} ${pageName === "profile" && styles["active"]}`} onClick={() => onClickNavigateMenu("profile")}>
                                            <div className={styles["gw-icon"]}>
                                                <Menu1AgentSvg />
                                            </div>
                                            <div className={styles["gw-label"]}>
                                                <span>Profile</span>
                                            </div>
                                        </div>
                                        <div className={`gw-btn-link ${styles["gw-menu-item"]} ${pageName === "sell_property" && styles["active"]}`} onClick={() => onClickNavigateMenu("sell_property")}>
                                            <div className={styles["gw-icon"]}>
                                                <Menu2GroupSvg />
                                            </div>
                                            <div className={styles["gw-label"]}>
                                                <span>Sell Property</span>
                                            </div>
                                        </div>
                                        <div className={`gw-btn-link ${styles["gw-menu-item"]} ${styles["gw-premium"]} ${pageName === "broadcast" && styles["active"]}`} onClick={() => onClickNavigateMenu("broadcast")}>
                                            <div className={styles["gw-icon"]}>
                                                <Menu3Signal2Svg />
                                            </div>
                                            <div className={styles["gw-label"]}>
                                                <span>Broadcast</span>
                                            </div>
                                        </div>
                                        <div className={`gw-btn-link ${styles["gw-menu-item"]} ${styles["gw-premium"]} ${pageName === "pin_location" && styles["active"]}`} onClick={() => onClickNavigateMenu("pin_location")}>
                                            <div className={styles["gw-icon"]}>
                                                <Menu4PinLightSvg />
                                            </div>
                                            <div className={styles["gw-label"]}>
                                                <span>Pin Location</span>
                                            </div>
                                        </div>
                                        <div className={`gw-btn-link ${styles["gw-menu-item"]} ${pageName === "favorite" && styles["active"]}`} onClick={() => onClickNavigateMenu("favorite")}>
                                            <div className={styles["gw-icon"]}>
                                                <Menu5StarLightSvg />
                                            </div>
                                            <div className={styles["gw-label"]}>
                                                <span>Favorite</span>
                                            </div>
                                        </div>
                                        <div className={`gw-btn-link ${styles["gw-menu-item"]} ${pageName === "recently_view" && styles["active"]}`} onClick={() => onClickNavigateMenu("recently_view")}>
                                            <div className={styles["gw-icon"]}>
                                                <Menu6ViewLightSvg />
                                            </div>
                                            <div className={styles["gw-label"]}>
                                                <span>Recently View</span>
                                            </div>
                                        </div>
                                        <div className={`gw-btn-link ${styles["gw-menu-item"]} ${pageName === "setting" && styles["active"]}`} onClick={() => onClickNavigateMenu("setting")}>
                                            <div className={styles["gw-icon"]}>
                                                <Menu7SettingLineLightSvg />
                                            </div>
                                            <div className={styles["gw-label"]}>
                                                <span>Setting</span>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className={`gw-btn-link ${styles["gw-menu-item"]} ${pageName === "policy" && styles["active"]}`} onClick={() => onClickNavigateMenu("policy")}>
                                    <div className={styles["gw-icon"]}>
                                        <MenuFile />
                                    </div>
                                    <div className={styles["gw-label"]}>
                                        <span>Policy</span>
                                    </div>
                                </div>
                                <div className={`gw-btn-link ${styles["gw-menu-item"]} ${pageName === "get_help" && styles["active"]}`} onClick={() => onClickNavigateMenu("get_help")}>
                                    <div className={styles["gw-icon"]}>
                                        <Menu8QuestionLightSvg />
                                    </div>
                                    <div className={styles["gw-label"]}>
                                        <span>Get Help</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={12} className={styles["gw-content"]}>
                        {props?.children}
                    </Col>
                    <Col span={6}>
                        <div className={`gw-container ${styles["gw-container"]}`}>
                            <div className={styles["gw-img-container"]}>
                                <img className={styles["gw-img"]} src={growestate} alt="growestate" />
                                <div className={styles["gw-block-footer"]}>
                                    <img onClick={_ => showModal("IOS")} className={styles["gw-img-2"]} src={ios} alt="growestate" />
                                    <img onClick={_ => showModal("ANDROID")} className={styles["gw-img-3"]} src={android} alt="growestate" />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal title={isModalOpen === "ANDROID" ? "Growestate Android App" : "Growestate iOS App"} visible={isModalOpen} onCancel={handleCancel} footer={null}>
                {
                    isModalOpen === "ANDROID"
                        ? <img style={{width: "100%"}} src={qr_android} alt="growestate" />
                        : <img style={{width: "100%"}} src={qr_ios} alt="growestate" />
                }
            </Modal>
        </div>
    )
}

export default MyGrowMain;