import { Image } from "antd";
import { imgfallback } from "../../utils/common";

const ImageCustom = ({ src = "", ...props }) => {
    if (!(src || "").includes("//")) {
        src = imgfallback;
    }

    return (
        <Image
            {...props}
            preview={false}
            referrerPolicy='no-referrer'
            src={src || imgfallback}
            fallback={imgfallback} />
    )
}

export default ImageCustom;