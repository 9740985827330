import styles from './style.module.scss';
import MyGrowMain from "../MyGrow";
import { Button, Col, Input, Row } from 'antd';
import { ReactComponent as S1UserBoxLight } from '../../assets/icons/pages/Mygrow/s-1-User_box_light.svg';
import { ReactComponent as S2LockLight } from '../../assets/icons/pages/Mygrow/s-2-Lock_light.svg';
import { ReactComponent as S3WalletLight } from '../../assets/icons/pages/Mygrow/s-3-Wallet_light.svg';
import { ReactComponent as S4BellLight } from '../../assets/icons/pages/Mygrow/s-4-Bell_light.svg';
import { ReactComponent as S5Languages } from '../../assets/icons/pages/Mygrow/s-5-languages 1.svg';
import { ReactComponent as S6PinAltLight } from '../../assets/icons/pages/Mygrow/s-6-Pin_alt_light.svg';
import { ReactComponent as S7DesktopLight } from '../../assets/icons/pages/Mygrow/s-7-desktop_light.svg';
import { eraseCookie, fetchLogout, tokenFieldName } from '../../services/service.auth';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../Routes';
import { useAuthContext } from '../../context/authentication';
import { useDispatch } from 'react-redux';
import { updateUserData } from '../../redux/action.user';

const MyGrow7Setting = (props) => {
    const dispatch = useDispatch();
    const { data: authData, action: authAction } = useAuthContext();
    const navigate = useNavigate();
    const actionLogout = () => {
        fetchLogout().then(response => {
            eraseCookie(tokenFieldName);
            window.location.href = `${RoutePath.find_property_map.path}`;
            authAction.updateAuth({
                isAuthentication: false
            });
            dispatch(updateUserData({}));
        });
    }
    return (
        <MyGrowMain>
            <div className={`gw-text-title`}>
                <span>Setting</span>
            </div>
            <Row gutter={[16, 16]} className={`gw-mt-24`}>
                <Col span={12}>
                    <Row gutter={[16, 16]}>
                        {/* <Col span={24}>
                            <Button className={`${styles["gw-btn-option"]}`}>
                                <div className={styles["gw-icon"]}>
                                    <S1UserBoxLight />
                                </div>
                                <div className={styles["gw-label"]}>
                                    <span>Account information </span>
                                </div>
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Button className={`${styles["gw-btn-option"]}`}>
                                <div className={styles["gw-icon"]}>
                                    <S2LockLight />
                                </div>
                                <div className={styles["gw-label"]}>
                                    <span>Password and Security</span>
                                </div>
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Button className={`${styles["gw-btn-option"]}`}>
                                <div className={styles["gw-icon"]}>
                                    <S3WalletLight />
                                </div>
                                <div className={styles["gw-label"]}>
                                    <span>Payment</span>
                                </div>
                            </Button>
                        </Col> */}
                        <Col span={24}>
                            <Button className={`${styles["gw-btn-option"]}`} onClick={actionLogout}>
                                <div className={styles["gw-icon"]}>
                                    <S1UserBoxLight />
                                </div>
                                <div className={styles["gw-label"]}>
                                    <span>Log out</span>
                                </div>
                            </Button>
                        </Col>
                    </Row>
                </Col>
                {/* <Col span={12}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Button className={`${styles["gw-btn-option"]}`}>
                                <div className={styles["gw-icon"]}>
                                    <S4BellLight />
                                </div>
                                <div className={styles["gw-label"]}>
                                    <span>Notification</span>
                                </div>
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Button className={`${styles["gw-btn-option"]}`}>
                                <div className={styles["gw-icon"]}>
                                    <S5Languages />
                                </div>
                                <div className={styles["gw-label"]}>
                                    <span>Language and Region</span>
                                </div>
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Button className={`${styles["gw-btn-option"]}`}>
                                <div className={styles["gw-icon"]}>
                                    <S6PinAltLight />
                                </div>
                                <div className={styles["gw-label"]}>
                                    <span>Location</span>
                                </div>
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Button className={`${styles["gw-btn-option"]}`}>
                                <div className={styles["gw-icon"]}>
                                    <S7DesktopLight />
                                </div>
                                <div className={styles["gw-label"]}>
                                    <span>Media</span>
                                </div>
                            </Button>
                        </Col>
                    </Row>
                </Col> */}
            </Row>
        </MyGrowMain>
    )
}

export default MyGrow7Setting;