import React, { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Badge, Button, Divider, Dropdown, Image, Input, Menu, Tooltip } from 'antd';
import logo from '../../assets/icons/logo/logo-text.png';
import logoM from '../../assets/icons/logo/logo_m.png';
import { ReactComponent as Icon1 } from '../../assets/icons/header/1-Pin_alt_duotone.svg';
import { ReactComponent as Icon2 } from '../../assets/icons/header/2-File_dock_duotone.svg';
import { ReactComponent as Icon3 } from '../../assets/icons/header/3-pin_duotone.svg';
import { ReactComponent as Icon4 } from '../../assets/icons/header/4-User_box_duotone.svg';
import { ReactComponent as IcoBadgeNotification } from '../../assets/icons/header/ico-badge-notification.svg';
import { ReactComponent as IcoBadgeMassenger } from '../../assets/icons/header/ico-badge-massenger.svg';
import { ReactComponent as NotificationHeader } from '../../assets/icons/header/notification-header.svg';
import { ReactComponent as NotificationIcClock } from '../../assets/icons/header/notification-ic_clock.svg';
import { ReactComponent as NotificationIco1 } from '../../assets/icons/header/notification-ico-1.svg';
import { ReactComponent as NotificationIco2 } from '../../assets/icons/header/notification-ico-2.svg';
import { ReactComponent as NotificationIco3 } from '../../assets/icons/header/notification-ico-3.svg';
import { ReactComponent as SearchAltDuotone } from '../../assets/icons/header/search_alt_duotone.svg';
import { useAuthContext } from '../../context/authentication';
import { useModalContext } from '../../context/modals';
import { filterEvent, imgfallback } from '../../utils/common';
import { RoutePath } from '../../Routes';
import { getBasename } from '../../App';
import $ from 'jquery';
import OverlayNotificationList from './overlayNotification';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUserProfile } from '../../services/service.auth';
import { updateUserData } from '../../redux/action.user';
import firebaseService from '../../services/firebase.service';
import { updateCountNewMessage, updateCountNewNotification, updateRecentMessages } from '../../redux/action.message';
import { prepareSnapshot } from '../../utils/common';
import ImageCustom from '../ImageCustom';
import { getAutocomplete } from '../../services/service.property';
import SimpleBar from 'simplebar-react';
import { updatePropertyMapList } from '../../redux/action.property';
import * as services from '../../services/service.header';
import { useMediaQuery } from 'react-responsive';

const { Search } = Input;


let keyLocalSearchKeyword = "search_keyword";
let statusVisibleMenu = {
    idle: "IDLE",
    show_keyword: "KEYWORD",
    show_search_value: "SEARCH_VALUE"
};

let setTimeoutSearch;

const HeaderComponent = () => {
    // const { userData } = useSelector((state) => state.user);
    const [userData, setUserData] = useState({});
    const refInput = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { data: auth } = useAuthContext();
    const { action: modalAction } = useModalContext();
    const [toggleMenu, setToggleMenu] = useState(false);
    const [valueSearch, setValueSearch] = useState("");
    const [activeTab, setActiveTab] = useState("1");
    const [searchKeyword, setSearchKeyword] = useState([]);
    const [statusVMenu, setStatusVMenu] = useState(statusVisibleMenu.idle);
    const [autocompleteList, setAutocompleteList] = useState([]);
    const dispatch = useDispatch()
    const [locationFindProperty, setLocationFindProperty] = useState(RoutePath.find_property_map.path)

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [showSearch, setShowSearch] = useState(false);


    useEffect(_ => {
        async function loadNotificaionData() {
            const responseDataProperty = await services.fetchNotificationsCount();
            dispatch(updateCountNewNotification(responseDataProperty?.data?.data?.count || 0));
        }
        
        try {
            const localKeyword = localStorage.getItem(keyLocalSearchKeyword)
            if (localKeyword) {
                setSearchKeyword(JSON.parse(localKeyword))
            }
            loadNotificaionData()
        } catch (error) {
            console.error(error)
        }
    }, []);

    useEffect(
        () => {
            if ([RoutePath.find_property_list.path, RoutePath.find_property_map.path].some(sitem => sitem === location.pathname)) {
                setLocationFindProperty(() => {
                    return (location.pathname === RoutePath.find_property_list.path
                        ? RoutePath.find_property_list.path
                        : RoutePath.find_property_map.path)
                })
            }
            return () => {}
        },
        [location]
    )

    const onClickLogin = () => {
        modalAction.updateModalLogin(true);
    }

    const LayoutIsNotLogin = () => {
        return (
            <div className={`${styles["gw-btn-action"]}`}>
            {
                isMobile && !showSearch && (
                    <Button 
                        onClick={() => {
                            setShowSearch(true)
                        }}
                        className={`${styles["gw-btn-circle"]}`} 
                        shape="circle" icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <circle cx="11" cy="11" r="6" stroke="#333333" strokeWidth="1.2"/>
                        <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#DD1508" strokeWidth="1.2" strokeLinecap="round"/>
                        <path d="M20 20L17 17" stroke="#333333" strokeWidth="1.2" strokeLinecap="round"/>
                        </svg>} />
                )
            }
            {
                isMobile && showSearch && (
                    <Button 
                        onClick={() => {
                            setShowSearch(false)
                        }}
                        className={`${styles["gw-btn-circle"]}`} 
                        shape="circle" icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>} />
                )
            }
                <Button className={`${styles["gw-btn-login"]}`} type="primary" onClick={onClickLogin}>Login</Button>
            </div>
        )
    }

    const LayoutIsLogin = ({
        isMobile = false
    }) => {
        const { countNewMessage = 0, countNewNotification = 0 } = useSelector(state => state.message);
        const dispatch = useDispatch();
        const getPopupContainer = () => {
            return document.getElementById("header-dropdown-container")
        }
        return (
            <div className={`${styles["gw-btn-action"]} ${styles["is-login"]}`}>
                {
                    isMobile && !showSearch && (
                        <Button 
                            onClick={() => {
                                setShowSearch(true)
                            }}
                            className={`${styles["gw-btn-circle"]}`} 
                            shape="circle" icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <circle cx="11" cy="11" r="6" stroke="#333333" strokeWidth="1.2"/>
                            <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#DD1508" strokeWidth="1.2" strokeLinecap="round"/>
                            <path d="M20 20L17 17" stroke="#333333" strokeWidth="1.2" strokeLinecap="round"/>
                            </svg>} />
                    )
                }
                {
                    isMobile && showSearch && (
                        <Button 
                            onClick={() => {
                                setShowSearch(false)
                            }}
                            className={`${styles["gw-btn-circle"]}`} 
                            shape="circle" icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>} />
                    )
                }
                <Dropdown 
                    // visible={true}
                    getPopupContainer={getPopupContainer} 
                    placement='bottomRight' 
                    overlayClassName={`${styles["gw-dropdown-notificaion"]}`} 
                    overlay={notificationMenu} 
                    trigger={['click']}>
                    <Badge count={countNewNotification}>
                        <Button 
                            className={`${styles["gw-btn-circle"]}`} 
                            shape="circle" icon={<IcoBadgeNotification />} />
                    </Badge>
                </Dropdown>
                <Dropdown onVisibleChange={(visible) => {
                    if (visible) {
                        dispatch(updateCountNewMessage(0));
                    }
                }} getPopupContainer={getPopupContainer} placement='bottomRight' overlayClassName={styles["gw-dropdown-messenger"]} overlay={messengerMenu} trigger={['click']}>
                    <Badge count={countNewMessage}>
                        <Button className={`${styles["gw-btn-circle"]}`} shape="circle" icon={<IcoBadgeMassenger />} />
                    </Badge>
                </Dropdown>
            </div>
        )
    }

    const HeaderMenu = ({
        type,
        labelEN,
        labelTH
    }) => {
        return (
            <div className={styles["gw-header-menu"]}>
                <div className={styles["gw-left"]}>
                    <span className={styles["gw-text-title"]}>{labelEN}</span>
                    <span className={styles["gw-text-descrioption"]}>{labelTH}</span>
                </div>
                <div className={styles["gw-right"]}>
                    {
                        type === "notificaion" && (
                            <div>
                                <NotificationHeader />
                            </div>
                        )
                    }

                </div>
            </div>
        )
    }

    const FooterMenu = ({link}) => {
        return (
            <div className={`gw-btn-link ${styles["gw-footer-menu"]}`} onClick={() => {
                if (!link) return;
                navigate(link)
            }}>
                <span>ดูทั้งหมด</span>
            </div>
        )
    }

    const notificationMenu = () => {
        return (
            <Menu>
                <Menu.ItemGroup 
                    title={<HeaderMenu type="notification" labelEN="Notification" labelTH="การแจ้งเตือน" />} className={styles["gw-block-header-menu"]}>
                    <Menu.Divider />
                    <OverlayNotificationList />
                </Menu.ItemGroup>
            </Menu>
        )
    };
    const ListRecentMessage = () => {
        const { recentMessages = [] } = useSelector(state => state.message);
        const recentMessageList = useMemo(() => {
            const MessageItem = ({ dataKey, dataItem }) => {
                const onClickNavigateChat = () => {
                    let uid = userData.uid;
                    let sendToUID = dataItem.toId;
                    if (dataItem.toId === uid) {
                        sendToUID = dataItem.fromId;
                    }
                    navigate({
                        pathname: RoutePath.page_chat_detail.path,
                        search: `?user_id=${sendToUID}`
                    });
                }
                return (
                    <Menu.Item eventKey={`ev_menu_${dataKey}`} key={`menu_${dataKey}`} className={`${styles['gw-menu-item']}`} onClick={onClickNavigateChat}>
                        <div className={styles['gw-menu-item-img']}>
                            <ImageCustom src={dataItem.profileImageUrl} />
                        </div>
                        <div className={styles['gw-menu-item-description']}>
                            <div className={styles['gw-user-info']}>
                                <span className={styles["gw-text-username"]}>{dataItem.display_name}</span>
                                <span className={styles["gw-text-message"]}>{dataItem.text}</span>
                            </div>
                            <div className={styles['gw-user-notification']}>
                                <span className={styles['gw-date-time']}>{dayjs().to(dayjs(dataItem.timestamp))}</span>
                            </div>
                        </div>
                    </Menu.Item>
                )
            }
    
            return [...recentMessages].map((dataItem, dataIndex) => {
                const key = `chat_${dataIndex}`;
                return <MessageItem key={key} dataItem={dataItem} dataKey={key}/>
            })
    
        }, [recentMessages]); 

        return(<>
            {recentMessageList}
        </>)
    }

    const messengerMenu = () => {
        const SubHeaderMenu = () => {
            return (
                <div className={styles["gw-sub-header-menu"]}>
                    <Input placeholder="ค้นหา" prefix={<SearchAltDuotone />} />
                </div>
            )
        }
        return (
            <Menu>
                <Menu.ItemGroup title={<HeaderMenu type="messanger" labelEN="Chat" labelTH="การสนทนา" />} className={styles["gw-block-header-menu"]}>
                    <Menu.Divider />
                    <Menu.ItemGroup title={<SubHeaderMenu />}>
                        <ListRecentMessage />
                    </Menu.ItemGroup>
                </Menu.ItemGroup>
                <Menu.Divider />
                <Menu.ItemGroup title={<FooterMenu link={RoutePath?.page_chat_detail?.path} />} className={styles["gw-block-footer-menu"]}></Menu.ItemGroup>
            </Menu>
        )
    }

    const quickMenu = (
        <Menu className='menu-keyword'>
            <Menu.ItemGroup title="Key Word">
                {
                    searchKeyword.map((item, index) => {
                        return (<Menu.Item key={index} onClick={() => onClickKeyword(item)}><span>{item}</span></Menu.Item>)
                    })
                }
            </Menu.ItemGroup>
        </Menu>
    );

    const searchMenu = (
        <Menu className='gw-menu-toggle'>
            {
                !autocompleteList.length && (
                    <Menu.Item key={-1} className="search-item" disabled>
                        <span className='text'>ไม่พบข้อมูล</span>
                    </Menu.Item>
                )
            }
            {
                autocompleteList.map((item, index) => (
                    <Menu.Item key={index} className="search-item" onClick={_ => onClickItemSearch(item.text)}>
                        <Tooltip trigger={["hover"]} title={item.text} mouseLeaveDelay={0.1}>
                            <span className='text'>{item.text}</span>
                            <span className='type'>{item.type}</span>
                        </Tooltip>
                    </Menu.Item>
                ))
            }
        </Menu>
    );

    const onFocusSearch = () => {
        setToggleMenu(true);
        if (valueSearch) {
            setStatusVMenu(statusVisibleMenu.show_search_value);
        } else {
            setStatusVMenu(statusVisibleMenu.show_keyword);
        }
    }

    $(document).on("mouseup", function (e) {
        let container = $(`.gw-menu-toggle`);
        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            setToggleMenu(false)
            setStatusVMenu(statusVisibleMenu.idle)
        }
    });

    const onBlurSearch = () => {
        // setToggleMenu(false)
        // setStatusVMenu(statusVisibleMenu.idle)
    }

    const onClickKeyword = (value) => {
        if (!value) return;
        refInput.current.setValue(value);
        setValueSearch(value);
        setToggleMenu(false);
        setStatusVMenu(statusVisibleMenu.idle);
        onSubmitSearch(value);
    }

    const onClickItemSearch = (value) => {
        if (!value) return;
        refInput.current.setValue(value);
        setValueSearch(value);
        setToggleMenu(false);
        setStatusVMenu(statusVisibleMenu.idle);
        onSetSearchKeyword(value);
        onSubmitSearch(value);
    }

    const onSearch = (value, is_set_keyword = true) => {
        if (!value) return;
        if (is_set_keyword) {
            onSetSearchKeyword(value);
        }
        onGetAutocomplete(value);
        setStatusVMenu(statusVisibleMenu.show_search_value);
    }

    const onSubmitSearch = (value) => {
        if (!value) return;
        onSetSearchKeyword(value);
        dispatch(updatePropertyMapList({
            list: [],
            meta: {}
        }));
        navigate({
            pathname: RoutePath.find_property_map.path,
            search: `?${filterEvent.encodeFilter({
                places_name: value
            })}`
        });
        setToggleMenu(false);
        setStatusVMenu(statusVisibleMenu.idle);
    }

    const onSetSearchKeyword = (_value) => {
        if (!_value) return;
        let value = _value || valueSearch;
        let oldSearchKeywordStr = localStorage.getItem(keyLocalSearchKeyword)
        let searchKeywordList = [];
        try {
            if (oldSearchKeywordStr) {
                searchKeywordList = JSON.parse(oldSearchKeywordStr) || [];
            }
        } catch (error) {
            console.error(error)
        } finally {
            searchKeywordList = searchKeywordList.filter(item => item !== value);
            searchKeywordList.unshift(value);
            searchKeywordList = searchKeywordList.slice(0, 100);
            localStorage.setItem(keyLocalSearchKeyword, JSON.stringify(searchKeywordList));
            setSearchKeyword(searchKeywordList);
        }
    }

    useEffect(() => {
        Object.entries(RoutePath).map(([rKey, rItem]) => {
            if (rItem.path === location.pathname || rItem.path === getBasename(location.pathname)) {
                setActiveTab(rItem?.props?.active_tab || "1");
                return "";
            }
            return "";
        })
    }, [location]);

    const onChangeSearch = (value) => {
        setValueSearch(value);

        if (setTimeoutSearch) clearTimeout(setTimeoutSearch);
        setTimeoutSearch = setTimeout(function () {
            if (`${value || ""}`.length > 1) {
                onSearch(`${value || ""}`.trim(), false);
            } else {
                setStatusVMenu(statusVisibleMenu.show_keyword);
            }
        }, 500);
    }

    const onGetAutocomplete = async (value) => {
        try {
            const response = await getAutocomplete(value);
            setAutocompleteList(response?.data?.data || []);
        } catch (error) {
            console.log(error)
        }
    }

    const visibleSearchMenu = () => {
        // !!(toggleMenu && valueSearch)
        return statusVMenu === statusVisibleMenu.show_search_value;
    }

    const visibleQuickMenu = () => {
        // !!(toggleMenu && !valueSearch)
        return statusVMenu === statusVisibleMenu.show_keyword;
    }

    if (isMobile) {
        return (
            <>
                <header className={`${styles["gw-header"]}`}>
                    <div className={"gw-container"} id="header-dropdown-container">
                        {
                            isMobile && !showSearch &&
                            <Link onClick={_ => window.location.href = `${RoutePath.find_property_map.path}`} style={{ marginRight: "auto" }}>
                                <div className={`${styles["gw-logo"]}`}>
                                    <img src={logoM} alt='logo' />
                                </div>
                            </Link>
                        }
                        {
                            isMobile && showSearch && (
                                <div className={styles["gw-header-search-mobile"]} style={{ marginRight: "auto", width: "100%" }}>
                                    <Dropdown
                                        getPopupContainer={triggerNode => triggerNode.parentElement}
                                        overlayClassName={`${styles["gw-dropdown-searchmenu"]}`}
                                        overlay={searchMenu} visible={visibleSearchMenu()} trigger={[]}>
                                        <Dropdown
                                            getPopupContainer={triggerNode => triggerNode.parentElement}
                                            overlayClassName={`${styles["gw-dropdown-quickmenu"]}`}
                                            overlay={quickMenu} visible={visibleQuickMenu()} trigger={[]}>
                                            <Search
                                                ref={refInput}
                                                onBlur={onBlurSearch}
                                                onFocus={onFocusSearch}
                                                onChange={e => onChangeSearch(e.target.value)}
                                                className={`gw-menu-toggle ${styles["gw-input-search"]}`}
                                                placeholder="แขวง, เขต, จังหวัด"
                                                onSearch={onSubmitSearch}
                                                onPressEnter={e => onSubmitSearch(e.target.value)}
                                            />
                                        </Dropdown>
                                    </Dropdown>
                                </div>
                            )
                        }
                        {auth?.isAuthentication ? <LayoutIsLogin isMobile={isMobile} /> : <LayoutIsNotLogin isMobile={isMobile} />}
                    </div>
                </header>
                
                <footer className={`${styles["gw-footer"]}`}>
                    <div className={`gw-container gw-footer-container ${styles["gw-list-btn-link"]}`} >
                        <Link onClick={_ => {
                            if (location.pathname === RoutePath.find_property_list.path) {
                                navigate(`${locationFindProperty}${location.search}`)
                            } else {
                                window.location.href = `${locationFindProperty}${location.search}`;
                            }
                        }} className={`${styles["gw-btn-link"]} ${activeTab === "1" && styles["active"]}`}>
                            <div className="gw-footer-menu">
                                <Icon1 />
                                <span>Property</span>
                            </div>
                        </Link>
                        <Link to={RoutePath.news_feed_root.path} className={`${styles["gw-btn-link"]} ${activeTab === "2" && styles["active"]}`}>
                            <div className="gw-footer-menu">
                                <Icon2 />
                                <span>News Feed</span>
                            </div>
                        </Link>
                        <Link to={RoutePath.propmarket.path} className={`${styles["gw-btn-link"]} ${activeTab === "3" && styles["active"]}`}>
                            <div className="gw-footer-menu">
                                <Icon3 />
                                <span>Propmarket</span>
                            </div>
                        </Link>
                        <Link to={RoutePath.my_grow_profile.path} className={`${styles["gw-btn-link"]} ${activeTab === "4" && styles["active"]}`}>
                            <div className="gw-footer-menu">
                                <Icon4 />
                                <span>My Grow</span>
                            </div>
                        </Link>
                    </div>
                </footer>
            </>
        )
    }
    return (
        <>
            <header className={`${styles["gw-header"]}`}>
                <div className={"gw-container"} id="header-dropdown-container">
                    <Link onClick={_ => window.location.href = `${RoutePath.find_property_map.path}`}>
                        <div className={`${styles["gw-logo"]}`}>
                            <img src={logo} alt='logo' />
                        </div>
                    </Link>
                    <div>
                        {/* <Dropdown overlayClassName={`${styles["gw-dropdown-searchmenu"]}`} overlay={searchMenu} visible={!!(toggleMenu && valueSearch)} trigger={[]}>
                            <Dropdown overlayClassName={`${styles["gw-dropdown-quickmenu"]}`} overlay={quickMenu} visible={!!(toggleMenu && !valueSearch)} trigger={[]}> */}
                        <Dropdown 
                            getPopupContainer = {triggerNode => triggerNode.parentElement}
                            overlayClassName={`${styles["gw-dropdown-searchmenu"]}`} 
                            overlay={searchMenu} visible={visibleSearchMenu()} trigger={[]}>
                            <Dropdown 
                                getPopupContainer = {triggerNode => triggerNode.parentElement}
                                overlayClassName={`${styles["gw-dropdown-quickmenu"]}`} 
                                overlay={quickMenu} visible={visibleQuickMenu()} trigger={[]}>
                                <Search
                                    ref={refInput}
                                    onBlur={onBlurSearch}
                                    onFocus={onFocusSearch}
                                    onChange={e => onChangeSearch(e.target.value)}
                                    className={`gw-menu-toggle ${styles["gw-input-search"]}`}
                                    placeholder="แขวง, เขต, จังหวัด"
                                    onSearch={onSubmitSearch}
                                    onPressEnter={e => onSubmitSearch(e.target.value)}
                                />
                            </Dropdown>
                        </Dropdown>
                    </div>
                    <div className={`${styles["gw-list-btn-link"]}`}>
                        <Link onClick={_ => {
                            if (location.pathname === RoutePath.find_property_list.path) {
                                navigate(`${locationFindProperty}${location.search}`)
                            } else {
                                window.location.href = `${locationFindProperty}${location.search}`;
                            }
                        }} className={`${styles["gw-btn-link"]} ${activeTab === "1" && styles["active"]}`}>
                            <div className={`${styles["gw-link-text"]}`}>
                                <Icon1 />
                                <span>Find Property</span>
                            </div>
                        </Link>
                        <Link to={RoutePath.news_feed_root.path} className={`${styles["gw-btn-link"]} ${activeTab === "2" && styles["active"]}`}>
                            <div className={`${styles["gw-link-text"]}`}>
                                <Icon2 />
                                <span>News Feed</span>
                            </div>
                        </Link>
                        <Link to={RoutePath.propmarket.path} className={`${styles["gw-btn-link"]} ${activeTab === "3" && styles["active"]}`}>
                            <div className={`${styles["gw-link-text"]}`}>
                                <Icon3 />
                                <span>Propmarket</span>
                            </div>
                        </Link>
                        <Link to={RoutePath.my_grow_profile.path} className={`${styles["gw-btn-link"]} ${activeTab === "4" && styles["active"]}`}>
                            <div className={`${styles["gw-link-text"]}`}>
                                <Icon4 />
                                <span>My Grow</span>
                            </div>
                        </Link>
                    </div>
                    {auth?.isAuthentication ? <LayoutIsLogin /> : <LayoutIsNotLogin />}
                </div>
            </header>
            <div className={styles["gw-block-blank"]}></div>
        </>
    )
}

export default HeaderComponent;