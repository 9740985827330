import styles from './style.module.scss';
import { Button, Col, Drawer, Image, Input, Row, Upload } from "antd";
import { customRequest, filterEvent, imgfallback, resizeFile, showPrice, toBase64, validateNotLoadMore } from "../../utils/common";
import { ReactComponent as PriceTag } from '../../assets/icons/pages/findProertyDetail/price_tag.svg';
import { ReactComponent as Reply } from '../../assets/icons/pages/findProertyDetail/reply.svg';
import { ReactComponent as CmImgBoxLight } from '../../assets/icons/pages/findProertyDetail/cm_img_box_light.svg';
import { ReactComponent as Cm21HomeLight } from '../../assets/icons/pages/findProertyDetail/cm_2_1_home_light.svg';
import { ReactComponent as Cm22AddSquare } from '../../assets/icons/pages/findProertyDetail/cm_2_2_add_square.svg';
import { ReactComponent as CmHappyLight } from '../../assets/icons/pages/findProertyDetail/cm_happy_light.svg';
import { ReactComponent as CmSend } from '../../assets/icons/pages/findProertyDetail/cm_send.svg';
import * as services from '../../services/service.property';
import { useEffect, useMemo, useState } from 'react';
import firebaseService from '../../services/firebase.service';
import dayjs from 'dayjs';
import stylesDrawer from '../../components/MapFilter/style.module.scss';
import { ReactComponent as CloseRoundDuotone } from '../../assets/icons/pages/findProertyMap/close_round_duotone.svg';
import { useFilterContext } from '../../context/filter';
import { useLoadingListContext } from '../../context/loaddingList';
import { useLocation, useNavigate } from 'react-router-dom';
import CardItemSecondary from '../CardItemSecondary';
import { usePromiseTracker } from 'react-promise-tracker';
import InfiniteScroll from 'react-infinite-scroller';
import LoadMoreElement from '../LoadMore';
import { useAuthContext } from '../../context/authentication';
import { fetchUserProfile } from '../../services/service.auth';
import { RoutePath } from '../../Routes';

const CommentComponentBroadcast = ({
    inputCommentRef,
    dataItem = {},
    isNotShowComment = true
}) => {
    const navigate = useNavigate();
    const [comments, setComments] = useState([]);
    const [replyComent, setReplyComment] = useState(null);
    const [contentComment, setContentComment] = useState({});
    const { data: auth } = useAuthContext();
    const [userData, SetUserData] = useState({}); 

    const { promiseInProgress } = usePromiseTracker();
    const location = useLocation();
    const {data: filterData, action: filterAction} = useFilterContext();
    const {action: loadingListAction} = useLoadingListContext();
    const [showSelectCard, SetShowSelectCard] = useState(false);
    const [listSelectPlace, setListSelectPlace] = useState({
        datas: [],
        meta: {}
    });
    const [loadMorePage, setLoadMorePage] = useState(1);
    
    const closeGrid = (dataSelect) => {
        SetShowSelectCard(false);
        if (dataSelect?.property?.p_id) {
            setContentComment({
                type: 3,
                dataItem: dataSelect,
                attach_property_id: dataSelect?.property?.p_id
            });
        }
    }
    
    const showGrid = () => {
        SetShowSelectCard(true);
    }

    const afterVisibleChange = (event) => {
        if (event) {
            const filterSearchClean = filterEvent.decodeFilter(location);
            filterAction.updateData(filterSearchClean);
            loadDataProperty({...filterSearchClean}, false);
        } else {
            setListSelectPlace({
                datas: [],
                meta: {}
            });
            setLoadMorePage(1);
        }
    }

    useEffect(function () {
        if (loadMorePage !== 1 && Object.keys(filterData.filterSearchObj || {}).length > 0) {
            loadDataProperty({ ...filterData.filterSearchObj, page: loadMorePage }, true);
        }
        return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMorePage]);
    
    const loadDataProperty = async (filterBody = {}, loadMore = false) => {
        try {
            loadingListAction.updateData(true);
            const responseDataProperty = await services.fetchPropertyMyProperty(filterBody);
            const responseItems = responseDataProperty?.data?.data?.items || [];
            const responseMeta = responseDataProperty?.data?.data?.meta || {};
            if (responseDataProperty?.data?.data) {
                setListSelectPlace(function (prevData) {
                    return {
                        ...prevData,
                        meta: {...responseMeta}
                    }
                });
                setListSelectPlace(function (prevData) {
                    return {
                        ...prevData,
                        datas: [...(loadMore ? prevData.datas : []), ...responseItems].map(function (item) {
                            return {...item}
                        })
                    }
                });
            } else {
                throw Error("no data")
            }
        } catch (error) {
            console.log(error)
        } finally {
            loadingListAction.updateData(false);
        }
    }

    const loadMoreData = () => {
        if (!promiseInProgress && validateNotLoadMore(listSelectPlace.meta, loadMorePage)) {
            setLoadMorePage(prevData => {
                return prevData + 1;
            });
        }
    }

    useEffect(() => {
        async function loadData() {
            try {
                const response = await fetchUserProfile();
                if (response?.data?.data) {
                    SetUserData(response?.data?.data);
                }
            } catch (error) {
                console.log("error", error)
            }
        }
        if (auth?.isAuthentication) {
            loadData();
        }
        return () => {}
    }, [auth?.isAuthentication]);

    const prepareSnapshot = (items) => {
        let datas = [];

        items.docs.forEach((item) => {
            let id = item.id;
            let data = item.data();
            datas.push({
                key: id,
                id: id,
                ...data
            });
        });

        return datas;
    };

    useEffect(() => {
        if (!`${dataItem.bc_id}`) return;
        const unsubscribeComment = firebaseService.getBCComments(`${dataItem.bc_id}`).onSnapshot(_qs => {
            _qs.ref.collection("/comments").onSnapshot(qs => setComments(prepareSnapshot(qs)))
        });

        return () => {
            unsubscribeComment();
        }
    }, [dataItem?.bc_id]);

    const CommentItem = ({ dataItem = {}, isReply = false }) => {
        const [replyComments, setReplyComments] = useState([]);

        useEffect(() => {
            if (isReply) return;
            if (!dataItem.bc_comment_id) return;
        
            const unsubscribeReplyComment = firebaseService.getBCReplyComments(`${dataItem.bc_comment_id}`).onSnapshot(_qs => {
                _qs.ref.collection("/comments").onSnapshot(qs => setReplyComments(prepareSnapshot(qs)))
            });
    
            return () => {
                unsubscribeReplyComment();
            }
        }, [dataItem, isReply]);

        return (
            <div className='gw-item-comment' key={`item_comment_${dataItem.key}`}>
                <div className='gw-comment-detail'>
                    <Image
                        preview={false}
                        referrerPolicy='no-referrer'
                        className='gw-image-profile'
                        src={dataItem?.create_user?.avatar || "error"}
                        fallback={imgfallback} />
                    <div className='gw-comment-content'>
                        <div className='gw-user-info'>
                            <div className='gw-username'>
                                <span>{dataItem?.create_user?.display_name || ""}</span>
                            </div>
                            <div className='gw-block-dot'>
                                <svg className='gw-dot' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="50" cy="50" r="50" />
                                </svg>
                            </div>
                            <div className='gw-date'>
                                {
                                    dataItem?.created_at?.toDate &&
                                    dayjs().to(dayjs(dataItem?.created_at?.toDate()))}
                            </div>
                        </div>
                        <div className='gw-comment-value'>
                            {`${dataItem.comment_type}` === "1" && <CommentValueType1 dataItem={dataItem} />}
                            {`${dataItem.comment_type}` === "2" && <CommentValueType2 dataItem={dataItem} />}
                            {`${dataItem.comment_type}` === "3" && <CommentValueType3 dataItem={dataItem} />}
                        </div>
                        {
                            !isReply ? (
                                <div className='gw-action-comment'>
                                    <div className='gw-btn-reply' onClick={_ => { setReplyComment(dataItem) }}>
                                        <Reply />
                                        <span>ตอบกลับ</span>
                                    </div>
                                </div>
                                
                            ) : null
                        }
                        {
                            replyComments.map(item => <CommentItem isReply={true} key={`comment_item_${item.key}`} dataItem={item} />)
                        }
                    </div>
                </div>
            </div>
        )
    }

    const CommentValueType1 = ({ dataItem = {} }) => {
        return (
            <div className='gw-comment-type-1' key={`comment_type_1_${dataItem.key}`}>
                <span>{dataItem.message || ""}</span>
            </div>
        )
    }

    const CommentValueType2 = ({ dataItem = {} }) => {
        return (
            <div className='gw-comment-type-2' key={`comment_type_2_${dataItem.key}`}>
                <Image
                    className='gw-image-location'
                    src={dataItem?.file || 'error'}
                    fallback={imgfallback} />
            </div>
        )
    }

    const CommentValueType3 = ({ dataItem = {}, isPreview = false }) => {
        let image = "error"
        let title = "";
        let price = 0;
        let ref_p_id = "";
        if (isPreview) {
            const { propertyImages, advertisement, cost, p_id } = dataItem?.property || {};
            image = [...propertyImages || []][0]?.path || "no image";
            title = advertisement || "";
            price = cost || 0;
            ref_p_id = p_id;
        } else {
            const { advertisement, cost, image: ap_image, p_id} = dataItem?.attach_property || {};
            image = ap_image;
            title = advertisement || "";
            price = cost || 0;
            ref_p_id = p_id;
        }

        const onNavigateComment = () => {
            window.open(`${RoutePath.find_property_detail.path}?p_id=${ref_p_id}`);
        }

        return (
            <div className='gw-comment-type-3' key={`comment_type_3_${dataItem.key}`}>
                <Image
                    preview={false}
                    referrerPolicy='no-referrer'
                    className='gw-image-location'
                    src={image}
                    fallback={imgfallback} />
                <div className='gw-comment-value-detail'>
                    <span className='title' onClick={onNavigateComment}>{title}</span>
                    <div className='price'>
                        <PriceTag />
                        <span className='value'>{showPrice(price)}</span>
                        <span className='unit'>บาท</span>
                    </div>
                </div>
            </div>
        )
    }

    const onSendComment = async () => {
        const value = `${inputCommentRef?.current?.state?.value || ""}`.trim();
        let request = {
            comment_type: 0,
            message: "",
            attach_property_id: "",
            file: ""
        }
        if (contentComment.type === 2) {
            request.comment_type = 2;
            request.file = contentComment?.file?.fileUpload?.file_id;
            if (!request.file) return;
        } else if (contentComment.type === 3) {
            request.comment_type = 3;
            request.attach_property_id = contentComment?.dataItem?.property?.p_id;
            if (!request.attach_property_id) return;
        } else {
            request.comment_type = 1;
            request.message = value;
            if (!value) return;
        }
        if (!dataItem.bc_id) return;

        if (replyComent) {
            if (replyComent?.bc_comment_id) {
                try {
                    const response = await services.updateBCCommentReply(replyComent?.bc_comment_id, request);
                    inputCommentRef?.current?.setValue("");
                    setReplyComment(null);
                    setContentComment({});
                } catch (error) {
                    console.log("coment err", error)
                }
            }
        } else {
            try {
                const response = await services.updateBCComment(`${dataItem.bc_id}`, request);
                inputCommentRef?.current?.setValue("");
                setContentComment({});
            } catch (error) {
                console.log("coment err", error)
            }
        }
    }

    const elemComments = useMemo(() => {
        return comments.map(item => <CommentItem setReplyComment={setReplyComment} key={`comment_item_${item.bc_comment_id}`} dataItem={item} />)
    }, [comments]); 

    const onChangeUploadPhotos = ({ fileList: newFileList }) => {
        inputCommentRef?.current?.setValue("");
        let lastestFile = newFileList[newFileList.length - 1];
        if (lastestFile?.originFileObj) {
            resizeFile(lastestFile?.originFileObj).then(img => {
                lastestFile["originFileObj"] = img;
                lastestFile["size"] = img?.size || 0;
                lastestFile["type"] = img?.type;
                lastestFile["lastModified"] = img?.lastModified;
                lastestFile["lastModifiedDate"] = img?.lastModifiedDate;
                services.fetchUploadImage(img).then(async function (responeImage) {
                    lastestFile["fileUpload"] = responeImage?.data?.data || {};
                    let fileBase64 = null;
                    try {
                        fileBase64 = await toBase64(lastestFile.originFileObj);
                    } catch (error) {
                        
                    } finally {
                        lastestFile["fileBase64"] = fileBase64;
                        setContentComment({
                            type: 2,
                            file: lastestFile
                        });
                    }
                })
            })
        }
    };
    
    return (<>
        <div className={`${styles["gw-comments"]}`}>
            <div className='gw-detail-title'>
                <span>Comment</span>
            </div>
            <div className='gw-list-comment gw-mt-16'>
                {
                    comments.length === 0 
                    ? <span className='gw-no-data'>ไม่มีคอมเมนต์</span>
                    : null
                }
                {elemComments}
                {/* <Comments comments={comments} setReplyComment={setReplyComment} /> */}
            </div>
                {
                    !!userData.uid && (
                    <div className={`gw-comment-input${replyComent ? " has-reply" : ""}`}>
                        <div className='gw-input-addons'>
                            <Upload
                                action={false}
                                fileList={[]}
                                // previewFile={false}
                                // multiple={false}
                                onChange={onChangeUploadPhotos}
                                onPreview={[]}
                                customRequest={customRequest}
                                accept={"image/png, image/gif, image/jpeg"}
                            >
                                <Button>
                                    <CmImgBoxLight />
                                </Button>
                            </Upload>
                            <Button onClick={showGrid}>
                                <Cm21HomeLight />
                                <Cm22AddSquare className='gw-addsquare' />
                            </Button>
                        </div>
                        <div className='gw-input-user-profile'>
                            <Image
                                preview={false}
                                referrerPolicy='no-referrer'
                                className='gw-image-profile'
                                src={userData?.avatar || "error"}
                                fallback={imgfallback} />
                        </div>
                        <div className={`gw-input-box`}>
                            {
                                replyComent ? (
                                    <div className='gw-reply-container'>
                                        <div>
                                            <span>Reply</span>
                                            <div className='gw-user-info'>
                                                <div className='gw-username'>
                                                    <span>{replyComent?.create_user?.display_name || ""}</span>
                                                </div>
                                                <div className='gw-block-dot'>
                                                    <svg className='gw-dot' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="50" cy="50" r="50" />
                                                    </svg>
                                                </div>
                                                <div className='gw-date'>
                                                    {
                                                        replyComent?.created_at?.toDate && 
                                                        dayjs().to(dayjs(replyComent?.created_at?.toDate()))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='gw-reply-action'>
                                            <div className='gw-reply-close' onClick={_ => setReplyComment(null)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                contentComment.type ? <></>
                                    : (
                                        <Input onPressEnter={onSendComment} ref={ref => {
                                            if (inputCommentRef) {
                                                inputCommentRef.current = ref;
                                            }
                                            if (ref && location?.state?.is_message) {
                                                location.state.is_message = false;
                                                setTimeout(() => {
                                                    ref.input.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                                                    ref.focus();
                                                }, 500);
                                            }
                                        }} placeholder='เขียนความคิดเห็น...' suffix={(
                                            <>
                                                {/* <div className='gw-btn-link btn-coin'>
                                        <CmHappyLight />
                                    </div> */}
                                                <div className='gw-btn-link btn-coin'>
                                                    <CmSend onClick={onSendComment} />
                                                </div>
                                            </>
                                        )} />
                                    )
                            }
                            {
                                contentComment.type ? (
                                    <div className='gw-reply-container'>
                                        <div style={{width: "100%"}}>
                                            {
                                                contentComment.type === 2
                                                    ? (
                                                        <Image
                                                            className='gw-comment-preview-image'
                                                            src={contentComment.file.fileBase64 || ""}
                                                            alt="preview" />
                                                    )
                                                    : contentComment.type === 3
                                                        ? (<CommentValueType3 dataItem={contentComment?.dataItem} isPreview={true}/>)
                                                        : <></>
                                            }
                                        </div>
                                        <div className='gw-reply-action'>
                                            <div className='gw-reply-close' onClick={_ => setContentComment({})}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </div>
                                            
                                            <div className='gw-btn-link btn-coin'>
                                                <CmSend onClick={onSendComment}/>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                    )
                }
        </div>
        
        <Drawer
            className={`${stylesDrawer["gw-drawer-filter"]}`}
            headerStyle={{ display: "none" }}
            bodyStyle={{ padding: "0px" }}
            push={true}
            placement="right"
            afterVisibleChange={afterVisibleChange}
            onClose={closeGrid}
            closeIcon={null}
            visible={showSelectCard}>
            <InfiniteScroll
                style={{ padding: "32px 24px 46px 24px" }}
                initialLoad={false}
                loadMore={loadMoreData}
                hasMore={validateNotLoadMore(listSelectPlace.meta, loadMorePage)}
                useWindow={false}
            >
                <div className={`gw-search-resoult-list`}>
                    <div className="gw-search-result">
                        <Row gutter={[0, 16]} className="gw-mt-24">
                            <Col span={24}>
                                <div className="gw-btn-action">
                                    <div className="gw-btn-link" onClick={closeGrid}>
                                        <CloseRoundDuotone />
                                    </div>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="gw-search-result-header">
                                    <span className="gw-result-count">
                                        <span>{listSelectPlace?.meta?.itemCount || 0}</span>
                                        <span>Posts</span>
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]} className="gw-mt-16">
                            {
                                [...listSelectPlace.datas].map((item, index) => {
                                    return (
                                        <CardItemSecondary key={index} dataitem={item} hideSelectPlace={closeGrid} isNotShowComment={isNotShowComment}/>
                                    )
                                })
                            }
                            <LoadMoreElement {...{ dataMeta: listSelectPlace.meta, loadMorePage, loadMoreData }} />
                        </Row>
                    </div>
                </div>
            </InfiniteScroll>
        </Drawer>
    </>)
}

export default CommentComponentBroadcast;