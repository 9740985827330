import { memo, useEffect, useMemo, useState } from 'react';
import { Button, Col, Divider, Image, Row, Select, Spin } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { cleanFilter, filterEvent, imgfallback, prepareFilter, validateNotLoadMore } from '../../utils/common';
import styles from './style.module.scss';
import { RoutePath } from '../../Routes';
import { fetchAnotherPerperty, fetchPerperty, getPropertyAnotherProfile } from '../../services/service.property';
import { useFilterContext } from '../../context/filter';
import LoadMoreElement from '../../components/LoadMore';
import ListElement from '../../components/ListElement';
import InfiniteScroll from 'react-infinite-scroller';
import { usePromiseTracker } from 'react-promise-tracker';
import { Helmet } from 'react-helmet';
import { useLoadingListContext } from '../../context/loaddingList';
import { ReactComponent as H1CheckRingRoundLight } from "../../assets/icons/pages/Mygrow/h-1-check_ring_round_light.svg";
import { ReactComponent as H2InfoLight } from "../../assets/icons/pages/Mygrow/h-2-Info_light.svg";
import { ReactComponent as FacebookNegative } from '../../assets/icons/pages/Mygrow/Facebook - Negative.svg';
import { ReactComponent as PhoneFill } from '../../assets/icons/pages/Mygrow/Phone_fill.svg';
import { ReactComponent as CreditCardFill } from '../../assets/icons/pages/Mygrow/Credit card_fill.svg';
import { ReactComponent as AppleNegative } from '../../assets/icons/pages/Mygrow/Apple - Negative.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as IcoExpandDownLight } from '../../assets/icons/pages/findProertyMap/filter/expand_down_light.svg';
import { ReactComponent as IcoBadgeMassenger } from '../../assets/icons/header/ico-badge-massenger.svg';
import { useSelector } from 'react-redux';
import { ReactComponent as IconBack } from '../../assets/icons/pages/findProertyDetail/arrow_left_back.svg';
import { useMediaQuery } from 'react-responsive';

const AnotherProfile = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const { userData: ownerUserData } = useSelector((state) => state.user)
    const { uid } = useParams();
    const { promiseInProgress } = usePromiseTracker();
    const navigate = useNavigate();
    const location = useLocation();
    const [_dataPropertyList, setDataPropertyList] = useState([]);
    const [dataPropertyMeta, setDataPropertyMeta] = useState({});
    const {data: filterData, action: filterAction} = useFilterContext();
    const [loadMorePage, setLoadMorePage] = useState(1);
    const {action: loadingListAction} = useLoadingListContext();
    const [userData, setUserData] = useState({});

    const dataPropertyList = useMemo(() => {
        return _dataPropertyList;
    }, [_dataPropertyList]);

    useEffect(function () {
        if (loadMorePage !== 1 && Object.keys(filterData.filterSearchObj || {}).length > 0) {
            loadDataProperty({ ...filterData.filterSearchObj, page: loadMorePage }, true);
        }
        return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMorePage]);

    const onChangeSelect = (value) => {
        const filterSearchClean = cleanFilter(prepareFilter({
            sale_status: value
        }))
        filterAction.updateData(filterSearchClean);
        loadDataProperty({ ...filterSearchClean });
    }

    const loadDataProperty = async (requestBody, loadMore = false) => {

        try {
            loadingListAction.updateData(true);
            const responseDataProperty = await fetchAnotherPerperty(requestBody, uid);
            const responseItems = responseDataProperty?.data?.data?.items || [];
            const responseMeta = responseDataProperty?.data?.data?.meta || {};
            if (responseDataProperty?.data?.data) {
                setDataPropertyMeta(function (prevData) {
                    return {...responseMeta}
                });
                setDataPropertyList(function (prevData) {
                    return [...(loadMore ? prevData : []), ...responseItems]
                });
            }else {
                throw Error("No data")
            }
        } catch (error) {
            console.log("error" , error)
            setDataPropertyMeta(function () {
                return {}
            });
            setDataPropertyList(function () {
                return []
            });
        } finally {
            loadingListAction.updateData(false);
        }
    }

    const loadMoreDataProperty = () => {
        if (!promiseInProgress && validateNotLoadMore(dataPropertyMeta, loadMorePage)) {
            setLoadMorePage(prevData => {
                return prevData + 1;
            });
        }
    }

    useEffect(() => {
        async function loadData(uid) {
            const response = await getPropertyAnotherProfile(uid);
            if (response?.data?.data) {
                setUserData(response?.data?.data || {});
            }
        }
        if (uid) {
            window.scrollTo(0, 0);
            loadData(uid);
            
            const filterSearchClean = filterEvent.decodeFilter(location);
            filterAction.updateData(filterSearchClean);
            loadDataProperty({ ...filterSearchClean });
            setLoadMorePage(1);
        }
        return () => {
            setUserData({});
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, uid]);

    const getImageVerify = (image = "") => {
        let icon = <H2InfoLight />;
        let active_cls = "";
        if (image) {
            icon = <H1CheckRingRoundLight />;
            active_cls = styles["gw-item-active"];
        }
        
        return {
            icon,
            active_cls,
            image
        }
    }

    const citizen_image = getImageVerify(userData?.user?.citizen_image);
    const citizen_user_image = getImageVerify(userData?.user?.citizen_user_image);

    const onClickNavigateChat = () => {
        if (userData?.user.uid && !isSelfUser()) {
            navigate(`${RoutePath.page_chat_detail.path}?user_id=${userData?.user.uid}`, {
                state: { created_by: userData?.user },
                preventScrollReset: true
            })
        }
    }

    const isSelfUser = () => {
        return ownerUserData?.uid === userData?.user.uid;
    }

    const onClikeNavigateBack = () => {
        if (window?.history?.state?.key) {
            navigate(-1);
        } else {
            navigate(RoutePath.find_property_map.path, {
                replace: true
            });
        }
    }

    return (
        <div className={`${styles["navigate-container"]}`}>
            <Helmet>
                <title>{`Propmarket Profile`} | Growestate</title>
            </Helmet>
            <div className={`gw-container`}>
                <Row gutter={[24, 24]} className={`gw-mt-32`}>
                    <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                        <div>
                            <div className={`gw-btn-link gw-btn-danger`} onClick={onClikeNavigateBack}>
                                <IconBack />
                                <span>Back</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    !userData.user && (
                        <Row gutter={[24, 24]} className={`gw-mt-16`}>
                        <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                                <div className={styles["gw-header"]}>
                                    <div className={styles["block-top-profile"]} style={{justifyContent: 'center'}}>
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "var(--gw-brand-color-red-1)" }} spin />} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )
                }
                {
                    !!userData?.user && (
                        <>
                            <Row gutter={[24, 24]} className={`gw-mt-16`}>
                                <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                                    <div className={styles["gw-header"]}>
                                        <div className={styles["block-top-profile"]}>
                                            <div className={styles["gw-user-info"]}>
                                                <div className={styles["gw-image-profile"]}>
                                                    <Image
                                                        preview={false}
                                                        referrerPolicy='no-referrer'
                                                        src={userData?.user?.avatar || ""}
                                                        fallback={imgfallback} />
                                                </div>
                                                <div className={styles["gw-user-details"]}>
                                                    <div className={styles["gw-user-name"]}>
                                                        <span>{userData?.user?.display_name || ""}</span>
                                                        <div className='gw-user-action'>
                                                            {
                                                                !isSelfUser() ? (<>
                                                                    <Button className='btn-chat' onClick={onClickNavigateChat}>
                                                                        <IcoBadgeMassenger />
                                                                    </Button>
                                                                </>) : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={styles["gw-user-contact"]}>
                                                        {/* <Button
                                                            className={`${styles["gw-btn-circle"]}`}
                                                            shape="circle" icon={<FacebookNegative />} />
                                                        <Button
                                                            className={`${styles["gw-btn-circle"]}`}
                                                            shape="circle" icon={<PhoneFill />} />
                                                        <Button
                                                            className={`${styles["gw-btn-circle"]}`}
                                                            shape="circle" icon={<AppleNegative />} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles["gw-status"]}>
                                                <div className={styles["gw-group-text"]}>
                                                    <span className={styles["gw-text-count"]}>{userData?.total_property || 0}</span>
                                                    <span className={styles["gw-text-detail"]}>
                                                        รายการอสังหา
                                                    </span>
                                                </div>
                                                <div className={styles["gw-group-text"]}>
                                                    <span className={styles["gw-text-count"]}>{userData?.sold_property || 0}</span>
                                                    <span className={styles["gw-text-detail"]}>
                                                        ที่ขายแล้ว
                                                    </span>
                                                </div>
                                                <div className={styles["gw-group-text"]}>
                                                    <span className={styles["gw-text-count"]}>{userData?.sale_property || 0}</span>
                                                    <span className={styles["gw-text-detail"]}>
                                                        กำลังขาย
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider className="gw-mt-24" />
                                        <div className={styles["gw-description"]}>
                                            <span className={styles["gw-text-title"]}>Description</span>
                                            <span className={styles["gw-text-detail"]}>{userData?.user?.about_me || userData?.about_me || ""}</span>
                                        </div>
                                        <Row gutter={[24, 24]} className={styles["gw-list-document"]}>
                                            {
                                                citizen_image.image && (
                                                    <Col span={12}>
                                                        <div
                                                            className={`${styles["gw-item-document"]} ${citizen_image.active_cls}`}
                                                        >
                                                            <div className={styles["gw-icon"]}>
                                                                {citizen_image.icon}
                                                            </div>
                                                            <div className={styles["gw-label"]}>
                                                                <span>รูปถ่ายบัตรประชาชน</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            }
                                            {
                                                citizen_user_image.image && (
                                                    <Col span={12}>
                                                        <div className={`${styles["gw-item-document"]} ${citizen_user_image.active_cls}`}>
                                                            <div className={styles["gw-icon"]}>
                                                                {citizen_user_image.icon}
                                                            </div>
                                                            <div className={styles["gw-label"]}>
                                                                <span>รูปถ่ายตัวเองคู่กับบัตรประชาชน</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <InfiniteScroll
                                threshold={750}
                                initialLoad={false}
                                loadMore={loadMoreDataProperty}
                                hasMore={validateNotLoadMore(dataPropertyMeta, loadMorePage)}
                            >
                                <Row gutter={[24, 24]} className={`gw-mt-32`}>
                                    <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                                        <div className={`${styles["gw-header-content"]}`}>
                                            <div className={`${styles["gw-header-text"]}`}>
                                                <span className={`${styles["gw-title"]}`}>Property</span>
                                            </div>
                                            <Select
                                                placeholder="เลือก..."
                                                suffixIcon={<IcoExpandDownLight />}
                                                defaultValue=""
                                                onChange={onChangeSelect}
                                            >
                                                <Select.Option value="">ทั้งหมด</Select.Option>
                                                <Select.Option value="sold">ขายแล้ว</Select.Option>
                                                <Select.Option value="sale">รอขาย</Select.Option>
                                            </Select>
                                        </div>
                                    </Col>
                                    <ListElement list={dataPropertyList} type={"DEFAULT_12_6"} isMobile={isMobile}/>
                                    <LoadMoreElement {...{ 
                                        dataMeta: dataPropertyMeta, 
                                        loadMorePage: loadMorePage, 
                                        loadMoreData: loadMoreDataProperty }} />
                                </Row>
                            </InfiniteScroll>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default AnotherProfile;