import stylesSetting from '../MyGrow7Setting/style.module.scss';
import styles from './style.module.scss';
import MyGrowMain from "../MyGrow";
import { Button, Col, Input, Row } from 'antd';
import { ReactComponent as Hg1InfoLight } from '../../assets/icons/pages/Mygrow/hg-1-Info_light.svg';
import { ReactComponent as Hg2QuestionLight } from '../../assets/icons/pages/Mygrow/hg-2-Question_light.svg';
import { ReactComponent as Hg3ChatAlt3Light } from '../../assets/icons/pages/Mygrow/hg-3-Chat_alt_3_light.svg';
import { ReactComponent as Hg4ChieldCheckLight } from '../../assets/icons/pages/Mygrow/hg-4-Chield_check_light.svg';
import { RoutePath } from '../../Routes';
import { useNavigate } from 'react-router-dom';

const MyGrow8GetHelp = (props) => {
    const navigate = useNavigate();
    return (
        <MyGrowMain>
            <div className={`gw-text-title`}>
                <span>Get help</span>
            </div>
            <Row gutter={[16, 16]} className={`gw-mt-24`}>
                <Col span={12}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Button className={`${stylesSetting["gw-btn-option"]}`} onClick={_ => navigate(RoutePath.my_grow_report_problem.path)}>
                                <div className={stylesSetting["gw-icon"]}>
                                    <Hg1InfoLight />
                                </div>
                                <div className={stylesSetting["gw-label"]}>
                                    <span>แจ้งปัญหา</span>
                                </div>
                            </Button>
                        </Col>
                        {/* <Col span={24}>
                            <Button className={`${stylesSetting["gw-btn-option"]}`}>
                                <div className={stylesSetting["gw-icon"]}>
                                    <Hg2QuestionLight />
                                </div>
                                <div className={stylesSetting["gw-label"]}>
                                    <span>Help Center</span>
                                </div>
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Button className={`${stylesSetting["gw-btn-option"]}`}>
                                <div className={stylesSetting["gw-icon"]}>
                                    <Hg3ChatAlt3Light />
                                </div>
                                <div className={stylesSetting["gw-label"]}>
                                    <span>Support Requests</span>
                                </div>
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Button className={`${stylesSetting["gw-btn-option"]}`}>
                                <div className={stylesSetting["gw-icon"]}>
                                    <Hg4ChieldCheckLight />
                                </div>
                                <div className={stylesSetting["gw-label"]}>
                                    <span>Privacy and Security Help</span>
                                </div>
                            </Button>
                        </Col> */}
                    </Row>
                </Col>
            </Row>
        </MyGrowMain>
    )
}

export default MyGrow8GetHelp;