import styles from "./style.module.scss";
import MyGrowMain from "../MyGrow";
import { Button, Col, Form, Input, InputNumber, notification, Row, Select, Slider } from "antd";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as IcExpandMore } from "../../assets/icons/pages/Propmarket/my-property-create/ic_expand_more.svg";
import { ReactComponent as IcUploadPhoto } from "../../assets/icons/pages/Propmarket/my-property-create/ic_add_a_photo.svg";
import { ReactComponent as IcUploadVideo } from "../../assets/icons/pages/Propmarket/my-property-create/ic_video_fill.svg";
import { ReactComponent as PriceTag } from "../../assets/icons/pages/findProertyDetail/price_tag.svg";
import { ReactComponent as SearchAltDuotone } from '../../assets/icons/header/search_alt_duotone.svg';
import MapContainer from "../../components/GMap";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../Routes";
import ModalMap from './ModalMap';
import { fetchPropertyCategory } from "../../services/service.common";
import { useForm } from "antd/lib/form/Form";
import * as services from "../../services/service.pinLocation";
import $ from 'jquery';
import { fetchUploadImage } from "../../services/service.property";

const MyGrow4PinLocationCreate = props => {
    const navigate = useNavigate();
    const [form] = useForm();
    const [mapImage, setMapImage] = useState(null);

    const [masterData, setMasterData] = useState({
        property_category: [],
    });

    useEffect(() => {
        const loadData = async () => {
            Promise.allSettled([
                fetchPropertyCategory(),
            ]).then(function (responses) {
                const [
                    resPropertyCategory,
                ] = responses;
                setMasterData(function (prevData){
                    return {
                        ...prevData,
                        property_category: 
                            (resPropertyCategory?.value?.data?.data || [])
                            .sort((a,b) => (a.cate_id > b.cate_id) ? 1 : ((b.cate_id > a.cate_id) ? -1 : 0)),
                    }
                })
            })
        }
        loadData();
        return () => { };
    }, []);
    const onClickNavigateBack = () => {
        if (window?.history?.state?.key) {
            navigate(-1);
        } else {
            navigate(RoutePath.my_grow_pin_location_list.path, {
                replace: true
            });
        }
        // navigate(RoutePath.my_grow_broadcast.path)
    }
    const [formData, setFormData] = useState({
        area_range: 1000
    });
    const formDataMemo = useMemo(() => formData, [formData]);
    const [areaRange, setAreaRange] = useState(formData.area_range);
    const [visibleModalMap, setVisibleModalMap] = useState(false);
    const [userPinMarker, setUserPinMarker] = useState(null)

    const showModalMap = () => {
        setVisibleModalMap(true);
    }

    const hideModalMap = () => {
        setVisibleModalMap(false);
    }

    const onFinish = async values => {
        const sourceValues = {...values, ...userPinMarker, radius_m: areaRange};
        const dataPrepareValues = prepareData(sourceValues);
        if (validateData(dataPrepareValues)) {
            try {
                try {
                    if (!mapImage) throw Error("no mapImage!")
                    const response = await fetchUploadImage(mapImage);
                    dataPrepareValues.map_image = response?.data?.data?.file_id || "";
                } catch (error) {
                    console.log("Upload map fail!")
                }
                const response = await services.createPinLocation(dataPrepareValues);
                if (response?.data?.success) {
                    notification['success']({
                        message: 'Map',
                        description:
                            'สร้าง pin location สำเร็จ',
                    });
                    if (window?.history?.state?.key) {
                        navigate(-1);
                    } else {
                        navigate(RoutePath.my_grow_pin_location_list.path, {
                            replace: true
                        });
                    }
                } else {
                    throw Error("createPinLocation fail!")
                }
            } catch (error) {
                console.log(error)
                notification['error']({
                    message: 'Broadcast',
                    description:
                        'ไม่สามารถสร้าง broadcast ได้',
                });
            }
        }
    };

    const onFinishFailed = values => {
        console.log("onFinishFailed", values);
    };

    const prepareData = (sourceData = {}) => {
        let result = {};
        result["message"] = sourceData.message || "" //3,
        result["cate_id"] = sourceData.cate_id //3,
        result["address"] = sourceData.address || "" //"หาที่แจ่มๆ",
        result["title"] = sourceData.title || "" //"หาที่แจ่มๆ",
        result["desc"] = sourceData.desc || "" //"string",
        result["cost_min"] = sourceData.cost_min //0,
        result["cost_max"] = sourceData.cost_max //0,
        result["radius_m"] = sourceData.radius_m //500,
        result["lat"] = sourceData.location?.lat || 0 //0,
        result["map_image"] = "" //"string",
        result["lng"] = sourceData.location?.lng || 0 //0,
        result["is_showing"] = true //true
        return result;
    }

    const validateData = (sourceData = {}) => {
        if (!sourceData?.lat || !sourceData?.lng || !sourceData?.radius_m) {
            notification['error']({
                message: 'Map',
                description:
                    'Please set raduis area!',
            });
            return false;
        }
        return true;
    }
    const [countTitle, setCountTitle] = useState(0);
    const [countDesc, setCountDesc] = useState(0);
    const [countAddress, setCountAddress] = useState(0);

    const onChangeTitle = (event) => {
        setCountTitle(`${event?.target?.value || ""}`.length);
    }
    const onChangeDesc = (event) => {
        setCountDesc(`${event?.target?.value || ""}`.length);
    }
    const onChangeAddress = (event) => {
        setCountAddress(`${event?.target?.value || ""}`.length);
    }

    useEffect(() => {
        if (userPinMarker?.location?.lat) {
            $.ajax({
                url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${userPinMarker.location.lat},${userPinMarker.location.lng}&language=th&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
                type: "GET",
                dataType: 'json',
                cache: false,
                success: function (response) {
                    const address_components = [...response?.results || []][0]?.address_components || [];
                    let address = address_components
                        .filter(fitem => [...fitem?.types || []].some(sitem => sitem !== "plus_code"))
                        .map(mitem => mitem.long_name)
                        .join(" ");
                        
                    if (address) {
                        form.setFieldsValue({
                            address
                        });
                        setCountAddress(`${address || ""}`.length);
                    }
                }, 
                error: function (error) {
                    console.log(error)
                }
            });
        }
        return () => {}
    }, [form, userPinMarker])
    
    return (
        <MyGrowMain>
            <div className={`gw-text-title`}>
                <span>Pin Location</span>
            </div>
            <div className={`gw-mt-24 ${styles["gw-block-container"]}`}>
                <Form
                    name="form_data"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    initialValues={formDataMemo}
                    form={form}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <label className={styles["gw-text-title"]}>
                                <span>Pin Location for your own place</span>
                            </label>
                        </Col>
                        {/* <Col span={24} className={styles["gw-form-count"]}>
                            <Form.Item
                                className={`${styles["gw-form-input"]}`}
                                name="title"
                                rules={[{ required: true, message: 'Please input!' }]}
                            >
                                <Input
                                    placeholder={`หัวข้อ`}
                                    onChange={onChangeTitle}
                                />
                            </Form.Item>
                            <span className={styles["gw-count-text"]}>{countTitle}/30 ตัวอักษร</span>
                        </Col>
                        <Col span={24} className={styles["gw-form-count"]}>
                            <Form.Item
                                className={`${styles["gw-form-textarea"]}`}
                                name="desc"
                                rules={[{ required: true, message: 'Please input!' }]}
                            >
                                <Input.TextArea 
                                    placeholder='กรอกรายละเอียด' 
                                    maxLength={1000} 
                                    style={{ height: 120 }} 
                                    onChange={onChangeDesc} />
                            </Form.Item>
                            <span className={styles["gw-count-text"]}>{countDesc}/1000 ตัวอักษร</span>
                        </Col> */}
                        <Col span={24} className={styles["gw-form-count"]}>
                            <Form.Item
                                className={`${styles["gw-form-textarea"]}`}
                                name="address"
                                rules={[{ required: true, message: 'กรุณากำหนดขอบเขต' }]}
                            >
                                <Input.TextArea 
                                    disabled
                                    placeholder='' 
                                    maxLength={1000} 
                                    style={{ height: 120 }} 
                                    onChange={onChangeAddress} />
                            </Form.Item>
                            {/* <span className={styles["gw-count-text"]}>{countAddress}/1000 ตัวอักษร</span> */}
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className={`${styles["gw-form-selector"]}`}
                                name="cate_id"
                                rules={[{ required: true, message: 'Please select!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="ประเภทอสังหา"
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    suffixIcon={<IcExpandMore />}
                                >
                                {
                                    masterData.property_category.map(function (item) {
                                        return <Select.Option
                                            key={item?.cate_id}
                                            value={item?.cate_id}>{item?.cate_name || ""}</Select.Option>
                                    })
                                }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className={`${styles["gw-form-input"]}`}
                                name="cost_min"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject('Please input!');
                                            } else if (getFieldValue("cost_min") > getFieldValue("cost_max")) {
                                                return Promise.reject('ราคา(จาก) ต้องมากกว่า ราคา(ถึง)');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputNumber
                                    prefix={<PriceTag />}
                                    controls={false}
                                    placeholder='ราคา(จาก)'
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className={`${styles["gw-form-input"]}`}
                                name="cost_max"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject('Please input!');
                                            } else if (getFieldValue("cost_min") > getFieldValue("cost_max")) {
                                                return Promise.reject('ราคา(จาก) ต้องมากกว่า ราคา(ถึง)');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputNumber
                                    prefix={<PriceTag />}
                                    controls={false}
                                    placeholder='ราคา(ถึง)'
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <label className={styles["gw-text-title"]}>
                                <span>Location</span>
                            </label>
                        </Col>
                        <Col span={24}>
                            <div className={`${styles["gw-chat-header-search"]}`}>
                                {/* <Input placeholder="ค้นหา" prefix={<SearchAltDuotone />} /> */}
                                <Button
                                    className={`${styles["btn-form"]} ${styles["gw-btn-red"]}`}
                                    onClick={showModalMap}
                                >
                                    <div>
                                        <span>{"กำหนดขอบเขต"}</span>
                                    </div>
                                </Button>
                            </div>
                            <div className={styles["gw-map-broadcast"]}>
                                <MapContainer
                                    isBroadCast={true}
                                    defaultMarker={userPinMarker?.location}
                                    defaultZoom={userPinMarker?.zoom}
                                    defaultValue={{
                                        rangeArea: areaRange
                                    }}
                                />
                            </div>
                        </Col>
                        <Col span={24}>
                            <Row justify='space-between'>
                                <Col>
                                    <Button
                                        // disabled={activeTab <= 1}
                                        className={`${styles["btn-form"]} ${styles["gw-btn-clear"]}`}
                                        htmlType="button" onClick={onClickNavigateBack}>
                                        <div>
                                            <span>Cancel</span>
                                        </div>
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className={`${styles["btn-form"]} ${styles["gw-btn-confirm"]}`}
                                        htmlType={"submit"}
                                    >
                                        <div>
                                            <span>{"Create"}</span>
                                        </div>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </div>
            <ModalMap 
                visibleModalMap={visibleModalMap} 
                hideModalMap={hideModalMap} 
                userPinMarker={userPinMarker} 
                setUserPinMarker={setUserPinMarker}
                formData={formData}
                setAreaRange={setAreaRange}
                setMapImage={setMapImage}
                />
        </MyGrowMain>
    );
};

export default MyGrow4PinLocationCreate;
