const checkModalNews = (payload) => {
    const modalNews = window.localStorage.getItem("modalNews");
    if (modalNews === "LOADED") {
        return false;
    }
    return payload;
}

const setLoadedModalNews = () => {
    window.localStorage.setItem("modalNews", "LOADED")
}

const lastLocation = {
    key: "GW_LAST_LOCATION",
    get() {
        let result = {}
        try {
            result = JSON.parse(localStorage.getItem(this.key) || "{}")
        } catch (error) {
            result = {}
        }
        return result;
    },
    set(body = {}) {
        let result = {}
        try {
            localStorage.setItem(this.key, JSON.stringify(body));
            result = body;
        } catch (error) {
            result = {}
        }
        return result
    }
}

const lastLocationFindProperty = {
    key: "GW_LAST_LOCATION_FIND_PROPERTY",
    get() {
        let result = {}
        try {
            result = JSON.parse(localStorage.getItem(this.key) || "{}")
        } catch (error) {
            result = {}
        }
        return result;
    },
    set(body = {}) {
        let result = {}
        try {
            localStorage.setItem(this.key, JSON.stringify(body));
            result = body;
        } catch (error) {
            result = {}
        }
        return result
    }
}

export  {
    checkModalNews,
    setLoadedModalNews,
    lastLocation,
    lastLocationFindProperty
};