/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Modal } from 'antd';
import styles from './styles.module.scss';
import { ReactComponent as CircleClose } from '../../assets/icons/modal/circle-close.svg';
import logo from '../../assets/icons/logo/logo-text.png';
import logo2 from '../../assets/icons/logo/Growestate-Android-IOS.png';
import background from '../../assets/icons/modal/login/login-background.svg';
import background2 from '../../assets/icons/modal/login/bg.png';
import icoApple from '../../assets/icons/modal/login/ico-apple.svg';
import icoFacebook from '../../assets/icons/modal/login/ico-facebook.svg';
import icoGoogle from '../../assets/icons/modal/login/ico-google.svg';
import { useAuthContext } from '../../context/authentication';
import { useModalContext } from '../../context/modals';
import { signInwithApple, signInwithFacebook, signInWithGoogle } from '../../services/firebase.utils';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { fetchUserLogin, fetchUserRegister, getCookie, tokenRFFieldName, setCookie, tokenFieldName } from '../../services/service.auth';
import { useEffect } from 'react';

const ModalLoginComponent = () => {
    const { data: authData, action: authAction } = useAuthContext();
    const { data: modal, action: modalAction } = useModalContext();

    const handleCancel = () => {
        modalAction.updateModalLogin(false);
    };

    useEffect(() => {
        const token = getCookie(tokenRFFieldName);
        if (token) {
            authAction.updateAuth({
                isAuthentication: true
            });
            modalAction.updateModalLogin(false);
        }
        return () => {

        }
    }, []);

    const actionLonin = (user) => {
        const providerData = (user?.providerData || [])?.[0] || {};
        const user_id = user?.uid || "";
        let bodyData = {}
        switch (providerData.providerId) {
            default:
                bodyData = prepareDataRegister(providerData, user_id);
                break;
        }
        fetchUserRegister(bodyData).then(function (response) {
            const { access_token, refresh_token, expire } = response?.data?.data || {};
            setCookie(tokenFieldName, access_token, refresh_token, expire);
            authAction.updateAuth({
                isAuthentication: true
            });
            modalAction.updateModalLogin(false);
            modalAction.updateDataLogin(true);
        }).catch(function (ex2) {
            console.log(ex2)
        })
    }

    const prepareDataRegister = (sourceData = {}, user_id = "") => {
        const data = {
            "uid": user_id,
            "display_name": sourceData.displayName || "",
            "fname": "",
            "lname": "",
            "email": sourceData.email || "",
            "tel": sourceData.phoneNumber || "",
            "avatar": sourceData.photoURL || ""
        }
        return data;
    }


    const onClickSocialLogin = async (action) => {
        try {
            let response = {};
            switch (action) {
                case "facebook":
                    response = await signInwithFacebook();
                    break;
                case "google":
                    response = await signInWithGoogle();
                    break;
                case "apple":
                    response = await signInwithApple();
                    break;
                default:
                    break;
            }
            if (response?.user) {
                actionLonin(response?.user || {});
            }
        } catch (error) {
            console.log("Authenticate ", error);
        }
    }

    return (
        <>
            <Modal className="gw-modal-container" width={"100%"} visible={modal.isModalLoginShow} footer="" closable={false}>
                <div className='gw-modal-block-close'>
                    <div className='gw-modal-close' onClick={handleCancel}>
                        <CircleClose />
                    </div>
                </div>
                <div className={`${styles["gw-modal-block-content"]}`}>
                    <div className={`${styles["gw-modal-content"]}`}>
                        <img className={`${styles["background"]}`} src={background2} alt="background" />
                        <div className={`${styles["detail"]}`}>
                            <img className={`${styles["logo"]}`} src={logo2} alt="logo" />
                            <span className={`${styles["text"]}`}>Location based real property marketing platforms</span>
                        </div>
                    </div>
                    <div className={`${styles["gw-modal-action"]}`}>
                        <span className={`${styles["text-login"]}`}>Login</span>
                        <span className={`${styles["text-please-login"]}`}>Please login to your account</span>
                        <div className={`${styles["list-btn-social"]}`}>
                            <Button hidden className={`${styles["btn-facebook"]}`} onClick={_ => onClickSocialLogin("facebook")}>
                                <div className={`${styles["btn-text-box"]}`}>
                                    <span>Continue with</span>
                                    <img src={icoFacebook} alt="facebook" />
                                </div>
                            </Button>
                            <Button className={`${styles["btn-google"]}`} onClick={_ => onClickSocialLogin("google")}>
                                <div className={`${styles["btn-text-box"]}`}>
                                    <span>Continue with</span>
                                    <img src={icoGoogle} alt="google" />
                                </div>
                            </Button>
                            <Button className={`${styles["btn-apple"]}`} onClick={_ => onClickSocialLogin("apple")}>
                                <div className={`${styles["btn-text-box"]}`}>
                                    <span>Continue with</span>
                                    <img src={icoApple} alt="apple" />
                                </div>
                            </Button>
                        </div>
                        <a onClick={handleCancel} className={`${styles["btn-skip-login"]}`}>
                            <span className={`${styles["text-skip-login"]}`}>Skip Login</span>
                        </a>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalLoginComponent;