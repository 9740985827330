import { useEffect, useState } from 'react';
import stylesBroadcast from './../MyGrow3Broadcast/style.module.scss';
import styles from './style.module.scss';
import MyGrowMain from "../MyGrow";
import { defaultValueFilter, filterEvent, validateNotLoadMore } from '../../utils/common';
import { Button, Col, Input, Row } from 'antd';
import CardItemPrimary from '../../components/CardItemPrimary';
import MapFilter from '../../components/MapFilter';
import { ReactComponent as IcoFilter } from '../../assets/icons/pages/findProertyMap/filter_alt.svg';
import * as services from "../../services/service.property";
import { useLocation, useNavigate } from 'react-router-dom';
import { useFilterContext } from '../../context/filter';
import LoadMoreElement from '../../components/LoadMore';
import { RoutePath } from '../../Routes';
import ListElement from '../../components/ListElement';
import InfiniteScroll from 'react-infinite-scroller';
import { usePromiseTracker } from 'react-promise-tracker';
import { useLoadingListContext } from '../../context/loaddingList';

const MyGrow6Policy = (props) => {
    
    return (
        <MyGrowMain>
            <div className={`${styles["gw-header-base"]}`}>
                <div className={`gw-text-title`}>
                    <span>Policy</span>
                </div>
            </div>
            <div className={`gw-mt-40`} style={{ paddingBottom: 35}}>
                <div className={`${styles["gw-header"]}`}>
                    <span className={`${styles["gw-text-title-1"]}`}>ข้อความปฏิเสธความรับผิดชอบ - www.growestate.ai</span>
                    <span className={`${styles["gw-text-indent"]}`}>ยินดีต้อนรับสู่ www.growestate.ai เว็บไซต์ตลาดการขายอสังหาริมทรัพย์ที่ให้คุณมีโอกาสแลกเปลี่ยนข้อมูลและติดต่อกับผู้อื่นที่สนใจซื้อขายอสังหาริมทรัพย์ โปรดทำความเข้าใจและยอมรับข้อกำหนดและเงื่อนไขดังต่อไปนี้ก่อนที่คุณจะใช้บริการของเรา:</span>
                    <ol>
                        <li><span>เป็นตลาดแลกเปลี่ยนข้อมูลการซื้อขายอสังหาริมทรัพย์: www.growestate.ai เป็นเพียงช่องทางในการแลกเปลี่ยนข้อมูลเกี่ยวกับการซื้อขายอสังหาริมทรัพย์เท่านั้น การซื้อขายอสังหาริมทรัพย์ที่เกิดขึ้นในเว็บไซต์นี้เป็นความรับผิดชอบของผู้ใช้งานเอง ทางเว็บไซต์ไม่มีส่วนเกี่ยวข้องในกระบวนการซื้อขายหรือธุรกรรมที่เกิดขึ้น โปรดทำความเข้าใจและใช้ดุลยพินิจในการตัดสินใจเกี่ยวกับการซื้อขายหรือการกระทำใดๆ ในเว็บไซต์นี้</span></li>
                        <li><span>ความรับผิดชอบในข้อมูลและการตรวจสอบ: เนื้อหาและข้อมูลที่ผู้ใช้งานนำเสนอในเว็บไซต์นี้เป็นความรับผิดชอบของผู้ใช้งานเอง ควรตรวจสอบและพิจารณาข้อมูลที่ได้รับให้ละเอียดก่อนการทำธุรกรรม รวมถึงการตรวจสอบสภาพอสังหาริมทรัพย์ ข้อมูลทางกฎหมาย และความเสี่ยงที่อาจเกิดขึ้นในกระบวนการซื้อขาย</span></li>
                        <li><span>การใช้ฟังก์ชั่น "Broadcast" และ "Pin Location": การใช้บอร์ดแคสเพื่อสอบถามผู้ใช้รายอื่นและกำหนดขอบเขตพื้นที่เพื่อรอให้มีคนมาโพสขายอสังหาริมทรัพย์ในขอบเขตที่เราตั้งเอาไว้ ควรใช้ดุลยพินิจในการตัดสินใจในการทำธุรกรรมหรือติดต่อกับผู้ใช้งานอื่น ๆ ในเว็บไซต์นี้ ทางเราไม่มีส่วนเกี่ยวข้องในกรณีของการทำธุรกรรมหรือการเกิดปัญหาระหว่างผู้ใช้งาน</span></li>
                        <li><span>ความเสี่ยงในการทำธุรกรรม: การซื้อขายที่เกิดขึ้นในเว็บไซต์นี้เป็นเรื่องที่มากับความเสี่ยง โปรดทำความเข้าใจและใช้ดุลยพินิจในการตัดสินใจในการซื้อขายหรือการกระทำใดๆ เพื่อลดความเสี่ยงที่อาจเกิดขึ้น</span></li>
                        <li><span>การควบคุมและการลงทุน: เว็บไซต์นี้ไม่ได้ให้คำแนะนำในการลงทุนหรือการเสนอตัวลงทุน การตัดสินใจในการลงทุนควรเป็นไปตามความพร้อมและความเข้าใจของคุณเอง ควรหาคำปรึกษาจากผู้เชี่ยวชาญก่อนที่จะทำการลงทุน</span></li>
                        <li><span>การละเมิดลิขสิทธิ์และข้อจำกัดในการใช้บริการ: เราไม่อนุญาตให้ใช้ข้อมูลหรือเนื้อหาที่ละเมิดลิขสิทธิ์ หรือข้อจำกัดในการให้บริการเพื่อประโยชน์ส่วนตัวหรือประโยชน์ทางการค้าของบุคคลอื่น</span></li>
                    </ol>
                    <span className={`${styles["gw-text-indent"]}`}>การใช้บริการของ www.growestate.ai ให้ถือปฏิบัติตามข้อกำหนดและเงื่อนไขข้างต้น เราหวังว่าเว็บไซต์ของเราจะเป็นส่วนหนึ่งในการช่วยให้คุณซื้อขายอสังหาริมทรัพย์ได้ง่ายและปลอดภัย</span>
                    <span className={`${styles["gw-text-indent"]}`}>ขอบคุณที่ใช้บริการเว็บไซต์ www.growestate.ai และขอให้คุณมีประสบการณ์การซื้อขายที่เป็นประโยชน์และปลอดภัย</span>
                </div>
                <br />
                <div className={`${styles["gw-header"]}`}>
                    <span className={`${styles["gw-text-title-1"]}`}>Disclaimer - www.growestate.ai</span>
                    <span className={`${styles["gw-text-indent"]}`}>Welcome to www.growestate.ai, a real estate marketplace website that allows you to exchange information and connect with others interested in buying and selling properties. Please read and accept the following terms and conditions before using our services:</span>
                    <ol>
                        <li><span>Marketplace for Property Information Exchange: www.growestate.ai serves solely as a platform for exchanging information related to buying and selling real estate. Any property transactions that occur on this website are the sole responsibility of the users. The website is not involved in any property sales or transactions. Please use your own judgment when making decisions regarding property transactions or any other activities on this site.</span></li>
                        <li><span>Responsibility for Information and Verification: Users are responsible for the content and information they post on this website. It is essential to thoroughly review and consider the information provided before engaging in any transactions, including verifying property conditions, legal information, and potential risks involved in the buying and selling process.</span></li>
                        <li><span>Usage of "Broadcast" and "Pin Location" Functions: The use of the "Broadcast" function to inquire with other users and the "Pin Location" function to wait for property listings within specific areas requires cautious decision-making. The website does not take responsibility for any transactions or issues that may arise between users.</span></li>
                        <li><span>Transaction Risks: Transactions conducted on this website come with inherent risks. Please exercise caution and make informed decisions to minimize potential risks.</span></li>
                        <li><span>Control and Investment: This website does not provide investment advice or offer investment opportunities. Any investment decisions should be made based on your own readiness and understanding. Seek advice from experts before making any investment decisions.</span></li>
                        <li><span>Copyright Infringement and Service Limitations: Users are not permitted to use copyrighted content or violate any service limitations for personal or commercial gains of others.</span></li>
                    </ol>
                    <span className={`${styles["gw-text-indent"]}`}>By using the services of www.growestate.ai, you agree to adhere to the aforementioned terms and conditions. We hope that our website will be a helpful tool for facilitating safe and beneficial property transactions.</span>
                    <span className={`${styles["gw-text-indent"]}`}>Thank you for using www.growestate.ai, and we wish you successful and secure property dealings.</span>
                </div>
            </div>
        </MyGrowMain>
    )
}

export default MyGrow6Policy;