import styles from './style.module.scss';
import { AutoComplete, Button, Col, Input, Modal, Row } from "antd";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { defaultCenter, mapModalSizeStyles, mapStyles, MapTypeId } from '../FindPropertyMap/settings';
import React, { useCallback, useEffect, useState } from 'react';
import iconMarkLogo from '../../assets/icons/maps/mark.png';
import { ReactComponent as BackLight } from '../../assets/icons/pages/Propmarket/my-property-create/Back_light.svg';
import { ReactComponent as DellLight } from '../../assets/icons/pages/Propmarket/my-property-create/Dell_light.svg';
import icoMapLayer1 from '../../assets/icons/pages/findProertyMap/maps_1.svg';
import icoMapLayer2 from '../../assets/icons/pages/findProertyMap/maps_2.svg';
import PropTypes from 'prop-types';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

const { Search } = Input;
const ModalMap = ({
    visibleModalMap: visible = true,
    hideModalMap: hideModal,
    userPinMarker,
    setUserPinMarker
}) => {
	const [map, setMap] = useState(null);
    const [pin, setPin] = useState();
    const [zoom, setZoom] = useState();
    const [mapData, setMapData] = useState({
        location: {},
        mapTypeId: MapTypeId.ROADMAP
    });
	const onLoad = useCallback(function callback(map) {
		setMap(map)
	}, []);
	const onUnmount = useCallback(function callback(map) {
		setMap(null)
	}, []);
    useEffect(() => {
        setPin(userPinMarker?.location)
    }, [userPinMarker, visible]);
    const onClickSubmit = () => {
        setUserPinMarker({
            location: pin || defaultCenter,
            zoom: map?.getZoom() || 7,
            percel: {}
        });
        hideModal();
    }
    const onClickMapChangePinLocation = (event) => {
        setPin(event?.latLng.toJSON())
        map.panTo(event?.latLng.toJSON());
    }

    const onSetMapChangePinLocation = (value = {}) => {
        setPin(value)
        map.panTo(value);
        map.setZoom(15);
    }

	useEffect(() => {
		if (map) {
			if (mapData.location.lat && mapData.location.lng) {
				map.panTo(mapData.location);
			}
		}
	}, [mapData.location, map]);

	useEffect(() => {
		if (map) {
			if (mapData.zoom) {
				map.setZoom(mapData.zoom)
			}
		}
	}, [mapData.zoom, map]);

    const onClickToggleLayer = () => {
        if (MapTypeId.ROADMAP === mapData.mapTypeId) {
            setMapData(prevState => {
                return {
                    ...prevState,
                    mapTypeId: MapTypeId.HYBRID
                }
            });
        } else {
            setMapData(prevState => {
                return {
                    ...prevState,
                    mapTypeId: MapTypeId.ROADMAP
                }
            });
        }
    }

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        }
    }

    function showPosition(position) {
        setMapData(prevState => {
            return {
                ...prevState,
                zoom: 15,
                location: {
                    lat: position?.coords?.latitude,
                    lng: position?.coords?.longitude
                }
            }
        });
    }

    const onClickSetCurrentLocation = () => {
        getLocation()
    }

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address) => {
        const regex_lat_lng = /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi;
        setValue(address, false);
        clearSuggestions();
    
        if (regex_lat_lng.test(`${address}`)) {
            const split_str = `${address}`.split(",");
            const lat = Number(split_str[0]);
            const lng = Number(split_str[1]);
            
            try {
                onSetMapChangePinLocation({
                    lat,
                    lng
                });
            } catch (error) {
                console.warn(`🌦️ ~ handleSelect ~ error:`, error);
            }
        } else {
            try {
                const results = await getGeocode({ address });
                const selectLocation = getLatLng(results[0]);
                setPin(selectLocation);
                map.panTo(selectLocation);
                map.setZoom(15);
            } catch (error) {
                console.warn(`🌦️ ~ handleSelect ~ error:`, error);
            }
        }
    };

    return (
        <Modal visible={visible} title={null} closable={null} footer={null} width={1280} className={styles["gw-modal-map"]}>
            <Row gutter={[0, 40]}>
                <Col span={24} className={styles["gw-block-container"]}>
                    <Row gutter={[0, 24]}>
                        <Col>
                            <label className={`${styles["gw-text-title"]}`}>
                                <span>Location</span>
                            </label>
                        </Col>
                        <Col span={24}>
                            <AutoComplete
                                dropdownMatchSelectWidth={300}
                                style={{
                                    width: 250,
                                }}
                                options={
                                    [
                                        ...[...(value ? [{
                                            value: value,
                                            label: value
                                        }] : [])],
                                        ...(data || []).map((item, index) => {
                                            return {
                                                value: item["description"],
                                                label: item["description"]
                                            }
                                        })
                                    ]
                                }
                                className={`${styles["gw-input-search"]}`}
                                onSelect={handleSelect}
                            >
                                <Search
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    disabled={!ready}
                                    placeholder="ค้นหา"
                                />
                            </AutoComplete>
                            <div className={`${styles["btn-action-maps"]}`}>
                                <Button shape="circle" onClick={onClickToggleLayer}>
                                    <img src={icoMapLayer1} alt="" />
                                </Button>
                                {/* <Button shape="circle" onClick={onClickSetCurrentLocation}>
                                    <img src={icoMapLayer2} alt="" />
                                </Button> */}
                            </div>
                            {/* <div className={styles["gw-group-btn-action"]}>
                                <Button shape='circle'>
                                    <BackLight />
                                </Button>
                                <Button shape='circle'>
                                    <DellLight />
                                </Button>
                            </div> */}
                            <GoogleMap
                                mapTypeId={mapData.mapTypeId}
                                options={{
                                    styles: mapData.mapTypeId === MapTypeId.ROADMAP ? mapStyles : {},
                                    disableDefaultUI: true,
                                }}
                                mapContainerStyle={mapModalSizeStyles}
                                zoom={7}
                                center={defaultCenter}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                mapContainerClassName={styles["gw-map-container"]}
                                onCenterChanged={() => {
                                    // console.log(map)
                                    // console.log("onCenterChanged")
                                    // console.log(map?.getBounds()?.toJSON())
                                }}
                                onZoomChanged={() => {
                                    setZoom(map?.getZoom())
                                }}
                                onClick={onClickMapChangePinLocation}
                            >
                                <Marker
                                    icon={{
                                        url: iconMarkLogo,
                                        scaledSize: {
                                            width: 30,
                                            height: 43
                                        },
                                    }}
                                    position={pin || defaultCenter}
                                    draggable={true}
                                    onDragEnd={(e) => {
                                        setPin(e.latLng.toJSON())
                                    }}
                                />
                            </GoogleMap>
                        </Col>
                        <Col span={24} className={styles["btn-group-footer"]}>
                            <Row justify="center" align='middle'>
                                <Col>
                                    <Row gutter={[26, 0]}>
                                        <Col>
                                            <Button
                                                className={`${styles["btn-form"]} ${styles["gw-btn-clear"]}`}
                                                onClick={hideModal}>
                                                <div>
                                                    <span>Cancel</span>
                                                </div>
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                className={`${styles["btn-form"]} ${styles["gw-btn-confirm"]}`}
                                                onClick={onClickSubmit}
                                            >
                                                <div>
                                                    <span>Submit</span>
                                                </div>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}

// ModalMap.propTypes = {
//     visibleModalMap: PropTypes.bool,
//     hideModalMap: PropTypes.func,
//     userPinMarker: PropTypes.exact({
//         location: PropTypes.object,
//         polygons: PropTypes.array,
//         zoom: PropTypes.number,
//         percel: PropTypes.object,
//     }),
//     setUserPinMarker: PropTypes.func
// }

export default ModalMap;