import { createSlice } from '@reduxjs/toolkit'

export const propertySlice = createSlice({
    name: 'property',
    initialState: {
        propertyList: {
            list: [],
            meta: {}
        },
    },
    reducers: {
        updatePropertyMapList: (state, sender) => {
            state.propertyList = {...sender.payload}
        }
    },
})

// Action creators are generated for each case reducer function
export const { updatePropertyMapList } = propertySlice.actions

export default propertySlice.reducer