import { useEffect, useState } from 'react';
import stylesBroadcast from './../MyGrow3Broadcast/style.module.scss';
import styles from './style.module.scss';
import MyGrowMain from "../MyGrow";
import { defaultValueFilter, filterEvent, validateNotLoadMore } from '../../utils/common';
import { Button, Col, Form, Input, Row, notification } from 'antd';
import CardItemPrimary from '../../components/CardItemPrimary';
import MapFilter from '../../components/MapFilter';
import { ReactComponent as IcoFilter } from '../../assets/icons/pages/findProertyMap/filter_alt.svg';
import * as services from "../../services/service.property";
import { useLocation, useNavigate } from 'react-router-dom';
import { useFilterContext } from '../../context/filter';
import LoadMoreElement from '../../components/LoadMore';
import { RoutePath } from '../../Routes';
import ListElement from '../../components/ListElement';
import InfiniteScroll from 'react-infinite-scroller';
import { usePromiseTracker } from 'react-promise-tracker';
import { useLoadingListContext } from '../../context/loaddingList';
import { useForm } from 'antd/lib/form/Form';
import {  } from '../../services/service.auth';
import { useMediaQuery } from 'react-responsive';

const MyGrow8ReportAPoblem = (props) => {
    const [form] = useForm();
    const navigate = useNavigate();

    const onFinish = async (values) => {
        try {
            await services.updateChat(prepareData(values));
            notification['success']({
                message: `ส่งรายงานปัญหาเรียบร้อย!`,
            });
            form.setFieldsValue({ message: "" });
        } catch (error) {
            console.log(error);
            notification['error']({
                message: 'มีบางอย่างผิดพลาด',
            });
        }
    }

    const prepareData = (source_data) => {
        return {
            "type": 1,
            "message": source_data.message,
            "attach_property_id": "",
            "file": "",
            "to_id": "HIzS9rUDnoZw4NWFOAezxIIEDZ22"
        }
    }
    const onFinishFailed = (errorInfo) => {
        console.log(`🌦️ ~ onFinishFailed ~ errorInfo:`, errorInfo);
    }

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    return (
        <MyGrowMain>
            <div className={`${styles["gw-header"]}`}>
                <div className={`gw-text-title`}>
                    <span>แจ้งปัญหา</span>
                </div>
            </div>
            <Form
                className={`${isMobile ? `gw-mt-16` : `gw-mt-40`} ${styles["gw-block-container"]}`}
                name="form_profile"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={{}}
                form={form}
            >
                <Row gutter={[24, 24]} className={`gw-mt-8`}>
                    <Col span={24}>
                        <Form.Item
                            className={`${styles["gw-form-input"]}`}
                            name="message"
                            rules={[{ required: true, message: 'Please input!' }]}
                        >
                            <Input.TextArea placeholder='รายงานปัญหา/ข้อเสนอ' maxLength={1000} style={{height: "100px"}}/>
                        </Form.Item>
                    </Col>
                    
                    </Row>
                    <Row gutter={[24, 35]} justify='space-between' className={styles["gw-btn-actions"]}>
                        <Col span={24}>
                            <Button
                                className={`${styles["btn-form"]} ${styles["gw-btn-confirm"]}`}
                                htmlType={"submit"}
                            >
                                <div>
                                    <span>{"ส่ง"}</span>
                                </div>
                            </Button>
                        </Col>
                    </Row>
                </Form>
        </MyGrowMain>
    )
}

export default MyGrow8ReportAPoblem;