import { memo, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { filterEvent, validateNotLoadMore } from '../../utils/common';
import styles from './style.module.scss';
import icoLogo from '../../assets/icons/logo/logo.png';
import { ReactComponent as IcoFilter } from '../../assets/icons/pages/findProertyMap/filter_alt.svg';
import CardItemPrimary from '../../components/CardItemPrimary';
import MapFilter from '../../components/MapFilter';
import { RoutePath } from '../../Routes';
import { fetchPerperty } from '../../services/service.property';
import { useFilterContext } from '../../context/filter';
import LoadMoreElement from '../../components/LoadMore';
import ListElement from '../../components/ListElement';
import InfiniteScroll from 'react-infinite-scroller';
import { usePromiseTracker } from 'react-promise-tracker';
import { Helmet } from 'react-helmet';
import { useLoadingListContext } from '../../context/loaddingList';
import { useDispatch, useSelector } from 'react-redux';
import { updatePropertyMapList } from '../../redux/action.property';
import { useMediaQuery } from 'react-responsive';
import addRingLight from '../../assets/icons/pages/Mygrow/Add_ring_light.svg';
import { useCallback } from 'react';
import ppImage from '../../assets/icons/pages/findProertyList/S__4161590.png';

const IndexPage = () => {
    const storeDataList = useSelector((state) => state.property.propertyList)
    const dispatch = useDispatch()
    const { promiseInProgress } = usePromiseTracker();
    const navigate = useNavigate();
    const location = useLocation();
    const [_visibleDrawerFilter, setVisibleDrawerFilter] = useState(false);
    const [_dataPropertyList, setDataPropertyList] = useState([]);
    const [dataPropertyMeta, setDataPropertyMeta] = useState({});
    const {data: filterData, action: filterAction} = useFilterContext();
    const [loadMorePage, setLoadMorePage] = useState(1);
    const {action: loadingListAction} = useLoadingListContext();

    const dataPropertyList = useMemo(() => {
        return _dataPropertyList;
    }, [_dataPropertyList]);
    
    const visibleDrawerFilter = useMemo(() => {
        return _visibleDrawerFilter;
    }, [_visibleDrawerFilter]);

    const onClikeNavigateMap = () => {
        const path = isMobile ? RoutePath.find_property_map.pathMobile : RoutePath.find_property_map.path;
        window.location.href = `${path}${location.search}`;
    }

    const showDrawerFilter = () => {
        setVisibleDrawerFilter(true);
    };

    const closeDrawerFilter = () => {
        setVisibleDrawerFilter(false);
    };
    
    useEffect(() => {
        if (storeDataList?.list?.length > 0) {
            setDataPropertyMeta(function (prevData) {
                return {...storeDataList?.meta || {}}
            });
            setDataPropertyList(function (prevData) {
                return [...storeDataList?.list || []]
            });
        } else {
            const filterSearchClean = filterEvent.decodeFilter(location);
            filterAction.updateData(filterSearchClean);
            loadDataProperty({ ...filterSearchClean });
            setLoadMorePage(1)
        }
        return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(function () {
        if (loadMorePage !== 1 && Object.keys(filterData.filterSearchObj || {}).length > 0) {
            loadDataProperty({ ...filterData.filterSearchObj, page: loadMorePage }, true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMorePage]);

    const onFilterFinish = (values) => {
        const path = isMobile ? RoutePath.find_property_list.pathMobile : RoutePath.find_property_list.path;
        navigate({
            // pathname: path,
            search: `?${filterEvent.encodeFilter(values)}`
        });
        closeDrawerFilter();
    };

    const onFilterFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFilterReset = (...args) => {
        console.log('Reset:', `defaultValueFilter`);
    };

    const loadDataProperty = async (requestBody, loadMore = false) => {
        try {
            loadingListAction.updateData(true);
            const responseDataProperty = await fetchPerperty(requestBody);
            const responseItems = responseDataProperty?.data?.data?.items || [];
            const responseMeta = responseDataProperty?.data?.data?.meta || {};
            if (responseDataProperty?.data?.data) {
                dispatch(updatePropertyMapList({
                    list: [...(loadMore ? dataPropertyList : []), ...responseItems],
                    meta: {...responseMeta}
                }));
                setDataPropertyMeta(function (prevData) {
                    return {...responseMeta}
                });
                setDataPropertyList(function (prevData) {
                    return [...(loadMore ? prevData : []), ...responseItems]
                });
            }else {
                throw Error("No data")
            }
        } catch (error) {
            console.log("error" , error)
            setDataPropertyMeta(function () {
                return {}
            });
            setDataPropertyList(function () {
                return []
            });
        } finally {
            loadingListAction.updateData(false);
        }
    }

    const loadMoreDataProperty = () => {
        if (!promiseInProgress && validateNotLoadMore(dataPropertyMeta, loadMorePage)) {
            setLoadMorePage(prevData => {
                return prevData + 1;
            });
        }
    }

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const onNavigateAddBtn = useCallback(
        () => {
            navigate(RoutePath.propmarket_my_property_create.path);
        },
        [navigate],
    );

    return (
        <div className={`${styles["navigate-container"]}`}>
            <Helmet>
                <title>ขายบ้าน ขายที่ดิน ขายคอนโด ด้วยตัวช่วย AI - GrowEstate.AI</title>
                <meta name="title" content="ขายบ้าน ขายที่ดิน ขายคอนโด ด้วยตัวช่วย AI - GrowEstate.AI" />
                <meta name="description" content="AI ช่วยในการซื้อขายอสังหาริมทรัพย์ 
แพลตฟอร์มอสังหาฯเอไอที่จะช่วยให้การซื้อขายเช่าอสังหาริมทรัพย์เป็นเรื่องง่าย ขายได้ไว ปลอดภัย ประกาศขายบ้านเดี่ยว บ้านแฝด ทาวน์โฮม ทาวน์เฮ้าส์ ที่ดิน อาคารพาณิชย์ อพาร์ทเม้น สำนักงาน ปั๊มน้ำมัน ร้านค้า ห้าง ประกาศเช่า ออฟฟิศ การตลาดเชิงรุก ระบบฉลาด พร้อมใช้งาน" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://growestate.ai" />
                <meta property="og:title" content="ขายบ้าน ขายที่ดิน ขายคอนโด ด้วยตัวช่วย AI - GrowEstate.AI" />
                <meta property="og:description" content="AI ช่วยในการซื้อขายอสังหาริมทรัพย์ 
แพลตฟอร์มอสังหาฯเอไอที่จะช่วยให้การซื้อขายเช่าอสังหาริมทรัพย์เป็นเรื่องง่าย ขายได้ไว ปลอดภัย ประกาศขายบ้านเดี่ยว บ้านแฝด ทาวน์โฮม ทาวน์เฮ้าส์ ที่ดิน อาคารพาณิชย์ อพาร์ทเม้น สำนักงาน ปั๊มน้ำมัน ร้านค้า ห้าง ประกาศเช่า ออฟฟิศ การตลาดเชิงรุก ระบบฉลาด พร้อมใช้งาน" />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://growestate.ai" />
                <meta property="twitter:title" content="ขายบ้าน ขายที่ดิน ขายคอนโด ด้วยตัวช่วย AI - GrowEstate.AI" />
                <meta property="twitter:description" content="AI ช่วยในการซื้อขายอสังหาริมทรัพย์ 
แพลตฟอร์มอสังหาฯเอไอที่จะช่วยให้การซื้อขายเช่าอสังหาริมทรัพย์เป็นเรื่องง่าย ขายได้ไว ปลอดภัย ประกาศขายบ้านเดี่ยว บ้านแฝด ทาวน์โฮม ทาวน์เฮ้าส์ ที่ดิน อาคารพาณิชย์ อพาร์ทเม้น สำนักงาน ปั๊มน้ำมัน ร้านค้า ห้าง ประกาศเช่า ออฟฟิศ การตลาดเชิงรุก ระบบฉลาด พร้อมใช้งาน" />

            </Helmet>
            <div className={`${styles["btn-navigate-action"]}`}>
                <Button shape="circle" onClick={onNavigateAddBtn}>
                    <img src={addRingLight} alt="" />
                    <span>{`Create`}</span>
                </Button>
                <Button shape="circle" onClick={onClikeNavigateMap}>
                    <img src={icoLogo} alt="" />
                    <span>Map</span>
                </Button>
            </div>
            <div className={`gw-container`}>
                <InfiniteScroll
                    threshold={750}
                    initialLoad={false}
                    loadMore={loadMoreDataProperty}
                    hasMore={validateNotLoadMore(dataPropertyMeta, loadMorePage)}
                >
                    <Row gutter={[0, 24]} className={"gw-mt-32"}>
                        <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                            <div className={`${styles["gw-header"]}`}>
                                <div className={`${styles["gw-header-text"]}`}>
                                    <span className={`${styles["gw-title"]}`}>
                                        <span className={`${styles["gw-title-sub"]}`}>Powered by</span>
                                        <img className={`${styles["logo"]}`} src={ppImage} alt="logo" />
                                    </span>
                                    <span style={{ display: 'flex', gap: '2px' }} className={`${styles["gw-sub-title"]}`}>
                                        <span style={{color: "#b6babd"}}>ดาวน์โหลดได้แล้วทั้งระบบ</span>
                                        <a href={'https://play.google.com/store/apps/details?id=com.houseofhope.growastate'} 
                                            style={{color: "var(--gw-brand-color-red-1)", cursor: "pointer"}} target='_blank' rel="noreferrer">Android</a>
                                        <span style={{color: "#b6babd"}}>และ</span>
                                        <a href={'https://apps.apple.com/co/app/growestate/id6445862568'} 
                                            style={{color: "var(--gw-brand-color-red-1)", cursor: "pointer"}} target='_blank' rel="noreferrer">iOS</a>
                                    </span>
                                </div>
                                <div className={`${styles["gw-header-action"]}`}>
                                    <Button type="link" className={`${styles["btn-link"]}`} onClick={showDrawerFilter}>
                                        <IcoFilter />
                                        <span>Filter</span>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <ListElement list={dataPropertyList} type={"DEFAULT_12_6"} isMobile={isMobile}/>
                        <LoadMoreElement {...{ 
                            dataMeta: dataPropertyMeta, 
                            loadMorePage: loadMorePage, 
                            loadMoreData: loadMoreDataProperty }} />
                    </Row>
                </InfiniteScroll>
            </div>
            <MapFilter 
                visibleDrawerFilter={visibleDrawerFilter}
                closeDrawerFilter={closeDrawerFilter}
                onFilterFinish={onFilterFinish}
                onFilterFinishFailed={onFilterFinishFailed}
                onFilterReset={onFilterReset}
            />
        </div>
    )
}

export default IndexPage;