import { Button, Row, Col, Image, Input } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { useModalContext } from '../../context/modals';
import GWMap from './MapsNew';
import MapFilter from '../../components/MapFilter';
import styles from './style.module.scss';
import icoFilter from '../../assets/icons/pages/findProertyMap/filter_alt.svg';
import { ReactComponent as IcoFilter } from '../../assets/icons/pages/findProertyMap/filter_alt.svg';
import icoMenu from '../../assets/icons/pages/findProertyMap/menu.svg';
import icoMapLayer1 from '../../assets/icons/pages/findProertyMap/maps_1.svg';
import icoMapLayer2 from '../../assets/icons/pages/findProertyMap/maps_2.svg';
import { ReactComponent as CloseRoundDuotone } from '../../assets/icons/pages/findProertyMap/close_round_duotone.svg';
import * as localServices from '../../services/localstorage';
import { filterEvent, prepareFilterPropertySimilar, showPrice, validateNotLoadMore } from '../../utils/common';
import CardItemSecondary from "../../components/CardItemSecondary";
import { RoutePath } from "../../Routes";
import SimpleBar from 'simplebar-react';
import { fetchPerperty, fetchPerpertySimilar, propertyPropertyMapsData } from "../../services/service.property";
import { useFilterContext } from "../../context/filter";
import * as local from '../../services/localstorage';
import { useMediaQuery } from "react-responsive";
import addRingLight from '../../assets/icons/pages/Mygrow/Add_ring_light.svg';
import { fetchBroadcast, fetchBroadcastMaping } from "../../services/service.broadcast";
import axios from "axios";
import EditorView from "../../components/TextEditor/indexView";
import { useSelector } from 'react-redux';
import clsx from "clsx";
import InfiniteScroll from "react-infinite-scroller";
import { usePromiseTracker } from "react-promise-tracker";
import LoadMoreElement from "../../components/LoadMore";
import { useLoadingListContext } from "../../context/loaddingList";

const layoutColumnWeb = {
    LAYOUT_1: {
        id: "LAYOUT_1",
        gutter: [24, 0],
        col_1: 18,
        col_2: 6
    },
    LAYOUT_2: {
        id: "LAYOUT_2",
        gutter: [0, 0],
        col_1: 24,
        col_2: 0
    }
}

const layoutColumnMobile = {
    LAYOUT_1: {
        id: "LAYOUT_1",
        gutter: [24, 0],
        col_1: 4,
        col_2: 20
    },
    LAYOUT_2: {
        id: "LAYOUT_2",
        gutter: [0, 0],
        col_1: 24,
        col_2: 0
    }
}

const MapTypeId = {
    "ROADMAP": "roadmap",
    "SATELLITE": "satellite",
    "HYBRID": "hybrid",
    "TERRAIN": "terrain"
}

let timmerInitLoad;

const IndexPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const filterSearchClean = filterEvent.decodeFilter(location);

    const modalContext = useRef(useModalContext());
    const [visibleDrawerFilter, setVisibleDrawerFilter] = useState(false);
    const [mapData, setMapData] = useState({
        location: {},
        mapTypeId: MapTypeId.ROADMAP
    });
    const [dataPropertyList, setDataPropertyList] = useState([]);
    const [dataPropertyMeta, setDataPropertyMeta] = useState({});
    const [isLoadProperty, setIsLoadProperty] = useState(false);
    const { data: filterData, action: filterAction } = useFilterContext();
    const [dataPropertySimilarList, setDataPropertySimilarList] = useState([]);
    const [dataPropertySimilarMeta, setDataPropertySimilarMeta] = useState({});
    const [dataBroadcastList, setDataBroadcastList] = useState([]);
    const { userData } = useSelector((state) => state.user);
    const [loadMorePage, setLoadMorePage] = useState(1);
    const { promiseInProgress } = usePromiseTracker();
    const {action: loadingListAction} = useLoadingListContext();

    useEffect(() => {
        const currentMNewsData = localServices.checkModalNews(modalContext?.current?.data);
        modalContext.current.action.updateModalNews(currentMNewsData);
        return () => { };
    }, [modalContext]);

    useEffect(() => {
        setVisibleDrawerFilter(false);
        const filterSimilar = local.lastLocation.get();
        if (filterSimilar.title_deen_no || filterSimilar.title_deen_no === 0) {
            showGrid({
                data: {
                    property: {
                        title_deen_no: filterSimilar.title_deen_no
                    }
                }
            })
        }
        return () => { };
    }, []);

    const onClikeNavigateList = () => {
        navigate(`${RoutePath.find_property_map.path}${location.search}`)
    }

    const showDrawerFilter = () => {
        setVisibleDrawerFilter(true);
    };

    const closeDrawerFilter = () => {
        setVisibleDrawerFilter(false);
    };

    useEffect(() => {
        filterAction.updateData({ ...filterSearchClean, is_init: true });
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const onFilterFinish = (_values) => {
        const values = { ..._values };
        navigate({
            // pathname: RoutePath.find_property_map.path,
            search: `?${filterEvent.encodeFilter(values)}`
        });
        closeDrawerFilter();
    };

    const onFilterFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFilterReset = (...args) => {
        console.log('Reset:', `defaultValueFilter`);
    };


    const closeGrid = () => {
        setDataPropertySimilarList([]);
        setDataPropertySimilarMeta({});
        local.lastLocation.set({});
    }

    const showGrid = async (markerData = {}) => {
        const properties = markerData?.properties || {};
        local.lastLocation.set({
            // title_deen_no: filterSimilar.title_deen_no,
            p_id: properties.property.p_id
        });
        setDataPropertySimilarList([{ ...properties }]);
        setDataPropertySimilarMeta({});
        return;
        try {
            const filterSimilar = prepareFilterPropertySimilar(markerData?.properties?.property || {});
            local.lastLocation.set({
                // title_deen_no: filterSimilar.title_deen_no,
                p_id: filterSimilar.p_id
            });
            const response = await fetchPerpertySimilar(filterSimilar, filterSimilar.title_deen_no);
            if (response?.data?.data) {
                setDataPropertySimilarList([...(response?.data?.data?.items || [])]);
                setDataPropertySimilarMeta({ ...(response?.data?.data?.meta || {}) });
                return;
            }
            throw Error("prepareFilterPropertySimilar no data");
        } catch (error) {
            console.warn("error", error);
            setDataPropertySimilarList([]);
            setDataPropertySimilarMeta({});
        } finally {
        }
    }

    const onClickToggleLayer = () => {
        if (MapTypeId.ROADMAP === mapData.mapTypeId) {
            setMapData(prevState => {
                return {
                    ...prevState,
                    mapTypeId: MapTypeId.HYBRID
                }
            });
        } else {
            setMapData(prevState => {
                return {
                    ...prevState,
                    mapTypeId: MapTypeId.ROADMAP
                }
            });
        }
    }

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        }
    }

    function showPosition(position) {
        setMapData(prevState => {
            return {
                ...prevState,
                zoom: 15,
                location: {
                    lat: position?.coords?.latitude,
                    lng: position?.coords?.longitude
                }
            }
        });
    }

    const onClickSetCurrentLocation = () => {
        getLocation()
    }

    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        loadDataBroadcast(cancelTokenSource);
        return () => {
            cancelTokenSource.cancel("Cancelling request");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const loadDataProperty = async (requestBody, cancelTokenSource, loadMore = false) => {
        setIsLoadProperty(true);
        try {
            loadingListAction.updateData(true);
            const responseDataProperty = await propertyPropertyMapsData(requestBody, cancelTokenSource);
            if (responseDataProperty?.data?.data) {
                setDataPropertyMeta(function () {
                    return { ...(responseDataProperty?.data?.data?.meta || {}) }
                });
                setDataPropertyList(function (prevData) {
                    return [...(loadMore ? prevData : []), ...(responseDataProperty?.data?.data?.items || [])]
                });
                setIsLoadProperty(false);
            } else {
                setDataPropertyMeta(function () {
                    return {}
                });
                setDataPropertyList(function () {
                    return []
                });
            }
        } catch (error) {
            console.warn(`🌦️ ~ loadDataProperty ~ error:`, error);
        } finally {
            setIsLoadProperty(false);
            loadingListAction.updateData(false);
        }
    }

    
    useEffect(() => {
        const cancelTokenSourcePP = axios.CancelToken.source();
        if (timmerInitLoad) clearTimeout(timmerInitLoad);
        timmerInitLoad = setTimeout(() => {
            setDataPropertyMeta(function () {
                return {}
            });
            setDataPropertyList(function () {
                return []
            });
            loadDataProperty({ ...filterData.filterSearchObj }, cancelTokenSourcePP);
        }, 700);
        return () => {
            cancelTokenSourcePP.cancel("Cancelling request");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData])

    useEffect(function () {
        const cancelTokenSourcePP = axios.CancelToken.source();
        if (loadMorePage !== 1 && Object.keys(filterData.filterSearchObj || {}).length > 0) {
            loadDataProperty({ ...filterData.filterSearchObj, page: loadMorePage }, true, cancelTokenSourcePP);
        }
        return () => {
            cancelTokenSourcePP.cancel("Cancelling request");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMorePage]);

    const loadDataBroadcast = async (cancelTokenSource) => {
        setIsLoadProperty(true);
        try {
            const response = await fetchBroadcast({
                page: 1,
                limit: 10
            }, cancelTokenSource);
            setDataBroadcastList(response?.data?.data?.items || []);
        } catch (error) {
            console.warn(`🌦️ ~ loadDataBroadcast ~ error:`, error);
        } finally {
            setIsLoadProperty(false);
        }
    }

    const onNavigateAddBtn = useCallback(
        () => {
            navigate(RoutePath.propmarket_my_property_create.path);
        },
        [navigate],
    );

    const onClickBoardCast = (data = {}) => {
        navigate(`${RoutePath.propmarket.path}/detail/broadcast?bc_id=${data?.bc_id}`)
    }

    const ShowDescription = ({
        data
    }) => {
        try {
            return (
                <div className="gw-textarea-block" category={data?.cate_id}>
                    <Input.TextArea 
                        className={clsx("gw-textarea-card")}
                        category={data?.cate_id}
                        broadcasttypeid={data?.broadcast_type_id}
                        placeholder=''
                        autoSize={true} value={data?.desc} readOnly/>
                </div>
            )
            const parseData = JSON.parse(data?.desc);
            if (parseData.root) {
                return <EditorView value={data?.desc} selectCategory={data?.cate_id}/>;
            }
            throw Error("no data");
        } catch (error) {
            return "";
        }
    }

    const loadMoreDataProperty = (page) => {
        if (!promiseInProgress && !isLoadProperty && validateNotLoadMore(dataPropertyMeta, loadMorePage)) {
            setLoadMorePage(prevData => {
                return prevData + 1;
            });
        }
    }

    const hasMore = validateNotLoadMore(dataPropertyMeta, loadMorePage);
    
    return (
        <Row gutter={[24, 0]} className={`${styles["navigate-container"]}`}>
            <div className={`${styles["btn-navigate-action"]}`}>
                <Button shape="circle" onClick={onNavigateAddBtn}>
                    <img src={addRingLight} alt="" />
                    <span>{`Create`}</span>
                </Button>
                <Button shape="circle" onClick={showDrawerFilter}>
                    <img src={icoFilter} alt="" />
                    <span>Filter</span>
                </Button>
            </div>
            <div className={`${styles["btn-action-maps"]}`}>
                <Button shape="circle" onClick={onClickToggleLayer}>
                    <img src={icoMapLayer1} alt="" />
                </Button>
                <Button shape="circle" onClick={onClickSetCurrentLocation}>
                    <img src={icoMapLayer2} alt="" />
                </Button>
            </div>
            <Col span={15} className={`${styles["col-map"]}`}>
                <GWMap
                    {
                    ...{
                        showSerchResult: showGrid,
                        hideSerchResult: closeGrid,
                        mapData: mapData,
                        pinList: dataPropertyList,
                        isMobile: false
                    }
                    }
                />
            </Col>
            <Col span={9} style={{ padding: "0px 15px" }}>
                <SimpleBar scrollableNodeProps={{ id: "simplebar-custom-scroll" }} className="gw-search-resoult-list" style={{ paddingRight: "10px" }} id="simplebar-custom">
                    <InfiniteScroll
                        threshold={550}
                        initialLoad={false}
                        loadMore={loadMoreDataProperty}
                        hasMore={hasMore}
                        useWindow={false}
                        getScrollParent={() => document.getElementById("simplebar-custom-scroll")}
                    >
                        <div className="gw-search-result" style={{ width: "100%" }}>
                            <SimpleBar style={{ width: "100%", overflow: "auto", paddingBottom: "16px" }}>
                                <div className="gw-mt-16" style={{ display: "flex", gap: 16, width: "100%" }}>
                                    {
                                        dataBroadcastList.map((item, index) => {
                                            return (
                                                <div className="gw-mini-card" style={{ minWidth: 120, width: 120 }} key={`${index}`}
                                                    onClick={_ => onClickBoardCast(item)}>
                                                    <div style={{ minWidth: 120, width: 120, minHeight: 125, maxHeight: 125, overflow: "hidden" }}>
                                                        <ShowDescription data={item} />
                                                    </div>
                                                    <div className="gw-avatar">
                                                        <Image width={25} height={25} preview={false} src={item?.create_by?.avatar} />
                                                    </div>
                                                    <div className="gw-text-price">
                                                        อ.{item?.amphures?.am_name || ""}
                                                        {/* <br /> */}
                                                        &nbsp;
                                                        จ.{item?.province?.pv_name || ""}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        userData?.uid && (
                                            <div className="gw-mini-card" style={{ minWidth: 120, width: 120 }} onClick={_ => navigate("/propmarket/broadcast")}>
                                                <div style={{ minWidth: 120, width: 120, minHeight: 125, maxHeight: 125, overflow: "hidden" }}>
                                                    {/* <ShowDescription /> */}
                                                    <div className="gw-add">
                                                        +
                                                    </div>
                                                </div>
                                                <div className="gw-avatar">
                                                    {/* <Image width={25} height={25} preview={false} src={`item?.create_by?.avatar`} /> */}
                                                </div>
                                                <div className="gw-text-price">
                                                    {/* ราคา {showPrice(`item?.cost_min`)}-{showPrice(`item?.cost_max`)} */}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </SimpleBar>
                            <Row gutter={[0, 16]} className="gw-mt-24">
                                <Col span={24}>
                                    <div className="gw-search-result-header">
                                        <span className="gw-result-count">
                                            {/* <span>{dataPropertySimilarList.length}</span>
                                        <span>Posts</span> */}
                                        </span>
                                        <div className="gw-btn-link gw-result-filter" onClick={showDrawerFilter}>
                                            <IcoFilter />
                                            <span>Filter</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]} className="gw-mt-16" style={{ paddingLeft: 4, paddingRight: 4 }}>
                                {
                                    dataPropertyList.map((item, index) => {
                                        return (
                                            <Col key={index} span={12}>
                                                <CardItemSecondary isSelector={false} dataitem={item} />
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            <br />
                            <br />
                            <LoadMoreElement {...{
                                dataMeta: dataPropertyMeta,
                                loadMorePage: loadMorePage,
                                loadMoreData: loadMoreDataProperty
                            }} />
                        </div>
                    </InfiniteScroll>
                </SimpleBar>
            </Col>
            <MapFilter
                visibleDrawerFilter={visibleDrawerFilter}
                closeDrawerFilter={closeDrawerFilter}
                onFilterFinish={onFilterFinish}
                onFilterFinishFailed={onFilterFinishFailed}
                onFilterReset={onFilterReset}
            />
        </Row>
    )
}

export default IndexPage;