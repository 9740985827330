import React, { createContext, useContext, useState } from "react";
import { node } from 'prop-types';
import { defaultValueFilter, filterEvent } from "../utils/common";

const FilterContext = createContext({});

export function useFilterContext() {
    return useContext(FilterContext)
}

const initialData = {
    ...defaultValueFilter,
    filterSearchObj: {}
}

function FilterProvidor({ children }) {
    const [data, setData] = useState(initialData);

    function updateData(nextData) {
        setData(prevData => ({
            ...prevData,
            ...filterEvent.prepareDefaultFilterModal(nextData),
            filterSearchObj: nextData
        }))
    }

    const dataStore = {
        data,
        action: {
            updateData,
        }
    }

    return <FilterContext.Provider value={dataStore}>{children}</FilterContext.Provider>
}

FilterProvidor.prototype = {
    children: node.isRequired
}

export default FilterProvidor;