/*global google*/
import styles from './style.module.scss';
import { Button, Col, Input, Modal, Row } from "antd";
import GoogleMapReact from 'google-map-react';
import { DrawingManager, GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api';
import { defaultCenter, mapModalSizeStyles, mapPolygonStyles, mapStyles, MapTypeId } from '../FindPropertyMap/settings';
import { useCallback, useEffect, useRef, useState } from 'react';
import iconMarkLogo from '../../assets/icons/maps/mark.png';
import { ReactComponent as BackLight } from '../../assets/icons/pages/Propmarket/my-property-create/Back_light.svg';
import { ReactComponent as DellLight } from '../../assets/icons/pages/Propmarket/my-property-create/Dell_light.svg';
import icoMapLayer1 from '../../assets/icons/pages/findProertyMap/maps_1.svg';
import icoMapLayer2 from '../../assets/icons/pages/findProertyMap/maps_2.svg';

const { Search } = Input;

const ModalMapPolygon = ({
    visibleModalMap: visible = true,
    hideModalMap: hideModal,
    userPinMarker,
    setUserPinMarker
}) => {
    let labels = [];
    let areaShow = 0;
	const [map, setMap] = useState(null)
    const [zoom, setZoom] = useState();
    const [mapData, setMapData] = useState({
        location: {},
        mapTypeId: MapTypeId.ROADMAP
    });
	const onLoad = useCallback(function callback(map) {
		setMap(map)
	}, []);
	const onUnmount = useCallback(function callback(map) {
		setMap(null)
	}, []);
    let drawingManagerRef = useRef();
    const _drawingOptions = {
        drawingControl: true,
        polygonOptions: {...mapPolygonStyles},
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
            drawingModes: ["polygon"]
        }
    }

    const [drawingOptions, SetDrawingOptions] = useState(_drawingOptions);

    const [polygons, setPolygons] = useState(userPinMarker?.polygons || []);

    useEffect(() => {
        setPolygons(userPinMarker?.polygons)
        return () => { }
    }, [userPinMarker?.polygons]);
    
    function onClickBackPolygons() {
        setPolygons([...polygons.slice(0,-1)])
    }

    function onClickClearPolygons() {
        setPolygons([]);
    }

    function onLoadDrawingManager(_drawingManager) {
        drawingManagerRef = _drawingManager;
    }

    function onPolygonComplete(polygon) {
        const get_polygons = [...polygon.getPath().getArray().map(item => item.toJSON())]
        setPolygons(get_polygons);
        attachPolygonInfoWindow(polygon)
        polygon.setMap(null);
    }

    function attachPolygonInfoWindow(polygon) {
        if (!polygon.labels) polygon.labels = [];
        for (let i = 0; i < polygon.labels.length; i++) {
            polygon.labels[i].setMap(null);
        }
        for (let i = 0; i < labels.length; i++) {
            labels[i].setMap(null);
        }
        polygon.labels = [];
        let path = polygon.getPath();
        let points = path.getArray();
        let area = google.maps.geometry.spherical
            .computeArea(path.getArray())
            .toFixed(0);
        areaShow = area;
        let bounds = new google.maps.LatLngBounds();
        let i;

        for (i = 0; i < points.length; i++) {
            bounds.extend(points[i]);
        }

        let boundsCenter = bounds.getCenter();
        // let centerLabel = new MapLabel({
        //     map: map,
        //     fontSize: 20,
        //     align: "left",
        //     zIndex: 1
        // });

        // polygon.labels.push(centerLabel);

        // centerLabel.set("position", bounds.getCenter());
        // centerLabel.set("text", area + "m2");
        if (path.getLength() < 2) return;
        // for (let i = 0; i < polygon.getPath().getLength(); i++) {
        //     // for each side in path, compute center and length
        //     let start = polygon.getPath().getAt(i);
        //     let end = polygon.getPath().getAt(i < polygon.getPath().getLength() - 1 ? i + 1 : 0);
        //     let sideLength = google.maps.geometry.spherical.computeDistanceBetween(start, end);
        //     let sideCenter = google.maps.geometry.spherical.interpolate(start, end, 0.5);
        //     console.log(sideLength, sideCenter)
        //     let sideLabel = new MapLabel({
        //         map: map,
        //         fontSize: 16,
        //         align: "center",
        //         zIndex: 1
        //     });
        //     sideLabel.set("position", sideCenter);
        //     sideLabel.set("text", sideLength.toFixed(2) + "m");
        //     polygon.labels.push(sideLabel);
        // }
        labels = polygon.labels
    }

    const submitPolygons = () => {
        hideModal();
        setUserPinMarker(prev => {
            return {
                ...prev,
                polygons,
                zoom: map?.getZoom() || 7,
                percel: {}
            }
        })
    }

	useEffect(() => {
		if (map) {
			if (mapData.location.lat && mapData.location.lng) {
				map.panTo(mapData.location);
			}
		}
	}, [mapData.location, map]);

	useEffect(() => {
		if (map) {
			if (mapData.zoom) {
				map.setZoom(mapData.zoom)
			}
		}
	}, [mapData.zoom, map]);

    const onClickToggleLayer = () => {
        if (MapTypeId.ROADMAP === mapData.mapTypeId) {
            setMapData(prevState => {
                return {
                    ...prevState,
                    mapTypeId: MapTypeId.HYBRID
                }
            });
        } else {
            setMapData(prevState => {
                return {
                    ...prevState,
                    mapTypeId: MapTypeId.ROADMAP
                }
            });
        }
    }

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        }
    }

    function showPosition(position) {
        setMapData(prevState => {
            return {
                ...prevState,
                zoom: 15,
                location: {
                    lat: position?.coords?.latitude,
                    lng: position?.coords?.longitude
                }
            }
        });
    }

    const onClickSetCurrentLocation = () => {
        getLocation()
    }


    return (
        <Modal visible={visible} title={null} closable={null} footer={null} width={1280} className={styles["gw-modal-map"]}>
            <Row gutter={[0, 40]}>
                <Col span={24} className={styles["gw-block-container"]}>
                    <Row gutter={[0, 24]}>
                        <Col>
                            <label className={`${styles["gw-text-title"]}`}>
                                <span>Location</span>
                            </label>
                        </Col>
                        <Col span={24}>
                            <div className={`${styles["btn-action-maps"]}`}>
                                <Button shape='circle' onClick={onClickClearPolygons}>
                                    <DellLight />
                                </Button>
                                <Button shape="circle" onClick={onClickToggleLayer}>
                                    <img src={icoMapLayer1} alt="" />
                                </Button>
                                {/* <Button shape="circle" onClick={onClickSetCurrentLocation}>
                                    <img src={icoMapLayer2} alt="" />
                                </Button> */}
                            </div>
                            <GoogleMap
                                mapTypeId={mapData.mapTypeId}
                                options={{
                                    styles: mapData.mapTypeId === MapTypeId.ROADMAP ? mapStyles : {},
                                    disableDefaultUI: true,
                                    // disableDoubleClickZoom: true,
                                    // fullscreenControl: false,
                                    // streetViewControl: false,
                                    // mapTypeControl: false,
                                    // zoomControl: false,
                                    // scaleControl: false,
                                    // draggingCursor: false,
                                }}
                                mapContainerStyle={mapModalSizeStyles}
                                zoom={userPinMarker?.zoom || 7}
                                center={userPinMarker?.location || defaultCenter}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                mapContainerClassName={styles["gw-map-container"]}
                                onZoomChanged={() => {
                                    setZoom(map?.getZoom())
                                }}
                            >
                                {
                                    userPinMarker?.location && 
                                    <Marker
                                        icon={{
                                            url: iconMarkLogo,
                                            scaledSize: {
                                                width: 30,
                                                height: 43
                                            },
                                        }}
                                        position={userPinMarker?.location}
                                    />
                                }
                                <DrawingManager
                                    drawingMode={google.maps.drawing.OverlayType.POLYGON}
                                    onLoad={onLoadDrawingManager}
                                    options={drawingOptions}
                                    onPolygonComplete={onPolygonComplete}
                                />
                                <Polygon
                                    options={mapPolygonStyles}
                                    editable={false}
                                    draggable={false}
                                    path={polygons}
                                />
                            </GoogleMap>
                        </Col>
                        <Col span={24} className={styles["btn-group-footer"]}>
                            <Row justify="center" align='middle'>
                                <Col>
                                    <Row gutter={[26, 0]}>
                                        <Col>
                                            <Button
                                                className={`${styles["btn-form"]} ${styles["gw-btn-clear"]}`}
                                                onClick={hideModal}>
                                                <div>
                                                    <span>Cancel</span>
                                                </div>
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                className={`${styles["btn-form"]} ${styles["gw-btn-confirm"]}`}
                                                onClick={submitPolygons}
                                            >
                                                <div>
                                                    <span>Submit</span>
                                                </div>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}

export default ModalMapPolygon;