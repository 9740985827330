import { useMediaQuery } from "react-responsive";
import PageMapOld from "../FindPropertyMapOld/index";
import PageMapNew from "../FindPropertyMapNew/index";

const IndexPage = () => {
    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });
    return isMobile ? <PageMapOld /> : <PageMapNew />
}

export default IndexPage;