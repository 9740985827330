import { collection } from "./config";
import { database, firestore } from "./firebase.utils";

const dbComment = firestore.collection(collection.property_comment);
const dbReplyComment = firestore.collection(collection.property_comment_reply);
const dbMessages = firestore.collection(collection.messages);
const dbRecentMessages = firestore.collection(collection.recent_messages);
const dbCommentBC = firestore.collection(collection.broadcast_comment);
const dbReplyCommentBC = firestore.collection(collection.broadcast_comment_reply);

const getComments = (id = "") => {
    return dbComment.doc(id);
};

const getReplyComments = (id = "") => {
    return dbReplyComment.doc(id);
};

const getBCComments = (id = "") => {
    return dbCommentBC.doc(id);
};

const getBCReplyComments = (id = "") => {
    return dbReplyCommentBC.doc(id);
};

const getFavorites = (p_id = "", uid = "") => {
    return database.ref(`/users/${uid}/favorit/${p_id}`)
}

const getUsersCoins = (p_id = "", uid = "") => {
    return database.ref(`/users/${uid}/coins/${p_id}`)
}

const getCoins = (p_id = "", uid = "") => {
    return database.ref(`/coins/${p_id}`)
}

const getCommentCounts = (p_id = "", uid = "") => {
    return database.ref(`/comment/${p_id}`)
}

const getMessages = () => {
    return dbMessages
};

const getRecentMessages = () => {
    return dbRecentMessages
};

const firebaseService = {
    getComments,
    getReplyComments,
    getFavorites,
    getUsersCoins,
    getCoins,
    getMessages,
    getRecentMessages,
    getCommentCounts,
    getBCComments,
    getBCReplyComments,
};

export default firebaseService;