import styles from './style.module.scss';
import { Button, Col, Form, Image, Input, InputNumber, message, Row, Select, Switch, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { ReactComponent as IcExpandMore } from '../../assets/icons/pages/Propmarket/my-property-create/ic_expand_more.svg';
import { ReactComponent as IcUploadPhoto } from '../../assets/icons/pages/Propmarket/my-property-create/ic_add_a_photo.svg';
import { ReactComponent as IcUploadVideo } from '../../assets/icons/pages/Propmarket/my-property-create/ic_video_fill.svg';
import { ReactComponent as PriceTag } from '../../assets/icons/pages/findProertyDetail/price_tag.svg';
import { useEffect, useState } from 'react';
import { fetchUploadImage } from '../../services/service.property';
import { customRequest, getBase64FromUrl, resizeFile, toBase64 } from '../../utils/common';
import ImgCrop from 'antd-img-crop';

const MyPropertyCreateStep1 = ({
    form,
    formData,
    masterData,
    setIsPending,
    isMobile = false
}) => {
    const [catId, setCatId] = useState(formData.places_type || null);
    const [propertyType, setPropertyType] = useState(formData.property_type || null);
    const [countHeaderAds, setCountHeaderAds] = useState(0);
    const [countMoreInformation, setCountMoreInformation] = useState(0);

    useEffect(() => {
        setCatId(formData.places_type);
        return () => {}
    },[formData.places_type]);

    const onChangeHeaderAds = (event) => {
        setCountHeaderAds(`${event?.target?.value || ""}`.length);
    }
    const onChangeMoreInformation = (event) => {
        setCountMoreInformation(`${event?.target?.value || ""}`.length);
    }

    useEffect(() => {
        setCountHeaderAds(`${form.getFieldValue("header_ads") || ""}`.length);
        setCountMoreInformation(`${form.getFieldValue("more_information") || ""}`.length);
        setCountHeaderAds(`${form.getFieldValue("header_ads") || formData.advertisement || ""}`.length);
        setCountMoreInformation(`${form.getFieldValue("more_information") || formData.description || ""}`.length);
    }, [form, formData.advertisement, formData.description])

    const [visiblePreview, setVisiblePreview] = useState(false);
    const [imagePreview, setImagePreview] = useState("");
    const [maxPhotos] = useState(30);
    const [maxVideos] = useState(1);

    const onPreviewPhoto = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        setImagePreview(src);
        setVisiblePreview(true);
    };

    const uploadButtonPhotos = (
        <div className={styles["gw-btn-upload"]}>
            <IcUploadPhoto />
            <div className={styles["gw-btn-upload-text"]}>
                <span>Upload photo</span>
            </div>
        </div>
    );

    const CustomUpload = ({ fileList = [], onChange}) => {
        const onRemovePhoto = file => {
            const updatedFileList = fileList.filter(item => item.uid !== file.uid);
            onChange(updatedFileList); // Call the onChange function with the updated fileList
        };

        const customRequest2 = ({ file, onSuccess }) => {
            setIsPending(true);
            if (file) {
                setIsPending(true);
                resizeFile(file).then(img => {
                    setIsPending(true);
                    fetchUploadImage(img).then(function (responeImage) {
                        file["fileUpload"] = responeImage?.data?.data || {};
                        const fileListSave = ([...fileList, {
                            originFileObj: file,
                            ...file
                        }]);
                        onChange(fileListSave); // Call the onChange function with the updated fileList
                    }).finally(() => {
                        setIsPending(false);
                    })
                }).finally(() => {
                    setIsPending(false);
                })
            } else {
                setIsPending(false);
            }
        }
        
        return (
            <ImgCrop aspect={3 / 2} rotate modalTransitionName="none" modalMaskTransitionName="none">
                <Upload
                    action={false}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={onPreviewPhoto}
                    customRequest={customRequest2}
                    accept={"image/png, image/gif, image/jpeg"}
                    onRemove={onRemovePhoto}
                >
                    {fileList.length < maxPhotos && uploadButtonPhotos}
                </Upload>
            </ImgCrop>
        )
    };
    
    return (
        <Row gutter={[24, 35]}>
            <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                <div className={styles["gw-block-container"]}>
                    <label className={styles["gw-text-title"]}>
                        <span>Property Type</span>
                    </label>
                    <Row gutter={[16, 16]} className={`gw-mt-24`}>
                        <Col span={24}>
                            <Form.Item
                                className={`${styles["gw-form-selector"]}`}
                                name="country"
                                rules={[{ required: true, message: 'Please select!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="ประเทศ"
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    suffixIcon={<IcExpandMore />}
                                >
                                    {
                                        masterData.master_country.map(function (item) {
                                            return <Select.Option 
                                                key={item?.country_id} 
                                                value={item?.country_id}>{item?.country_name || ""}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className={`${styles["gw-form-selector"]}`}
                                name="property_type"
                                rules={[{ required: true, message: 'Please select!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="ประเภท"
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    suffixIcon={<IcExpandMore />}
                                    onChange={(event) => {
                                        setPropertyType(event);
                                    }}
                                >
                                    <Select.Option value={`sale`}><span>ขาย</span></Select.Option>
                                    <Select.Option value={`rent`}><span>เช่า</span></Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className={`${styles["gw-form-selector"]}`}
                                name="places_type"
                                rules={[{ required: true, message: 'Please select!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="ประเภทอสังหา"
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    suffixIcon={<IcExpandMore />}
                                    onChange={(event) => {
                                        setCatId(event);
                                    }}
                                >
                                    {
                                        masterData.property_category.map(function (item) {
                                            return <Select.Option 
                                                key={item?.cate_id} 
                                                value={item?.cate_id}>{item?.cate_name || ""}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                className={`${styles["gw-form-input"]}`}
                                name="sale_price"
                                rules={[{ required: true, message: 'Please input!' }]}
                            >
                                <InputNumber
                                    prefix={<PriceTag />}
                                    controls={false}
                                    placeholder={propertyType === 'rent' ? 'ราคาเช่า' : 'ราคาขาย'}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={`${styles["gw-form-selector"]}`}
                                name="sale_price_unit"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    placeholder="หน่วย"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    suffixIcon={<IcExpandMore />}
                                >
                                    <Select.Option value="THB">THB</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Row justify='space-between' align='bottom'>
                                <Col>
                                    <label className={`${styles["gw-text-title"]}`}>
                                        <span>Header Ads</span>
                                    </label>
                                </Col>
                                <Col>
                                    <label className={`${styles["gw-text-sub-title"]}`}>
                                        <span>{`${countHeaderAds}/2000 อักษร`}</span>
                                    </label>
                                </Col>
                            </Row>
                            <Form.Item
                                className={`gw-mt-8 ${styles["gw-form-textarea"]}`}
                                name="header_ads"
                                rules={[{ required: true, message: 'Please input!' }]}
                            >
                                <Input.TextArea placeholder='ข้อความไม่เกิน 2000 อักษร' maxLength={2000} style={{ height: 120 }} onChange={onChangeHeaderAds} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <label className={`${styles["gw-text-title"]}`}>
                                <span>Add Photos</span>
                            </label>
                            <Image
                                width={10}
                                style={{ display: 'none' }}
                                src={imagePreview}
                                preview={{
                                    visible: visiblePreview,
                                    src: imagePreview,
                                    onVisibleChange: value => {
                                        setVisiblePreview(value);
                                    },
                                }}
                            />
                            <Form.Item
                                className={`gw-mt-8 ${styles["gw-form-upload"]}`}
                                name="upload_photos"
                                // getValueFromEvent={getFileListPhoto}
                                valuePropName="fileList"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            console.log(`🌦️ ~ validator ~ value:`, value);
                                            if (value.length < 1) {
                                                return Promise.reject('Please upload more than 1 images!');
                                            } else if (value.length > 30) {
                                                return Promise.reject('Upload upto 30 images!');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <CustomUpload />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Row justify='space-between' align='bottom'>
                                <Col>
                                    <label className={`${styles["gw-text-title"]}`}>
                                        <span>More Information</span>
                                    </label>
                                </Col>
                                <Col>
                                    <label className={`${styles["gw-text-sub-title"]}`}>
                                        <span>{`${countMoreInformation}/5000 อักษร`}</span>
                                    </label>
                                </Col>
                            </Row>
                            <Form.Item
                                className={`gw-mt-8 ${styles["gw-form-textarea"]}`}
                                name="more_information"
                                rules={[{ required: true, message: 'Please input!' }]}
                            >
                                <Input.TextArea placeholder='ข้อความไม่เกิน  5000 อักษร' maxLength={5000} style={{ height: 120 }} onChange={onChangeMoreInformation} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                <div className={styles["gw-block-container"]}>
                    <label className={styles["gw-text-title"]}>
                        <span>ข้อมูลคำถาม</span>
                    </label>
                    <Row gutter={[16, 16]}>
                        <Col span={24} className={styles["gw-form-list"]}>
                            <Form.List name="questionnaires">
                                {
                                    (questionnaires) => {
                                        let index_no = 0;
                                        // eslint-disable-next-line array-callback-return
                                        return questionnaires.map((field, index) => {
                                            const dataItem = formData?.questionnaires[index] || {};
                                            if (![...dataItem.cate_ids || []].includes(catId)) {
                                                return "";
                                            }
                                            index_no++;
                                            switch (dataItem.type) {
                                                case "INPUT_TEXT":
                                                    return (
                                                        <Row key={field.key} gutter={[16, 16]} className={`gw-mt-16`} justify={'center'} align={"middle"}>
                                                            <Col span={12} className={styles["gw-text-topic"]}>
                                                                <span>{`${index_no}. ${dataItem["label"]}`}</span>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    className={`${styles["gw-form-input"]}`}
                                                                    name={[index, "value_text"]}
                                                                    rules={[
                                                                        ({ getFieldValue }) => ({
                                                                            validator(_, value) {
                                                                                if (value && !/^\d+$/.test(value)) {
                                                                                    return Promise.reject("Please input number only!");
                                                                                } else if (!value) {
                                                                                    return Promise.reject("Please input!");
                                                                                }
                                                                                return Promise.resolve();
                                                                            },
                                                                        }),
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        controls={false}
                                                                        placeholder={dataItem["placeholder"]}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    );
                                                case "INPUT_TEXT_UNIT":
                                                    return (
                                                        <Row key={field.key} gutter={[16, 16]} className={`gw-mt-16`} justify={'center'} align={"middle"}>
                                                            <Col span={12} className={styles["gw-text-topic"]}>
                                                                <span>{`${index_no}. ${dataItem["label"]}`}</span>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    {...field}
                                                                    className={`${styles["gw-form-input"]}`}
                                                                    name={[index, "value_text"]}
                                                                    rules={[
                                                                        ({ getFieldValue }) => ({
                                                                            validator(_, value) {
                                                                                if (value && !/^\d+$/.test(value)) {
                                                                                    return Promise.reject("Please input number only!");
                                                                                }else if (!value) {
                                                                                    return Promise.reject("Please input!");
                                                                                }

                                                                                if (["size_sqm", "use_size_sqm"].some(sitem => sitem === dataItem["output_field"]) && !((Number(value) || 0) > 0)) {
                                                                                    return Promise.reject(`ตรวจสอบ${dataItem["label"]}!`);
                                                                                }
                                                                                return Promise.resolve();
                                                                            },
                                                                        }),
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        suffix={dataItem["label_unit"]}
                                                                        controls={false}
                                                                        placeholder={dataItem["placeholder"]}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    );
                                                case "SELECTOR":
                                                    return (
                                                        <Row key={field.key} gutter={[16, 16]} className={`gw-mt-16`} justify={'center'} align={"middle"}>
                                                            <Col span={12} className={styles["gw-text-topic"]}>
                                                                <span>{`${index_no}. ${dataItem["label"]}`}</span>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    className={`${styles["gw-form-selector"]}`}
                                                                    name={[index, "value_text"]}
                                                                    rules={[
                                                                        ({ getFieldValue }) => ({
                                                                            validator(_, value) {
                                                                                if (!value) {
                                                                                    return Promise.reject("Please select!");
                                                                                }
                                                                                return Promise.resolve();
                                                                            },
                                                                        }),
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        showSearch
                                                                        placeholder={dataItem["placeholder"]}
                                                                        allowClear={true}
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        suffixIcon={<IcExpandMore />}
                                                                    >
                                                                        {
                                                                            dataItem["options"].map((optionItem, optionIndex) => {
                                                                                return (
                                                                                    <Select.Option
                                                                                        key={`option_${optionIndex}`}
                                                                                        value={optionItem?.value_text}>
                                                                                        {optionItem?.label}
                                                                                    </Select.Option>
                                                                                );
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    );
                                                case "SWITCH":
                                                    return (
                                                        <Row key={field.key} gutter={[16, 16]} className={`gw-mt-16`} justify={'center'} align={"middle"}>
                                                            <Col span={12} className={styles["gw-text-topic"]}>
                                                                <span>{`${index_no}. ${dataItem["label"]}`}</span>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    className={`${styles["gw-form-switch"]}`}
                                                                    name={[index, "value_bool"]}
                                                                    valuePropName="checked"
                                                                >
                                                                    <Switch />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    );

                                                default:
                                                    return null;
                                            }
                                        })
                                    }
                                }
                            </Form.List>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    )
}

export default MyPropertyCreateStep1;