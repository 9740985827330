import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import base_url from './config';

const endpoint = {
    auth: `${base_url}/auth`,
    register: `${base_url}/auth/register`,
    logout: `${base_url}/auth/logout`,
    auth_profile: `${base_url}/auth/profile`,
    auth_refresh_token: `${base_url}/auth/refreshToken`,
    auth_get_public_token: `${base_url}/auth/getPublicToken`,
}

const fetchUserLogin = (uid = "") => {
    const url = `${endpoint.auth}/${uid}`;
    return trackPromise(axios.get(url));
}

const fetchUserRegister = (body) => {
    localStorage.removeItem("search_keyword");
    return trackPromise(axios.post(endpoint.register, body));
}

const fetchLogout = (body = {}) => {
    return trackPromise(axios.post(endpoint.logout, {
        token: getCookie(tokenFieldName)
    }))
}

const refreshAccessToken = (pbt = "") => {
    const token = getCookie(tokenFieldName);
    const tokenRf = getCookie(tokenRFFieldName);
    if (!token || !tokenRf || !pbt) return Promise.resolve("no token");
    
    let body = {};
    body["old_access_token"] = token;
    body["refresh_token"] = tokenRf;
    // return new Promise(resolve => {
    //     resolve({
    //         data: {
    //             data: {
    //                 "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIxMTI1MDU2Nzg3MDEzMjM3NzI0MzgiLCJpYXQiOjE2NjQzODA4OTcsImV4cCI6MTY2NDQ2NzI5N30.SQIurC8VRidPUp7N1xQsPK0YPgFuIsQ56EU25jIXAAw",
    //                 "expire": "2022-09-29T16:01:37.000Z",
    //                 "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIxMTI1MDU2Nzg3MDEzMjM3NzI0MzgiLCJyZWZyZXNoIjp0cnVlLCJpYXQiOjE2NjQzODA4OTcsImV4cCI6MTY2NTI0NDg5N30.NKWjYqMMOFo30KdJ8lW7a3UlgrYQqv349NXZxcPywkA",
    //             }
    //         }
    //     })
    // })
    return axios.post(endpoint.auth_refresh_token, body, {
        Authorization: `Bearer ${pbt}`
    });
}

const getPublicToken = () => {
    return axios.get(endpoint.auth_get_public_token, {
        auth: {
            username: "growestate",
            password: "growestate"
        }
    });
}

const tokenFieldName = "access_token";
const tokenRFFieldName = "refresh_token";
const tokenExpFieldName = "access_token_exp";
const tokenPBFieldName = "public_token";

function setCookie(name, value, refresh_token, c_expires) {
    // var expires = "";

    // if (c_expires) {
    //     var date = new Date(c_expires);
    //     console.log("date", date)
    //     expires = "; expires=" + date.toUTCString();
    // }
    // // expires = "; expires=" + c_expires;
    // console.log(expires)
    // document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    if (value) localStorage.setItem(tokenFieldName, value);
    if (refresh_token) localStorage.setItem(tokenRFFieldName, refresh_token);
    if (c_expires) localStorage.setItem(tokenExpFieldName, c_expires);
}

function getCookie(name) {
    // var nameEQ = name + "=";
    // var ca = document.cookie.split(';');
    // for(var i=0;i < ca.length;i++) {
    //     var c = ca[i];
    //     while (c.charAt(0)==' ') c = c.substring(1,c.length);
    //     if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    // }
    const token = localStorage.getItem(name);
    if (token) {
        return token;
    }
    return null;
}

function eraseCookie(name) {   
    // document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    localStorage.removeItem("search_keyword");
    localStorage.removeItem(tokenFieldName);
    localStorage.removeItem(tokenRFFieldName);
    localStorage.removeItem(tokenExpFieldName);
}

const fetchUserProfile = () => {
    return trackPromise(axios.get(endpoint.auth_profile));
}

function updateAccessToken(value) {
    localStorage.setItem(tokenFieldName, value);
}

const updateUserProfile = (body={}) => {
    return trackPromise(axios.put(`${endpoint.auth_profile}`, body));
}

export {
    fetchUserLogin,
    fetchUserRegister,
    fetchLogout,
    refreshAccessToken,
    tokenFieldName,
    tokenRFFieldName,
    tokenPBFieldName,
    setCookie,
    getCookie,
    eraseCookie,
    fetchUserProfile,
    updateAccessToken,
    updateUserProfile,
    getPublicToken,
}