/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState, useEffect } from "react";
import { Form, Input, Button, Drawer, Select, Slider, Radio, Checkbox, Dropdown, Space, Menu, InputNumber, Divider, Collapse } from "antd";
import { ReactComponent as IcoPinLight } from '../../assets/icons/pages/findProertyMap/filter/pin_light.svg';
import { ReactComponent as IcoExpandDownLight } from '../../assets/icons/pages/findProertyMap/filter/expand_down_light.svg';
import styles from './style.module.scss';
import $ from 'jquery';
import SimpleBar from 'simplebar-react';
import clsx from "clsx";
import { useModalContext } from "../../context/modals";
import { fetchMasterAmphures, fetchMasterProvince, fetchPropertyCategory } from "../../services/service.common";
import { useMasterDataContext } from "../../context/masterData";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchBroadcastType } from "../../services/service.broadcast";

const BroadcastFilter = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const { dataDrawer: { visibleDrawerBroadcastFilter }, action: { updateDrawerBroadcastFilter } } = useModalContext();
    const { masterData: { provinces, masterDataBoardCast }, action: { updateProvinceDatas, updateMasterDataBoardCast } } = useMasterDataContext();
    const [districts, setDistricts] = useState([]);
    const [defaultValueFilters, setDefaultValueFilters] = useState({
        loaded: false,
    });


    function formatterSlider(value) {
        let showText = "";
        if (value >= 1000000) {
            showText = `${(value / 1000000).toFixed(1)} m.`
        } else {
            showText = `${(value / 1000).toFixed(0)} k.`
        }
        return `${showText}`;
    }

    // Define the parent container for the tooltip
    const getTooltipContainer = (triggerNode) => {
        return triggerNode.parentNode;
    };

    useEffect(() => {
        async function loadData() {
            try {
                if (provinces?.length > 0) return;
                const reponse = await fetchMasterProvince();
                updateProvinceDatas(reponse?.data?.data || []);
            } catch (error) {
                console.warn(`🌦️ ~ loadData fetchMasterProvince ~ error:`, error);
            }
            try {
                if (masterDataBoardCast?.category?.length > 0) return;
                const reponse = await fetchPropertyCategory();
                updateMasterDataBoardCast({
                    category: reponse?.data?.data || []
                });
            } catch (error) {
                console.warn(`🌦️ ~ loadData fetchPropertyCategory ~ error:`, error);
            }
            try {
                if (masterDataBoardCast?.type?.length > 0) return;
                const reponse = await fetchBroadcastType();
                updateMasterDataBoardCast({
                    type: reponse?.data?.data || []
                })
            } catch (error) {
                console.warn(`🌦️ ~ loadData fetchBroadcastType ~ error:`, error);
            }
        }
        if (visibleDrawerBroadcastFilter) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateProvinceDatas, visibleDrawerBroadcastFilter]);

    const onChangeProvince = async (value) => {
        const response = await fetchMasterAmphures(value);
        setDistricts(response?.data?.data || []);
        if (defaultValueFilters.loaded) {
            form.setFieldsValue({
                amphures_id: null
            });
        }
    }

    const searchWithFilter = (values) => {
        const filters = {...values};
        const filter_type = masterDataBoardCast?.type?.find((item) => item?.broadcast_type_id === filters["property_type"])?.name;
        if (filter_type) {
            filters["filter_type"] = filter_type;
        }
        delete filters["property_type"];
        if (filters?.["price"]?.[0]) {
            filters["cost_min"] = filters?.["price"]?.[0] || "0";
        }
        if (filters?.["price"]?.[1]) {
            filters["cost_max"] = filters?.["price"]?.[1] || "0";
        }
        delete filters["price"];
        // remove filter field null or empty
        Object.keys(filters).forEach(key => {
            if (!filters[key] || (Array.isArray(filters[key]) && filters[key]?.every?.(item => !item))) delete filters[key];
        });
        navigate({
            pathname: location.pathname,
            search: `?${encodeFilter(filters)}`,
        });
        updateDrawerBroadcastFilter(false);
    }

    const encodeFilter = (objSearch = {}) => {;
        const queryString = Object.keys(objSearch).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(objSearch[key])
        }).join('&');
        return queryString;
    }

    useEffect(() => {
        const filters = decodeFilter(location, { range_field: ["price"] });
        const pv_id = `${filters.pv_id || ""}`;
        if (pv_id) {
            onChangeProvince(pv_id);
        }
        setDefaultValueFilters({
            loaded: true,
            ...filters
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Drawer
            forceRender
            className={`${styles["gw-drawer-filter"]}`}
            headerStyle={{ display: "none" }}
            bodyStyle={{ padding: "0px" }}
            push={true}
            // mask={null}
            placement="right"
            onClose={_ => updateDrawerBroadcastFilter(false)}
            closeIcon={null}
            visible={visibleDrawerBroadcastFilter}>
            <div style={{ padding: "32px 24px 46px 24px" }}>
                <div className={`${styles["gw-dw-content"]}`}>
                    <div className={`${styles["gw-dw-header"]}`}>
                        <div className={`${styles["gw-header-text"]}`}>
                            <span className={`${styles["gw-title"]}`}>Filter</span>
                            <span className={`${styles["gw-sub-title"]}`}>ค้นหาที่อยู่อาศัยที่ใช่สำหรับคุณ</span>
                        </div>
                        <div className={`${styles["gw-header-action"]}`}>
                            <Button type="link" className={`${styles["btn-back"]}`} onClick={_ => updateDrawerBroadcastFilter(false)}>
                                Back
                            </Button>
                        </div>
                    </div>
                    <div className={`${styles["gw-dw-form"]}`}>
                        {
                            defaultValueFilters.loaded && (
                                <Form
                                    form={form}
                                    name="basic"
                                    initialValues={{ ...defaultValueFilters }}
                                    onFinish={searchWithFilter}
                                    // onFinishFailed={onFilterFinishFailed}
                                    autoComplete="off"
                                >
        
                                    <Form.Item
                                        className="filter-dropdown gw-mt-16"
                                        name="cate_id"
                                        label={
                                            <label className={`${styles["field-label"]}`}>
                                                <span>ประเภทอสังหา</span>
                                            </label>
                                        }
                                        labelCol={{ span: 24 }}>
                                        <Select
                                            placeholder="เลือก..."
                                            allowClear
                                            suffixIcon={<IcoExpandDownLight />}
                                        >
                                            {
                                                masterDataBoardCast.category.map(function (item) {
                                                    return <Select.Option
                                                        key={`${item?.cate_id}`}
                                                        value={`${item?.cate_id}`}>{item?.cate_name || ""}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        className="filter-dropdown gw-mt-16"
                                        name="property_type"
                                        label={
                                            <label className={`${styles["field-label"]}`}>
                                                <span>Property Type</span>
                                            </label>
                                        }
                                        labelCol={{ span: 24 }}>
                                        <Select
                                            placeholder="เลือก..."
                                            allowClear
                                            suffixIcon={<IcoExpandDownLight />}
                                        >
                                            {
                                                masterDataBoardCast.type.map(function (item) {
                                                    return <Select.Option
                                                        key={`${item?.broadcast_type_id}`}
                                                        value={`${item?.broadcast_type_id}`}>{item?.name || ""}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        className={clsx(styles["gw-form-input-range"], "gw-mt-16")}
                                        name="price"
                                        label={
                                            <label className={`${styles["field-label"]}`}>
                                                <span>Price (THB)</span>
                                            </label>
                                        }
                                        labelCol={{ span: 24 }}>
                                        <Slider range step={100} min={0} max={2000000} tipFormatter={formatterSlider}
                                            tooltipVisible tooltipPlacement="bottom" tooltipPrefixCls="gw-tooltip ant-tooltip"
                                            getTooltipPopupContainer={getTooltipContainer} />
                                    </Form.Item>
                                    <Form.Item
                                        className="filter-dropdown gw-mt-16"
                                        name="pv_id"
                                        label={
                                            <label className={`${styles["field-label"]}`}>
                                                <span>จังหวัด</span>
                                            </label>
                                        }
                                        labelCol={{ span: 24 }}>
                                        <Select
                                            placeholder="เลือก..."
                                            allowClear
                                            suffixIcon={<IcoExpandDownLight />}
                                            onChange={onChangeProvince}
                                            showSearch
                                            filterOption={(input, option) =>
                                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={provinces.map(item => ({
                                              value: `${item.id}`,
                                              label: item.pv_name,
                                            }))}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className="filter-dropdown gw-mt-16"
                                        name="amphures_id"
                                        label={
                                            <label className={`${styles["field-label"]}`}>
                                                <span>อำเภอ</span>
                                            </label>
                                        }
                                        labelCol={{ span: 24 }}>
                                        <Select
                                            placeholder="เลือก..."
                                            allowClear
                                            suffixIcon={<IcoExpandDownLight />}
                                            showSearch
                                            filterOption={(input, option) =>
                                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={districts.map(item => ({
                                              value: `${item.id}`,
                                              label: item.am_name,
                                            }))}
                                        />
                                    </Form.Item>
        
        
                                    <Form.Item className={`${styles["gw-action"]}`}>
                                        <Button className={`${styles["gw-btn-clear"]}`} onClick={() => {
                                            form.setFieldsValue({
                                                cate_id: null,
                                                property_type: null,
                                                price: [null, null],
                                                pv_id: null,
                                                amphures_id: null,
                                            });
                                            form.submit();
                                        }}>
                                            <div>
                                                <span>Clear</span>
                                            </div>
                                        </Button>
                                        <Button className={`${styles["gw-btn-confirm"]}`} htmlType="submit">
                                            <div>
                                                <span>Confirm</span>
                                            </div>
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )
                        }
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default BroadcastFilter;


export const decodeFilter = (location = {}, field_custom = {
    range_field: []
}) => {
    let filterSearchObj = {};
    if (location.search) {
        const search_str = `${location.search}`.substring(1)
        filterSearchObj = JSON.parse('{"'
            + search_str.replace(/&/g, '","').replace(/=/g, '":"')
            + '"}', function (key, value) {
                return key === "" ? value : decodeURIComponent(value)
            });
        field_custom.range_field.forEach(key => {
            if (filterSearchObj[key]) {
                filterSearchObj[key] = filterSearchObj[key].split(",");
            }
        });
    }
    return filterSearchObj;
}