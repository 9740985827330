import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import base_url from './config';

const endpoint = {
    broadcast: `${base_url}/broadcast`,
    broadcast_by_bc_id: `${base_url}/broadcast/{bc_id}`,
    broadcast_broadcast_maping: `${base_url}/broadcast/broadcast-maping`,
    broadcast_show: `${base_url}/broadcast/broadcast-show/{bm_id}`,
    broadcast_type: `${base_url}/broadcast-type`,
}

const fetchBroadcastMaping = (params, cancelTokenSource) => {
    return trackPromise(axios.get(endpoint.broadcast_broadcast_maping,{
        params: { ...params },
        cancelToken: cancelTokenSource?.token
    }));
}

const createBroadcast = (body = {}) => {
    return trackPromise(axios.post(endpoint.broadcast, body));
}

const fetchBroadcast = (params, cancelTokenSource) => {
    return trackPromise(axios.get(endpoint.broadcast, {
        params: { ...params },
        cancelToken: cancelTokenSource?.token
    }));
}

const fetchBroadcastByBcId = (bc_id = "") => {
    const url = endpoint.broadcast_by_bc_id.replace(`{bc_id}`, bc_id)
    return trackPromise(axios.get(url));
}

const deleteBroadcastByBcId = (bc_id = "") => {
    const url = endpoint.broadcast_by_bc_id.replace(`{bc_id}`, bc_id)
    return trackPromise(axios.delete(url));
}

const patchBroadcastShow = (bm_id = "") => {
    const url = endpoint.broadcast_show.replace(`{bm_id}`, bm_id)
    return trackPromise(axios.patch(url, {
        "is_showing": false
    }));
}

const fetchBroadcastType = () => {
    return trackPromise(axios.get(endpoint.broadcast_type));
}

export {
    fetchBroadcastMaping,
    createBroadcast,
    fetchBroadcast,
    fetchBroadcastByBcId,
    deleteBroadcastByBcId,
    patchBroadcastShow,
    fetchBroadcastType,
}